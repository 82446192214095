import React, { memo, useState } from 'react';
import { TouchableOpacity, Text, View, KeyboardAvoidingView, Platform, Image } from 'react-native';
import Background from '../components/Background';
import Logo from '../assets/logo.svg';
import Header from '../components/Header';
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import BackButton from '../components/BackButton';
import { theme } from '../core/theme';
import { styles } from '../styles/LoginScreenStyle'
import { emailValidator, passwordValidator } from '../core/utils';
import { useFonts } from 'expo-font';
import { AppLoading } from 'expo';
import { ScrollView } from 'react-native';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';




export default function LoginScreen({ route, navigation }) {
  

  
  async function save(key, value) {
    console.log("Saving " + JSON.stringify(value))
    await AsyncStorage.setItem(key, JSON.stringify(value));
  }
  
  async function getValueFor(key) {
    let result = await AsyncStorage.getItem(key);
    if (result) {
      return result;
    } else {
      return null;
    }
  }
  
 

  
  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [data, setData] = useState([]);
  const [inputText, setInputText] = useState('');
  const [text, setText] = useState('');

  

  const _onLoginPressed = () => {
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);

    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }
    
    //http://outpostorganizer.com/SITE/api.php/records/SmallGroups?camp=wartburg
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({"command": "ValidateLogin",
      "email": email.value,
      "pass": password.value})
   })
   .then((response) => response.json())
   .then((responseJson) => {
         //
         setData(responseJson.records);
         if(responseJson.code==1)
         {
            save("user", (responseJson.records))
            getConfig(responseJson.records)
          }
          else
          {
            console.log(JSON.stringify(responseJson))
            //
            setEmail({ ...email, error: "Invalid Username or Password" });
            setPassword({ ...password, error: "Invalid Username or Password" });
            return;
          }
          }
          
   )
         
      
      .catch((error) => {
         console.error(error);
      });
      
      function getConfig(u)
      {
        fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
          method: 'POST',
          body: JSON.stringify({"command": "GetConfig",
          "home": u.Home,
          "user": u.UID})
       })
       .then((response) => response.json())
       .then((responseJson) => {
             if(responseJson.code==1)
             {
              var newConfig = responseJson.records
            var newTools = responseJson.activities
            var newAdditionalTools = responseJson.additionalActivities
            if(newTools!=null && newTools.length>0)
            {

              newConfig.tools = responseJson.records.tools.concat(newTools.split(",")).map((item) => parseInt(item))
            }
            if(newAdditionalTools!=null && newAdditionalTools.length>0)
            {
            newConfig.additionalTools = responseJson.records.additionalTools.concat(newAdditionalTools)
            newConfig.additionalTools = newConfig.additionalTools.filter((item) => item != null && item != "").map((item) => parseInt(item))
            }
            newConfig.permissions = responseJson.permissions
            console.log(newConfig)
            save("config", newConfig)
            save("configHash", responseJson.hash)
                navigation.navigate('Dashboard', {user: u, config: newConfig} );
            }
            console.log(responseJson)
              }
              
       ).finally(() => {
       })

      }

 // 
  };



  return (

  <View style={[styles.container]}>
    <View style={[styles.fixed]}>
      <Background />
    </View>
    <ScrollView style={[styles.fixed, {backgroundColor: 'transparent'}]}>
    <View style={styles.container}><BackButton goBack={() => navigation.navigate('HomeScreen')} />

<Image source={require("../assets/icon-transparent.png")} style={{width: 100, height: 100, marginBottom: 40}}/>
<View style={styles.loginBox}>
<TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Email</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor + "65", borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
  returnKeyType="next"
  value={email.value}
  onChangeText={text => setEmail({ value: text, error: '' })}
  error={!!email.error}
  errorText={email.error}
  autoCapitalize="none"
  autoCompleteType="email"
  textContentType="emailAddress"
  keyboardType="email-address"
  underlineColorAndroid="transparent"
/>

<TextInput 
 label={<Text style={{color: theme.colors.itemTextColor}}>Password</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor + "65", borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
  returnKeyType="done"
  value={password.value}
  onChangeText={text => setPassword({ value: text, error: '' })}
  error={!!password.error}
  errorText={password.error}
  
  secureTextEntry
/>
<View>
<View style={styles.forgotPassword}>
  <TouchableOpacity
    onPress={() => navigation.navigate('ForgotPasswordScreen')}
  >
    <Text style={styles.label}>Forgot your password?</Text>
  </TouchableOpacity>
  <Button style={styles.loginButton} mode="contained" onPress={_onLoginPressed}>
  <Text style={styles.loginButtonText} >Log In</Text>
</Button>

</View>

</View><View style={styles.row}>
  <Text style={styles.label}>Don’t have an account? </Text>
  <TouchableOpacity onPress={() => navigation.navigate('RegisterScreen')}>
    <Text style={styles.link}>Sign up</Text>
  </TouchableOpacity>
</View>
</View>

</View>
</ScrollView>
</View>



   
  );

};

