import React, { memo, useState, useEffect } from 'react';
import Background from '../components/Background';
import Logo from '../assets/logo.svg';

import Header from '../components/Header';
import Paragraph from '../components/Paragraph';
import Button from '../components/Button';
import { useFonts } from 'expo-font';
import { AppLoading } from 'expo';
import { theme } from '../core/theme';
import { styles } from '../styles/ScoreboardStyle-old';
import { Text, View } from 'react-native';
import GraphBar from '../components/GraphBar';
import { ScrollView } from 'react-native';



export default function Scoreboard({ route, navigation }) {

  var user = route.params.user;
var config = route.params.config;
  
  const [data, setData] = useState(null);

  const [graphs, setGraphs] = useState(null);

  const [maxVal, setMaxVal] = useState(0);


    
//
useEffect(() => {
  fetch('http://outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({"command": "GetScores",
      "user": user.UID,
      "camp": user.Home,
      })
   })
.then((response) => response.json())
.then((responseJson) => {
  
  setData(responseJson.records);
  
} ).catch((exception) => {
  
})
}, []);


 useEffect(() => {
  console.log("data: " + data)
  if(data!=null)
  {
    console.log(data)
    setGraphs(data.map(function(item, index) {
      return (<GraphBar key={item.id} place={index+1} color={theme.colors.medBlue} maxValue={data[0].tempPoints} value={item.tempPoints} group={item.Name}/>);
    }))
  }
 }, [data])



  return (
    <View style={styles.container}>
    <View style={styles.scoreboardBox}>
    <ScrollView style={{width: "100%"}}>
  {graphs}
    </ScrollView>


    </View>

    </View>
   ) 
};

