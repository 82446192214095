import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/Settings2Style';
import { Dimensions, Image, Text, Touchable, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {Ionicons, Entypo} from '@expo/vector-icons'
import TextInput from '../components/TextInput';
import Avatar from '../components/Avatar';
import * as ImagePicker from 'expo-image-picker'
import StatusOverlay from '../components/StatusOverlay'
import ConfettiCannon from 'react-native-confetti-cannon';
import { navTo } from '../core/utils';


async function uploadImageAsync(uri) {
  let apiUrl = 'https://outpostorganizer.com/SITE/v2/API.php';



  let uriParts = uri.split('.');
  let fileType = uriParts[uriParts.length - 1];

  let formData = new FormData();
  formData.append('photo', {
    uri,
    name: `photo.${fileType}`,
    type: `image/${fileType}`,
  });

  let options = {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  return fetch(apiUrl, options);
}

export default function SecretSanta({ route, navigation })  {

    const [image, setImage] = useState(null);
  
    const pickImage = async () => {
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        base64: true,
        allowsEditing: true,
        aspect: [3, 3],
        quality: 1,
      });
  
  
        setImage(result);
      
    };
  
  var user = route.params.user;
var config = route.params.config;

  
  const [loading, setLoading] = React.useState(false);
  const [confetti, setConfetti] = React.useState(null)
  const [ssStatus, setSSStatus] = React.useState(user.ssIsParticipating==0 ? false : true);
  const [ssAssigned, setSSAssigned] = React.useState("");
  const [giftSuggestion, setGiftSuggestion] = React.useState("");
  const [assignedGiftSuggestion, setSSAGiftSuggestion] = React.useState("")
  const [giftError, setGiftError] = React.useState(false);
  const [img, setIMG] = React.useState(null)
  var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];



  useEffect(() => {
      setLoading(true)
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetSSData",
        "camp": user.Home,
        "user": user.UID
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      console.log(responseJson)
      setSSStatus(responseJson.code==1 ? true : false)
      setSSAssigned(responseJson.AssignedScreenName)
      setGiftSuggestion(responseJson.UserGiftSuggestions)
      setSSAGiftSuggestion(responseJson.AssignedGiftSuggestions)
      setIMG(responseJson.imgURL)
      setLoading(false);

   });
  }, [])
  
  function unassignSS() {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UnassignSS",
        "camp": user.Home,
        "user": user.UID
      })
    })
    .then((response) => {
    return(response.json())
    })
    .then((responseJson) => {
    }).finally(() => setLoading(false));
  }

  function ssComplete()
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SSComplete",
        "camp": user.Home,
        "user": user.UID
      })
    })
    .then((response) => {
    return(response.json())
    })
    .then((responseJson) => {
      setConfetti(<ConfettiCannon count={100} colors={["#E1E8F7", "#f00", "#0f0"]} origin={{x: Dimensions.get("screen").width/2, y: 0}} />)
    }).finally(() => setLoading(false));
  }
      
  function assignSS()
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "AssignSS",
        "camp": user.Home,
        "user": user.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(user.screenName=="Plank")
    {
      setParticipants(responseJson.participants)
    }
   }).finally(() => setLoading(false));
  }

  function validateConfig(configItem)
  {
    if(configItem==null)
    {
      return true;
    }
    return config[configItem];
  }

  function toggleStatus()
  {
    setSSStatus(!ssStatus)
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "SetSSStatus",
      "camp": user.Home,
      "user": user.UID,
      "status": !ssStatus
    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
    console.log(responseJson)
    setLoading(false);

 });

  }

  function getAssignedText()
  {
    if(ssStatus)
    {
      if(ssAssigned=="" || ssAssigned===null || ssAssigned==undefined)
      {
        return "You have not been assigned anyone yet.";
      }
      else
      {
        return  "You have been assigned: " + ssAssigned;
      }
    }
    else
    {
      return "";
    }
    
  }


  
function updateGiftSuggestion() {
  if(giftSuggestion=="" || giftSuggestion==null)
  {
    setGiftError(true)
  }
  else
  {
  setGiftError(false)
  setLoading(true)
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "SSUpdateGiftSuggestion",
      "camp": user.Home,
      "user": user.UID,
      "suggestion": giftSuggestion
  })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
    console.log(responseJson)
    setLoading(false)
 });
  }


}

   
  return (
    
    
    <View style={styles.container}>
      {loading ? <StatusOverlay config={config}></StatusOverlay> : null}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
          </View>
      <Button onP={() => {navTo("Dashboard", user, config, null, navigation)}} style={styles.backButton} mode="outlined">
      <Ionicons name="return-down-back" size={24} color={theme.colors.itemTextColor}></Ionicons>
        </Button>
    
    <View style={[styles.content, {backgroundColor: themeColor, borderColor: themeColor}]}>
        
    <ScrollView style={{width: "90%"}}
   contentContainerStyle={{width: '100%'}}>
          <View><Text style={styles.title}>Secret Santa</Text>
                {confetti}
                {user.ssIsParticipating==1 ?  <Button style={[{backgroundColor: theme.colors.greenButton, borderColor: "#000", borderWidth: 2}]} onP={() => {ssComplete()}}><Text style={{color: "#000"}}>Mark as Complete</Text></Button>
      : null}<Text style={[styles.title, {fontSize: 16}]}>Currently Opted {ssStatus ? "In!" : "Out!"}</Text>
          <Button disabled={ssAssigned!=undefined} onP={() => {toggleStatus()}} style={{width: "80%", alignSelf: "center", backgroundColor: ssStatus ? theme.colors.redButton : theme.colors.greenButton}}><Text style={{color: "#000"}}>{ssStatus ? "Opt Out" : "Opt In"}</Text></Button>
          <View style={ssStatus==false ? {display: "none"} : {}}>
          <TextInput
            label="Gift Suggestions"
            multiline={true}
            value={giftSuggestion}
            errorText={giftError ? "Error.  Gift Suggestions is blank." : ""}
            onChangeText={text => {setGiftSuggestion(text)}}
            style={{width: "100%", borderColor: theme.colors.itemTextColor, borderWidth: 1}}/>
          <Button onP={() => {updateGiftSuggestion()}} style={{width: "80%", alignSelf: "center", backgroundColor: theme.colors.greenButton}}><Text style={{color: "#000"}}>Update Gift Suggestions</Text></Button>
          </View>
          <Text style={styles.subTitle}>{getAssignedText()}</Text>
          <Avatar logoStyle={{borderRadius: 200, borderWidth: 1}} user={{profilePicURL: img}}></Avatar>
          <View style={ssAssigned=="" || ssAssigned==undefined || ssAssigned==null? {display: "none"} : {}}>
          <Text style={assignedGiftSuggestion=="" ? {display:"none"} : styles.subTitle}>Gift Suggestions:</Text>        
          <Text style={styles.giftIdeas}>{assignedGiftSuggestion!="" ? assignedGiftSuggestion : ssAssigned + " has not put in gift ideas yet.\nOnce they do, they will appear here."}</Text>
          </View>
              </View>  
    </ScrollView>
      </View>
      {confetti}
      </View>



    
    </View>
   ) };
