import { Dimensions, StyleSheet } from 'react-native'
import { theme } from '../core/theme'

export const styles = StyleSheet.create({
    
    fixed: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0   
    },
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
        backgroundColor: theme.colors.listItemBackground
    },
    logoutButton: {
        position: "absolute",
        top: 0,
        width: 20,
        left: 20,
        zIndex: 100,
        borderRadius: 50
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "stretch",
    },

    navDrawer: {
      display: "flex",
      flexDirection: "column",
      flex: 0.1,
      alignItems: "stretch"
    },
    content: {
        borderWidth: 4,
        flexDirection: "column",
        justifyContent: "flex-start",   
        alignItems: "center",    
        paddingTop: 50, 
        flex: 0.9,
        display: "flex"

    },
    button: {
      position: "absolute",
      bottom: 60,
      width: 20,
      right: 20,
      zIndex: 100,
      borderRadius: 50,
      backgroundColor: theme.colors.listItemBackground,
      borderColor: "#000",
      borderWidth: 2
  },
  
  leaderboardButton: {
    position: "absolute",
    top: 60,
    width: 20,
    right: 20,
    zIndex: 500,
    borderRadius: 50,
    backgroundColor: theme.colors.listItemBackground,
    borderColor: "#000",
    borderWidth: 2
},

backButton: {
  position: "absolute",
  top: 0,
  width: 30,
  left: 20,
  zIndex: 100,
  borderRadius: 50,
  backgroundColor: theme.colors.listItemBackground,
  borderColor: "#000",
  borderWidth: 1
},AddButton: {
  position: "absolute",
  bottom: 100,
  width: 30,
  right: 40,
  zIndex: 100,
  borderRadius: 50,
  backgroundColor: theme.colors.listItemBackground,
  borderColor: "#000",
  borderWidth: 1
},
    
  });