import { memo } from 'react';
import { TouchableOpacity, Dimensions, StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import * as React from 'react';
import CheckedBox from '../assets/checkedBox.svg';
import UncheckedBox from '../assets/uncheckedBox.svg';
import {MaterialIcons} from '@expo/vector-icons';
import { ScrollView } from 'react-native';
import TodayItem from './TodayItem';
import Button from './Button';


const SpotTask = ({  SID, mark, title, style, children, ...props }) => {


const styles = StyleSheet.create({
  text: {
    fontSize: 22,
    lineHeight: 26,
    color: theme.colors.darkBlue,
    textAlign: 'right',
    
    flex: 1,
    textAlignVertical: "bottom"
    
  },
  header: {
    fontSize: 24,
    color: theme.colors.itemTextColor,
    lineHeight: 24,
    textAlign: 'left',
    marginLeft: 20,
    
        
    flex: 1,
    textAlignVertical: "bottom"
  },
  container:
  {
    backgroundColor: theme.colors.listItemBackground,
    marginBottom: 14,
    borderRadius: 14,
    width: '90%',
    flex: 2,
    flexDirection: 'column',
    textAlign: 'center',
    
  },
  TOContainer:
  {
    borderRadius: 14,
    width: '100%',
    flex: 2,
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: 25,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  subContainer:
  {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    marginBottom: 10,
    flex: 4,
  },
  
});


return (
  <View style={styles.container}>
 
  <TouchableOpacity onPress={() => {
    mark(title, SID)
  }} activeOpacity={0.2} style={styles.TOContainer} >
    <View style={styles.subContainer}>
    <MaterialIcons color={theme.colors.itemTextColor} name='highlight-remove' size={32} style={{position: "absolute", left: -20, top: -7}} />
      <Text style={styles.header}>{title}</Text>
      </View>
  </TouchableOpacity>
  </View>
   );
};
export default memo(SpotTask);
