import React, { memo, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/SignUpStyle';
import { Dimensions, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import TextInput from '../components/TextInput';
import StatusBar from '../components/StatusOverlay';
import SearchableDropdown from 'react-native-searchable-dropdown';
import { Picker } from '@react-native-picker/picker';
import { navTo } from '../core/utils';



export default function SignUp({ route, navigation })  {
  
  function openDetails()
  {
    console.log(event.attachedSID)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetSingleItem",
        "camp": user.Home,
        "SID": event.attachedSID,
        "user": user.UID,
      })
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        navigation.navigate('Details', {spot: responseJson.records, id: 0, user: user, config: config, confetti: () => {}, onBack: () => {navigation.navigate("Calendar", {user: user, config: config})}})
      }
      )
  }


  var user = route.params.user;
var config = route.params.config;
  var event = route.params.event;
  var refreshBack = route.params.refresh;
  var refreshBack2 = route.params.refresh2;
  var workerList = event.Workers.split("&");
  var pageID = route.params.id;  
  var index = config!=null ? config.tools.indexOf(pageID) : 0;
  var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];
  var listThemeColor = config.theme.length>index ? config.theme[config!=null ? config.tools.indexOf(0) : 0] : theme.colors.activities[config!=null ? config.tools.indexOf(0) : 0];
    

  console.log(event)

  if(workerList.indexOf(user.screenName) == -1)
  {
    console.log("not in list")
    workerList.pop();
    workerList.push(user.screenName + "& ")
  }
  else
  {
    console.log("In List")
  }
  const [details, setDetails] = React.useState(event.Details)
  const [workers, setWorkers] = React.useState(workerList.join(", ").slice(0, -2))
  const [loading, setLoading] = React.useState(false);

  const[category, setCategory] = React.useState(null)
  const[selectedDefault, setSelectedDefault] = React.useState(-1)
  const[categoryFlag, setCategoryFlag] = React.useState(false)


  function formatWorkers()
  {
    var formattedWorkers = workers.split(", ").join("&");
    if(formattedWorkers.charAt(formattedWorkers.length-1)!="&" && formattedWorkers.length>0)
    {
      formattedWorkers = formattedWorkers + "&"
    }
    return formattedWorkers
  }


  function submit() {
    console.log(selectedDefault)
    if(selectedDefault==-1)
    {
      setLoading(false)
      setCategoryFlag(true)
      return;
    }
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "UpdateCalendar",
        "EID": event.EID,
        "camp": user.Home,
        "user": user.UID,
        "workers": formatWorkers(),
        "details": selectedDefault==99999 && !(category.DefaultValues==null) ? details : category.DefaultValues.split("&").find((dv, index) => index==selectedDefault)

        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    console.log(responseJson)
    setLoading(false)
    refreshBack();
    refreshBack2();
    navTo("back");
  }).catch((error) =>
  {
    setLoading(false);
  })

  }
  
  
  

  useEffect(() => {
    if(event.CID!=-1)
    {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetCategoryInfo",
        "camp": user.Home,
        "CID": event.CID,
        "user": user.UID,
      })
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        setCategory(responseJson.records)
      }
      )
    }
  }, []);
  
  
  return (
    
    
    <View style={styles.container}>
      {loading ? <StatusBar config={config}></StatusBar> : null}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer isSubPage={true} customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
            
            navTo(navName, user, config, null, navigation)
          
          }} /></View>

    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
        
 <TextInput
  label="Title"
  returnKeyType="next"
  value={event.Title + " | " + event.Date}
  editable={false}
  onChangeText={text => console.log(text)}
  style={{width: "90%", borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/>    
  
<TextInput
  label="Volunteers"
  returnKeyType="next"
  value={workers}
  placeholder={"Matthew, Mark, Luke, "}
  editable={config.permissions.CanModifyVolunteers==1}
  onChangeText={w => setWorkers(w)}
  style={{width: "90%", borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/>    
<Picker
  style={{backgroundColor: "#eee", width: "100%", borderWidth: 2, borderColor: "#000"}}
  selectedValue={selectedDefault}
  onValueChange={(itemValue, itemIndex) =>
    {
    console.log(itemValue)
    console.log(category.DefaultValues!=null && itemValue!=99999 ? category.DefaultValues.split("&").find((dv, index) => index==itemValue) : "Error")
    
    setSelectedDefault(itemValue)
    }
  }>
                <Picker.Item label={"Select an option!"} value={-1} />
    {category==null || category.DefaultValues==null ? null : category.DefaultValues.split("&").map((dv,index) => {
      if(dv!=null && dv!="")
      {
              return (<Picker.Item label={dv} value={index} />)
      }
            })}
            <Picker.Item label={"Other"} value={99999} />
</Picker>
{categoryFlag ? <Text style={{color: theme.colors.redButton}}>Required.</Text> : null}
{selectedDefault==99999 || details.length>0 ? <TextInput
  label="Details"
  returnKeyType="next"
  value={details}
  placeholder={"Put Details Here!"}
  multiline={true}
  onChangeText={d => setDetails(d)}
  style={{width: "90%", height: 200, paddingTop: 0, paddingBottom: 0, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/> : null}    
<Button onP={() => {
  openDetails()}} style={event.attachedSID==null ? {display: "none"} : {backgroundColor: listThemeColor, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "90%"}}><Text style={{color: theme.colors.itemTextColor}}>View Checklist</Text></Button>
<Button onP={() => {
  setLoading(true)
  submit()
}} style={{backgroundColor: theme.colors.greenButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "40%"}}><Text style={{color: theme.colors.itemTextColor}}>Sign Up!</Text></Button>

      </View>
    
      </View>



    
    </View>
   ) };
