import { Dimensions, StyleSheet } from 'react-native'
import { theme } from '../core/theme'

export const styles = StyleSheet.create({
    
    fixed: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0   
    },
    subTitle: {
      color: theme.colors.itemTextColor,
    },
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
        backgroundColor: theme.colors.listItemBackground
    },
    logoutButton: {
        position: "absolute",
        top: 0,
        width: 20,
        left: 20,
        zIndex: 100,
        borderRadius: 50
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      marginBottom: 10,
      alignItems: "stretch",
    },

    navDrawer: {
      display: "flex",
      flexDirection: "column",
      flex: 0.1,
      alignItems: "stretch"
    },
    content: {
        borderWidth: 4,
        flexDirection: "column",
        justifyContent: "flex-start",   
        alignItems: "center",    
        paddingTop: 50, 
        flex: 0.9,
        display: "flex"

    },
    floatingButton: {
      width: 60,
      height: 60,
      position: "absolute",
      borderRadius: 50,
      bottom: "10%",
      right: "5%",
      backgroundColor: theme.colors.listItemBackground,
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
    },
    floatingButton2: {
      width: 60,
      height: 60,
      position: "absolute",
      borderRadius: 50,
      bottom: "10%",
      left: "5%",
      backgroundColor: theme.colors.listItemBackground,
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
    },
    error: {
      color: theme.colors.error
    },
    title: {
      fontSize: 22,
      alignSelf: "center",
      marginTop: 15,
      marginBottom: 15,
      color: theme.colors.itemTextColor
    },
    button: {
      width: "100%",
      borderRadius: 50,
      height: 60,
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 2,
      borderColor: "#000",
      backgroundColor: theme.colors.listItemBackground,

      
    },
    modal: {
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.colors.listItemBackground,
      zIndex: 100,
      paddingTop: 20,
      alignItems: "center"
    },
    submitButton: {
      borderWidth: 2,
      width: "80%",
      borderColor: "#000",
      backgroundColor: theme.colors.greenButton,
    },
    cancelButton: {
      borderWidth: 2,
      width: "80%",
      borderColor: "#000",
      backgroundColor: theme.colors.redButton,
    },
    label: {
      color: theme.colors.itemTextColor
    }
    
  });