import React, { memo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import Button from './Button';
import Icon from 'react-native-vector-icons/FontAwesome5';


const Announcement = ({  AID, heading, children, onP }) => {

  function on() {
    onP(AID)
  }
  
  const styles = StyleSheet.create({
    announcementText: {
      fontSize: 16,
      paddingBottom: 10,
      color: theme.colors.itemTextColor
    
    },  
    
    button: {
      width: 10,
      padding: 0,
      alignSelf: "center",
      backgroundColor: theme.colors.announcements,
    },
    announcementTextHeading: {
      fontSize: 18,
      color: theme.colors.itemTextColor
,      paddingTop: 10
    },  
    subContainer: {
      
      flexDirection: "row"
    },
    container: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "90%",
      margin: 10,
      padding: 10,
      borderRadius: 7,
      flexDirection: "column",
      backgroundColor: theme.colors.listItemBackground
    },
    textContainer: {
      flexDirection: "column",
      width: "80%"
    }
  });

return(
<View style={styles.container}>
  <View style={styles.subContainer}>
    <View style={styles.textContainer}>
    <Text style={styles.announcementTextHeading}>{heading}</Text>
    <Text style={styles.announcementText}>{children}</Text>
    </View>
    <Button onP={on} style={styles.button} mode="outlined">
      <Icon name="trash" size={25} color={theme.colors.itemTextColor} />
      </Button>
      </View>
      <View style={{width: "100%", borderBottomColor: 'black', borderBottomWidth: 1, opacity: .15}}/>
      </View>


)}


export default memo(Announcement);
