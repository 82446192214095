import React, { memo, useEffect } from 'react';
import { styles } from '../styles/InventoryAdminStyle';
import { Dimensions, Image, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { MaterialCommunityIcons, Ionicons, FontAwesome } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import Heading from '../components/Heading';
import InventoryCategory from '../components/InventoryCategory';
import TextInput from '../components/TextInput';
import { Picker } from '@react-native-picker/picker';
import Button from '../components/Button';
import ShopSizeEntry from '../components/ShopSizeEntry';
import StatusBar from '../components/StatusOverlay';
import Barcode from '../components/Barcode';
import * as ImagePicker from 'expo-image-picker'
import {manipulateAsync} from 'expo-image-manipulator';
import BackButton from '../components/BackButton'
import CartEntry from '../components/CartEntry';


var barcodeTypes = [
  "CODE128",
  "CODE39",
  "EAN",
  "ITF",
  "MSI",
  "UPC"
]

var sizes = [
  { 
    id: 1,
    name: "Baby",
    values: [
      {
        id: 1,
        value: "0-3 Months",
        abbreviatedValue: "3M"
      },
      {
        id: 2,
        value: "3-6 Months",
        abbreviatedValue: "6M"
      },
      {
        id: 3,
        value: "6-9 Months",
        abbreviatedValue: "9M"
      },
      {
        id: 4,
        value: "12 Months",
        abbreviatedValue: "12M"
      },
      {
        id: 5,
        value: "18 Months",
        abbreviatedValue: "18M"
      },
      {
        id: 6,
        value: "24 Months",
        abbreviatedValue: "24M"
      },
      
    ]
  },
  { id: 2,
    name: "Youth",
    values: [
      
      {
        id: 1,
        value: "Extra Small",
        abbreviatedValue: "XS"
      },
      {
        id: 2,
        value: "Small",
        abbreviatedValue: "S"

      },
      {
        id: 3,
        value: "Medium",
        abbreviatedValue: "M"
      },
      {
        id: 4,
        value: "Large",
        abbreviatedValue: "L"
      },
      {
        id: 5,
        value: "Extra Large",
        abbreviatedValue: "XL"
      }
    ]
  },
  {
    id: 3,
    name: "Adult",
    values: [
      {
        id: 1,
        value: "Small",
        abbreviatedValue: "S"
      },
      {
        id: 2,
        value: "Medium",
        abbreviatedValue: "M"

      },
      {
        id: 3,
        value: "Large",
        abbreviatedValue: "L"
      },
      {
        id: 4,
        value: "Extra Large",
        abbreviatedValue: "XL"
      },
      {
        id: 5,
        value: "Double Extra Large",
        abbreviatedValue: "XXL"
      },
      {
        id: 6,
        value: "Triple Extra Large",
        abbreviatedValue: "XXXL"
      }
    ]
  },
  {
    id: 4,
    name: "Other",
    values: [
      {
        id: 1,
        value: "Small",
        abbreviatedValue: "S"
      },
      {
        id: 2,
        value: "Medium",
        abbreviatedValue: "M"

      },
      {
        id: 3,
        value: "Large",
        abbreviatedValue: "L"
      }
    ]
  },
  {
    id: 5,
    name: "Specified Value"
  }
]

var sortOptions = [
  {id: 1, name: "Type"}, {id: 2, name: "Size"}
]


export default function InventoryFrontDesk({ route, navigation })  {
  var user = route.params.user;
  var config = route.params.config;
  var pageID = route.params.id!=null ? route.params.id : 0;  

  const [viewCart, setViewCart] = React.useState(false)

  const [searchText, setSearchText] = React.useState("")
  const [filterType, setFilterType] = React.useState(0)

  const [image, setImage] = React.useState(null)
  const [showImageDialog, setShowImageDialog] = React.useState(false)

  const [barcodeMenuVisibility, setBarcodeMenuVisibility] = React.useState(false)


  function submitCart()
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "ProcessCart",
      "camp": user.Home,
      "user": user.UID,
      "cart": cart
    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  console.log(responseJson)
  setCart({})
  setViewCart(false)
  setShowEditor(false)
  fetchData()
  setLoading(false)
 })
  }

  function addToCart(workingItem, sizeType, size)
  {
    var sizeObj = (sizeType.values.find((s) => s.id==size))
    var fetchedItem = (fetchItemFromInventoryWithoutIID(workingItem.itemGroupID, sizeType.id, size))
    var IID = fetchedItem.IID
      var newCart = cart
      if(cart[IID]==null || cart[IID]==undefined)
      {
        newCart[IID] = 1
        if(newCart.total==null || newCart.total==undefined)
        {
          newCart.total = parseFloat(fetchedItem.price)
        }
        else
        {
          newCart.total = parseFloat(newCart.total) + parseFloat(fetchedItem.price)
        }
      }
      else if(cart[IID]<workingItem.quantity[sizeType.id][size])
      {
        newCart[IID] = cart[IID]+1  
        if(newCart.total==null || newCart.total==undefined)
        {
          newCart.total = parseFloat(fetchedItem.price)
        }
        else
        {
          newCart.total = parseFloat(newCart.total) + parseFloat(fetchedItem.price)
        }
      }
      console.log(newCart)
    setCart(newCart)
  }

  function removeFromCart(workingItem, sizeType, size)
  {
    var sizeObj = (sizeType.values.find((s) => s.id==size))
    var fetchedItem = (fetchItemFromInventoryWithoutIID(workingItem.itemGroupID, sizeType.id, size))
    var IID = fetchedItem.IID
      var newCart = cart
      if(cart[IID]==null || cart[IID]==undefined)
      {
        newCart[IID] = 0
        if(newCart.total==null || newCart.total==undefined)
        {
          newCart.total = 0
        }
        else if (Math.round(parseFloat(newCart.total) - parseFloat(fetchedItem.price)) >=0)
        {
          newCart.total = parseFloat(newCart.total) - parseFloat(fetchedItem.price)
        }
      }
      else if(cart[IID]>0)
      {
        newCart[IID] = cart[IID]-1  
        if(newCart.total==null || newCart.total==undefined)
        {
          newCart.total = 0
        }
        else if (Math.round(parseFloat(newCart.total) - parseFloat(fetchedItem.price)) >=0)
        {
          newCart.total = parseFloat(newCart.total) - parseFloat(fetchedItem.price)
        }
      }
      else if(cart[IID]==0)
      {
        if (Math.round(parseFloat(newCart.total) - parseFloat(fetchedItem.price)) >=0)
        {
          newCart.total = parseFloat(newCart.total) - parseFloat(fetchedItem.price)
        }
      }
      console.log(newCart)
    setCart(newCart)
  }

  function fetchItemFromInventoryWithoutIID(GID, sizeType, size)
  {
    if(rawInventory!=null)
    {
      var needles = rawInventory.find((item) => item.itemGroupID==GID && item.sizeType==sizeType && item.size==size)
      return needles
    }
    else
    {
      return null
    }
  }

  function fetchItemFromInventoryWithIID(IID)
  {
    if(rawInventory!=null)
    {
      var needles = rawInventory.find((item) => item.IID==IID)
      return needles
    }
    else
    {
      return null
    }

  }

  function pickImage() 
  { setShowImageDialog(!showImageDialog) }

  const pickImageFromLibrary = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [2, 3],
      quality: 1,
    });
  
  
    if (result.hasOwnProperty('uri')) {
      const manipulateResult = await manipulateAsync(
           result.uri,
          [],
          { 
            base64: true,
            compress: 0.4 } // from 0 to 1 "1 for best quality"
      );
      setImage(manipulateResult);
    
  };
  }

  const pickImageFromCamera = async () => {

    const permissionResult = await ImagePicker.requestCameraPermissionsAsync()
    if(permissionResult.granted === false)
    {
      alert("This app does not have access to your camera.")
      return;
    }

    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [3, 2],
      quality: 1,
    });
  
  
    if (result.hasOwnProperty('uri')) {
      const manipulateResult = await manipulateAsync(
           result.uri,
          [],
          { 
            base64: true,
            compress: 0.4 } // from 0 to 1 "1 for best quality"
      );
      setImage(manipulateResult);
    
  };
  }
  
  
  var newNumber =  new Date().getMinutes()

  const emptyList = <Text style={{color: theme.colors.itemTextColor, fontSize: 15}}>No Inventory Items Loaded.</Text>

  const [sort, setSort] = React.useState(1)
  const [types, setTypes] = React.useState([])
  const [inventory, setInventory] = React.useState(null)
  const [inventoryList, setInventoryList] = React.useState(emptyList)
  const [showEditor, setShowEditor] = React.useState(false)
  const [catInventory, setCatInventory] = React.useState(null)

  const [showTypeBox, setShowTypeBox] = React.useState(false)
  const [rawInventory, setRawInventory] = React.useState(null)

  
  const [workingItem, setWorkingItem] = React.useState(null)
  const [isShowingEmptySize, toggleEmptySize] = React.useState(null)
  const [barcodeEntry, setBarcodeEntry] = React.useState(false)

  const [updatedItemCode, setUpdatedItemCode] = React.useState(null)
  const [updatedItemCodeType, setUpdatedItemCodeType] = React.useState(null)

  const [barcodeMap, setbarcodeMap] = React.useState(null)
  const [barcodeList, setBarcodeList] = React.useState([])

  const [loading, setLoading] = React.useState(false)

  const [showBarcodeField, setShowBarcodeField] = React.useState(false)

  const [barcodeSearch, setBarcodeSearch] = React.useState("")
  const barcodeSearchRef = React.createRef()

  const [cart, setCart] = React.useState({})
  const [renderedCart, renderCart] = React.useState(null)
  const [barcodeEntryButtonVisiblity, setBarcodeEntryButtonVisibility] = React.useState(true)

  useEffect(() => {
    if(cart!={})
{
  console.log(Object.keys(cart))
    renderCart(
      Object.keys(cart).filter((cartItem) => cartItem!="total").map((cartItem) => {
        console.log("cartItem: ", cartItem)
        var fItem = fetchItemFromInventoryWithIID(cartItem)
        console.log(sizes[fItem.sizeType])
        return(
          <CartEntry key={cartItem} removeFromCart={removeFromCart} addToCart={addToCart} cart={cart}  item={fItem} sizeInfo={sizes.find(item => item.id==fItem.sizeType).name + " - " + sizes.find(item => item.id==fItem.sizeType).values.find(sizeTypeValue => sizeTypeValue.id==fItem.size).value}></CartEntry>
          )
      })
    )
}
  }, [cart, viewCart])

  function updateQuantity(type, IGD, sizeType, size, quantity)
  {

    var newInv = {...catInventory}
    console.log(type, IGD, sizeType, size, quantity)
    newInv[type][IGD].quantity[sizeType][size] = quantity
    setCatInventory(newInv)
    
  }

  
  function updateQuantity2(type, IGD, sizeType, size, quantity)
  {

    var newInv = {...catInventory}
    if(newInv[type]==null || newInv[type]==undefined)
    {
      newInv[type] = {}
    }
    if(IGD==-1)
    {
      console.log(type, IGD, sizeType, size, quantity)
      
      newInv[type][IGD] = workingItem
      newInv[type][IGD].quantity = {}
    }
    if(newInv[type][IGD].quantity[sizeType]==null) {
      newInv[type][IGD].quantity[sizeType] = {}
    }
    newInv[type][IGD].quantity[sizeType][size] = quantity
    console.log(newInv[type][IGD])
    setCatInventory(newInv)
    toggleEmptySize(false)
  }

  function updateCode()
  {
    setWorkingItem({...workingItem, itemCode: updatedItemCodeType + ":" + updatedItemCode})
    setBarcodeEntry(false)
  }


  useEffect(() => {
    if(workingItem!=null && workingItem!=undefined && workingItem.type=="Other")
    {
      setShowTypeBox(true)
    }
    console.log(workingItem)
  }, [workingItem])





useEffect(() => {
  console.log("LOADING!!__________________________________________")
  fetchData()
}, [])

function fetchData()
{
  setLoading(true)
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "GetInventoryList",
      "user": user.UID,
      "camp": user.Home,
    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  var barcodeMap = {}
  responseJson.records.map((item) => {
    barcodeMap[((item.itemCode!=null || item.itemCode!="") ? item.itemCode.split(":")[1] : "")] = item.IID
  })
  setbarcodeMap(barcodeMap)
  setBarcodeList(responseJson.records.map((item) => {if(item.itemCode!=null) {return (
    item.itemCode.split(":")[1]
  )}
}))
  setInventory(responseJson.records)
  setRawInventory(responseJson.records2)
  setLoading(false)
 })
}

function deleteItem()
{
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "DeleteInventoryGroup",
      "user": user.UID,
      "camp": user.Home,
      "itemGroup": workingItem.itemGroupID
    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  console.log(responseJson)
  setShowEditor(false)
  setShowTypeBox(false)
  setImage(null)

  fetchData()
 })
}

function submitChanges(GID)
{
  setLoading(true)
  if(image!=null)
  {
    console.log(GID)
  fetch('https://www.outpostorganizer.com/SITE/v2/uploadAPI.php', {
        method: 'POST',
        body: JSON.stringify({
        "user": user.UID,
        "camp": user.Home,
        "spot": GID,
        "image": image.base64,
        "type": "inventory",
        
        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    console.log("Complete:", responseJson)
    var imageURL = "https://www.outpostorganizer.com/SITE" + responseJson.inventoryPath
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateInventoryGroup",
        "camp": user.Home,
        "itemGroupID": GID,
        "user": user.UID,
        "itemName": workingItem.itemName,
        "imageURL": imageURL,
        "itemCode": workingItem.itemCode,
        "itemPrice": workingItem.price,
        "itemDesc": workingItem.description,
        "itemType": workingItem.type
        
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log("Complete")
      setShowEditor(false)
      setShowTypeBox(false)
      setImage(null)
      console.log(responseJson)
      submitQuantityChanges(responseJson.itemGroupID, responseJson.imgURL)
       })
  })
  }
  else
  {
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateInventoryGroup",
        "camp": user.Home,
        "itemGroupID": GID,
        "user": user.UID,
        "itemName": workingItem.itemName,
        "imageURL": workingItem.imageURL,
        "itemCode": workingItem.itemCode,
        "itemPrice": workingItem.price,
        "itemDesc": workingItem.description,
        "itemType": workingItem.type
        
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log("Complete")
      setShowEditor(false)
      setShowTypeBox(false)
      setImage(null)

      submitQuantityChanges(responseJson.itemGroupID, responseJson.imgURL)
   })
} 
}

function submitQuantityChanges(newItemGroupID, imgURL)
{
  setLoading(true)
 (Object.keys(workingItem.quantity).map((sizeType) => {
    (Object.keys(workingItem.quantity[sizeType]).map((size) => {
      
      console.log("HERE", workingItem.itemGroupID, sizeType, size, workingItem.quantity[sizeType][size])
      var currentItem = (rawInventory!=null && rawInventory!=undefined) ? (rawInventory.find((i) => i.itemGroupID==workingItem.itemGroupID && i.sizeType==sizeType && i.size==size)) : null
      var cIID = currentItem==null || currentItem==undefined ? -1 : currentItem.IID
      
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateInventoryItem",
        "camp": user.Home,
        "itemGroupID": newItemGroupID,
        "IID": cIID,
        "user": user.UID,
        "itemName": workingItem.itemName,
        "imageURL": imgURL,
        "itemCode": workingItem.itemCode,
        "itemPrice": workingItem.price,
        "itemDesc": workingItem.description,
        "itemType": workingItem.type,
        "sizeType": sizeType,
        "size": size,
        "quantity": workingItem.quantity[sizeType][size]
        
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson2) => {
    fetchData()
      console.log(responseJson2)
    })
  }))
}))
}


useEffect(() => {
if(inventory!=null){
  const categorizedInventory = {}
  inventory.map((item) => {
      if(!(item.type in categorizedInventory)) {
        categorizedInventory[item.type] = {}
      }
      if(!(item.itemGroupID in categorizedInventory[item.type])) {
        var currentItemQuantity = item.quantity
        item.quantity = {}
        item.quantity[item.sizeType] = {}
        item.quantity[item.sizeType][item.size] = currentItemQuantity
        categorizedInventory[item.type][item.itemGroupID] = item
        categorizedInventory[item.type][item.itemGroupID].totalQuantity = +currentItemQuantity
      }
      else {
        if(categorizedInventory[item.type][item.itemGroupID].quantity[item.sizeType]==null){
          categorizedInventory[item.type][item.itemGroupID].quantity[item.sizeType] = {}
        }
        categorizedInventory[item.type][item.itemGroupID].quantity[item.sizeType][item.size] = item.quantity
        categorizedInventory[item.type][item.itemGroupID].totalQuantity += +item.quantity
      }
  })
  setCatInventory(categorizedInventory)
}
}, [inventory])

useEffect(() => {
  console.log("fired")
  if(catInventory!=null)
  {
    var keys = Object.keys(catInventory)
    setTypes(keys)
    if(workingItem!=null && showEditor)
    {
      console.log("setting")
      if(workingItem.IID!=-1)
      {
        setWorkingItem(catInventory[inventory.find((rItem => rItem.itemGroupID==workingItem.itemGroupID)).type][workingItem.itemGroupID]) 
      }
      else
      {
        setWorkingItem(catInventory[workingItem.type][workingItem.itemGroupID])
      }
    } 
    if(filterType!=0)
    {
      keys = keys.filter((key) => key==filterType)
    }    
    setInventoryList(
      keys.map((title) => {
        return(
        <InventoryCategory hideTitleIfEmpty={true} forceHideTitle={filterType!=0} open={true} title={title}>
          {Object.values(catInventory[title]).filter((item) => 
          ((((item.itemCode!=null) && (item.itemCode.length>0)) ? item.itemCode.split(":")[1].includes(searchText) : searchText==null)
          || (item.itemName!=null) ? item.itemName.includes(searchText) : searchText==null
          )).map((item) => {
            console.log(item.description.includes(searchText))
            var cartGroupQuantity = 0
            Object.keys(cart).filter((cartItem) => cartItem!="total").map((cartItem) => {
              var fetchedItem = (fetchItemFromInventoryWithIID(cartItem))
              if(fetchedItem!=null && fetchedItem.itemGroupID==item.itemGroupID)
              {
                cartGroupQuantity += cart[cartItem]
              }
            })
          return(
            <TouchableOpacity onPress={() => {
              update(item.itemGroupID)
            }} style={{width: "90%", display: "flex", flexDirection: "row", alignItems: "center"}}>
              <View style={{justifyContent: "flex-start", paddingVertical: 10, paddingLeft: 10, width: "100%", alignContent: "center", display :"flex", flexDirection: "row", height: "100%"}}>
              <View style={{flex: 0.15}}>
              <Image source={{uri: item.imageURL + "?refresh=" + newNumber}} style={{width: "100%", height: 55}}></Image>              
              </View>
              <View style={{flex: 0.65, justifyContent: "flex-start", paddingLeft:10, alignContent: "center", display :"flex", flexDirection: "column", height: "100%"}}>
                <Text numberOfLines={2} style={{color: theme.colors.itemTextColor,  fontFamily: "Carter-One", fontSize: 18}}>{item.itemName}</Text>
                <Text style={{color: theme.colors.itemTextColor, fontSize: 18, fontFamily: "Glacial-Indifference"}}>Quantity: {item.totalQuantity - cartGroupQuantity}</Text>
              </View>
              </View>
            </TouchableOpacity>          )
        })}</InventoryCategory>)
      })
    )
  }
  else
  {
    setLoading(false)
  }
}, [catInventory, searchText, filterType, showEditor, showBarcodeField])


function update(item)
{
  setWorkingItem(catInventory[inventory.find((rItem => rItem.itemGroupID==item)).type][item]) 
}

useEffect(() => {
  if(workingItem!=null)
  {
    setShowEditor(true)
    setUpdatedItemCodeType(workingItem.itemCode != null ? workingItem.itemCode.split(":")[0] : null)
    setUpdatedItemCode(workingItem.itemCode != null ? workingItem.itemCode.split(":")[1] : null)
  }
}, [workingItem])

useEffect(() => {
  if(!showEditor)
  {
    setWorkingItem(null)
  }
}, [showEditor])




  async function navTo(location) {
  navigation.navigate(location, {user: user, config: config})
    
  }

function addFromBarcode()
{
  setLoading(true)
  if(barcodeList!=null && barcodeList!=[])
  {
  console.log(barcodeList)
  console.log(barcodeSearch)
  var options = (barcodeList.filter((item) => item!=undefined && item.includes(barcodeSearch)))
  if(options==null || options.length==0)
  {
    console.log("Error")
  }
  else
  {
    var itemCode = options[0]
    var fullItem = fetchItemFromInventoryWithIID(barcodeMap[itemCode])
    console.log(fullItem)
    console.log()
    var item = fullItem.IID
    
    if(item in cart)
    {
      if(cart[item]<rawInventory.find((item) => item.IID==fullItem.IID).quantity)
      {
        var newCart = cart
        newCart[item] = cart[item] + 1
        newCart.total = (parseFloat(cart.total==null ? 0 : cart.total) + parseFloat(fullItem.price))
        setCart(newCart)
      }
    }
    else
    {
      if(0==rawInventory.find((item) => item.IID==fullItem.IID).quantity)
      {
        console.log("Nothing")
      }
      else
      {
      var newCart = cart
      newCart[item] = 1
      newCart.total = (parseFloat(cart.total==null ? 0 : cart.total) + parseFloat(fullItem.price))
      setCart(newCart)    
    }
    }
  }
}
  setShowBarcodeField(false)
  setBarcodeSearch("")
}

useEffect(() => {
  if(!showBarcodeField)
  {
    setLoading(false)
  }
}, [showBarcodeField])
  
function focusOnBarcode()
{
  barcodeSearchRef.current?.focus()
}


  
  return (
    
    
    <View style={styles.container}>

      {loading ? <StatusBar config={config}></StatusBar> : null}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer active={pageID} customTheme={config.theme} items={config.tools} shrink={true} navTo={navTo} />
      </View>

    <View style={[styles.content, {backgroundColor: theme.colors.list + "12", borderColor: theme.colors.list}]}>
    <TouchableOpacity onPress={() => {
if(showEditor || viewCart)
{
  setShowEditor(false)
  setViewCart(false)
  setWorkingItem(null)
}

else
{
  navTo("Dashboard", user, config, null, navigation)
}

}} style={styles.backButton} mode="outlined">
<Ionicons style={{}} name="return-down-back" size={24} color={theme.colors.itemTextColor}></Ionicons>
</TouchableOpacity>
{showBarcodeField && 
<TouchableOpacity onPress={() => {setBarcodeSearch("")
  setShowBarcodeField(false)}}
style={{display: "absolute", top: -200, bottom: -200, left: 0, right: 0, backgroundColor: theme.colors.itemTextColor + "22", width: "100%", height: Dimensions.get("screen").height}}><Text></Text>
<View style={styles.middleBackButton2} mode="outlined"><Text style={{color: theme.colors.itemTextColor, fontSize:30, fontFamily: "Carter-One"}}>Scan Barcode{"\n"}
</Text><View style={{alignSelf: "center", alignItems: "center", alignContent: "center", textAlign: "center", marginTop: -20}}><FontAwesome name="barcode" size={74} color={theme.colors.itemTextColor}></FontAwesome>
</View><Text style={{color: theme.colors.itemTextColor, fontSize:16, fontFamily: "Carter-One"}}>(Tap to Dismiss){"\n"}
</Text></View>
<View style={styles.middleBackButton} mode="outlined">
<TextInput
showSoftInputOnFocus={true}
autoFocus={showBarcodeField}
ref={ref => {barcodeSearchRef = ref}}
selectionColor={theme.colors.listItemBackground}
theme={{ colors: { text: theme.colors.listItemBackground } }}
style={{width: "100%", alignSelf: "center", color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 0, fontSize: 10, height: 40}}
          returnKeyType="next"
          multiline={true}
          value={barcodeSearch}
          onChangeText={text => { if(text!=null && text!=undefined && text.includes("\n")){
            addFromBarcode()
          } else {(setBarcodeSearch(text))}}}
            
          ></TextInput>
</View></TouchableOpacity>}
{barcodeEntryButtonVisiblity && 
<TouchableOpacity onPress={() => {
  setShowBarcodeField(!showBarcodeField)
  focusOnBarcode()

}} style={styles.otherBackButton} mode="outlined">
<Ionicons style={{}} name="barcode" size={24} color={theme.colors.itemTextColor}></Ionicons>
</TouchableOpacity>}
{viewCart &&
      <ScrollView style={{position: "absolute", top: 0, marginTop: 40, bottom: 0, left: 0, right: 0, backgroundColor: theme.colors.listItemBackground, zIndex: 2}}>
              <Text style={{fontSize: 23, color: theme.colors.itemTextColor, fontFamily: "Carter-One", textAlign: "center", width: "100%"}}>Your Cart</Text>
             {renderedCart}
             <Button style={{backgroundColor: theme.colors.greenButton, width: 200, justifyContent: "flex-end", alignSelf: "flex-end"}} onP={() => {submitCart()}}><Text style={{color: theme.colors.itemTextColor}}>Submit</Text></Button>
</ScrollView>
}
    {showEditor &&
      <ScrollView style={{position: "absolute", top: 0, marginTop: 40, bottom: 0, left: 0, right: 0, backgroundColor: theme.colors.listItemBackground, zIndex: 1}}>
 {/*<TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Description</Text>}
multiline={true}
theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
style={{width: "100%", alignSelf: "center", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="next"
          value={workingItem.description}
          onChangeText={text => { setWorkingItem({...workingItem, description: text})
            }}
          ></TextInput>      */}
      <View style={{display: "flex", flexDirection: "row"}}>
      <View style={{display: "flex", flexDirection: "column", flex: 0.7, padding: 4}}>
        <TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Item Name</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{marginTop: 20, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="next"
          editable={false}
          value={workingItem.itemName}
          onChangeText={text => setWorkingItem({...workingItem, itemName: text})}
          />
        
        <TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Price</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="next"
          keyboardType="numeric"
          editable={false}
          value={workingItem.price}
          onChangeText={text => setWorkingItem({...workingItem, price: text})}
          />
        </View>
     
        <View style={{display: "flex", flexDirection: "column", flex: 0.5, padding: 15}}>

          
          <Image source={{uri: image==null ? workingItem.imageURL + "?id=" + newNumber : image.base64==null ? image : "data:image/png;base64," + image.base64}} style={{flex: 1, height: 200}} />
        </View>
      </View>
      <Text style={{fontSize: 18, paddingLeft: 5, color: theme.colors.itemTextColor}}>Sizes</Text>
      {Object.keys(workingItem.quantity).map((itemType) => {
        return(
          Object.keys(workingItem.quantity[itemType]).map((size) => {
            return(
              <ShopSizeEntry removeFromCart={removeFromCart} addToCart={addToCart} cart={cart} IID={ (fetchItemFromInventoryWithoutIID(workingItem.itemGroupID, sizes.find((s) => s.id == itemType).id, size).IID)} updateQuantity={updateQuantity} sizes={sizes} workingItem={workingItem} sizeType={sizes.find((s) => s.id == itemType)} size={size}></ShopSizeEntry>
            )
          })
        )
      })}

  
      <View style={{paddingBottom: 300}}></View>
      </ScrollView>}
      <Text style={{fontSize: 23, color: theme.colors.itemTextColor, fontFamily: "Carter-One"}}>{config.InventoryFrontTitle}</Text>
      <TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Search</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="next"
          value={searchText}
          onChangeText={text => setSearchText(text)}
          />
<View style={{borderWidth: 1, width: "100%"}}>      
<Picker
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,  borderWidth: 2, borderColor: "#000", width: "100%"}}
          selectedValue={filterType}
          onValueChange={(itemValue, itemIndex) =>
            {
            setFilterType(itemValue)
            }
          }>
          <Picker.Item label="All" value={0} />
          {types.map((type) => {
            return(
            <Picker.Item label={type} value={type} />
            )
          })}
        </Picker></View>
    <ScrollView
   contentContainerStyle={{width: '100%', marginTop: 30}}>
    {inventoryList}
    <View style={{paddingBottom: 200}}></View>
    </ScrollView>
    
    <View style={{position: "absolute", bottom: 20, left: 0, right: 0, height: 70, zIndex: 5, padding: 5, display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", backgroundColor: theme.colors.listItemBackground, borderColor: theme.colors.itemTextColor, borderWidth:2}}>
    <Text style={{color: theme.colors.itemTextColor, fontSize: 22, marginLeft: 20, fontFamily: "Glacial-Indifference", flex: 0.5}}>Total: {cart.total == null ? 0 : Math.round(cart.total*100)/100}</Text>
    <TouchableOpacity onPress={() => {
      setViewCart(!viewCart)
      setBarcodeEntryButtonVisibility(!barcodeEntryButtonVisiblity)}} style={{borderRadius: 9, borderWidth: 2, flex: 0.5, padding: 5, marginRight: 20, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row"}}>
      <Ionicons name="cart" color={theme.colors.itemTextColor} size={32}></Ionicons>
      <Text style={{color: theme.colors.itemTextColor, fontSize: 22, marginLeft: 20, fontFamily: "Glacial-Indifference"}}>View Cart</Text>
</TouchableOpacity>
    </View>
      </View>
  </View>



    
    </View>
   ) };
