import React, { memo, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import Background from '../components/Background';
import { styles } from '../styles/RegisterScreenStyle'

import {
  emailValidator,
  passwordValidator,
  nameValidator,
} from '../core/utils';
import { ScrollView } from 'react-native';
import TextInput from '../components/TextInput';
import Button from '../components/Button';
import { theme } from '../core/theme';

export default function RegisterScreen({ route, navigation }) {
  const [name, setName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [entryCode, setEntryCode] = useState({value: '', error: ""});

  const _onSignUpPressed = () => {
    const nameError = nameValidator(name.value);
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);

    if (emailError || passwordError || nameError) {
      setName({ ...name, error: nameError });
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
      "command": "AddUser",
      "email": email.value,
      "screenName": name.value,
      "entrycode": entryCode.value,
      "password": password.value,
      

      })
   })
   .then((response) => response.json())
   .then((responseJson) => {
         console.log(responseJson)
         if(responseJson.code==1)
         {
          navigation.navigate('HomeScreen');
         }
         else
         {
          
         }
          }
          
   )
    
  };



  return (    
  
  <View style={[styles.container]}>
    <View style={[styles.fixed]}>
      <Background />
    </View>
    <ScrollView style={[styles.fixed, {backgroundColor: 'transparent'}]}>
    <View style={styles.container}>

      <View style={styles.registerBox}>
      <TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Access Code</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor + "65", borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
        returnKeyType="next"
        value={entryCode.value}
        onChangeText={text => setEntryCode({ value: text, error: '' })}
        error={!!entryCode.error}
        errorText={entryCode.error}
      />
<TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Name</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor + "65", borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
        returnKeyType="next"
        value={name.value}
        onChangeText={text => setName({ value: text, error: '' })}
        error={!!name.error}
        errorText={name.error}
      />

      <TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Email</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor + "65", borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
        returnKeyType="next"
        value={email.value}
        onChangeText={text => setEmail({ value: text, error: '' })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />

      <TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Password</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor + "65", borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
        returnKeyType="done"
        value={password.value}
        onChangeText={text => setPassword({ value: text, error: '' })}
        error={!!password.error}
        errorText={password.error}
        secureTextEntry
      />

      <Button mode="contained" onPress={_onSignUpPressed} style={styles.registerButton}>
        <Text style={styles.registerButton}>Sign Up</Text>
      </Button>
      <View style={styles.row}>
        <Text style={styles.label}>Already have an account? </Text>
        <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')}>
          <Text style={styles.link}>Login</Text>
        </TouchableOpacity>
      </View>
      </View>
      </View></ScrollView>
</View>
  );
  
};
