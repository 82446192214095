import Matter from "matter-js"
import Bird from "../components/Bird"
import Floor from "../components/Floor"
import { Dimensions } from "react-native"

const windowHeight = Dimensions.get('window').height*0.95
const windowWidth = Dimensions.get('window').width

export default (data) => {
    let engine = Matter.Engine.create({enableSleeping: false})
    engine.gravity.y = 0.4
    let world = engine.world

    var s=0;
    data.groups.map((group) => {
        if(s<parseInt(data.points[group.id]))
        {
            s = parseInt(data.points[group.id]);
        }
    })
    
    var game = {
        physics: {engine, world},   
        Floor: Floor(world, 'black', {x: windowWidth/2, y: windowHeight*0.9}, {height: 30, width: windowWidth}),
        RightWall: Floor(world, 'black', {x: windowWidth*0.95, y: 0}, {height: windowHeight*2, width: 50}),
        LeftWall: Floor(world, 'black', {x: 0, y: 0}, {height: windowHeight*2, width: 15}),
        Bird: Bird(world, "transparent", {x: 60, y: -1000}, {height: 5, width: 50})
    }

    data.groups.map((item, index) => {
        
        if(parseInt(data.points[item.id]!=null && data.points[item.id]!=undefined && data.points[item.id])>0)
        {
        game[item.Name] = Bird(world, item.Color, {x: (windowWidth/2)+Math.floor(Math.random()*10), y: -50-((20+data.points[item.id]/20)*index)}, {height: (data.points[item.id]*(windowHeight*0.25))/s, width: (data.points[item.id]*(windowHeight*0.25))/s}, item.Name, data.points[item.id])
}
else
{
    console.log("Not showing: " + item.Name + " because they only have " + data.points[item.id] + " points")
}
})

   
    return game;
}