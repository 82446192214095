import React, { memo, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 




export default function InventoryCategory({ single, open, title, children, hideTitleIfEmpty, forceHideTitle }) {

  const [showChildren, toggleChildren] = React.useState(open)
 
const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
  },
  header: {
    fontSize: 25,
    width: "100%",
    color: theme.colors.itemTextColor,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    color: theme.colors.itemTextColor,
  }
});

const [fontsLoaded] = useFonts({
  'Glacial-Indifference-Bold': require('../assets/fonts/glacial-indifference/GlacialIndifference-Bold.otf'),
});

const onLayoutRootView = useCallback(async () => {
  if (fontsLoaded) {
    await SplashScreen.hideAsync();
  }
}, [fontsLoaded]);


if (!fontsLoaded) {
  return null;
}
if((children.length==0 && hideTitleIfEmpty))
{
  return null
}
else if(forceHideTitle)
{
  return children
}
else if(children.length==0)
{
  return <Text style={[styles.header, {fontFamily: "Glacial-Indifference-Bold", color: theme.colors.itemTextColor}]}>{title}</Text>
}
return(
<View style={styles.container} onLayout={onLayoutRootView}>
  
<TouchableOpacity onPress={() => {
  toggleChildren(!showChildren)
}} style={single ? {display: "none"} : styles.title} >
{!showChildren ? <MaterialCommunityIcons color={theme.colors.itemTextColor} name="chevron-right" size={28} /> : <MaterialCommunityIcons color={theme.colors.itemTextColor} name="chevron-down" size={28}/>}<Text style={[styles.header, {fontFamily: "Glacial-Indifference-Bold", color: theme.colors.itemTextColor}]} numberOfLines={1}>{title}</Text>
</TouchableOpacity>
{showChildren ? children : null}
</View>
)
  
}

