import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/DirectoryStyle';
import { Dimensions, Image, Linking, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {MaterialIcons, Entypo, FontAwesome5, MaterialCommunityIcons, Ionicons, Feather} from '@expo/vector-icons';
import MappedUser from '../components/MappedUser';
import { activities } from '../core/activities';
import { navTo } from '../core/utils';
import Title from '../components/Title';
import { Picker } from '@react-native-picker/picker';
import Avatar from '../components/Avatar';
import TextInput from '../components/TextInput';
import StatusBar from '../components/StatusOverlay';

var size = 25
var additionalInfos = [
  {
    id: 1,
    name: "Bible Verse",
    varName: "Bible Verse",
    inputType: "text",
    type: "Username",
    link: "https://biblegateway.com/passage/?search=",
    icon: <FontAwesome5 name="bible" size={size} color="#fff"></FontAwesome5>

  },
  {
    id: 2,
    name: "View in Directory",
    varName: "Directory",
    icon: <MaterialIcons name="folder-shared" size={size} color="#fff"></MaterialIcons>
  },
  {
    id: 3,
    name: "Send Notification",
    varName: "Send Notification",
    icon: <MaterialIcons name="phone-in-talk" size={size} color="#fff"></MaterialIcons>
  }
  

]

export default function Support({ route, navigation })  {
  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;
  var [colorList, setColorList] = React.useState(JSON.parse(JSON.stringify(theme.colors.activities)).sort(() => Math.random() - 0.5))

 
var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

  const [loading, setLoading] = React.useState(false)
  const [userList, setUserList] = React.useState([])
  const [dirUser, setDirUser] = React.useState(null)
  const [dirUserInfo, setDirUserInfo] = React.useState(null)
  const [dirUserBio, setDirUserBio] = React.useState("")
  const [updatingInfo, setUpdatingInfo] = React.useState(null)

  function launchUpdater(name, value)
  {
    setUpdatingInfo({name: name, value: value})
  }

  function updateBio(newBio)
  {
    setDirUserInfo({...dirUserInfo, PrayerInfo: newBio})
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdatePrayerInfo",
        "camp": user.Home,
        "user": user.UID,
        "newPI": newBio
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    })
    }


    function sendUpdate()
  {
    if(updatingInfo.name!="Send Notification")
    {
    setLoading(true);
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateInfo",
        "camp": user.Home,
        "user": user.UID,
        "key": updatingInfo.name,
        "val": updatingInfo.value,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log("Response!!: ",responseJson)
    setUpdatingInfo(null)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetUserInfo",
        "camp": user.Home,
        "user": user.UID,
        "dirUser": dirUser.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    setDirUserInfo({...responseJson.records, ...responseJson.userInfo});
    setLoading(false)
    })
    
    })
  }
  else
  {
   
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SendNotification",
        "camp": user.Home,
        "user": dirUser.UID,
        "title": "You have recieved a message from Outpost!!",
        "message": updatingInfo.value,
        "sendingUser": user.UID
      })
    })
   .then((response) => {
   return(response.text())
  })
   .then((responseJson) => {
    console.log(responseJson)
    //setDirUserInfo({...responseJson.records, ...responseJson.userInfo});
    setLoading(false)
    })
    
  }
  }


    useEffect(() => {
      console.log("Update:",dirUserInfo)
    }, [dirUserInfo])
  

  useEffect(() => {
    if(dirUser!=null)
    {
      setLoading(true);
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetUserInfo",
        "camp": user.Home,
        "user": user.UID,
        "dirUser": dirUser.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    setDirUserInfo({...responseJson.records, ...responseJson.userInfo});
    setLoading(false)
    })
    }
  }, [dirUser])

  useEffect(() => {
    setLoading(true);
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetUsers",
        "camp": user.Home,
        "user": user.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    setUserList(Object.values(responseJson.records));
    setLoading(false)
    })
  }, [])

  function handleClick(aI)
  {
    switch(aI.id)
    {
      case(1):
        Linking.openURL(aI.link + dirUserInfo["Bible Verse"])
        break;
      case(2):
        navigation.navigate("Directory", {user: user, config: config, refresh: refresh, startingUser: dirUserInfo.UID})
        break
      case(3):
        launchUpdater("Send Notification", "")
        break
      default:
        console.log("Todo:",aI.id)
      
    }
  }


  function getRandomColor(ind) {
    return colorList[ind % colorList.length]
  }
  
  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
          </View>
{updatingInfo!=null ? <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
  <Text style={{fontSize: 26, color: theme.colors.itemTextColor, fontFamily: "Glacial-Indifference"}}>
    {updatingInfo.name=="Send Notification" ? "" : "Update "}{updatingInfo.name}
  </Text>
  <TextInput
label={<Text style={{color: theme.colors.itemTextColor}}>{additionalInfos.find(aI => aI.varName == updatingInfo.name).id==3 ? "Notification Text" : updatingInfo.name} {additionalInfos.find((aI) => aI.varName==updatingInfo.name).id==4 ? "Link" : ""}</Text>}
autoCapitalize="words"
multiline={true}
theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
style={{width: "90%", alignSelf: "center", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          keyboardType={additionalInfos.find((aI) => aI.varName==updatingInfo.name).id==4 ? "url" : additionalInfos.find((aI) => aI.varName==updatingInfo.name).inputType}
          returnKeyType="next"
          value={updatingInfo.value}
          onChangeText={text => { setUpdatingInfo({...updatingInfo, value: text})
            }}
></TextInput>          
<View style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "100%"}}>
<Button style={{flex: 0.3, backgroundColor: theme.colors.redButton}} onP={() => {setUpdatingInfo(null)}}><Text style={{color: theme.colors.itemTextColor}}>Cancel</Text></Button>
<Button style={{flex: 0.3, backgroundColor: theme.colors.greenButton}} onP={() => {sendUpdate()}}><Text style={{color: theme.colors.itemTextColor}}>Submit</Text></Button>
</View></View> : 
    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
      {loading && <StatusBar></StatusBar>}
    <Picker
  style={{width: "90%", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderWidth: 2, borderColor: theme.colors.itemTextColor}}
  selectedValue={dirUser !=null ? dirUser.UID : null}
  onValueChange={(selectedUser, itemIndex) => {

   console.log(selectedUser)
    setDirUser(userList.find(u => u.UID==selectedUser))
    
  }
  }>
     <Picker.Item label={"Select a user"} value={-1}></Picker.Item>
    {userList.length>0 ? userList.map((user) => {
      if(user!=null && user.screenName!=null && user.screenName!="")
      {
      return(
        <Picker.Item label={user.screenName} value={user.UID}></Picker.Item>
      )
      } 
    }) : null}

</Picker>
{dirUserInfo !=null ?
<View style={{width: "100%"}}>
<Avatar user={dirUserInfo} 
      containerStyle={
            { alignSelf: "center",
              borderRadius: Dimensions.get("window").width*0.5, 
              width: Dimensions.get("window").width*0.5, 
              height: Dimensions.get("window").width*0.5,
              borderWidth: 4
            }} 
          logoStyle={
            {
              width: "100%", 
              height: "100%", 
              resizeMode: "stretch"
            }} />
<Text style={styles.title}>{dirUserInfo.screenName}</Text>
<ScrollView style={{width: "100%", height: Dimensions.get("screen").height * 0.40}}>
  {dirUserInfo!=null && ((dirUserInfo.PrayerInfo!=null && dirUserInfo.PrayerInfo!="") || dirUserInfo.UID==user.UID) ? 
<TextInput
label={<Text style={{color: theme.colors.itemTextColor}}>Prayer Information</Text>}
autoCapitalize="words"
multiline={true}
theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
style={{width: "90%", alignSelf: "center", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}

          returnKeyType="next"
          editable={user.UID==dirUserInfo.UID}
          value={dirUserInfo.PrayerInfo}
          onChangeText={text => { updateBio(text)
            }}
></TextInput> : null
}
          {additionalInfos.map((aI, index) => {
            if(dirUserInfo[aI.varName]!=null && dirUserInfo[aI.varName]!="" || (aI.varName!="Bible Verse" && (aI.varName!="Send Notification" || dirUserInfo["pushToken"]!=null)))
            {
            return(
              <TouchableOpacity onLongPress={() => {user.UID==dirUserInfo.UID && aI.id==1 ? launchUpdater(aI.varName, dirUserInfo[aI.varName]) : null}} onPress={() => {handleClick(aI)}} style={{width: "80%", alignSelf: "center", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                <View style={{width: 50, justifyContent: "center", alignItems: "center", padding: 5, margin: 5, backgroundColor: getRandomColor(index)}}>
                  {aI.icon}
                  </View>
                  <Text style={{zIndex: 999, overflow: "visible", elevation: 5, backgroundColor: "transparent", color: theme.colors.itemTextColor, fontSize: 20, fontFamily: "Glacial-Indifference", shadowColor: getRandomColor(index), textShadowColor: getRandomColor(index), shadowOffset: {width: 2, height: 20}, textShadowOffset: {width: -1.5, height: 3}, shadowOpacity: 0.5, textShadowRadius: 7}}>{dirUserInfo[aI.varName]==null ? aI.name : dirUserInfo[aI.varName]}</Text>
                </TouchableOpacity>
            )
            }
            else if(dirUserInfo.UID==user.UID && (aI.varName!="Send Notification" || dirUserInfo["pushToken"]!=null))
            {
              return(
                <TouchableOpacity onPress={() => {launchUpdater(aI.varName, "")}} style={{width: "80%", alignSelf: "center", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                  <View style={{width: 50, justifyContent: "center", alignItems: "center", padding: 5, margin: 5, backgroundColor: getRandomColor(index)}}>
                    {aI.icon}
                    </View>
                    <Text style={{zIndex: 999, overflow: "visible", elevation: 5, backgroundColor: "transparent", color: theme.colors.itemTextColor, fontSize: 20, fontFamily: "Glacial-Indifference", shadowColor: getRandomColor(index), textShadowColor: getRandomColor(index), shadowOffset: {width: 2, height: 20}, textShadowOffset: {width: -1.5, height: 3}, shadowOpacity: 0.5, textShadowRadius: 7}}>Click to Add {aI.name}</Text>
                  </TouchableOpacity>
              )
            }
          })}</ScrollView>
</View>
 : null}
      </View>



        }
    </View>
    
    </View>
   ) };
