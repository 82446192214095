import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/NewsStyle';
import { Dimensions, Image, Text, View, TouchableOpacity } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {MaterialIcons, FontAwesome5, MaterialCommunityIcons, Ionicons, Entypo} from '@expo/vector-icons';
import { navTo } from '../core/utils';
import Title from '../components/Title';
import { shareAsync } from 'expo-sharing';
import * as Print from 'expo-print'


export default function NewsList({ route, navigation })  {


  
  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;
 
var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];


var [colorList, setColorList] = React.useState(JSON.parse(JSON.stringify(theme.colors.activities)).sort(() => Math.random() - 0.5))

const [news, setNews] = React.useState(null)
const [active,setActive] = React.useState(null)
const [userList, setUserList] = React.useState(null)

function getColor(ind) {
  return colorList[ind % colorList.length]
}

function enable(listItem)
{
  if(userList!=null)
  {
    setActive({...listItem, userPosted: userList.find((u) => u.UID==listItem.PostedBy).screenName})
  }
  
}

const html = active!=null ? `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
    <h1 style="font-size: 50px; font-family: Carter-One,Garamond; font-weight: normal;">
      ` + active.Title + `
    </h1>` + (active.imgURL!=null && active.imgURL!="" ? `
    <img
      src="` + active.imgURL + `"
      style="width: 90vw; max-height: 300px; " />` : null) + `
    <div style="margin-top: 10px">` + active.Body + `
    </div>
    <div style="color: #000000; width: 100%; text-align: left; padding: 5px; background-image: linear-gradient(to right,` + theme.colors.activities.join("44, ") + `); position: absolute; bottom: 2">
    Brought to you by Outpost
    </div>
  </body>
</html>
` : "";


const printToFile = async () => {
  // On iOS/android prints the given html. On web prints the HTML from the current page.
  const { uri } = await Print.printToFileAsync({html});
  console.log('File has been saved to:', uri);
  await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
};

useEffect(() => {
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetNews",
        "user": user.UID,
        "camp": user.Home,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    setNews(responseJson.records)
    setUserList(responseJson.userList)
   })
}, [])

  
  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
          </View>
          <TouchableOpacity onPress={() => {
            if(active==null)
            {
        navTo("back", user, config, refresh, navigation)
            }
            else
            {
              setActive(null)
            }
    
      }} style={styles.backButton} mode="outlined">
      <Ionicons style={{}} name="return-down-back" size={24} color={theme.colors.itemTextColor}></Ionicons>
        </TouchableOpacity>
    
    {active ? 
    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
      <ScrollView style={{margin: 20, marginTop: 50}}>
      
      <Text style={{fontFamily: "Carter-One", fontSize: 19, color: theme.colors.itemTextColor, width: "95%"}} color={theme.colors.itemTextColor}>{active.Title}</Text>
      <Text style={{marginLeft: 5, fontFamily: "Carter-One", fontSize: 14, color: theme.colors.itemTextColor, width: "95%"}} color={theme.colors.itemTextColor}>Posted {active.DatePosted} by {active.userPosted}</Text>
      {active.imgURL!=null ? 
      <Image  style={{resizeMode: "cover", width: Dimensions.get("window").width*0.8, height: 250}} source={{uri: active.imgURL}}></Image>
      
      : null}
      <Text style={{color: theme.colors.itemTextColor, marginTop: 5, marginBottom: 200}}>{active.Body}</Text>
   </ScrollView>
   <TouchableOpacity onPress={() => {
          printToFile()
      }} style={styles.shareButton} mode="outlined">
      <Entypo style={{}} name="share" size={24} color={theme.colors.itemTextColor}></Entypo>
        </TouchableOpacity>
</View>
    :
    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
          <Title color={theme.colors.itemTextColor}>News List</Title>
          {news!=null ? (news.map((item, ind) => {
            return(
          <TouchableOpacity onPress={() => {
            enable(item)
          }} style={{backgroundColor: getColor(ind), width: "90%", display: "flex", flexDirection: "row", alignItems: "center"}}>
            <View style={{justifyContent: "flex-start", padding:20, width: "100%", alignContent: "center", display :"flex", flexDirection: "column", height: "100%"}}>
              <Text numberOfLines={2} style={{color: theme.colors.itemTextColor,  fontFamily: "Carter-One", fontSize: 20}}>{item.Title}</Text>
              <Text style={{color: theme.colors.itemTextColor, fontSize: 20, fontFamily: "Glacial-Indifference"}}>Posted: {item.DatePosted} {userList!=null ? "by " + userList.find((u) => u.UID==item.PostedBy).screenName : null}</Text>
            </View>
          </TouchableOpacity>
)
          }))
           : null}
           {(config.tools.includes(63) || config.additionalTools.includes(63)) && 
              <TouchableOpacity onPress={() => {
          navTo(63, user, config, refresh, navigation)
      }} style={styles.shareButton} mode="outlined">
      <MaterialCommunityIcons style={{}} name="newspaper-plus" size={24} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
        </TouchableOpacity>}
      </View>
}



    
    </View>
    </View>
   ) };
