import React, { memo, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons'; 
import { Picker } from '@react-native-picker/picker';
import TextInput from './TextInput';
import Button from './Button';




export default function SizeEntry({ updateBarcode, updateQuantity, workingItem, sizeType, sizes, size }) {


  if(sizeType==null)
  {
    const [newSizeType, setNewSizeType] = React.useState(null)
    const [newSize, setNewSize] = React.useState(null)
    const [newAmt, setNewAmt] = React.useState(null)
    return(
      <View style={{display: "flex", flexDirection: "row", width: "100%", borderColor: "#000", borderWidth: 2}}>
            <Picker
                style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,  borderWidth: 2, borderColor: "#000", flex: 0.4}}
                selectedValue={newSizeType}
                onValueChange={(itemValue, itemIndex) => {
              setNewSizeType(itemValue)
                }}>
                {newSizeType==null && <Picker.Item label={"Select"} value={-999} />}
                {sizes.map((type) => {
                  return(
                  <Picker.Item label={type.name} value={type.id} />
                  )
                })}
              </Picker>
            {newSizeType==null ? null : newSizeType==5 ?  <View style={{flex: 0.4, paddingRight: 15}}><TextInput
                label={<Text style={{color: theme.colors.itemTextColor}}>Size</Text>}
                theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
                style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
                returnKeyType="next"
                keyboardType="numeric"
                value={newSize}
                onChangeText={text => setNewSize(text)}
                /></View>
               : <Picker 
                style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,  borderWidth: 2, borderColor: "#000", flex: 0.45}}
                selectedValue={newSize}
                onValueChange={(itemValue, itemIndex) => {
                  setNewSize(itemValue)
                }}>
                {newSize==null && <Picker.Item label={"Select"} value={-999} />}
                {sizes.find(s => s.id==newSizeType).values.map((type) => {
                  return(
                  <Picker.Item label={type.abbreviatedValue} value={type.id} />
                  )
                })}
              </Picker>     }   
              <View style={{flex: 0.2}}>
              <TextInput
                label={<Text style={{fontSize: 14, color: theme.colors.itemTextColor}}>Amt</Text>}
                theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
                style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 0}}
                returnKeyType="next"
                keyboardType="numeric"
                value={newAmt}
                onChangeText={text => updateQuantity(workingItem.type, workingItem.itemGroupID, newSizeType, newSize, text)}
                />
              </View>
      
            </View>
            
        )
  }
return(
<View style={{display: "flex", flexDirection: "row", width: "100%", borderColor: "#000", borderWidth: 2}}>
{workingItem.IID!=-1 && <TouchableOpacity onPress={() => {updateBarcode(workingItem, workingItem.itemGroupID, sizeType.id, size)}} style={{flex: 1, width: 40, borderRadius: 10, borderColor: "#000", alignItems: "center", justifyContent: "center" }}>
        <Ionicons style={{alignContent: "center", alignSelf: "center", justifyContent: "center"}} name="barcode" size={24} color={theme.colors.itemTextColor}></Ionicons>
        </TouchableOpacity>}
      <Picker
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,  borderWidth: 2, borderColor: "#000", flex: 0.35}}
          selectedValue={sizeType.id}
          onValueChange={(itemValue, itemIndex) => {}
       //     setWorkingItem({...workingItem, type: itemValue})
          }>
          {sizes.map((type) => {
            return(
            <Picker.Item label={type.name} value={type.id} />
            )
          })}
        </Picker>
      {sizeType.id==5 ?         <View style={{flex: 0.2, paddingRight: 15}}><TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Size</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="next"
          keyboardType="numeric"
          value={size}
          editable={false}
          /></View>
         : <Picker 
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,  borderWidth: 2, borderColor: "#000", flex: 0.35}}
          selectedValue={sizeType.values.find((s) => s.id==size).id}
          onValueChange={(itemValue, itemIndex) => {}
       //     setWorkingItem({...workingItem, type: itemValue})
          }>
          {sizeType.values.map((type) => {
            return(
            <Picker.Item label={type.abbreviatedValue} value={type.id} />
            )
          })}
        </Picker>     }   
        <View style={{flex: 0.2}}>
        <TextInput
          label={<Text style={{fontSize: 14, color: theme.colors.itemTextColor}}>Amt</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 0}}
          returnKeyType="next"
          keyboardType="numeric"
          value={workingItem.quantity[sizeType.id][size]}
          onChangeText={text => updateQuantity(workingItem.type, workingItem.itemGroupID, sizeType.id, size, text)}
          />
        </View>

      </View>
      
  )
  
}

