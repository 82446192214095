import React, { memo, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/SignUpStyle';
import { Dimensions, Image, Keyboard, KeyboardAvoidingView, Platform, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import TextInput from '../components/TextInput';
import StatusBar from '../components/StatusOverlay';
import { Calendar } from 'react-native-calendars';
import { Entypo } from '@expo/vector-icons'; 
import Moment from 'moment';
import SearchableDropdown from 'react-native-searchable-dropdown';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { createElement } from 'react-native-web';
import { Picker } from '@react-native-picker/picker';
import { navTo } from '../core/utils';

export default function NewEvent({ route, navigation })  {

  var user = route.params.user;
  var config = route.params.config;
  var refreshBack = route.params.refresh;
  var refresh = refreshBack
  var refreshBack2 = route.params.refresh2;

  var timeDisplay = Platform.OS === 'ios' ? 'inline' : 'default';

  const [workers, setWorkers] = React.useState(null)
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState("")

  const[time, setTime] = React.useState(new Date())
  const [dotsMapped, setDots] = React.useState(null)
  const [selectedDay, setSelectedDay] = React.useState([])
  const [formattedSelDay, setFSD] = React.useState({})
  const [updateTime, setUpdateTime] = React.useState(false)
  const [flag, setFlag] = React.useState(false)
  const [formattedTime, setFormattedTime] = React.useState(null)
  const [spots, setSpots] = React.useState([])
  const [selectedSpots, setSelectedSpots] = React.useState({name: "Attach a list item?", id: -1})
  const [categories, setCategories] = React.useState([])
  const [selectedCategory, setSelectedCategory] = React.useState({name: "Select a category", id: -1})
  var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];


  //useEffect to get all spots
  useEffect(() => {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
        body: JSON.stringify({
          "command": "GetCategories",
          "camp": user.Home,
          "user": user.screenName,
          })
          })
          .then((response) => response.json())
          .then((responseJson) => {
            setCategories([{id: -1, name: "Select a category!"}, ...responseJson.records.map((category) => {
              return(
                {
                  id: category.CID,
                  name: category.CategoryName
                }
              )
            })]);
            setLoading(categories.length==1);
            })
            .catch((error) => {
              console.error(error);
              });
              fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
                method: 'POST',
                  body: JSON.stringify({
                    "command": "GetAllListItems",
                    "camp": user.Home,
                    "user": user.screenName,
                    })
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                      setSpots([{id: -1, name: "Attach a list item"}, ...responseJson.records.map((spot) => {
                        return(
                          {
                            id: spot.SID,
                            name: spot.site_Name
                          }
                        )
                      })]);
                      setLoading(spots.length==1);
                      })
                      .catch((error) => {
                        console.error(error);
                        });
          
          
                 

        
              }, []);
              

  function getTime() {
    console.log(time)
    var t = "";
    if(time.getHours()==0)
    {
      t += 12;
    }
    else
    {
    t += time.getHours()
    }
    t += ":"
    t += time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()
    return t
  }


  useEffect(() => {
    console.log(time)
    if(time!=null)
    {
      setFormattedTime(getTime())
    }
  }, [time])
  

  function submit() {
    if(title==null || workers==null || title=="" || workers=="")
    {
      setFlag(true)
      setLoading(false)
      return;
    }
    console.log(selectedCategory)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "AddToCalendar",
        "camp": user.Home,
        "eventName": title,
        "eventTime": time,
        "user": user.UID,
        "volunteerAmount": workers,
        "dates": selectedDay,
        "attachedSite": selectedSpots.id,
        "category": selectedCategory.id,

        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
    refreshBack();
    refreshBack2();
    console.log(responseJson)
    navTo("back");
  }).catch((error) =>
  {
    setLoading(false);
  })

  }
  
  
  

  useEffect(() => {
    var s = {}
    selectedDay.map((sDay) => {
      s[sDay.dateString] = {selected: true, selectedColor: config!=null && config.theme.assignments!=null ? config.theme.assignments : themeColor}
    })
    setFSD(s)
  }, [selectedDay])

  
  return (
    
    
    <View style={styles.container}>
      {loading ? <StatusBar config={config}></StatusBar> : null}
    <KeyboardAvoidingView enabled behavior='height' style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
      </View>

    <View style={{borderWidth: 4,
        flexDirection: "column",
        flex: 0.9,
        display: "flex",
        padding: 10,
        backgroundColor: config!=null && config.theme.assignments!=null ? config.theme.assignments + "12" : themeColor + "12", borderColor: config!=null && config.theme.assignments!=null ? config.theme.assignments : themeColor}} contentContainerStyle={{ borderWidth: 4,
          flexDirection: "column",
          justifyContent: "flex-start",   
          alignItems: "center",    
          borderWidth: 0,
          display: "flex",
          }}>
<View style={{width: "100%"}}>
 <TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Title</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="next"
  value={title}
  errorText={flag && (title=="" || title==null) ? "Error.  Title is required." : ""}
  onChangeText={text => setTitle(text)}
/>    
<Picker
  style={{backgroundColor: "#eee", width: "100%", borderWidth: 2, borderColor: "#000"}}
  selectedValue={selectedCategory.id}
  onValueChange={(itemValue, itemIndex) =>
    {
    console.log(itemValue)    
    setSelectedCategory(categories.find((c) => c.id==itemValue))
    }
  }>
    {categories.map((dv,index) => {
      if(dv!=null && dv!="")
      {
              return (<Picker.Item label={dv.name} value={dv.id} />)
      }
            })}
</Picker>

<View style={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "flex-start"}}>
{
    createElement('input', {
      type: 'time',
      value: formattedTime,
      style: {marginTop: 10, borderRadius: 10, fontSize: 16, width: "40%", borderColor: theme.colors.itemTextColor, borderWidth: 1},

      onInput: (e) => {
        console.log("E: ", e.target.value)
        setFormattedTime((e.target.value))
      }
    })
  }
<TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Slots</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{width: "45%", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  keyboardType="numeric"
  returnKeyType="next"
  errorText={flag && (workers=="" || workers==null) ? "Volunteers required." : ""}
  value={workers}
  editable={true}
  onChangeText={w => setWorkers(w.replace(/[^0-9]/g, ''))}
/>

</View>

<View style={{width: "100%"}}>

<Calendar
  selected={Moment(Date.now()).format("YYYY-MM-DD")}
 
 
  markedDates={formattedSelDay}
  renderArrow={direction => {
    if(direction=="left")
    {
      return (
        <Entypo name="chevron-left" size={24} color={theme.colors.itemTextColor} />
      )
    }
    else
    {
      return (
        <Entypo name="chevron-right" size={24} color={theme.colors.itemTextColor} />
      )
    }
    
  }}
  onDayPress={day => {
    if(selectedDay.find(d => d.dateString==day.dateString)!=undefined)
    {
      setSelectedDay(selectedDay.filter(item => item.dateString != day.dateString))
    }
    else
    {
      setSelectedDay([...selectedDay, day])
    }
    
  }}
  enableSwipeMonths={true}
  

  // Agenda container style

/></View>
<KeyboardAvoidingView enabled behavior='position' style={{backgroundColor: theme.colors.listItemBackground}}>
<SearchableDropdown
            onItemSelect={(item) => {
              console.log(item)
              setSelectedSpots(item)
            }}
            containerStyle={{ padding: 5 }}
            itemStyle={{
              padding: 10,
              marginTop: 2,
              backgroundColor: theme.colors.listItemBackground,
              color: theme.colors.itemTextColor,
              borderRadius: 5,
            }}
            itemTextStyle={{ color: theme.colors.itemTextColor }}
            itemsContainerStyle={{ maxHeight: 140, borderRadius: 10, paddingBottom: 5, backgroundColor: theme.colors.listItemBackground }}
            items={spots}
            placeholderTextColor={theme.colors.itemTextColor}
            textInputProps={
              {
                placeholder: selectedSpots.name,
                underlineColorAndroid: "transparent",
                
                backgroundColor: theme.colors.listItemBackground,
                style: {
                    padding: 12,
                    borderWidth: 1,
                    borderRadius: 5,
                    color: theme.colors.itemTextColor,
                },

              }
            }
            listProps={
              {
                nestedScrollEnabled: true,
              }
            }
        />
        </KeyboardAvoidingView>
<View style={{flexDirection: "row", width: "100%", justifyContent: "space-evenly"}}>
<Button onP={() => {
  setLoading(true)
  submit()
}} style={{backgroundColor: theme.colors.greenButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, flex: 0.4}}><Text style={{width: "100%", color: theme.colors.itemTextColor}}>Create</Text></Button>
<Button onP={() => {
  navTo("back", user, config, refresh, navigation)
}} style={{backgroundColor: theme.colors.redButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, flex: 0.4}}><Text style={{color: theme.colors.itemTextColor}}>Cancel</Text></Button>
</View>
      </View>
    
      </View>

      </KeyboardAvoidingView>

    
    </View>
   ) };
