import Matter from 'matter-js'
import React from 'react'
import { View, Image, Text } from 'react-native'
import { useFonts } from 'expo-font';
import {pickTextColorBasedOnBgColorSimple} from '../../core/utils.js'

const Bird = props => {

    
    const [fontsLoaded] = useFonts({
        'Carter-One': require('../../assets/fonts/carter-one/CarterOne.ttf'),
      });
      

    const widthBody = props.body.bounds.max.x - props.body.bounds.min.x
    const heightBody = props.body.bounds.max.y - props.body.bounds.min.y

    const xBody = props.body.position.x - widthBody/2
    const yBody = props.body.position.y - heightBody/2
    const textColor = pickTextColorBasedOnBgColorSimple(props.color, "#fff", "#000")
    const color = props.color;
    const name = props.name;
    const points = props.points;
    return(
        <View style={{
            borderRadius: widthBody,
            borderColor: "black",
            
            backgroundColor: color,
            borderStyle: 'solid',
            position: 'absolute',
            left: xBody,
            justifyContent: "center",
            alignItems: "center",
            top: yBody,
            width: widthBody,
            height: heightBody
        }}><Text style={{textAlign: "center", fontSize: widthBody/5, fontFamily: "Carter-One", color: textColor}}>{name}</Text><Text style={{fontSize: widthBody/8, fontFamily: "Carter-One", color: textColor}}>{points}</Text></View>
    )

}

export default (world, color, pos, size, name, points) => {
    //Todo: Change rect to circle
    const initialBird = Matter.Bodies.circle(
        pos.x, pos.y, size.width, 
        {label: 'Bird', restitution: 0.5, slop: 0.5}
    )
    Matter.World.add(world, initialBird)

    return {
        body: initialBird,
        color,
        pos,
        name,
        points,
        renderer: <Bird/>
    }
}
