import { Dimensions, StyleSheet } from 'react-native'
import { theme } from '../core/theme'

export const styles = StyleSheet.create({
    
    fixed: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0   
    },
    backButton: {
      position: "absolute",
      top: 10,
      left: 10,
      width: 60,
      justifyContent: "center",
      alignItems: "center",
      height: 50,
      borderRadius: 40, 
      backgroundColor: theme.colors.listItemBackground,
      borderWidth: 2,
    },
    here: {
      marginLeft: 20
    },
    title:
    {
      fontSize: 30,
      marginTop: 10,
      color: theme.colors.itemTextColor
    },
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
        backgroundColor: theme.colors.listItemBackground
    },
    logoutButton: {
        position: "absolute",
        top: 0,
        width: 20,
        left: 20,
        zIndex: 100,
        borderRadius: 50
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "stretch",
    },

    navDrawer: {
      display: "flex",
      flexDirection: "column",
      flex: 0.1,
      alignItems: "stretch"
    },
    content: {
        borderWidth: 4,
        flexDirection: "column",
        justifyContent: "flex-start",   
        alignItems: "center",    
        paddingTop: 20, 
        flex: 0.9,
        display: "flex"

    },
    map: {
      width: "100%",
      height: 400
    }
    
  });