import React, { memo, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/SettingsStyle';
import { Dimensions, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {activities} from '../core/activities';
import {Ionicons} from '@expo/vector-icons'
import { navTo } from '../core/utils';
import TextInput from '../components/TextInput';

export default function Settings({ route, navigation })  {
  var user = route.params.user;
  var config = route.params.config;
  var refresh = route.params.refresh;

  var pages = activities(config.theme)

  const [formattedTasks, setFormattedTasks] = React.useState(null);
  const [myTasks, setMyTasks] = React.useState(null);
  const [items, setItems] = React.useState(null);
  const [complete, setComplete] = React.useState(false);
  const [filter, setFilter] = React.useState("")
  const [allTools, setAllTools] = React.useState(false)


  var pageID = route.params.id;  
  var index = config!=null ? config.tools.indexOf(pageID) : 0;
  var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];


  useEffect(() => {
    if(myTasks!=null)
    {
    }
  }, [myTasks])
  
  

  useEffect(() => {
    global.marked = 0;

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',

    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      
      setMyTasks({tasks: responseJson.calendar, claims: responseJson.claimedSites});
   });

  }, [])

  function validateConfig(configItem)
  {
    if(configItem==null)
    {
      return true;
    }
    return config[configItem];
  }

  
  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />

      </View>

    <View style={[styles.content, {backgroundColor: theme.colors.listItemBackground, borderColor: theme.colors.listItemBackground}]}>
    <Button onLongP={() => {
      if(user.UID==9)
      {
        setAllTools(!allTools)
      }
      }} onP={() => {refresh(); navigation.goBack()}} style={styles.backButton} mode="outlined">
      <Ionicons name="return-down-back" size={24} color={theme.colors.itemTextColor}></Ionicons>
        </Button>
        <Text style={styles.title}>Other Tools</Text>
        <TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Search</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{width: "90%", alignSelf: "center", borderColor: theme.colors.itemTextColor, borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
  value={filter}
  onChangeText={text => setFilter(text)}
  autoCapitalize="none">
  </TextInput>

        <View style={{flex: 0.9, width: "80%"}}>
    <ScrollView 
   contentContainerStyle={{width: '100%', paddingBottom: 50, justifyContent: "center", alignItems: "center"}}>
    {pages.filter((p) => {      
      return (p.name.toLowerCase().includes(filter))}).map((page) => {
      if(page.id==515 && user.UID==9)
      {
        return(
          <Button style={[{backgroundColor: theme.colors.listItemBackground, borderColor: "#000", borderWidth: 2}]} onP={() => {navTo(page.id, user, config, null, navigation)}}>
            <Text style={{color: theme.colors.itemTextColor}}>{page.name}</Text>
          </Button>
        )
      }
      else if((page.id!=515 && !config.tools.includes(page.id) && config.additionalTools.includes(page.id)) || allTools)
      {
    return(
        <Button style={[{backgroundColor: theme.colors.listItemBackground, borderColor: "#000", borderWidth: 2}]} onP={() => {
          console.log(page.id, user, config, null, navigation)
          navTo(page.id, user, config, null, navigation)}}>
          <Text style={{color: theme.colors.itemTextColor}}>{page.name}</Text></Button>
      ) 
    }
    })}
    </ScrollView>
    </View>
      </View>
    
      </View>



    
    </View>
   ) };
