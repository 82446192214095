import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/MapStyle';
import { Dimensions, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
//import MapView from 'react-native-maps';
//import {Marker} from 'react-native-maps';
import * as Location from 'expo-location';
import {MaterialIcons, FontAwesome5, MaterialCommunityIcons, Ionicons} from '@expo/vector-icons';
import MappedUser from '../components/MappedUser';
import { activities } from '../core/activities';
import { navTo } from '../core/utils';


export default function Map({ route, navigation })  {
  var user = route.params.user;
var config = route.params.config;
  const mapRef = React.createRef();
  const [markers, setMarkers] = React.useState(null);
  var refresh = route.params.refresh;
  const [formattedTasks, setFormattedTasks] = React.useState(null);
  const [myTasks, setMyTasks] = React.useState(null);
  const [items, setItems] = React.useState(null);
  const [complete, setComplete] = React.useState(false);
  const [data, setData] =React.useState(null)
  const [sites, setSites] = React.useState(null)
  const [currentSite, setCurrentSite] = React.useState(null)
  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentSpots, setCurrentSpots] = useState(null)
  const [currentCount, setCurrentCount] = useState([])
  const [currentTaskAmt, setCurrentTaskAmt] = useState({})
  const [usersHere, setUsersHere] = useState(null)
  const [mappedUser, setMappedUsers] = useState(null)

var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];
console.log({pageID, index, themeColor})
  function calculateBar() {
    return(Math.round(100*parseFloat(currentTaskAmt.total - currentTaskAmt.progress) / parseFloat(currentTaskAmt.total)))
  }



  useEffect(() => {
    (async () => {
      
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        setErrorMsg('Permission to access location was denied');
        return;
      }

      let location = await Location.getCurrentPositionAsync({});
      setLocation(location);
    })();
  }, []);


  useEffect(() => {
    if(usersHere!=null)
    {
      setMappedUsers(usersHere.map((user) => {
        return(
          <MappedUser user={user}></MappedUser>
        )
      }))
    }
    else
    {
      setMappedUsers(null)
    }
  }, [usersHere])

  useEffect(() =>{
    if(location!=null)
    {
      mapRef
    }
  }, [location])


  const latitude = parseFloat(38.2830)
  const  longitude = parseFloat(-90.1905)
  const  latitudeDelta=  0.009
  const  longitudeDelta= 0.009


  const animateMap = (lat, lng) => {
    mapRef.current.animateToRegion({ // Takes a region object as parameter
        longitude: lat,
        latitude: lng,
        latitudeDelta: 0.005,
        longitudeDelta: 0.005,
    },1000);
}


  
  function determineCurrentSite(coordinates)
  {
    setCurrentSite(sites.find((s) => (s.Latitude==coordinates.latitude) && (s.Longitude==coordinates.longitude)))
  }


  useEffect(() => {
    if(currentSite!=null)
    {
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "GetSiteDetails",
          "list": 1,
          "user": user.UID,
          "site": currentSite.SiteID,
          "camp": user.Home,

      })
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if(responseJson.code==1)
      {
        setCurrentSpots(responseJson.records)
        setUsersHere(responseJson.usersHere)
        setCurrentCount(responseJson.count)
        setCurrentTaskAmt({progress: responseJson.progress,
                           total: responseJson.total})
      }
      else if(responseJson.code==4)
      {
        setLoading(false);
      }
      
    });
  }
  }, [currentSite])

  function getIcon(type, lib)
  {
    
    var color = "#4E3524";
    switch(parseInt(lib))
    {
      case(1):
        return <MaterialIcons name={type} size={34} color={color} />
      case(2):
        return <FontAwesome5 name={type} size={30} color={color} />
      case(3):
        return <MaterialCommunityIcons name={type} size={30} color={color} />
      default:
        return <MaterialIcons name="location-pin" size={34} color={color} />
    }
  }
  

  useEffect(() => {
    global.marked = 0;

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetListItems",
        "list": 1,
        "user": user.UID,
        "camp": user.Home,
        "approval": 0

    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    if(responseJson.code==1)
    {
      setData(responseJson.records);
      setSites(responseJson.sites)
    }
    else if(responseJson.code==4)
    {
      setLoading(false);
    }
 //     
   });
  }, [])


 /* useEffect(() => {
    if(sites!=null)
    {
      setMarkers(sites.map((site)=> {
        return(
          <Marker 
            id={site.SiteID}
            onPress={(t) => {
              setLoading(true)
              determineCurrentSite(t.nativeEvent.coordinate)

            } }
            coordinate={{
            latitude: parseFloat(site.Latitude),
            longitude: parseFloat(site.Longitude)}}
            title={site.SiteName}
            >{getIcon(site.Type, site.IconLibrary)}</Marker>
        )
      }))
      
    
    }
  }, [data, sites])*/
  
  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
          </View>

    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
    {/*<MapView style={styles.map}
              ref={mapRef}
              showsUserLocation={true}
              customMapStyle={[
                {
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#ebe3cd"
                    }
                  ]
                },
                {
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#523735"
                    }
                  ]
                },
                {
                  "elementType": "labels.text.stroke",
                  "stylers": [
                    {
                      "color": "#f5f1e6"
                    }
                  ]
                },
                {
                  "featureType": "administrative",
                  "elementType": "geometry.stroke",
                  "stylers": [
                    {
                      "color": "#c9b2a6"
                    }
                  ]
                },
                {
                  "featureType": "administrative.land_parcel",
                  "elementType": "geometry.fill",
                  "stylers": [
                    {
                      "color": "#bf78bd"
                    },
                    {
                      "visibility": "on"
                    },
                    {
                      "weight": 8
                    }
                  ]
                },
                {
                  "featureType": "administrative.land_parcel",
                  "elementType": "geometry.stroke",
                  "stylers": [
                    {
                      "color": "#dcd2be"
                    }
                  ]
                },
                {
                  "featureType": "administrative.land_parcel",
                  "elementType": "labels",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "administrative.land_parcel",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#ae9e90"
                    }
                  ]
                },
                {
                  "featureType": "landscape.man_made",
                  "elementType": "geometry.fill",
                  "stylers": [
                    {
                      "color": "#705842"
                    },
                    {
                      "visibility": "on"
                    },
                    {
                      "weight": 2.5
                    }
                  ]
                },
                {
                  "featureType": "landscape.man_made",
                  "elementType": "geometry.stroke",
                  "stylers": [
                    {
                      "color": "#705842"
                    },
                    {
                      "visibility": "on"
                    },
                    {
                      "weight": 8
                    }
                  ]
                },
                {
                  "featureType": "landscape.man_made",
                  "elementType": "labels.icon",
                  "stylers": [
                    {
                      "visibility": "on"
                    }
                  ]
                },
                {
                  "featureType": "landscape.man_made",
                  "elementType": "labels.text",
                  "stylers": [
                    {
                      "color": "#34322d"
                    },
                    {
                      "visibility": "on"
                    }
                  ]
                },
                {
                  "featureType": "landscape.natural",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#dfd2ae"
                    }
                  ]
                },
                {
                  "featureType": "landscape.natural",
                  "elementType": "geometry.fill",
                  "stylers": [
                    {
                      "color": "#e9f0e8"
                    },
                    {
                      "visibility": "on"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#dfd2ae"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "labels.text",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#93817c"
                    }
                  ]
                },
                {
                  "featureType": "poi.park",
                  "elementType": "geometry.fill",
                  "stylers": [
                    {
                      "color": "#a5b076"
                    }
                  ]
                },
                {
                  "featureType": "poi.park",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#447530"
                    }
                  ]
                },
                {
                  "featureType": "road",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#f5f1e6"
                    }
                  ]
                },
                {
                  "featureType": "road",
                  "elementType": "geometry.fill",
                  "stylers": [
                    {
                      "color": "#a4a27d"
                    }
                  ]
                },
                {
                  "featureType": "road.arterial",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#fdfcf8"
                    }
                  ]
                },
                {
                  "featureType": "road.highway",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#f8c967"
                    }
                  ]
                },
                {
                  "featureType": "road.highway",
                  "elementType": "geometry.stroke",
                  "stylers": [
                    {
                      "color": "#e9bc62"
                    }
                  ]
                },
                {
                  "featureType": "road.highway.controlled_access",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#e98d58"
                    }
                  ]
                },
                {
                  "featureType": "road.highway.controlled_access",
                  "elementType": "geometry.stroke",
                  "stylers": [
                    {
                      "color": "#db8555"
                    }
                  ]
                },
                {
                  "featureType": "road.local",
                  "elementType": "labels",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "road.local",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#806b63"
                    }
                  ]
                },
                {
                  "featureType": "transit.line",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#dfd2ae"
                    }
                  ]
                },
                {
                  "featureType": "transit.line",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#8f7d77"
                    }
                  ]
                },
                {
                  "featureType": "transit.line",
                  "elementType": "labels.text.stroke",
                  "stylers": [
                    {
                      "color": "#ebe3cd"
                    }
                  ]
                },
                {
                  "featureType": "transit.station",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#dfd2ae"
                    }
                  ]
                },
                {
                  "featureType": "water",
                  "elementType": "geometry.fill",
                  "stylers": [
                    {
                      "color": "#829ff8"
                    },
                    {
                      "weight": 1.5
                    }
                  ]
                },
                {
                  "featureType": "water",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#92998d"
                    }
                  ]
                }
              ]}
              region={
                { 
                  latitude: latitude,
                  longitude: longitude,
                  latitudeDelta: latitudeDelta,
                  longitudeDelta: longitudeDelta
                }
              }

              >{markers}
              
            </MapView>*/}
              
        {currentSite==null ? null : <View style={{display: "flex", width: "100%", padding: 10}}>
 <View style={{display: "flex", flexDirection: "row",  justifyContent: "space-around"}}>
        <Text style={styles.title}>
          {currentSite.SiteName}
        </Text>
        <Button onP={() => {
          navigation.navigate("List", {user: user, config: config, refresh: refresh, openSite: currentSite.SiteID})
        }} style={{backgroundColor: config.theme.list != null ? config.theme.map + "88" : themeColor + "88", borderWidth: 1, borderColor: "#000", width: "20%"}}><Ionicons name="return-down-forward" size={30} color="black"/></Button>
        </View>
        {Object.keys(currentTaskAmt).length>0 ? 
        <View style={{borderRadius: 30, borderWidth: 2, height: 30, width: "80%", justifyContent: "center", alignSelf: "center",  borderColor: theme.colors.itemTextColor}}>
          <View style={{borderRadius: 30, height: 30, backgroundColor: theme.colors.list + "77", width: calculateBar().toString() + "%"}}>   
          <Text></Text>     
          </View>
        </View>: null}
        <Text style={{alignSelf: "center", fontSize: 16,  color: theme.colors.itemTextColor}}>{calculateBar() + "% Complete"}</Text>
        <View style={styles.here}>
        {currentCount.length>0 ? <Text style={{alignSelf: "flex-start", fontSize: 20}}>{"Currently Here ("+ (parseInt(currentCount.length)) + "):"}</Text> : null}
        <View style={{width: "100%", height: Dimensions.get("screen").height/4.5}}><ScrollView contentContainerStyle={{paddingBottom: 100}}>
          {mappedUser}     
        </ScrollView></View>
        </View>
        </View>
}

      </View>
      </View>



    
    </View>
   ) };
