import React, { memo, useEffect } from 'react';
import Header from '../components/Header-old';
import Button from '../components/Button';
import Paragraph from '../components/Paragraph';
import { theme } from '../core/theme';
import { styles } from '../styles/ForgotPasswordScreenStyle'
import { View, Text } from 'react-native';
import Background from '../components/Background';

export default function HomeScreen({ route, navigation }) {

  
  return (    
    <Background>
    <Header>Welcome to Outpost</Header>

    <Paragraph>
    
    </Paragraph>
    <Button color={theme.colors.lightGreen} mode="contained" onPress={() => navigation.navigate('LoginScreen')}>
      Login
    </Button>
    <Button
      mode="outlined"
      onPress={() => navigation.navigate('RegisterScreen')}
    >
      <Text style={styles.forgotPassButtonText}>Sign Up</Text>
    </Button>
    </Background>
    ) ;
};

