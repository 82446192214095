import { memo } from 'react';
import { TouchableOpacity, Dimensions, StyleSheet, Text, View, Image } from 'react-native';
import { theme } from '../core/theme';
import * as React from 'react';
import CheckedBox from '../assets/checkedBox.svg';
import UncheckedBox from '../assets/uncheckedBox.svg';
import {MaterialCommunityIcons, FontAwesome, Ionicons} from '@expo/vector-icons'
import Task from './Task';
import { ScrollView } from 'react-native';
import TodayItem from './TodayItem';
import Button from './Button';
import TextInput from './TextInput';

const PrintComplexListItem = ({ priority, hasImage, config, editSite, isManager, approve, deny, simple, siteOwner, SID, adv, logs, claim, progress, title, isDenied, people, style, tasks, totalTasks, children, ...props }) => {
var advList;
const [selectedTasks, setSelectedTasks] = React.useState("")

const [image, setImage] = React.useState(hasImage ? "https://outpostorganizer.com/SITE/Global/Spots/" + SID + "/img.png?rand=" + (Math.random()*10000)%10000 : null);

function getPriorityColor()
{
  switch(priority)
  {
    case("0"):
      return theme.colors.greenButton + "52";
    case("2"):
      return theme.colors.error + "52";
    default:
      return "#ffffff"
      
  }
}

function attemptDeny() {
  var test = false;
  setChecked(true)
  if(selectedTasks=="")
  {
    setTaskError(true)
    test = true;
  }
  else
  {
    setTaskError(false)
  }
  if(!test)
  {
    deny(SID, "deny", comments, selectedTasks);
  }
}
  const taskList = tasks.map((task, index) => { 
  if(task!="")
  {
    return(<Task key={index} title={task}/> ) 
  }
   });

const open = () => {
  setChecked(!checked);
}

function calculatePercent() {
  return Math.round(progress*100)/100;
}

const list = () => {
  setSite(!showSite);
}

const openWindow = () => {
  adv(title);
  }


  const claimSite = () => {
    claim();
    }


const [checked, setChecked] = React.useState(false);
const [showSite, setSite] = React.useState(false);
const [comments, setComments] = React.useState("")
const [commentError, setCommentError] = React.useState(false)
const [taskError, setTaskError] = React.useState(false)

function isMarked(task)
{
  
  return selectedTasks.includes(task)
}

function mark(task)
{
  if(selectedTasks.includes(task))
  {
    setSelectedTasks(selectedTasks.replace(task + "& ",""))
  }
  else
  {
    let allTasks = totalTasks.split("& ");
    let newAllTasks = "";
    for(var i=0; i<allTasks.length; i++)
    {
      if(isMarked(allTasks[i]) || allTasks[i]==task)
      {
        newAllTasks = newAllTasks + allTasks[i] + "& "
      }
    }
    setSelectedTasks(newAllTasks)
  }
}

const styles = StyleSheet.create({
  text: {
    fontSize: 22,
    lineHeight: 26,
    color: "#000000",
    textAlign: 'right',
    
    flex: 1,
    textAlignVertical: "bottom"
    
  },
  item: {
    fontSize: 15,
    flex: 1,
    color: "#000000",
  },
  header: {
    fontSize: 12,
    color: "#000000",
    textAlign: 'left',
    flex: 0.5,
        
    textAlignVertical: "bottom"
  },
  percent: {
    fontSize: 12,
    color: "#000000",
    lineHeight: 24,
    textAlign: 'left',
    flex: 0.2,
    textAlignVertical: "bottom"
  },
  
  assignedTo: {
    fontSize: 12,
    color: "#000000",
    textAlign: 'left',
    flex: 0.5,
  },
  container:
  {
    lineHeight: 26,
    marginBottom: 7,
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    padding: 2,
    alignItems: 'center',
  },
  subContainer:
  {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 0,
    display: "flex",
    width: "100%"

  },
  expandedContainer:
  {
    flexDirection: 'column',
    justifyContent: "center",
    padding: 0,
    marginTop: 5,
    marginBottom: 10,
    flex: 1,
    width: "100%",
    alignItems: "center"
  },
  expandedContainerHide:
  {
    display: "none",
    overflow: "hidden"
    
  },
  checkbox:
  {
    
  },
  checkboxContainer:
  {
    position: 'absolute',
    left: 20,
    top: '22%',
    overflow: 'visible',
  },
  bar:
  {
    width: progress + "%",
    backgroundColor: config!=null && config.theme.list!=null ? config.theme.list : theme.colors.list,
    borderRadius: 4,
    flex: 50

  },
  barBackground:
  {
    width: "100%",
    backgroundColor: config!=null && config.theme.list!=null ? config.theme.list + "44" : theme.colors.list + "44",
    borderRadius: 4,
    marginBottom: 5,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  people:
  {
    textAlign: 'right',
    flexDirection: 'row',
    flex: 0.3
  },
  line:
  {
    height: 2,
    flex: 10,
    width: "100%",
    backgroundColor: "#000000",
    marginBottom: 5
  },
  lineHidden:
  {
    display: "none"
  },
  taskListHidden:
  {
    display: "none"
  },
  taskList:
  {
    textAlign: "left",
    width: "100%",
  },
  join: {
    height: 'auto',
    borderRadius: 20,
    backgroundColor: theme.colors.greenButton,
    color: '#000000',
  
    width: '30%',
    
    
},
deny: {
  height: 'auto',
  borderRadius: 20,
  backgroundColor: theme.colors.redButton,
  color: '#000000',

  width: '30%',
  
  
},
buttonContainer: {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-around"
},
taskContainer: {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "space-around"
},
owner: {
  fontSize: 16,
  color: "#000000",
},
task: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
},
title: {
  fontSize: 16,
  fontStyle: "italic",
  color: "#000000"
},

titleError: {
  fontSize: 16,
  fontStyle: "italic",
  color: "#000000",
  fontWeight: "bold",
  textDecorationColor: theme.colors.redButton,
  textDecorationLine: "underline"
}
  
});
var markableItems=totalTasks.split("& ").map((task) => {
  return(
    <TouchableOpacity activeOpacity={1} style={isMarked(task) ? [styles.task, {backgroundColor: theme.colors.redButton}] : [styles.task, {backgroundColor: "#ffffff"}]}><Button style={[{borderWidth: 3, borderColor: "#000000", width: 25, marginRight: 10}, isMarked(task) ? {backgroundColor: theme.colors.redButton} : {backgroundColor: theme.colors.greenButton + "93"}]} onP={() => {mark(task)}}></Button><Text style={styles.item}>{task}</Text></TouchableOpacity>
  )
})


return (
  <View style={[styles.container, isDenied ? {borderWidth: 4, borderColor: "#f54", backgroundColor: "#ffffff"} : {}]}>
    <View style={styles.subContainer}>
    {priority==1 ? <View style={{flex: 0.1}}><Text></Text></View> : <MaterialCommunityIcons style={{flex: 0.1}} name={priority==0 ? "arrow-down" : "exclamation-thick"} size={25} color={"#000000"}></MaterialCommunityIcons>}
      <Text style={styles.header}>{title}</Text>
      <Text numberOfLines={1} style={styles.percent}>{calculatePercent()}% Complete</Text>
    </View>
    <View style={styles.subContainer}>
      <View style={{flex: 0.1}}></View>
      <Text numberOfLines={1} style={styles.assignedTo}>Assigned to: {siteOwner}</Text>
    </View>
      {image==null ? null : <Image source={{uri: image}} style={{width: 200, height: 200, marginTop: 20}}/>}
      <Text style={styles.owner}>{isDenied==1 ?  logs=="" ? "Denied!" : "\nReason for Denial:\n" + logs : ""}</Text>
      <View style={[checked ? styles.expandedContainer : styles.expandedContainerHide ]}>
        <View style={styles.buttonContainer}>
        <Button style={styles.join} mode="outlined" onPress={openWindow}>
      <Text style={styles.join} >Join</Text>
  </Button> 
  {siteOwner!=null ? null : <Button style={styles.join} mode="outlined" onPress={claimSite}>
      <Text style={styles.join} >Claim</Text>
  </Button>}
  {!isManager ? null : <Button style={styles.join} mode="outlined" onPress={editSite}>
      <Text style={styles.join} >Edit</Text>
  </Button>}</View>
        <View style={[checked ? styles.line : styles.lineHidden ]}><Text></Text>
        </View>
        <View style={styles.taskList}>
        {progress==100 ? null : taskList}</View>
        
      </View>
  </View>
   );
};

export default memo(PrintComplexListItem);
