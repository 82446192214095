import React, { memo, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { emailValidator } from '../core/utils';
import Background from '../components/Background';
import BackButton from '../components/BackButton';
import Logo from '../assets/logo.svg';
import Header from '../components/Header';
import TextInput from '../components/TextInput';
import { theme } from '../core/theme';
import Button from '../components/Button';
import { useFonts } from 'expo-font';
import { AppLoading } from 'expo';
import { ScrollView } from 'react-native';
import { styles } from '../styles/ForgotPasswordScreenStyle';

export default function ForgotPasswordScreen({ route, navigation }) {
  const [email, setEmail] = useState({ value: '', error: '' });
  const [entrycode, setEntryCode] = useState({ value: '', error: '' });

  const _onSendPressed = () => {
    const emailError = emailValidator(email.value);

    if (emailError) {
      setEmail({ ...email, error: emailError });
      return;
    }

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
      "command": "ForgotPassword",
      "email": email.value,
      "entrycode": entrycode.value
      })
   })
   .then((response) => response.json())
   .then((responseJson) => {
         
         if(responseJson.code==1)
         {
            navigation.navigate('HomeScreen', {user: responseJson.records} );
          }
          else if(responseJson.code==2)
          {
            setEmail({value: email.value, error: responseJson.details})
          }
          else if(responseJson.code==3)
          {
            setEntryCode({value: entrycode.value, error: responseJson.details})
          }
          }
          
   )
         
      
      .catch((error) => {
         console.error(error);
      });
    

   // navigation.navigate('LoginScreen');
  };

    return (
  
    <View style={[styles.container]}>
      <View style={[styles.fixed]}>
        <Background />
      </View>
      <ScrollView style={[styles.fixed, {backgroundColor: 'transparent'}]}>
      <View style={styles.container}>
      <View style={styles.forgotPassBox} >
      <TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Entry Code</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor + "65", borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
        returnKeyType="done"
        value={entrycode.value}
        onChangeText={text => setEntryCode({ value: text, error: '' })}
        error={!!entrycode.error}
        errorText={entrycode.error}
        autoCapitalize="none"
      />
      <TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Email</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor + "65", borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
        returnKeyType="done"
        value={email.value}
        onChangeText={text => setEmail({ value: text, error: '' })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />

      <Button mode="contained" onPress={_onSendPressed} style={styles.forgotPassButton}>
        <Text style={styles.forgotPassButtonText}>Send Reset Instructions</Text>
      </Button>

      <TouchableOpacity
        style={styles.back}
        onPress={() => navigation.navigate('LoginScreen')}
      >
        <Text style={styles.label}>← Back to login</Text>
      </TouchableOpacity>

      </View></View></ScrollView></View>
  );

};
