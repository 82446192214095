import React, { memo, useCallback } from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons'; 
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { Entypo } from '@expo/vector-icons'; 
import { activities } from '../core/activities';
import { configureFonts } from 'react-native-paper';



export default function  NavDrawer({  items, active, navTo, shrink, children, isSubPage, customTheme }) {

const size = shrink ? 25 : 50
var navs = activities(customTheme, size)
 

const styles = StyleSheet.create({
  container: {
    
    //get screen height for height using getDimensions("window").height"),

    flex: 0.9,
    width: "100%",
    justifyContent: "space-evenly",
    display: "flex",
    flexDirection: "column",
    
  },
  header: {
    fontSize: 32,
    color: theme.colors.itemTextColor
  },
  item: {
    flex: 0.15,
    justifyContent: "center",
    alignItems: "center"
  }
});

const [fontsLoaded] = useFonts({
  'Carter-One': require('../assets/fonts/carter-one/CarterOne.ttf'),
});

const onLayoutRootView = useCallback(async () => {
  if (fontsLoaded) {
    await SplashScreen.hideAsync();
  }
}, [fontsLoaded]);

if (!fontsLoaded) {
  return null;
}

/*scoreboard: "#3e3e3d",
    announcements: "#f5008f",
    assignments: "#22b5d3",
    map: "#62d945",
    list: "#fe9d0b"*/
    
  return(

<View style={styles.container}>
  
    {
    items.map((tool, index) => {
      var item = navs.find((nav) => nav.id==tool)
      if(item!=null && (item.isAllowed == null || item.isAllowed))
      {
      return(
      <View key={item.id} style={[styles.item, {backgroundColor: (customTheme!=null) && (customTheme.length>index) ? customTheme[index] : theme.colors.activities[index]}]}>
        <TouchableOpacity style={{alignItems: "center", justifyContent: "center", alignContent: "center", backgroundColor: "#fff0", width: "100%", flex: 1}} onPress={() => {
          if(isSubPage && active==item.id)
          {
            navTo("back");
          }
          else if(active==item.id)
          {
            navTo("Dashboard");
          }
          else
          {
            navTo(item.id)
          }
          
      }}>
        {active==item.id ? <Entypo name="chevron-right" size={size} color="white" /> : item.icon}
        <Text style={{color: "white", fontSize: 20, fontFamily: "Carter-One"}}>{(item).name}</Text>
      </TouchableOpacity></View>)
    
    }})}
</View>
)
  
}

