import React, { memo, useState, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/CalendarStyle';
import { Dimensions, Image, Text, View } from 'react-native';
import { createElement, TouchableOpacity } from 'react-native-web';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {Calendar, CalendarList, AgendaList} from 'react-native-calendars';
import {CalendarItem} from '../components/CalendarItem';
import { Entypo } from '@expo/vector-icons'; 
import Moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { navTo } from '../core/utils';
import { StyleSheet, Platform } from 'react-native';
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';
import { Picker } from 'react-native-web';


const html = `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
    <h1 style="font-size: 50px; font-family: Helvetica Neue; font-weight: normal;">
      Hello Expo!
    </h1>
    <img
      src="https://d30j33t1r58ioz.cloudfront.net/static/guides/sdk.png"
      style="width: 90vw;" />
  </body>
</html>
`;

var printStyles = [
  {
    id: 0,
    name: "Group By Day"
  },
  {
    id: 1,
    name: "Group By Category"
  }
]

export default function CalendarPage({ route, navigation })  {
  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;

  const [itemList, setItemList] = React.useState(null)
  const [dotsMapped, setDots] = React.useState(null)
  const [selectedDay, setSelectedDay] = React.useState(null)
  const [data, setData] = useState(null);

  const [reload, setReload] = useState(false);

  const [isShowingPrintDialog, togglePrintDialog] = useState(false);

  const [printStyle, setPrintStyle] = useState(0);
  const [printDirection, setPrintDirection] = useState(0);
  const [startDate, setStartDate] = useState(Moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(Moment().add(7, 'd').format("YYYY-MM-DD"));


  var pageID = route.params.id;  
  var index = config!=null ? config.tools.indexOf(pageID) : 0;
  var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];
  
console.log(config)
  function signUp(event) {
    navigation.navigate("SignUp", {refresh: refresh, config: config, refresh2: refresh2, user: user, event: event, id: pageID})
  }


  const [selectedPrinter, setSelectedPrinter] = React.useState();

  const print = async () => {
   
    navigation.navigate("CalendarPrint", {refresh: refresh, config: config, refresh2: refresh2, user: user, events: data, id: pageID, settings: {printStyle: printStyle, printDirection: printDirection, startDate: startDate, endDate: endDate}})
    
  };

  const printToFile = async () => {
    // On iOS/android prints the given html. On web prints the HTML from the current page.
    const { uri } = await Print.printToFileAsync({ html });
    console.log('File has been saved to:', uri);
    await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
  };

  const selectPrinter = async () => {
    const printer = await Print.selectPrinterAsync(); // iOS only
    setSelectedPrinter(printer);
  };




  function refresh2()
  {
    updateCalendar()
  }


  useEffect(() => {
    setItemList(null)
    
  }, [dotsMapped])

  

  function refresh() {
    updateCalendar()
  }

  
  function del(item)
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "command": "DeleteCalendarItem",
          "camp": user.Home,
          "user": user.screenName,
          "item": item.EID
          })
          })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson)
            updateCalendar()
          })
  }

  function updateCalendar()
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({"command": "GetCalendar",
      "camp": user.Home,
      "user": user.UID,
      "userGroups": user.smallGroup+","+user.groupList

      })
   })
.then((response) => response.json())
.then((responseJson) => {
  setData(responseJson.records);
  var d = {}
  responseJson.records.map((item) => {
    var amt = item.Workers.split("&").length;
    
      var c = amt-1>=item.amountOfWorkers ? theme.colors.medGreen : amt-1==0 ? theme.colors.red : theme.colors.yellow
  
    var currentCat = (responseJson.categories.find((cat) => cat.CID==item.CID))==null ? null : (responseJson.categories.find((cat) => cat.CID==item.CID)).Groups
    var access = true
  
    if(currentCat!=null && user!=null)
    {
      access=false;
      
      var userList = ((user.smallGroup + "," + user.groupList).split(","))
      var currentCatList = currentCat.split(",")
      userList.map((userGroup) => {
        if(currentCatList.includes(userGroup))
        {
          access=true
        }
      })
    }
    
    if(access)
    {
    if(item.Date in d)
    {
      d[item.Date].dots.push({key: item.EID, color: c})
    }
    else
    {
      d[item.Date] = {dots: [{key: item.EID, color: c}]}
    }
  }
  
    
  })
  setDots(d)


  
} ).catch((exception) => {
  
})
  }

  
 const vacation = {key: 'vacation', color: 'red'};
 const massage = {key: 'massage', color: 'blue', selectedDotColor: 'blue'};
 const workout = {key: 'workout', color: 'green'};
 

  useEffect(() => {
    updateCalendar()
  
  }, []);
    
  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={config.permissions.CanCreateSpots==1 ? styles.floatingButton : {display: "none"}}>
    <Button style={styles.button} onP={() => {    navigation.navigate("NewCalCat", {refresh: refresh, config: config, refresh2: refresh2, user: user, id: pageID})}} >
        <MaterialCommunityIcons name="calendar-blank-multiple" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>
      <Button style={styles.button} onP={() => {    navigation.navigate("NewEvent", {refresh: refresh, config: config, refresh2: refresh2, user: user, id: pageID})}} >
        <MaterialCommunityIcons name="calendar-plus" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>
      </View>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} /></View>

    <View style={[styles.content, {backgroundColor: config!=null && config.theme.assignments!=null ? config.theme.assignments + "12" : themeColor + "12", borderColor: config!=null && config.theme.assignments!=null ? config.theme.assignments : themeColor}]}><Calendar
     //Right now, each item in the datamapped object has a .dots property
     //I need the property to be the only thing, and in the actual object, not the sub object
  selected={Moment(Date.now()).format("YYYY-MM-DD")}
  markingType={'multi-dot'}
  markedDates={dotsMapped}
  renderArrow={direction => {
    if(direction=="left")
    {
      return (
        <Entypo name="chevron-left" size={24} color="black" />
      )
    }
    else
    {
      return (
        <Entypo name="chevron-right" size={24} color="black" />
      )
    }
    
  }}
  // Callback that fires when the calendar is opened or closed
  // Callback that gets called on day press
  onDayPress={day => {
    setSelectedDay(day)
    //

    var a = data.filter((item) => item.Date==day.dateString);
    if(a!=null)
    {
      setItemList(
        (a.sort((a,b) => {
          return(
            a.Time < b.Time ? -1 : 1
          )
        }).map((event, index) => {
        return (<CalendarItem  del={(item) => {del(item)}} key={index} userScreenName={user.screenName} isAdmin={config.permissions.CanModifyVolunteers==1} signUp={signUp} item={event} />)
        })))
      }
    else
    {
      setItemList(null)
    }
  }}
  // Initially selected day
  // Specify how each item should be rendered in agenda

  
  enableSwipeMonths={true}


  // Specify how each date should be rendered. day can be undefined if the item is not first in that day

  // Agenda theme
  theme={{
    selectedDayBackgroundColor: '#00adf5',
    selectedDayTextColor: '#ffffff',
    agendaDayTextColor: theme.colors.gray3,
    todayTextColor: theme.colors.red,
    dotColor: theme.colors.red,
  }}
  // Agenda container style
  
/>
<View style={[isShowingPrintDialog ? styles.fixed : {display: "none"}, {backgroundColor: theme.colors.listItemBackground, width: "100%", padding: 30}]}>
<Text style={{color: theme.colors.itemTextColor}}>Print Settings</Text>
<Text style={{color: theme.colors.itemTextColor, marginTop: 15}}>Item Grouping</Text>
  
  <Picker
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderWidth: 2, borderColor: "#000"}}
  selectedValue={printStyle}
  onValueChange={(newPrintStyle, itemIndex) => {
    
    setPrintStyle(newPrintStyle)
  }
  }>
    <Picker.Item label="Group By Day" value={0} />
    <Picker.Item label="Group By Event Type" value={1} />
</Picker>
<Text style={{marginTop: 15, color: theme.colors.itemTextColor}}>Start Date</Text>
{createElement('input', {
      type: 'date',
      value: startDate,
      style: {marginVertical: 10, borderRadius: 10, fontSize: 16, width: "40%", borderColor: theme.colors.itemTextColor, color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground, borderWidth: 1},

      onInput: (e) => {
        console.log("E: ", e.target.value)
        setStartDate((e.target.value))
      }
    })}
<Text style={{marginTop: 15}}>End Date</Text>
{createElement('input', {
      type: 'date',
      value: endDate,
      style: {marginVertical: 10, borderRadius: 10, fontSize: 16, width: "40%", borderColor: theme.colors.itemTextColor, color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground, borderWidth: 1},

      onInput: (e) => {
        console.log("E: ", e.target.value)
        setStartDate((e.target.value))
      }
    })}
<Button onP={print} style={[{zIndex: 500, borderColor: "#000", borderWidth: 1, backgroundColor: theme.colors.listItemBackground}]}><Entypo name='print' color={theme.colors.itemTextColor} size={25}></Entypo><Text style={{color: theme.colors.itemTextColor}}> Print</Text></Button>
</View>
<Button onP={() => {togglePrintDialog(!isShowingPrintDialog)}} style={[styles.floatingButton, {left: "5%", borderColor: "#000", borderWidth: 1}]}><Entypo name='print' color={theme.colors.itemTextColor} size={25}></Entypo></Button>
  {!isShowingPrintDialog ? <ScrollView contentContainerStyle={{width: '100%'}}>
  {itemList}
  <View style={{marginBottom: 80}}></View>
    </ScrollView> : null}
      </View>
    
      </View>



    
    </View>
   ) };
