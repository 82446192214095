import { Dimensions, StyleSheet} from 'react-native';
import {theme} from '../core/theme';

export const styles = StyleSheet.create({
    
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
        backgroundColor: theme.colors.listItemBackground
    },

    body: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        alignItems: "stretch",
      },

    navDrawer: {
        display: "flex",
        flexDirection: "column",
        flex: 0.1,
        alignItems: "stretch"
      },
      content: {
          borderWidth: 4,
          flexDirection: "column",
          justifyContent: "flex-start",   
          alignItems: "center",    
          paddingTop: 50, 
          flex: 0.9,
          display: "flex"
  
      }

});
  