import React, { memo, useEffect, useState } from 'react';
import { styles } from '../styles/EntryStyle';
import { Dimensions, ScrollView, Image, Text, TouchableOpacity, View } from 'react-native';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import StatusOverlay from '../components/StatusOverlay'
import { navTo, pickTextColorBasedOnBgColorSimple } from '../core/utils';
import { activities } from '../core/activities';
import Button from '../components/Button';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import TextInput from '../components/TextInput';
import MultiSelect from 'react-native-multiple-select'
import {Picker} from '@react-native-picker/picker';


export default function EntryCode({ navigation, route })  {
  var user = route.params.user;
var config = route.params.config;
var refresh = route.params.refresh;
var pageID = route.params.id;  
var index = config!=null ? config.tools.filter((p) => {
  var toolSet = activities(config)
  return toolSet.find((t) => t.id==p).isAllowed!="false"
}).indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

var [colorList, setColorList] = React.useState(JSON.parse(JSON.stringify(config.theme.length>0 ? config.theme : theme.colors.activities)).sort(() => Math.random() - 0.5))
function getColor(ind) {
  return colorList[ind % colorList.length]
}


const [entryCodes, setEntryCodes] = React.useState()
const [formattedEntryCodes, setFormattedEntryCodes] = React.useState(null)
const [loading, setLoading] = React.useState(false)
const [newEntryCode, isMakingNewEntryCode] = React.useState(false)

const [smallGroups,setSmallGroups] = React.useState(null)


const [currentCode, setCurrentCode] = React.useState(null)
const [primaryGroup, setPrimaryGroup] = React.useState(null)
const [selectedSmallGroups, setSelectedSmallGroups] = React.useState([])

  useEffect(() => {
    if(entryCodes==null || smallGroups==null)
    {
      setFormattedEntryCodes(
        <Text style={{color: theme.colors.itemTextColor, fontSize: 26, marginLeft: 5, fontFamily: "Carter-One"}}>Tap below to create an entry code</Text>
      )
    }
    else
    {
      if(entryCodes.records!=null)
      {
        console.log(entryCodes.records)
      setFormattedEntryCodes(
        entryCodes.records.map((code,ind) => {
          return(
            <View style={{backgroundColor: getColor(ind), padding: 10, marginVertical: 10, borderRadius: 20, width: "100%", justifyContent: "space-between", display: "flex", alignItems: "center", flexDirection: "row"}}>
            <View style={{backgroundColor: "transparent", padding: 0, borderRadius: 20, flex: 0.8, justifyContent: "space-between", display: "flex", alignItems: "center", flexDirection: "column"}}>
              <Text style={{color: pickTextColorBasedOnBgColorSimple(getColor(ind), "#eee", "#000"), fontSize: 26, marginLeft: 0, alignSelf: "flex-start", fontFamily: "Carter-One"}}>{entryCodes.smallGroups.find((s) => s.id==code.startingGroup)!=null ? entryCodes.smallGroups.find((s) => s.id==code.startingGroup).name : code.startingGroup}</Text>
              <Text style={{color: pickTextColorBasedOnBgColorSimple(getColor(ind), "#eee", "#000"), fontSize: 14, marginLeft: 5, width: "100%", marginLeft: 5, fontFamily: "Glacial-Indifference"}}>Created:{"\n"}{code.creationTime}</Text>
            </View>
            <Button onP={() => {deleteEntryCode(code.id)}} style={{backgroundColor: theme.colors.redButton, width:"auto"}}>
            <MaterialCommunityIcons name="delete" size={24} color={theme.colors.itemTextColor} />
            </Button>
            </View>
          )
        })
      )}
    }
  }, [entryCodes, smallGroups])

  function deleteEntryCode(codeID)
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "DeleteEntryCodes",
        "camp": user.Home,
        "user": user.UID,
        "deleteID": codeID
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
      
      setSmallGroups(responseJson.allGroups.map((gr) => {
        return({
          name: gr.Name,
          id: gr.id
        })
              }))
    setEntryCodes(({records: responseJson.records, smallGroups: responseJson.smallGroups}))
   }
   else if(responseJson.code==4)
   {
    setEntryCodes(null)
   }
  }).finally(() => {
    setLoading(false)
   });

  }


  function createCode()
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SubmitEntryCode",
        "camp": user.Home,
        "user": user.UID,
        "code": currentCode,
        "primaryGroup": primaryGroup,
        "otherGroups": selectedSmallGroups.join(",")
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
      
      setSmallGroups(responseJson.allGroups.map((gr) => {
        return({
          name: gr.Name,
          id: gr.id
        })
              }))
    setEntryCodes(({records: responseJson.records, smallGroups: responseJson.smallGroups}))
   }
  }).finally(() => {
    setLoading(false)
  setCurrentCode(null)
  setSelectedSmallGroups([])
  setPrimaryGroup(null)
  isMakingNewEntryCode(false)
   });

  }

  function cancelEditor()
  {
    
  setCurrentCode(null)
  setSelectedSmallGroups([])
  setPrimaryGroup(null)
  isMakingNewEntryCode(false)
  }


  function getUsers() {

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetEntryCodes",
        "camp": user.Home,
        "user": user.UID
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
      setSmallGroups(responseJson.allGroups.map((gr) => {
return({
  name: gr.Name,
  id: gr.id
})
      }))
    setEntryCodes(({records: responseJson.records, smallGroups: responseJson.smallGroups}))
   }}).finally(() => {
    setLoading(false)
   });

  }
  

  useEffect(() => {
  setLoading(true)
   getUsers()

  }, [])




  return (
    
    
    <View style={styles.container}>
      {loading && <StatusOverlay></StatusOverlay>}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer active={pageID} customTheme={config.theme} items={config.tools} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
      </View>
      <Button onP={() => {isMakingNewEntryCode(!newEntryCode)}} style={styles.AddButton} mode="outlined">
      <MaterialCommunityIcons name="plus" style={{width: "100%"}} size={24} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
        </Button>
      <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
   
      {newEntryCode && 
        <View style={{position: "absolute", zIndex: 50, top: 0, bottom: 0, left: 0, right: 0, backgroundColor: theme.colors.listItemBackground}}>
               <Text style={{color: theme.colors.itemTextColor, fontSize: 26, alignSelf: "center", marginTop: 20, fontFamily: "Carter-One"}}>New Entry Code</Text>
       <TextInput
  textColor={theme.colors.itemTextColor}
  label={<Text style={{color: theme.colors.itemTextColor}}>Entry Code</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 2}}
  returnKeyType="next"
  value={currentCode}
  onChangeText={text => setCurrentCode(text)}
/>    

<Picker
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,   borderWidth: 2, borderColor: "#000", marginBottom: 40}}
  selectedValue={primaryGroup}
  itemStyle={{
    fontSize: 26
  }}
  onValueChange={(itemValue, itemIndex) =>
    {
    console.log(itemValue)
    setPrimaryGroup(itemValue)
    }
  }>
  {primaryGroup==null ? <Picker.Item label="Click to Set Primary Group" value={null} /> : null }
  {smallGroups.map((sg) => {
    return(
      <Picker.Item label={sg.name} value={sg.id}  />
    )
  })}
  
</Picker>

<MultiSelect
 hideTags
 items={smallGroups}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {
  setSelectedSmallGroups(s)}
 }
 selectedItems={selectedSmallGroups}
 selectText="Select Other Groups"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 tagRemoveIconColor="#CCC"
 tagBorderColor="#CCC"
 tagTextColor="#CCC"
 selectedItemTextColor="#292"
 selectedItemIconColor="#292"
 itemTextColor="#000"
 displayKey="name"
 searchInputStyle={{ color: '#CCC' }}
 submitButtonColor="#CCC"
 submitButtonText="Submit"
/>
<Button onP={() => {
   createCode()
}} style={{backgroundColor: theme.colors.greenButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Create</Text></Button>
<Button onP={() => {
  cancelEditor()
}} style={{backgroundColor: theme.colors.redButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Cancel</Text></Button>

        </View>}
   <ScrollView style={{width: "90%"}}>
   {formattedEntryCodes}
   </ScrollView>
    </View>
    </View>
    </View>
   ) };
