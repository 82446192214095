import { Dimensions, StyleSheet } from 'react-native'
import { theme } from '../core/theme'

export const styles = StyleSheet.create({
    
    fixed: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0   
    },floatingButton: {
      width: 60,
      height: 60,
      position: "absolute",
      borderRadius: 50,
      bottom: "10%",
      right: "5%",
            justifyContent: "space-around",
      alignItems: "center",
      flexDirection: "row",
      zIndex: 1,
    },
    button: {
      width: "100%",
      borderRadius: 50,
      height: 60,
      backgroundColor: theme.colors.listItemBackground,
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 2,
      borderColor: "#000"
    },
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
        backgroundColor: theme.colors.listItemBackground
    },
    logoutButton: {
        position: "absolute",
        top: 0,
        width: 20,
        left: 20,
        zIndex: 100,
        borderRadius: 50
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "stretch",
    },

    navDrawer: {
      display: "flex",
      flexDirection: "column",
      flex: 0.1,
      alignItems: "stretch"
    },
    content: {
        borderWidth: 4,
        flexDirection: "column",
        justifyContent: "flex-start",   
        alignItems: "stretch",    
        flex: 0.9,
        display: "flex"

    }
    
  });