import React, { memo, useCallback, useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { AntDesign } from '@expo/vector-icons'; 
import { Picker } from '@react-native-picker/picker';
import TextInput from './TextInput';
import Button from './Button';




export default function ShopSizeEntry({ updateQuantity, workingItem, sizeType, sizes, size, addToCart, removeFromCart, cart, IID }) {

  function checkCart()
  {
    if(cart!=null && 
      cart[IID] !=null)
      {
        return cart[IID]
      }
      return 0
  }

  const [cartValue, setCartValue] = React.useState(0)

  useEffect(() => {
    
    if(cart!=null && 
      cart[IID] !=null)
      {
        setCartValue(cart[IID])
      }
      
  }, [cart])
  

 
return(
<View style={{display: "flex", flexDirection: "row", padding: 10, flex: 1, borderColor: "#000", borderWidth: 2, alignItems: "center"}}>
<View style={{display: "flex", flexDirection: "column", flex: 0.6}}>
<Text style={{marginTop: 10, fontFamily: "Glacial-Indifference", fontSize: 16, color: theme.colors.itemTextColor}}
          >{(sizeType.name)} - {((sizeType.values.find((s) => s.id==size)).value)}</Text>
      <Text style={{marginBottom: 10, fontFamily: "Glacial-Indifference", fontStyle: "italic", fontSize: 14, color: theme.colors.itemTextColor}}
          >{workingItem.quantity[sizeType.id][size] - cartValue} Remaining</Text>
      
      </View>
      <View style={{display: "flex", flexDirection: "row", flex: 0.4, justifyContent: "space-around"}}>
        <TouchableOpacity style={{padding: 5}} onPress={() => {
          console.log(cart)
          removeFromCart(workingItem, sizeType, size)
          setCartValue(checkCart())
        }}><AntDesign name="minus" size={24} color={theme.colors.itemTextColor}/></TouchableOpacity>
        <Text style={{color: theme.colors.itemTextColor, fontSize: 24, justifyContent: "center", alignContent: "center", alignSelf: "center", alignItems: "center", fontFamily: "Glacial-Indifference"}}>{cartValue}</Text>
        <TouchableOpacity style={{padding: 5}} onPress={() => {
          addToCart(workingItem, sizeType, size)
          setCartValue(checkCart())
        }}><AntDesign name="plus" size={24} color={theme.colors.itemTextColor}/></TouchableOpacity>
      </View>
      </View>
      
  )
  
}

