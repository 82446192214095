import {Fontisto, FontAwesome, MaterialCommunityIcons, Entypo, Ionicons, FontAwesome5, AntDesign} from '@expo/vector-icons'
import { theme } from '../core/theme';


export var pages = [
  
]


export function activities(configTheme, size) {
  return(
[
  
  {
    id: 0,
    name: "List",
    navName: "List",
    isAllowed: true,
    icon: <MaterialCommunityIcons name="format-list-bulleted" size={size==null ? 75 : size} color="white" />,
    color: configTheme!=null && configTheme.list!=null ? configTheme.list : theme.colors.list
  },
  {
    id: 1,
    name: "Map",
    navName: "Map",
    isAllowed: true,
    icon: <MaterialCommunityIcons name="map" size={size==null ? 75 : size} color="white" />,
    color: configTheme!=null && configTheme.map!=null ? configTheme.map : theme.colors.map
  },
  {
    id: 2,
    name: "Assignments",
    navName: "CalendarPage",
    isAllowed: true,
    icon: <MaterialCommunityIcons name="calendar-month-outline" size={size==null ? 75 : size} color="white" />,
    color: configTheme!=null && configTheme.assignments!=null ? configTheme.assignments : theme.colors.assignments
  },
  {
    id: 3,
    name: "Announcements",
    navName: "Announcements",
    isAllowed: true,
    color: configTheme!=null && configTheme.announcements!=null ? configTheme.announcements : theme.colors.announcements,
    icon: <Entypo name="megaphone" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 4,
    name: "Scoreboard",
    navName: "Secret",
    isAllowed: true,
    icon: <Entypo name="bar-graph" size={size==null ? 75 : size} color="white" />,
    color: configTheme!=null && configTheme.scoreboard!=null ? configTheme.scoreboard : theme.colors.scoreboard
  },
  {
    name: "Reports",
    navName: "Report",
    isAllowed: "true",
    id: 5,
    icon: <MaterialCommunityIcons name="abacus" size={size==null ? 75 : size} color="white" />,
    color: configTheme!=null && configTheme.reports!=null ? configTheme.reports : theme.colors.reports
  },
  {
    id: 6,
    name: "Secret Santa",
    navName: "SecretSanta",
    isAllowed: true,
    icon: <FontAwesome name="tree" size={size==null ? 75 : size} color="white" />,
    color: configTheme!=null && configTheme.santa!=null ? configTheme.santa : theme.colors.santa
  },
  {
    id: 51,
    name: "Edit Profile",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="account-edit" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 52,
    name: "Change Password",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="key-change" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 53,
    name: "Change Theme",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="palette" size={size==null ? 75 : size} color="white" />,
  },
  
  {
    id: 55,
    name: "Read Staff Shoutouts",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="bullhorn-variant" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 57,
    name: "Random Act of Kindness",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="heart" size={size==null ? 75 : size} color="white" />,
    configItem: "RAK"
  },
  {
    id: 54,
    name: "Reset List",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="reload" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 510,
    name: "Assign List",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="hand-front-left" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 59,
    name: "Secret Santa Hosting",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="tree" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 512,
    name: "Edit Config",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="cog" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 513,
    name: "Edit User",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="account-box-multiple" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 514,
    name: "Edit Small Group",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="account-group" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 515,
    name: "Raw Input",
    navName: "Settings2",
    icon: <MaterialCommunityIcons name="file-document-edit" size={size==null ? 75 : size} color="white" />,
  },
  {
    id: 60,
    name: "Directory",
    navName: "Directory",
    icon: <MaterialCommunityIcons name="folder-shared" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 61,
    name: "Shopping List",
    navName: "ShoppingList",
    icon: <Fontisto name="shopping-store" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 62,
    name: "News",
    navName: "NewsList",
    icon: <FontAwesome name="newspaper-o" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 63,
    name: "Create News",
    navName: "NewsCreate",
    icon: <MaterialCommunityIcons name="newspaper-plus" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 64,
    name: "Prayer Partners",
    navName: "Support",
    icon: <FontAwesome5 name="bible" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 71,
    name: "Inventory Admin",
    navName: "InventoryAdmin",
    icon: <AntDesign name="database" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 72,
    name: "Inventory Front Desk",
    navName: "InventoryFrontDesk",
    icon: <FontAwesome5 name="cash-register" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 80,
    name: "Game",
    navName: "Game",
    icon: <MaterialCommunityIcons name="nintendo-game-boy" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 81,
    name: "Messenger",
    navName: "Messenger",
    icon: <MaterialCommunityIcons name="message-star" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 82,
    name: "Edit Entry Codes",
    navName: "EntryCode",
    icon: <MaterialCommunityIcons name="car-door-lock" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 83,
    name: "Upload Images",
    navName: "FileUpload",
    icon: <MaterialCommunityIcons name="file-upload" size={size==null ? 75 : size} color="white" />
  },
  {
    id: 84,
    name: "View Files",
    navName: "ViewFile",
    icon: <Ionicons name="md-file-tray-full-sharp" size={size==null ? 75 : size} color="white" />
  },
  


])}