import React, { memo, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';




export default function Title({  children, color }) {

 
const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    
  },
  header: {
    fontSize: 32,
    color: theme.colors.itemTextColor
  },
});

const [fontsLoaded] = useFonts({
  'Carter-One': require('../assets/fonts/carter-one/CarterOne.ttf'),
});

const onLayoutRootView = useCallback(async () => {
  if (fontsLoaded) {
    await SplashScreen.hideAsync();
  }
}, [fontsLoaded]);

if (!fontsLoaded) {
  return null;
}

  return(

<View style={styles.container} onLayout={onLayoutRootView}>
<Text style={[styles.header, {fontFamily: "Carter-One", color: color}]}>{children==null ? "Outpost" : children}</Text>
</View>
)
  
}

