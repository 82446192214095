import { DarkTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import Header from './components/Header';
import Constants from 'expo-constants';

import {
  HomeScreen,
  LoginScreen,
  Dashboard,
  RegisterScreen,
  ForgotPasswordScreen,
  Wrapper,
  NewEvent,
  Settings2,
  NewSite,
  Secret,
  Landing,
  SecretSanta,
  Settings,
  CalendarPage,
  Scoreboard,
  Report,
  Directory,
  ShoppingList,
  Support,
  NewsList,
  Details,
  CalendarPrint,
  List,
  NewsCreate,
  SignUp,
  Map,
  Announcements,
  Game,
  ListPrint,
  Init,
  InventoryAdmin,
  InventoryFrontDesk,
  Messenger,
  EntryCode,
  NewCalCat,
  FileUpload,
  ViewFile
} from './screens';

const Router = createStackNavigator();

function MyStack() {
  return(
    <Router.Navigator
    initialRouteName='Init'
    screenOptions={{
      headerMode: "float",
      header: ({ navigation, route, options, back }) => {      
        return (
          <Header
            />
        );
      },
    
      
    }}


    
    >
    <Router.Screen name="Home" component={HomeScreen}  />
    <Router.Screen name="LoginScreen" component={LoginScreen} />
    <Router.Screen name="Dashboard" component={Dashboard}  />
    <Router.Screen name="RegisterScreen" component={RegisterScreen} />
    <Router.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen}  />
    <Router.Screen name="Wrapper" component={Wrapper} />
    <Router.Screen name="NewEvent" component={NewEvent}  />
    <Router.Screen name="Settings2" component={Settings2} />
    <Router.Screen name="NewSite" component={NewSite}  />
    <Router.Screen name="Secret" component={Secret} />
    <Router.Screen name="Landing" component={Landing}  />
    <Router.Screen name="SecretSanta" component={SecretSanta} />
    <Router.Screen name="Settings" component={Settings}  />
    <Router.Screen name="CalendarPage" component={CalendarPage} />
    <Router.Screen name="Scoreboard" component={Scoreboard} />
    <Router.Screen name="Report" component={Report} />
    <Router.Screen name="Directory" component={Directory} />
    <Router.Screen name="ShoppingList" component={ShoppingList} />
    <Router.Screen name="Support" component={Support} />
    <Router.Screen name="NewsList" component={NewsList} />
    <Router.Screen name="Details" component={Details} />
    <Router.Screen name="CalendarPrint" component={CalendarPrint} />
    <Router.Screen name="List" component={List} />
    <Router.Screen name="NewsCreate" component={NewsCreate} />
    <Router.Screen name="SignUp" component={SignUp} />
    <Router.Screen name="Map" component={Map} />
    <Router.Screen name="Announcements" component={Announcements} />
    <Router.Screen name="ListPrint" component={ListPrint} />
    <Router.Screen name="Game" component={Game} />
    <Router.Screen name="InventoryAdmin" component={InventoryAdmin} />
    <Router.Screen name="InventoryFrontDesk" component={InventoryFrontDesk} />
    <Router.Screen name="Init" component={Init} />
    <Router.Screen name="Messenger" component={Messenger} />
    <Router.Screen name="EntryCode" component={EntryCode} />
    <Router.Screen name="NewCalCat" component={NewCalCat} />
    <Router.Screen name="FileUpload" component={FileUpload} />
    <Router.Screen name="ViewFile" component={ViewFile} />
 
      
    </Router.Navigator>
  )
}


export default function Navigation() {
  return(
  <NavigationContainer theme={DarkTheme}><MyStack/></NavigationContainer>
  )
} 