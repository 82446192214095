import React, { memo, useEffect, useState } from 'react';
import { styles } from '../styles/EntryStyle';
import { Dimensions, ScrollView, Image, Text, TouchableOpacity, View } from 'react-native';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import StatusOverlay from '../components/StatusOverlay'
import { navTo, pickTextColorBasedOnBgColorSimple } from '../core/utils';
import { activities } from '../core/activities';
import Button from '../components/Button';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons'; 
import Moment from 'moment';
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import TextInput from '../components/TextInput';



export default function ViewFile({ navigation, route })  {
  var user = route.params.user;
var config = route.params.config;
var refresh = route.params.refresh;
var pageID = route.params.id;  
var index = config!=null ? config.tools.filter((p) => {
  var toolSet = activities(config)
  return toolSet.find((t) => t.id==p).isAllowed!="false"
}).indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

var [colorList, setColorList] = React.useState(JSON.parse(JSON.stringify(config.theme.length>0 ? config.theme : theme.colors.activities)).sort(() => Math.random() - 0.5))
function getColor(ind) {
  return colorList[ind % colorList.length]
}


function getLink(a)
{
  return "https://outpostorganizer.com/SITE/Global/Files/" + user.Home + "/" + a.FileCategory + "/" + a.FileName
}

function getType(t)
{
  return null//"Image"
}

const [files, setFiles] = React.useState()
const [formattedEntryCodes, setFormattedFiles] = React.useState(null)
const [loading, setLoading] = React.useState(false)

const [userList, setUsers] = React.useState(null)

const [activeFile, setActiveFile] = React.useState(null)
const [activeFileIsSaved, setActiveFileIsSaved] = React.useState(null)

const [search, setSearch]  =React.useState("")

function downloadFile(url){
  setLoading(true)
  const uri = url
    let fileUri = FileSystem.documentDirectory + activeFile.FileName;
    FileSystem.downloadAsync(uri, fileUri)
    .then(({ uri }) => {
        saveFile(uri).then(() => {
          setActiveFileIsSaved(true)
          setLoading(false)
          
        });
      })
      .catch(error => {
        console.error(error);
      })
}

const saveFile = async (fileUri) => {
    const { status } = await MediaLibrary.requestPermissionsAsync();
    if (status === "granted") {
        const asset = await MediaLibrary.createAssetAsync(fileUri)
        await MediaLibrary.createAlbumAsync("Download", asset, true)
    }
}


  useEffect(() => {
    if(files==null || userList==null)
    {
      setFormattedFiles(
        <Text style={{color: theme.colors.itemTextColor, fontSize: 26, marginLeft: 5, fontFamily: "Carter-One"}}>No Files</Text>
      )
    }
    else
    {
      setFormattedFiles(
        files.filter((f) => f.FileCategory.toLowerCase().includes(search.toLowerCase()) || f.DescribedName.toLowerCase().includes(search.toLowerCase()) ||
        userList.find((u) => u.UID==f.UploadUID)!=null && userList.find((u) => u.UID==f.UploadUID).screenName.toLowerCase().includes(search.toLowerCase())).map((f,ind) => {
          return(
            <TouchableOpacity onPress={() => {
              
              setActiveFile(f)
              console.log("https://outpostorganizer.com/SITE/global/Files/" + user.Home + "/" + f.FileCategory + "/" + f.FileName )
              }} style={{backgroundColor: getColor(ind), padding: 10, marginVertical: 10, borderRadius: 20, width: "100%", justifyContent: "space-between", display: "flex", alignItems: "center", flexDirection: "row"}}>
            <View style={{backgroundColor: "transparent", padding: 0, borderRadius: 20, flex: 0.8, justifyContent: "space-between", display: "flex", alignItems: "center", flexDirection: "column"}}>
              <Text style={{color: pickTextColorBasedOnBgColorSimple(getColor(ind), "#eee", "#000"), fontSize: 24, marginLeft: 0, alignSelf: "flex-start", fontFamily: "Carter-One"}}>{f.DescribedName} 
              <Text style={{color: pickTextColorBasedOnBgColorSimple(getColor(ind), "#eee", "#000"), fontSize: 20, alignSelf: "flex-end", marginLeft: 5, width: "100%", marginLeft: 5, fontFamily: "Glacial-Indifference"}}>  {getType(f.Type)}</Text>
              </Text>
              <Text style={{color: pickTextColorBasedOnBgColorSimple(getColor(ind), "#eee", "#000"), fontSize: 14, marginLeft: 5, width: "100%", marginLeft: 5, fontFamily: "Glacial-Indifference"}}>{(f.Desc)}</Text>
            </View>
            <Button onP={() => {del(f)}} style={{backgroundColor: theme.colors.redButton, width:"auto"}}>
            <MaterialCommunityIcons name="delete" size={24} color={theme.colors.itemTextColor} />
            </Button>
            </TouchableOpacity>
          )
        })
      )}
  }, [files, userList, search])



  function getData() {

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetFiles",
        "camp": user.Home,
        "user": user.UID
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
    setFiles(responseJson.records)
   }
   else if(responseJson.code==4)
   {
    setFiles(null)
   }
  }).finally(() => {
    setLoading(false)
   });

   fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "GetUsers",
      "camp": user.Home,
      "user": user.UID
      
  })
 })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  console.log(responseJson)
  if(responseJson.code==1)
  {
  setUsers(responseJson.records)
 }
 else if(responseJson.code==4)
 {
  setUsers(null)
 }
}).finally(() => {
  setLoading(false)
 });

  }
  

  useEffect(() => {
  setLoading(true)
   getData()

  }, [])

  
  function del(f) {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "DeleteFile",
        "camp": user.Home,
        "user": user.UID,
        "file": f.FID
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
    setFiles(responseJson.records)
   }
   else if(responseJson.code==4)
   {
    setFiles(null)
   }}).finally(() => {
    setLoading(false)
    setActiveFile(null)
    setActiveFileIsSaved(false)
   });

  }
  

  useEffect(() => {
  setLoading(true)
   getData()

  }, [])




  return (
    
    
    <View style={styles.container}>
      {loading && <StatusOverlay></StatusOverlay>}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer active={pageID} customTheme={config.theme} items={config.tools} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
      </View>
       <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
       {activeFile!=null && 
        <View style={{position: "absolute", zIndex: 50, top: 0, bottom: 0, left: 0, right: 0, marginTop: 40, backgroundColor: theme.colors.listItemBackground}}>
               <Text style={{color: theme.colors.itemTextColor, fontSize: 26, alignSelf: "center", marginTop: 20, fontFamily: "Carter-One"}}>{activeFile.DescribedName}</Text>
               <Text style={{color: theme.colors.itemTextColor, fontSize: 18, fontStyle: "italic", alignSelf: "center", marginTop: 0, fontFamily: "Carter-One"}}>Uploaded by {userList.find((u) => u.UID==activeFile.UploadUID).screenName} {"\n"}on {Moment(activeFile.UploadTime).format("MMM Do, YYYY")}{"\n"}at {Moment(activeFile.UploadTime).format("H:m A")}</Text>
              {activeFile.Type==1 &&
                <Image source={{uri: getLink(activeFile) }} resizeMode={"contain"} style={{alignSelf: "center", width: "90%", height: 300}}></Image>
              }

               <Text style={{color: theme.colors.itemTextColor, fontSize: 26, alignSelf: "center", width: "90%", marginLeft: 10, borderRadius: 5, borderWidth: 2, padding: 10, marginTop: 20, fontFamily: "Carter-One"}}>Description:{"\n"}
               <Text style={{color: theme.colors.itemTextColor, fontSize: 18, alignSelf: "center", marginTop: 20, fontFamily: "Glacial-Indifference"}}>
               {activeFile.Desc}</Text>
               </Text>
     
<Button disabled={activeFileIsSaved} onP={() => {
  downloadFile(getLink(activeFile))
}} style={{backgroundColor: activeFileIsSaved ? theme.colors.gray : theme.colors.greenButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>{activeFileIsSaved ? "Saved!" : "Download"}</Text></Button>
<Button onP={() => {
  del(activeFile)
}} style={{backgroundColor: theme.colors.redButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Delete</Text></Button>

        </View>}
        <Button onP={activeFile!=null ? () => {
          setActiveFileIsSaved(false)
          setActiveFile(null)
}  : () => {refresh()
navTo("Dashboard", user, config, null, navigation)}} style={styles.backButton} mode="outlined">
      <Ionicons name="return-down-back" style={{width: "100%"}} size={24} color={theme.colors.itemTextColor}></Ionicons>
        </Button>
        <TextInput   
        textColor={theme.colors.itemTextColor}
  label={<Text style={{color: theme.colors.itemTextColor}}>Search...</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="next"
  value={search}
  onChangeText={text => setSearch(text)}>

        </TextInput>
   <ScrollView style={{width: "90%"}}>
   {formattedEntryCodes}
   </ScrollView>
    </View>
    </View>
    </View>
   ) };
