import { memo } from 'react';
import { TouchableOpacity, Dimensions, StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import CheckBox from 'react-native-check-box'
import * as React from 'react';
import {LinearGradient} from 'expo-linear-gradient';
import { activities } from '../core/activities';

const TodayItem = ({ config, onPress, isDenied, title, time, style, children, type, ...props }) => {

const [checked, setChecked] = React.useState(false);

var toolList = activities(config)

function getGradient() {
  var colors = []
  if(config==null)
  {
    return [theme.colors.activities[0], theme.colors.activities[1]]
  }
  else
  {
    var listLoc = config.tools.indexOf(0)
    var mapLoc = config.tools.indexOf(1)
    if(listLoc!=-1)
    {
      colors.push(config.theme.length>listLoc ? config.theme[listLoc] : theme.colors.activities[listLoc])
    }
    if(mapLoc!=-1 && activities(config).find((t) => t.id == 1).isAllowed)
    {
      colors.push(config.theme.length>mapLoc ? config.theme[mapLoc] : theme.colors.activities[mapLoc])
    }
  }
  if(colors.length==0)
  {
    return [theme.colors.activities[0], theme.colors.activities[1]]
  }
  else if(colors.length==1)
  {
    return [colors[0], colors[0]]
  }
  return colors
  
}


const styles = StyleSheet.create({
  text: {
    fontSize: 20, 
    lineHeight: 26,
    color: "#fff",
    marginBottom: 14,
    textAlign: 'center',
    zIndex: 100

  },
  header: {
    fontSize: 24,
    lineHeight: 24,
    marginTop: 10,
    textAlign: 'center',
    color: "#fff",
    zIndex: 100
  },
  container:
  {
    lineHeight: 26,
    borderRadius: 15,
    backgroundColor: (config.theme.length>config.tools.indexOf(2)) ? config.theme[config.tools.indexOf(2)] : config.tools.indexOf(2)!=-1 ? theme.colors.activities[config.tools.indexOf(2)] : theme.colors.activities[0],
    marginBottom: 6,
    width: '100%',
    zIndex: 100,
    borderWidth: 5,
    borderColor: isDenied==1 ? "#f54" : theme.colors.listItemBackground
  }
  
});


return (
<TouchableOpacity activeOpacity={0.8} onPress={onPress} style={styles.container}>

<Text style={styles.header}>{title}</Text>
<Text {...props} style={styles.text}>{time=="\"\"" ? null : time}</Text>
{type==1 ?   <LinearGradient
  start={[0, 1]}
  end={[1, 0]}
  style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 0,    borderRadius: 10,}}
  colors={getGradient()}
   /> : null}
</TouchableOpacity>
   );
};
export default memo(TodayItem);
