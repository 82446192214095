import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Button as PaperButton } from 'react-native-paper';
import { theme } from '../core/theme';

const Button = ({  mode, onLongP, onP, style, children, ...props }) => {

  const styles = StyleSheet.create({
    button: {
      width: '100%',
      marginVertical: 10,
    },
    text: {
      fontWeight: 'bold',
      fontSize: 15,
      lineHeight: 26,
    },
  });
  
  return(
  <PaperButton onLongPress={onLongP} onPress={onP}
    style={[
      styles.button,
      mode === 'outlined' && { backgroundColor: theme.colors.surface },
      style,
    ]}
    labelStyle={styles.text}
    mode={mode}
    {...props}
  >
    {children}
  </PaperButton>
);
  }
export default memo(Button);
