import React, { memo, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/DetailsStyle';
import { Dimensions, TouchableOpacity, ActivityIndicator, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import TextInput from '../components/TextInput';
import StatusBar from '../components/StatusOverlay';
import SpotTask from '../components/SpotTask';
import Title from'../components/Title';
import {getSpotifyData, refreshTokens} from '../core/spotify'
import SpotifyWebAPI from 'spotify-web-api-js';

export const getValidSPObj = async () => {
  const tokenExpirationTime = await getSpotifyData('expirationTime');
  if (new Date().getTime() > tokenExpirationTime) {
    // access token has expired, so we need to use the refresh token
    console.log("refreshing tokens")
    await refreshTokens();
  }
  const accessToken = await getSpotifyData('accessToken');
  console.log("accessToken", accessToken)
  var sp = new SpotifyWebAPI();
  await sp.setAccessToken(accessToken);
  return sp;
}

export const getSpotifySongs = async () => {
  const sp = await getValidSPObj();
  var accessToken = await getSpotifyData('accessToken');
  var h = {
    'Authorization': (accessToken),
    'Content-Type': 'application/json'
}
console.log(h)
  fetch('https://api.spotify.com/v1/me', {
    headers: h
}).then(response => response.json()).then(responseJson => {
  console.log(responseJson)
  
});
 // const { id: userId } = await sp.getMe();
  const { item: {name: track} } = await sp.getMyCurrentPlayingTrack(userId, { limit: 50 });
  const { is_playing: state} = await sp.getMyCurrentPlaybackState(userId, { limit: 50 });
  console.log({track: track, state: state})
  return playlists;
};


export default function Details({ route, navigation })  {

  const [spotifyTrackingAvaliable, setSpotifyTrackingAvaliable] = React.useState(false)
  
  async function determineSpotifyTracking()
  {
    const tokenExpirationTime = await getSpotifyData('expirationTime');
    console.log(tokenExpirationTime)
    if (!tokenExpirationTime || new Date().getTime() > tokenExpirationTime) {
      setSpotifyTrackingAvaliable(false)
      //await refreshTokens();
    } else {
      setSpotifyTrackingAvaliable(true)
    }
  }

  useEffect(() => {
//   determineSpotifyTracking()
  }, [])

  useEffect(() => {
    if(spotifyTrackingAvaliable)
    {
      getSpotifySongs()
    }
  }, [spotifyTrackingAvaliable])



  var user = route.params.user;
var config = route.params.config;
var confetti = route.params.confetti;
  var item = route.params.spot;
  var refreshBack = route.params.onBack;
  var pageID = route.params.id;
  console.log("PageID: " + pageID)
  var index = config!=null ? config.tools.indexOf(pageID) : 0;
  var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

  const [loading,setLoading] = React.useState(false)
  const [tasks, setTasks] = React.useState(null);
  const [formattedTasks, setFormattedTasks] = React.useState(null);


  useEffect(() => {
    setTasks(item.tempTasks)
  }, [])

  function markItem(itemName, sid) {
    console.log(sid)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "MarkItem",
        "camp": user.Home,
        "site": item.SID, 
        "user": user.UID,
        "item": itemName
        
    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    setTasks(responseJson.records[0])
  }
    )

  }


  function submit() {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SubmitSpot",
        "camp": user.Home,
        "site": item.SID, 
        "user": user.UID,
        
    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    console.log(responseJson)
    setLoading(false);
    refreshBack();
    navigation.goBack();
  }
    )
  }


  useEffect(() => {
    if(tasks!=null)
    {
    setFormattedTasks(tasks.split("& ").map(function(spot, index) {

      return (<SpotTask SID={item.SID} mark={markItem} key={index} title={spot}/>);
      }))
    }
  }, [tasks] )

async function navTo2(location) {
  if(location=="back")
  {
    refreshBack(item.SID)
    navigation.goBack();      
  }
  else
  {
    navigation.navigate(location, {user: user, config: config, id: activities(config).find((item) => item.navName==location).id})
  }    
  }


  function navTo(location)
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "LeaveSite",
        "camp": user.Home,
        "user": user.UID,
        "spot": item.SID

        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
    refreshBack();
    navTo2(location);
  }).catch((error) =>
  {
    console.log(error)
    setLoading(false);
  })

  }

  function submit() {
    
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "SubmitSpot",
        "camp": user.Home,
        "user": user.UID,
        "site": item.SID

        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
    refreshBack();
    confetti();
    navigation.goBack();
  }).catch((error) =>
  {
    setLoading(false);
  })

  }
  
  
  

  useEffect(() => {
  
  }, [])

  
  return (
    
    
    <View style={styles.container}>
      {loading ? <StatusBar config={config}></StatusBar> : null}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer customTheme={config.theme} items={config.tools} active={0} shrink={true} navTo={navTo} isSubPage={true}/>
      </View>
    <View style={[styles.content, {backgroundColor: config.theme.list == null ? themeColor + "12" : config.theme.list + "12", borderColor: config.theme.list != null ? config.theme.list : themeColor}]}>
    <TouchableOpacity onPress={() => {
      getSpotifySongs()
    }} ><Title color={theme.colors.itemTextColor}>{item.site_Name}</Title></TouchableOpacity>
{tasks=="" ? <Button onP={() => {
  setLoading(true)
  submit()
}} style={{backgroundColor: theme.colors.greenButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "40%"}}><Text style={{color: theme.colors.itemTextColor}}>Submit</Text>
</Button> : null}
 <ScrollView
   contentContainerStyle={{width: '100%'}}>
      {tasks!="" ? formattedTasks : null}
  {loading ? <ActivityIndicator size="large" color={theme.colors.darkBlue}/> : null}
   {/* <ComplexListItem adv={advanced} progress="42%" title="Dining Hall" people="3" tasks={task1()}/>    */}
   <View style={{marginBottom: 50}}></View>
    </ScrollView>
      </View>
    
      </View>



    
    </View>
   ) };
