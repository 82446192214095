import * as AuthSession  from 'expo-auth-session';
import { spotifyCredentials } from '../core/utils';
import * as SecureStore from 'expo-secure-store';
import { encode as btoa } from 'base-64';


const scopesArr = ['user-read-currently-playing','user-top-read'];
const scopes = scopesArr.join(' ');

const getAuthorizationCode = async () => {
  try {
    const credentials = spotifyCredentials //we wrote this function above
    const redirectUrl = AuthSession.getRedirectUrl() //this will be something like https://auth.expo.io/@your-username/your-app-slug
    const result = await AuthSession.startAsync({
      authUrl:
        'https://accounts.spotify.com/authorize' +
        '?response_type=code' +
        '&client_id=' +
        credentials.clientId +
        (scopes ? '&scope=' + encodeURIComponent(scopes) : '') +
        '&redirect_uri=' +
        encodeURIComponent(redirectUrl),
    })
    return result.params.code
  } catch (err) {
    console.error(err)
  }
  return null
}


export const getTokens = async () => {
  try {
    const authorizationCode = await getAuthorizationCode() //we wrote this function above
    const credentials = spotifyCredentials //we wrote this function above (could also run this outside of the functions and store the credentials in local scope)
    const credsB64 = btoa(`${credentials.clientId}:${credentials.clientSecret}`);
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        Authorization: `Basic ${credsB64}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=authorization_code&code=${authorizationCode}&redirect_uri=${
        credentials.redirectUri
      }`,
    });
    const responseJson = await response.json();
    // destructure the response and rename the properties to be in camelCase to satisfy my linter ;)
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
    } = responseJson;
    const expirationTime = new Date().getTime() + expiresIn * 1000;
    await setSpotifyData('accessToken', accessToken);
    await setSpotifyData('refreshToken', refreshToken);
    await setSpotifyData('expirationTime', expirationTime);
  } catch (err) {
    console.error(err);
  }
}

export const refreshTokens = async () => {
  try {
    const credentials = spotifyCredentials //we wrote this function above
    const credsB64 = btoa(`${credentials.clientId}:${credentials.clientSecret}`);
    const refreshToken = await getSpotifyData('refreshToken');
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        Authorization: `Basic ${credsB64}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
    });
    const responseJson = await response.json();
    if (responseJson.error) {
      await getTokens();
    } else {
      const {
        access_token: newAccessToken,
        refresh_token: newRefreshToken,
        expires_in: expiresIn,
      } = responseJson;

      const expirationTime = new Date().getTime() + expiresIn * 1000;
      await setSpotifyData('accessToken', newAccessToken);
      if (newRefreshToken) {
        await setSpotifyData('refreshToken', newRefreshToken);
      }
      await setSpotifyData('expirationTime', expirationTime);
  } 
}catch (err) {
    console.error(err)
  }

}


export async function setSpotifyData(key, value)
{
  console.log("Storing ",JSON.stringify(value),"Under",key)

  return await SecureStore.setItemAsync(key, JSON.stringify(value))
}

export async function getSpotifyData(key)
{
  return await (SecureStore.getItemAsync(key))
}