import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/Settings2Style';
import { Dimensions, CheckBox, Image, Text, Touchable, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { createElement } from 'react-native-web';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {Ionicons, Entypo} from '@expo/vector-icons'
import TextInput from '../components/TextInput';
import Avatar from '../components/Avatar';
import * as ImagePicker from 'expo-image-picker'
import StatusOverlay from '../components/StatusOverlay'
import FeedbackItem from '../components/FeedbackItem';
import ConfettiCannon from 'react-native-confetti-cannon';
import MultiSelect from 'react-native-multiple-select'
import {Picker} from '@react-native-picker/picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {DraxProvider, DraxView, DraxList} from 'react-native-drax';
import { activities } from '../core/activities';
import { navTo, spotifyCredentials } from '../core/utils';
import { manipulateAsync } from 'expo-image-manipulator';
import { NestableScrollContainer, NestableDraggableFlatList, ScaleDecorator } from "react-native-draggable-flatlist"
import { getTokens } from '../core/spotify';

const getBackgroundColor = (alphaIndex) => {
  switch (alphaIndex % 6) {
    case 0:
      return '#ffaaaa';
    case 1:
      return '#aaffaa';
    case 2:
      return '#aaaaff';
    case 3:
      return '#ffffaa';
    case 4:
      return '#ffaaff';
    case 5:
      return '#aaffff';
    default:
      return '#aaaaaa';
  }
}

const getHeight = (alphaIndex) => {
  let height = 50;
  if (alphaIndex % 2 === 0) {
    height += 10;
  }
  if (alphaIndex % 3 === 0) {
    height += 20;
  }
  return height;
}

const getItemStyleTweaks = (alphaItem) => {
  return {
    backgroundColor: getBackgroundColor(alphaItem),
    height: getHeight(alphaItem),
  };
};

async function uploadImageAsync(uri) {
  let apiUrl = 'https://outpostorganizer.com/SITE/v2/API.php';



  let uriParts = uri.split('.');
  let fileType = uriParts[uriParts.length - 1];

  let formData = new FormData();
  formData.append('photo', {
    uri,
    name: `photo.${fileType}`,
    type: `image/${fileType}`,
  });

  let options = {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  return fetch(apiUrl, options);
}

export default function Settings2({ route, navigation })  {
  

    const [image, setImage] = useState(null);
  
    const pickImage = async () => {
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [3, 3],
        quality: 1,
      });
  
  
      if (result.hasOwnProperty('uri')) {
        const manipulateResult = await manipulateAsync(
             result.uri,
            [],
            { 
              base64: true,
              compress: 0.6 } // from 0 to 1 "1 for best quality"
        );
        setImage(manipulateResult);
      
            }      
    };


  const [themeList, setThemeList] = React.useState([])
  const [userBio, setUserBio] = React.useState(null)
  var user = route.params.user;
var config = route.params.config;
var pages = activities(config.theme)

  const [id,setID] = React.useState(route.params.id);

  
  const [loading, setLoading] = React.useState(false);
  
  const [screenName,setScreenName] = React.useState(user.screenName)
  const [email,setEmail] = React.useState(user.recoveryEmail)
  
  const [newPassword,setNewPassword] = React.useState("")
  const [newPasswordValidate,setNewPasswordValidate] = React.useState("")
  const [passwordError,setPasswordError] = React.useState(false)

  const [listNames, setListNames] = React.useState([])
  const [selectedListNames, setSelectedListNames] = React.useState([])

  const [userList, setUserList] = React.useState([])
  const [feedbackList, setFeedbackList] = React.useState(null)
  const [formattedFeedback, setFormattedFeedback] = React.useState(null)

  const [mission, setRAKMission] = React.useState(null)
  const [confetti, setConfetti] = React.useState(null)


  const [viewDeleteProfile, setViewDeleteProfile] = React.useState(null)

  const [ssStatus, setSSStatus] = React.useState(user.ssIsParticipating==0 ? false : true);
  const [ssAssigned, setSSAssigned] = React.useState("");
  const [giftSuggestion, setGiftSuggestion] = React.useState("");
  const [assignedGiftSuggestion, setSSAGiftSuggestion] = React.useState("")
  const [giftError, setGiftError] = React.useState(false);
  const [img, setIMG] = React.useState(null)

  const[participants,setParticipants] = React.useState(null)

  const[ssViewFull, setSSViewFull] = React.useState(false)

  const[assigningList, setAssigningList] = React.useState(null)
  const[assignSpots, setAssignSpots] = React.useState(null)
  const[formattedAssignSpots, setFormattedAssignSpots] = React.useState(null)

  const NUM_ITEMS = 10;
  function getColor(i) {
    const multiplier = 255 / (NUM_ITEMS - 1);
    const colorVal = i * multiplier;
    return `rgb(${colorVal}, ${Math.abs(128 - colorVal)}, ${255 - colorVal})`;
  }
  

  const staticDraggableData = [...Array(NUM_ITEMS)].map((d, index) => {
    const backgroundColor = getColor(index);
    return {
      key: `item-${index}`,
      label: (index) + "",
      height: 100,
      width: 60 + Math.random() * 40,
      backgroundColor,
    };
  });
  
  const[selectedTheme, setSelectedTheme] = React.useState(null)
  const[updatedTheme, setUpdatedTheme] = React.useState(false)
  const[customColors, setCustomColors] = React.useState(
    [
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff"
    ]
  )
  const[modalVisible, setModalVisible] = React.useState(false)
  const[colorPicker,setColorPicker] = React.useState(null)

  const[configItem, setConfigItem] = React.useState(null)

  const[updatingUser, setUpdatingUser] = React.useState(null)
  const[rawUserList, setRawUserList] = React.useState([])
  const[smallGroups, setSmallGroups] = React.useState([])
  const[selectedSmallGroups, setSelectedSmallGroups] = React.useState([])

  const[sqlCommand, setSQL] = React.useState(null)
  const[sqlResult, setSQLResult] = React.useState(null)

  const[updatingSmallGroup, setUpdatingSmallGroup] = React.useState(null)
  const[calendarCategories, setCalendarCategories] = React.useState(null)
  const[selectedCalendarCategories, setSelectedCalendarCategories] = React.useState([])
  const[msCalendarCategories, setMSCalendarCategories] = React.useState([])


  useEffect(() => {
    if(calendarCategories!=null)
    {
     // console.log(calendarCategories)
      setMSCalendarCategories(calendarCategories.map((c) => {
        return(
          {
            id: c.CID,
            name: c.CategoryName
          }
        )
      }))
    }
  },[calendarCategories])


  const[newPermissions, setNewPermissions] = React.useState({
    "canCreateAnnouncements": false,
    "canSendShoutouts": false,
    "canCreateSpots": false,
    "canApproveSpots": false,
    "canCreateEvents": false,
    "canModifyVolunteers": false,
    "scoreable": false
  })

  const[primaryActivities, setPrimaryActivities] = React.useState([])
  const[otherActivities, setOtherActivities] = React.useState([])


  var refresh = route.params.refresh
  var pageID = route.params.id; 
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];



  function runSql(isSelect) {
    if(isSelect==1)
    {
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "RunSelectSQL",
          "camp": user.Home,
          "sl": sqlCommand,
          "user": user.UID
  
      })
     })
     .then((response) => response.text()).then((responseJson) => {
        setLoading(false);
        console.log(responseJson)
        setSQLResult(JSON.parse(responseJson))
      })
    }
    else
    {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "RunSQL",
        "camp": user.Home,
        "sl": sqlCommand,
        "user": user.UID

    })
   })
   .then((response) => response.text()).then((responseJson) => {
      setLoading(false);
      setSQLResult(responseJson)
    })
  }
  }



  //function to update server config

  useEffect(() => {
    if(selectedTheme!=null && selectedTheme.value!=99999)
    {
      console.log("st", selectedTheme)
      setCustomColors(selectedTheme.colors
      )
    }

  }, [selectedTheme])

  

  useEffect(() => {

    if(id==510 && listNames.length>0)
    {
      setAssigningList(listNames[0])
    
    }
  }, [listNames])


  useEffect(() => {
    if(id==510 && assigningList!=null)
    {
      //fetch request GetListItems
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetListItems",
        "list": assigningList,
        "camp": user.Home,
        "user": user.UID,
        "approval": 0

    })
   })
   .then((response) => response.json()).then((responseJson) => {
      setAssignSpots(responseJson.records)
  })
  }

    }, [assigningList])

//assignSpots function to post new siteOwner

function assignToSpot(SID, uids) {
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
  method: 'POST',
  body: JSON.stringify({
    "command": "AssignToSpot",
    "SID": SID,
    "camp": user.Home,
    "LID": assigningList,
    "user": user.UID,
    "userAssigning": uids})
  }).then((response) => response.json()).then((responseJson) => {
    console.log(responseJson)
    
  setActiveSpot(null)
  setActiveUsers([])
  setAssignSpots(responseJson.records)
}).finally(() => {
})

}


function updateConfig(SID, uid) {
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
  method: 'POST',
  body: JSON.stringify({
    "command": "UpdateConfig",
    "camp": user.Home,
    "user": user.UID,
    "config": configItem})
  }).then((response) => response.json()).then((responseJson) => {
    setLoading(false)
})

}

useEffect(() => {
  console.log(activeSpot)
  if(activeSpot!=null && activeSpot.siteOwner!=null && activeSpot.siteOwner!="")
  {
    console.log(activeSpot.siteOwner.split(","), "2")
    setActiveUsers(
      activeSpot.siteOwner.split(",")
    )
  }
}, [activeSpot])


const [activeUsers, setActiveUsers] = React.useState([])
const [activeSpot, setActiveSpot] = React.useState(null)
    useEffect(() => {
      if(assignSpots!=null && assigningList!=null && assignSpots!="")
      {
      setFormattedAssignSpots(assignSpots.map((l) => {
        return(
          <View style={[{padding: 10, marginVertical: 10}, l.siteOwner==null ? {backgroundColor: theme.colors.redButton + "ff"} : {backgroundColor: "#ddd"}]}>
            <Text style={{color: "#000", fontSize: 20, fontWeight: "bold"}}>{(l).site_Name}</Text>
            <TouchableOpacity onPress={() => {
              setActiveUsers(
                l.siteOwner.split(",")

              )
              setActiveSpot(l)}} style={{borderRadius: 5,backgroundColor: theme.colors.greenButton, padding: 10, alignItems: "center", justifyContent:"center"}}><Text style={{color: theme.colors.itemTextColor, fontSize: 16, fontFamily: "Glacial-Indifference"}}>{l.siteOwner==null ? "Click to Assign" : 
            l.siteOwner.split(",").map((owner) => {
              console.log(userList, userList.find((u => u.id==owner)))
              return (
                (userList.find((u => u.id==owner))!=null ? userList.find((u => u.id==owner)).name : owner) + ", "
              )
            })
            }</Text></TouchableOpacity>
        
          </View>
        )
        }))
      }
    }, [assignSpots, assigningList])



  async function updateProfile() {
    
      if(image!=null)
      {
        setLoading(true)
      fetch('https://www.outpostorganizer.com/SITE/v2/uploadAPI.php', {
        method: 'POST',
        body: JSON.stringify({
          "user": user.UID,
          "image": image.base64,
          "type": "user"
          
      })
      })
     .then((response) => {
     return(response.json())
    })
     .then((responseJson) => {
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "UpdateProfile",
          "camp": user.Home,
          "user": user.UID,
          "newName": screenName,
          "newEmail": email,
          "profilePic": "https://www.outpostorganizer.com/SITE/Global/Users/" + user.UID + "/avatar.png?updated=" + (Math.random()*100)%100
      })
      })
     .then((response) => {
     return(response.json())
    })
     .then((responseJson) => {
        console.log(refresh)
      navTo("back", user, config, refresh, navigation)
     });
  
  
     });
  
    }
    else
    {
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "UpdateProfile",
          "camp": user.Home,
          "user": user.UID,
          "newName": screenName,
          "newEmail": email
      })
      })
     .then((response) => {
     return(response.json())
    })
     .then((responseJson) => {
        console.log(refresh)
      navTo("back", user, config, refresh, navigation)
    });
  
  
    }
  }

  

  function updatePassword() {
    if(newPassword==newPasswordValidate && newPassword.length>5)
    {
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "UpdatePassword",
          "camp": user.Home,
          "user": user.UID,
          "newPassword": newPassword,
      })
      })
     .then((response) => {
     return(response.json())
    })
     .then((responseJson) => {
        
        navigation.goBack()
     });
  
  
    }
    else
    {
      setPasswordError(true)
      setLoading(false)
    }
  }
function deleteUser()
{
  
  setLoading(true)
  if(updatingUser.UID==99999)
  {
      navTo("back", user, config, refresh, navigation)
    setUpdatingUser(null)
    setLoading(false)
    return;
  }
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "DeleteProfile",
      "camp": user.Home,
      "user": updatingUser.UID,

    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  setUpdatingUser(null)
  setLoading(false)
  navTo("back", user, config, refresh, navigation)
});

}



function deleteSmallGroup()
{
  if(updatingSmallGroup.id==99999)
  {
    setID(11)
    setUpdatingSmallGroup(null)
    setLoading(false)
    return;
  }
  setLoading(true)
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "DeleteSmallGroup",
      "camp": user.Home,
      "user": user.UID,
      "smallgroup": updatingSmallGroup.id,

    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  setLoading(false)
    navTo("back", user, config, refresh, navigation)
 });

}

function updateSmallGroup()
{
  setLoading(true)
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "UpdateSmallGroup",
      "camp": user.Home,
      "user": user.UID,
      "smallgroup": updatingSmallGroup.id,
      "newName": updatingSmallGroup.Name,
      "newPoints": updatingSmallGroup.Points,
      "newColor": updatingSmallGroup.Color,
      "permissions": newPermissions,
      "primaryActivities": primaryActivities.join(","),
      "otherActivities": otherActivities.join(","),
      "visibleGroups": selectedSmallGroups.join(","),
      "visibleCalendars": selectedCalendarCategories.join(",")



    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  console.log(responseJson)
  setLoading(false)
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "GetSmallGroups",
          "camp": user.Home,
          "user": user.UID
        })
      })
      .then((response) => {
        return(response.json())
      }
      )
      .then((responseJson) => {
        setSmallGroups(Object.values(responseJson.records))
      }
      );
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "GetCategories",
          "camp": user.Home,
          "user": user.UID
        })
      })
      .then((response) => {
        return(response.json())

      }
      )
      .then((responseJson) => {
        //console.log(responseJson.records)
        setCalendarCategories((responseJson.records))
      }
      );
  
 });

}


function deleteProfile() {
    
  fetch('http://outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "DeleteProfile",
      "camp": user.Home,
      "user": user.UID,
    
  })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
    
    navigation.navigate("LoginScreen")
 });



}

  function deleteItem(FID)
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "DeleteFeedback",
        "camp": user.Home,
        "user": user.UID,
        "Feedback": FID
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetFeedback",
        "user": user.UID,
        "camp": user.Home,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      setFeedbackList((responseJson.records))
   });
    
    
  });
}

function resetList()
{
  setLoading(true)
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "ResetList",
      "camp": user.Home,
      "user": user.UID,
      "list": selectedListNames.join("&")

    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  setLoading(false)
    navigation.goBack()
 });

}

function updateUser()
{
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "UpdateUser",
          "camp": user.Home,
          "user": updatingUser.UID,
          "newName": updatingUser.screenName,
          "newEmail": updatingUser.recoveryEmail,
          "smallGroup": updatingUser.smallGroup,
          "groupList": selectedSmallGroups.join(","),
          "newPassword": updatingUser.password,
          "points": updatingUser.points,
      })
      })
     .then((response) => {
     return(response.json())
    })
     .then((responseJson) => {
        console.log(responseJson)
        setLoading(false)
        setUpdatingUser(null)
        setSelectedSmallGroups(null)
        fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetUsers",
        "camp": user.Home,
        "user": user.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    setUserList(Object.values(responseJson.records).map((item) => {
        if(item.UID!=null)
        {
        return (
          {name: item.screenName,
          id: item.UID}
        )
          }
      }));
      setRawUserList(Object.values(responseJson.records))
    
     });
    })
}

function executeAdmin(aFID) {
  setID(aFID)
}

  useEffect(() => {
    if(feedbackList!=null && feedbackList.length>0 && userList.length>0)
    {
      setFormattedFeedback(feedbackList.map((feedback) => {
        return(
          <FeedbackItem del={deleteItem} feedback={feedback} userList={userList}></FeedbackItem>
        )
      }))
    }
    setLoading(false)

  }
  , [feedbackList,userList])

  function updateColor(oC)
  {
    setColorPicker(<View
      oldColor={customColors[oC]}
      onColorSelected={color => {
        var tempColors = customColors
        tempColors[oC] = color
        setCustomColors(tempColors)
        setModalVisible(false)
        }
      }
      style={{width: "100%", height: 300}}
    />)
  }



  useEffect(() => {
    if(colorPicker!=null)
    {
      setModalVisible(true)
    }
  }, [colorPicker])

  useEffect(() => {
    if(!modalVisible)
    {
    }
  }, [modalVisible])

  useEffect(() => {
    if(id==513 || id==514)
    {
      //fetch small groups
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "GetSmallGroups",
          "camp": user.Home,
          "user": user.UID
        })
      })
      .then((response) => {
        return(response.json())
      }
      )
      .then((responseJson) => {
        setSmallGroups(Object.values(responseJson.records))
      }
      );

    }
    if(id==55 || id==510 || id==513)
    {
      
    setLoading(true);
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetUsers",
        "camp": user.Home,
        "user": user.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    setUserList(Object.values(responseJson.records).map((item) => {
        if(item.UID!=null)
        {
        return (
          {name: item.screenName,
          id: item.UID}
        )
          }
      }));
      setRawUserList(Object.values(responseJson.records))
    if(id==55)
    {
      setLoading(true);
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetFeedback",
        "user": user.UID,
        "camp": user.Home,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      setFeedbackList((responseJson.records))
   });
    
    }
      setLoading(false);
      //navigation.goBack()
   });
    }
    else if(id==57)
    {
      setLoading(true)
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetRAK",
        "user": user.UID,
        "camp": user.Home,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      setRAKMission((responseJson.records))
      setLoading(false)
   });
    
    }
    else if(id==512)
    {
      setLoading(true)
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetConfig",
        "home": user.Home,
        "user": user.UID
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      setConfigItem((JSON.stringify(responseJson.records).split(",").join(",\n")))
      setLoading(false)
   });
    
    }
    else if(id==53)
    {
      //get userTheme from SecureStorage
      AsyncStorage.getItem("userTheme").then((value) => {
        if(value!=null)
        {
          setSelectedTheme(JSON.parse(value))
          setCustomColors(JSON.parse(value).colors)
        }
      })
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetThemeList",
        "camp": user.Home,
        "user": user.UID
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      console.log(responseJson.records)
      setUserBio(responseJson.bio)
      setThemeList(responseJson.records.map((t) => {
        return (
          {
            value: t.Value,
            name: t.Name,
            passphrase: t.Passphrase,
            colors: t.Colors.split(",")

          }
        )
      })) 
       
      setLoading(false);

   });
    }
    else if(id==58)
    {
      setLoading(true)
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetSSData",
        "camp": user.Home,
        "user": user.UID
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      
      setSSStatus(responseJson.code==1 ? true : false)
      setSSAssigned(responseJson.AssignedScreenName)
      setGiftSuggestion(responseJson.UserGiftSuggestions)
      setSSAGiftSuggestion(responseJson.AssignedGiftSuggestions)
      setIMG(responseJson.imgURL)
      
      setLoading(false);

   });
    
    }
    else if(id==59)
    {
      setLoading(true)
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetSSDataAdmin",
        "camp": user.Home,
        "user": user.UID
      })
    }).then((response) => response.json()).then((responseJson) => {
      setParticipants(responseJson.participants)}).finally(() => setLoading(false))
    }
    if(id==54 || id==510)
    {
      setLoading(true)
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetListNames",
        "camp": user.Home,
        "user": user.UID
      })
    }).then((response) => response.json()).then((responseJson) => {
      setListNames(responseJson.records.map((list) => {
        return(
          {name: list.Name,
          id: list.ListID}
        )
      }))}).finally(() => setLoading(false))
    }
      //navigation.goBack()
   
  }, [])



  const renderItem = ({ item, drag, isActive }) => {
    var page = pages.find(page => page.id==item)!=null ? pages.find(page => page.id==item) : {}
    console.log(page.id)
    return (
      <ScaleDecorator>
        <TouchableOpacity
          onPressIn={drag}
          style={[
            styles.rowItem,
            { padding: 10, marginVertical: 5, borderRadius: 10, backgroundColor: isActive ? themeColor : getItemStyleTweaks(page.id).backgroundColor },
          ]}
        >
          <Text style={{fontSize: 16, justifyContent: "center", alignSelf: "center"}}>{page.name}</Text>
        </TouchableOpacity>
      </ScaleDecorator>
    );
  };


  function markRAK() {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "markRAK",
        "camp": user.Home,
        "user": user.screenName
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    setConfetti(<ConfettiCannon count={250} colors={[theme.colors.list, theme.colors.map, theme.colors.announcements,theme.colors.assignments,theme.colors.scoreboard]} origin={{x: Dimensions.get("screen").width/2, y: 0}} />)
   });
  }
  function unassignSS() {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UnassignSS",
        "camp": user.Home,
        "user": user.UID
      })
    })
    .then((response) => {
    return(response.json())
    })
    .then((responseJson) => {
    }).finally(() => setLoading(false));
  }

  function ssComplete()
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SSComplete",
        "camp": user.Home,
        "user": user.UID
      })
    })
    .then((response) => {
    return(response.json())
    })
    .then((responseJson) => {
      setConfetti(<ConfettiCannon count={500} colors={["#E1E8F7", "#f00", "#0f0"]} origin={{x: Dimensions.get("screen").width/2, y: 0}} />)
    }).finally(() => setLoading(false));
  }
      
  function assignSS()
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "AssignSS",
        "camp": user.Home,
        "user": user.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(user.screenName=="Plank")
    {
      setParticipants(responseJson.participants)
    }
   }).finally(() => setLoading(false));
  }

  function validateConfig(configItem)
  {
    if(configItem==null)
    {
      return true;
    }
    return config[configItem];
  }

  function toggleStatus()
  {
    setSSStatus(!ssStatus)
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "SetSSStatus",
      "camp": user.Home,
      "user": user.UID,
      "status": !ssStatus
    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
    
    setLoading(false);

 });

  }

  //function applytheme to store theme in secure storage and update config.theme in secure storage
  function applyTheme()
  {
    console.log("SelectedTheme:",selectedTheme)
    AsyncStorage.setItem("userTheme", selectedTheme.value==99999 ? JSON.stringify({...selectedTheme, colors: customColors.map((color) => {
      if(color!=null && color!=undefined && color!="")
      {
        return color;
      }
      else
      {
        return "#000000";
      }
    })}) : JSON.stringify({...selectedTheme, colors: selectedTheme.colors})).then(() => {
      AsyncStorage.setItem("config", JSON.stringify({...config, theme: customColors.filter((c) => c!="").map((color) => {
        if(color!=null && color!=undefined && color!="")
        {
          console.log("color: " + color)
          return color;
        }
        else
        {
          console.log("color: " + color)
          return "transparent";
        }
      })})).then
      (() => {
        setLoading(false)
        setUpdatedTheme(true)
      }
      )
    }
    )    
  }
  
  function getAssignedText()
  {
    if(ssStatus)
    {
      if(ssAssigned=="" || ssAssigned===null || ssAssigned==undefined)
      {
        return "You have not been assigned anyone yet.";
      }
      else
      {
        return  "You have been assigned: " + ssAssigned;
      }
    }
    else
    {
      return "";
    }
    
  }
  
  
  function getPage() {
    switch(id)
    {
      case(51):
      return(
      <View>
        <Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
        <TextInput
          label="Screen Name"
          returnKeyType="next"
          value={screenName}
          onChangeText={text => {setPasswordError(false)
            setScreenName(text)}}
          style={{width: "100%", borderColor: theme.colors.itemTextColor, borderWidth: 1}}/>
        <TextInput
          label="Email"
          returnKeyType="next"
          value={email}
          onChangeText={text => {
            setPasswordError(false);
            setEmail(text)}}
          style={{width: "100%", borderColor: theme.colors.itemTextColor, borderWidth: 1}}/>    
      <TouchableOpacity onPress={() => {pickImage()}} style={{justifyContent: "center", alignItems: "center"}}>
      <Avatar 
          image={image}
          user={user} 
          containerStyle={
            { borderRadius: Dimensions.get("window").height*0.3, 
              width: Dimensions.get("window").height*0.3, 
              height: Dimensions.get("window").height*0.3,
              borderWidth: 4,
              alignSelf: "center"
            }} 
          logoStyle={
            {
              width: "100%", 
              height: "100%", 
              resizeMode: "stretch"
            }} /></TouchableOpacity>
            <View>
            {false ? <Button onP={getTokens}><Text>Link Spotify</Text></Button>
: null}
            </View>
            <Button onP={() => {updateProfile()}} style={{width: "80%", alignSelf: "center", backgroundColor: theme.colors.greenButton}}><Text style={{color: "#000"}}>Submit Changes</Text></Button>
            <Button onP={() => {setViewDeleteProfile(!viewDeleteProfile)}} style={{width: "80%", alignSelf: "center", backgroundColor: theme.colors.redButton}}><Text style={{color: "#000"}}>Delete Account</Text></Button>
            {viewDeleteProfile ? 
            <View>
              <Text style={styles.subTitle}>This action is permanent.  Are you sure you would like to delete your account?</Text>
              <Button onP={() => {deleteProfile()}} style={{width: "50%", alignSelf: "center", backgroundColor: theme.colors.redButton}}><Text style={{color: "#000"}}>Delete</Text></Button>
              </View>
            : null}
              </View>)
        case(52):
        return(
          <View>
            <Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
            <TextInput
              label="New Password"
              returnKeyType="next"
              value={newPassword}
              autoCompleteType={"password"}
              secureTextEntry={true}
              textContentType={"newPassword"}
              onChangeText={text => setNewPassword(text)}
              style={{width: "100%", backgroundColor: passwordError ? theme.colors.error + "65" : "#ccc", borderColor: passwordError ? theme.colors.error : theme.colors.itemTextColor, borderWidth: 1}}/>
            <TextInput
              label="Reenter New Password"
              returnKeyType="next"
              autoCompleteType={"password"}
              secureTextEntry={true}
              textContentType={"newPassword"}
              value={newPasswordValidate}
              onChangeText={text => setNewPasswordValidate(text)}
              style={{width: "100%", backgroundColor: passwordError ? theme.colors.error + "65" : "#ccc", borderColor: passwordError ? theme.colors.error : theme.colors.itemTextColor, borderWidth: 1}}/>    
              <Button onP={() => {setLoading(true);
                updatePassword()}} style={{width: "50%", alignSelf: "center", backgroundColor: theme.colors.greenButton}}><Text style={{color: "#000"}}>Submit</Text></Button>
                  </View>

        )
        case(54):
     return(
      <View>
      <Text style={[styles.title, {paddingBottom: 10}]}>{pages.find((p) => p.id==id).name}</Text>
      <MultiSelect
 hideTags
 items={listNames}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {setSelectedListNames(s)}}
 selectedItems={selectedListNames}
 selectText="Select Lists"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 tagRemoveIconColor="#CCC"
 tagBorderColor="#CCC"
 tagTextColor="#CCC"
 selectedItemTextColor="#292"
 selectedItemIconColor="#292"
 itemTextColor="#000"
 displayKey="name"
 searchInputStyle={{ color: '#CCC' }}
 submitButtonColor="#CCC"
 submitButtonText="Submit"
/>
      
<Button style={[{backgroundColor: theme.colors.greenButton, borderColor: "#000", borderWidth: 2}]} onP={() => {resetList()}}><Text style={{color: theme.colors.itemTextColor}}>Reset Lists</Text></Button>

</View>
    )   
    case(55):
    return(
      <View>
            <Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
            {formattedFeedback}
      </View>

    )
      case(57):
      return(
  <View><Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
  <Text style={styles.subTitle}>Todays Mission: </Text>
  <Text style={styles.text}>{mission==null ? "Give someone new a high-five today!" : mission}</Text>
  <Button style={[{backgroundColor: theme.colors.greenButton, borderColor: "#000", borderWidth: 2}]} onP={() => {markRAK()}}><Text style={{color: theme.colors.itemTextColor}}>Mark as Complete</Text></Button>
    
      </View>
  
        )
        case(59):
      return(
        <View><Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
        <View style={styles.contentContainer}>
        <Button onP={() => {setSSViewFull(!ssViewFull)}} style={{width: "80%", alignSelf: "center", backgroundColor: theme.colors.greenButton}}><Text style={{color: "#000"}}>{!ssViewFull ? "Show" : "Hide"} Gift Ideas</Text></Button>
        <Text style={[{fontSize: 18, color: theme.colors.itemTextColor}]}>Participants</Text>   
        <ScrollView style={{height: 500, width: "100%", paddingLeft: 10}}>
          {participants==null ? null : participants.map((p) => {
            return(
              <View>
                <Text style={[styles.name, p.ssIsParticipating==2 ? {backgroundColor: theme.colors.greenButton} : {}]}>{(p).screenName} {p.ssGiftSuggestions!=null && p.ssGiftSuggestions.length>0 ? <Ionicons name="checkmark-sharp" size={24}></Ionicons> : <Entypo name="cross" size={24}></Entypo>}</Text>
              {ssViewFull ? <Text style={[styles.name, {marginLeft: 20, flex: 1, flexWrap: "wrap"}]}>{p.ssGiftSuggestions!=null ? (p).ssGiftSuggestions : null}</Text> : null}
              </View>
            )
          })}
        </ScrollView> 
        <Button onLongP={() => {setLoading(true);unassignSS()}} onP={() => {setLoading(true);assignSS()}} style={{width: "80%", alignSelf: "center", backgroundColor: theme.colors.greenButton}}><Text style={{color: "#000"}}>{user.ssAssigned==null ? "A" : "Rea"}ssign Secret Santa</Text></Button>
        </View>
        </View>
  
        )
        case(510):
        return(
          <View><Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
          {listNames!=null ? <Picker
  style={{backgroundColor: theme.colors.surface, borderWidth: 2, borderColor: "#000"}}
  selectedValue={assigningList}
  onValueChange={(itemValue, itemIndex) =>
    setAssigningList(itemValue)
  }>
    {listNames!=null ? listNames.map((list) => {
      
      return(
        <Picker.Item label={list.name} value={list.id}></Picker.Item>
      )
    }) : null}
</Picker> : null}
<ScrollView contentContainerStyle={{marginBottom: 200}}>
  {formattedAssignSpots}
</ScrollView>
          </View>            
      
        )
        case(53):
        return(
          <View>
            <Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
            <Picker
  style={{backgroundColor: theme.colors.surface, borderWidth: 2, borderColor: "#000", marginTop: 10}}
  selectedValue={selectedTheme}
  onValueChange={(newTheme, itemIndex) => {
    setSelectedTheme(themeList.find(theme => theme.value==newTheme)) 
  }
  }>
     <Picker.Item label={"Select a theme"} value={-1}></Picker.Item>
    
    {themeList.map((theme) => {
      console.log(themeList)
      console.log(theme.passphrase, theme.passphrase.split(",").some((phrase) => userBio.toUpperCase().includes(phrase)))
      if(userBio==null || userBio=="" || theme.passphrase=="" || (!theme.passphrase.includes(",") && userBio.toUpperCase().includes(theme.passphrase)) || theme.passphrase.split(",").some((phrase) => userBio.toUpperCase().includes(phrase))) {
      return(
        <Picker.Item label={theme.name} value={theme.value}></Picker.Item>
      )
      }
      } 
    )}
</Picker>
<View style={{justifyContent:"center", width: "100%", "alignItems": "center", flexDirection: "row"}}>
<Text style={{color: theme.colors.itemTextColor, marginTop: 10, fontSize: 20}}>Selected Theme: </Text>
<Text style={{color: theme.colors.itemTextColor, marginTop: 10, fontSize: 16, textDecorationLine: "underline"}}>{selectedTheme!=null ? selectedTheme.name : null}</Text>
</View>
{
  modalVisible ? <View style={{position:"absolute", zIndex: 1000, bottom: 0, top:0, left: 0, right: 0, justifyContent: "center", alignItems: "center", backgroundColor: theme.colors.listItemBackground}}>
    {colorPicker}
    </View>
 : null
}
<ScrollView contentContainerStyle={{marginBottom: 200}}>
{selectedTheme!=null&&selectedTheme.value==99999 ? <View>{((customColors)).map((color, index) => {
  return(
    <Button onP={() => updateColor(index)} style={{borderColor: "#000", borderWidth: 2, backgroundColor: color}}><Text style={{backgroundColor: theme.colors.listItemBackground + "77", color: theme.colors.itemTextColor}}>Update Color {index}</Text></Button>
  )
})}</View> : null}

<Button onP={() => {setLoading(true);applyTheme()}} style={{backgroundColor: theme.colors.greenButton, width: 100, marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>Apply</Text></Button>
{updatedTheme ? <Text style={{color: theme.colors.itemTextColor, marginTop: 40, fontSize: 18, fontWeight: "bold"}}>Theme Updated!  Reload Outpost to see changes</Text> : null}
</ScrollView>
          </View>            
      
        )
      
            case(512):   
    return(
      <View><Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
      
      <TextInput
  label="Details"
  returnKeyType="next"
  value={configItem}
  placeholder={"Put Details Here!"}
  multiline={true}
  onChangeText={d => setConfigItem(d)}
  style={{width: "90%", height: 350, paddingTop: 0, paddingBottom: 0, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/>
<Button onP={() => {setLoading(true);updateConfig()}} style={{backgroundColor: theme.colors.greenButton, width: 100, marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>Update</Text></Button>
            </View>
      
            )
            case(513):   
    return(
      <View><Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
      <Picker
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderWidth: 2, borderColor: theme.colors.itemTextColor}}
  selectedValue={updatingUser !=null ? updatingUser.UID : null}
  onValueChange={(selectedUser, itemIndex) => {
    if(selectedUser==99999)
    {
      setUpdatingUser({screenName: "", UID: 99999, points: 0, recoveryEmail: "", password: ""})
    }
    else
    {
    setUpdatingUser(rawUserList.find(u => u.UID==selectedUser))
    setSelectedSmallGroups(rawUserList.find(u => u.UID==selectedUser).groupList.split(",").filter(g => g!=""))
    
    }
  }
  }>
     <Picker.Item label={"Select a user"} value={-1}></Picker.Item>
     <Picker.Item label={"New User"} value={99999}></Picker.Item>
    {userList.length>0 ? userList.map((user) => {
      if(user!=null)
      {
      return(
        <Picker.Item label={user.name} value={user.id}></Picker.Item>
      )
      } 
    }) : null}

</Picker>
{updatingUser!=null ? <ScrollView contentContainerStyle={{paddingBottom: 300}}>
  <TextInput
  label="Username"
  returnKeyType="next"
  value={updatingUser.screenName}
  placeholder={"Username"}
  onChangeText={d => setUpdatingUser({...updatingUser, screenName: d})}
  style={{width: "90%", height: 50, paddingTop: 0, paddingBottom: 0, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/>
<TextInput
  label="Password"
  returnKeyType="next"
  value={updatingUser.password}
  placeholder={"Password"}
  onChangeText={d => setUpdatingUser({...updatingUser, password: d})}
  style={{width: "90%", height: 50, paddingTop: 0, paddingBottom: 0, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/>
<Button onP={() => {setUpdatingUser({...updatingUser, password: "7daf48f924f246f48e576104ccc8c6e2fd7ef4a9f427ac9a8e7f7dfd43f87b1d"})}} style={{backgroundColor: theme.colors.greenButton, width: "70%", marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>Reset Password</Text></Button>
  <TextInput
  label="Recovery Email"
  returnKeyType="next"
  value={updatingUser.recoveryEmail}
  placeholder={"Recovery Email"}
  onChangeText={d => setUpdatingUser({...updatingUser, recoveryEmail: d})}
  style={{width: "90%", height: 50, paddingTop: 0, paddingBottom: 0, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/>
<TextInput
  label="Points"
  returnKeyType="next"
  value={updatingUser.points.toString()}
  placeholder={"Points"}
  onChangeText={d => setUpdatingUser({...updatingUser, points: d})}
  style={{width: "90%", height: 50, paddingTop: 0, paddingBottom: 0, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/>
<Text style={{paddingTop: 10, color: theme.colors.itemTextColor}}>Main Group</Text>
<Picker

  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderWidth: 2, borderColor: theme.colors.itemTextColor}}
  selectedValue={updatingUser.smallGroup}
  onValueChange={(selectedGroup, itemIndex) => {
    setUpdatingUser({...updatingUser, smallGroup: selectedGroup})
  }
  }>
      <Picker.Item label={"Select a Small Group"} value={-1}></Picker.Item>
    {smallGroups.length>0 ? smallGroups.map((group) => {
      if(group!=null && group.Name!=null && group.Name!="")
      {
      return(
        <Picker.Item label={group.Name} value={group.id}></Picker.Item>
      )
      }
    }) : null}

</Picker>
<Text style={{paddingTop: 30, marginBottom: 20, color: theme.colors.itemTextColor}}>Other Groups</Text>
<MultiSelect
 hideTags
 items={smallGroups.map((g) => {return {id: g.id, name: g.Name}})}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {
  setSelectedSmallGroups(s)}
 }
 selectedItems={selectedSmallGroups}
 selectText="Select Small Groups"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 tagRemoveIconColor="#CCC"
 tagBorderColor="#CCC"
 tagTextColor="#CCC"
 selectedItemTextColor="#292"
 selectedItemIconColor="#292"
 itemTextColor="#000"
 displayKey="name"
 searchInputStyle={{ color: '#CCC' }}
 submitButtonColor="#CCC"
 submitButtonText="Submit"
/>
     

<Button onP={() => {setLoading(true);deleteUser()}} style={{backgroundColor: theme.colors.redButton, width: "70%", marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>{updatingUser.UID==99999 ? "Cancel" : "Delete User"}</Text></Button>
<Button onP={() => {setLoading(true);updateUser()}} style={{backgroundColor: theme.colors.greenButton, width: "70%", marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>{updatingUser.UID==99999 ? "Add " : "Update "}User</Text></Button>


  
  </ScrollView> : null}
            </View>
      
            )
            case(514):   
      return(
        <View><Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>
   <Picker

style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderWidth: 2, borderColor: theme.colors.itemTextColor}}
selectedValue={updatingSmallGroup!=null ? updatingSmallGroup.id : null}
onValueChange={(selectedGroup, itemIndex) => {
  if(selectedGroup==99999)
  {
    setUpdatingSmallGroup({Name: "", id: 99999, Points: 0, Color: "#000000"})
    setPrimaryActivities([])
    setOtherActivities([])
    setNewPermissions({
      "canCreateAnnouncements": false,
    "canSendShoutouts": false,
    "canCreateSpots": false,
    "canApproveSpots": false,
    "canCreateEvents": false,
    "canModifyVolunteers": false,
    "scoreable": false})
    
    setSelectedCalendarCategories([])
    setSelectedSmallGroups([])

  }
  else
  {
    setUpdatingSmallGroup(smallGroups.find(g => g.id==selectedGroup))
    var newGroup = smallGroups.find(g => g.id==selectedGroup)
    console.log(newGroup)
    setPrimaryActivities(newGroup.PrimaryActivities.split(",").filter((item) => item!=null && item!="").map((a) => parseInt(a)))
    setOtherActivities(newGroup.Activities.split(",").filter((item) => item!=null && item!="").map((a) => parseInt(a)))
    var sg = smallGroups.find(g => g.id==selectedGroup)

    console.log(sg)
    setSelectedCalendarCategories(sg.VisibleCalCats.split(","))
    setSelectedSmallGroups(sg.VisibleGroups.split(","))
    setNewPermissions({
      "canCreateAnnouncements": newGroup.CanCreateAnnouncements==0 ? false : true,
    "canSendShoutouts": newGroup.CanSendShoutouts==0 ? false : true,
    "canCreateSpots": newGroup.CanCreateSpots==0 ? false : true,
    "canApproveSpots": newGroup.CanApproveSpots==0 ? false : true,
    "canCreateEvents": newGroup.CanCreateEvents==0 ? false : true,
    "canModifyVolunteers": newGroup.CanModifyVolunteers==0 ? false : true,
    "scoreable": newGroup.Scoreable==0 ? false : true})
  }
}
}>
    <Picker.Item label={"Select a Small Group"} value={-1}></Picker.Item>
  {smallGroups.length>0 ? smallGroups.map((group) => {
    if(group!=null && group.Name!=null && group.Name!="")
    {
    return(
      <Picker.Item label={group.Name} value={group.id}></Picker.Item>
    )
    }
  }) : null}
      <Picker.Item label={"New Small Group"} value={99999}></Picker.Item>
</Picker>
{updatingSmallGroup!=null ? <ScrollView contentContainerStyle={{paddingBottom: 300}}>
    <TextInput
    label="Small Group Name"
    returnKeyType="next"
    value={updatingSmallGroup.Name}
    placeholder={"Small Group name"}
    onChangeText={d => setUpdatingSmallGroup({...updatingSmallGroup, Name: d})}
    style={{width: "90%", height: 50, paddingTop: 0, paddingBottom: 0, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}
  />
  <View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row"}}>
  <CheckBox
    
    onValueChange={()=>{
      setNewPermissions({...newPermissions, scoreable: !newPermissions.scoreable})
    }}
    value={newPermissions.scoreable}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Scoreable?"}</Text>
</View>
<View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row"}}>
  <CheckBox
    
    onValueChange={()=>{
      setNewPermissions({...newPermissions, canCreateAnnouncements: !newPermissions.canCreateAnnouncements})
    }}
    value={newPermissions.canCreateAnnouncements}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Can Create System Announcements"}</Text>
</View>
<View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row"}}>
  <CheckBox
    
    onValueChange={()=>{
      setNewPermissions({...newPermissions, canSendShoutouts: !newPermissions.canSendShoutouts})
    }}
    value={newPermissions.canSendShoutouts}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Can Send Staff Shoutouts"}</Text>
</View><View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row"}}>
  <CheckBox
    
    onValueChange={()=>{
      setNewPermissions({...newPermissions, canCreateSpots: !newPermissions.canCreateSpots})
    }}
    value={newPermissions.canCreateSpots}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Can Create List Items"}</Text>
</View><View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row"}}>
  <CheckBox
    
    onValueChange={()=>{
      setNewPermissions({...newPermissions, canApproveSpots: !newPermissions.canApproveSpots})
    }}
    value={newPermissions.canApproveSpots}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Can Approve List Items"}</Text>
</View><View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row"}}>
  <CheckBox
    
    onValueChange={()=>{
      setNewPermissions({...newPermissions, canCreateEvents: !newPermissions.canCreateEvents})
    }}
    value={newPermissions.canCreateEvents}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Can Create Calender Events"}</Text>
</View><View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row", marginBottom: 50}}>
  <CheckBox
    
    onValueChange={()=>{
      setNewPermissions({...newPermissions, canModifyVolunteers: !newPermissions.canModifyVolunteers})
    }}
    value={newPermissions.canModifyVolunteers}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Can Modify Event Volunteer List"}</Text>
</View>
 <MultiSelect
 hideTags
 items={smallGroups.map((sg) => {
  return(
    {
      id: sg.id,
      name: sg.Name
    }
  )
 })}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {setSelectedSmallGroups(s)}}
 selectedItems={selectedSmallGroups}
 selectText=" Select Visible Groups"
 searchInputPlaceholderText="Search Groups..."
 onChangeInput={ (text)=> console.log(text)}
 tagRemoveIconColor="#CCC"
 tagBorderColor="#CCC"
 tagTextColor="#CCC"
 selectedItemTextColor="#292"
 selectedItemIconColor="#292"
 itemTextColor="#000"
 displayKey="name"
 searchInputStyle={{ color: '#CCC' }}
 submitButtonColor="#CCC"
 submitButtonText="Submit"
/>
<MultiSelect
 hideTags
 items={msCalendarCategories}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {
  setSelectedCalendarCategories(s)}
 }
 selectedItems={selectedCalendarCategories}
 selectText="Select Calendar Categories"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 tagRemoveIconColor="#CCC"
 tagBorderColor="#CCC"
 tagTextColor="#CCC"
 selectedItemTextColor="#292"
 selectedItemIconColor="#292"
 itemTextColor="#000"
 displayKey="name"
 searchInputStyle={{ color: '#CCC' }}
 submitButtonColor="#CCC"
 submitButtonText="Submit"
/>

  {
  modalVisible ? <View style={{position:"absolute", zIndex: 1000, bottom: 0, top:0, left: 0, right: 0, justifyContent: "center", alignItems: "center", backgroundColor: theme.colors.listItemBackground}}>
    {colorPicker}
    </View>
 : null
}

{createElement('input', {
      type: 'color',
      value: updatingSmallGroup.Color,
      style: {marginVertical: 10, borderRadius: 10, fontSize: 16, width: "40%", borderColor: theme.colors.itemTextColor, borderWidth: 1},

      onInput: (e) => {
        console.log("E: ", e.target.value)
        setUpdatingSmallGroup({...updatingSmallGroup, Color: (e.target.value)})
      }
    })}
    <View style={{display: "flex", flexDirection: "row"}}>
    <View style ={{flex: 0.5}}>
    <MultiSelect
 hideTags
 items={activities(config.theme)}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {
  console.log(s)
  setPrimaryActivities(s)}}
 selectedItems={primaryActivities}
 selectText="Primary Activities"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 tagRemoveIconColor="#CCC"
 tagBorderColor="#CCC"
 tagTextColor="#CCC"
 selectedItemTextColor="#292"
 selectedItemIconColor="#292"
 itemTextColor="#000"
 displayKey="name"
 searchInputStyle={{ color: '#CCC' }}
 submitButtonColor="#CCC"
 submitButtonText="Submit"
/>
<NestableScrollContainer>
      <NestableDraggableFlatList
        data={primaryActivities}
        renderItem={renderItem}
          keyExtractor={(item) => pages.find((p) => p.id==item).name}
        onDragEnd={(d) => {
          console.log(d)
          setPrimaryActivities(d.data)}}
      />
    
    </NestableScrollContainer>
    </View><View style={{flex: 0.5}}>
    <MultiSelect
 hideTags
 items={activities(config.theme).filter(activity => !primaryActivities.includes(activity.id))}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {
  console.log(s)
  setOtherActivities(s)}}
 selectedItems={otherActivities}
 selectText="Other Activities"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 tagRemoveIconColor="#CCC"
 tagBorderColor="#CCC"
 tagTextColor="#CCC"
 selectedItemTextColor="#292"
 selectedItemIconColor="#292"
 itemTextColor="#000"
 displayKey="name"
 searchInputStyle={{ color: '#CCC' }}
 submitButtonColor="#CCC"
 submitButtonText="Submit"
/>
<NestableScrollContainer>
      <NestableDraggableFlatList
        data={otherActivities}
        renderItem={renderItem}
          keyExtractor={(item) => pages.find((p) => p.id==item).name}
        onDragEnd={(d) => {
          console.log(d)
          setOtherActivities(d.data)}}
      />
    
    </NestableScrollContainer>
    </View>
    </View>
  <Button onP={() => {setLoading(true);deleteSmallGroup()}} style={{backgroundColor: theme.colors.redButton, width: "70%", marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>{updatingSmallGroup.id!=99999 ? "Delete Small Group" : "Cancel"}</Text></Button>
  <Button onP={() => {setLoading(true);updateSmallGroup()}} style={{backgroundColor: theme.colors.greenButton, width: "70%", marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>{updatingSmallGroup.id!=99999 ? "Update" : "Create"} Small Group</Text></Button>
  
  
    
    </ScrollView> : null}
              </View>
        
              )
     
            
            case(515):   
    return(
      <View><Text style={styles.title}>{pages.find((p) => p.id==id).name}</Text>

      <TextInput
  label="SQL"
  returnKeyType="next"
  value={sqlCommand}
  placeholder={"SQL"}
  onChangeText={d => setSQL(d)}
  style={{width: "90%", height: 50, paddingTop: 0, paddingBottom: 0, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/>
<Button onP={() => {setLoading(true);runSql(0)}} style={{backgroundColor: theme.colors.greenButton, width: "70%", marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>Run SQL</Text></Button>
<Button onP={() => {setLoading(true);runSql(1)}} style={{backgroundColor: theme.colors.greenButton, width: "70%", marginLeft: 10}}><Text style={{color: theme.colors.itemTextColor}}>Run SQL SELECT</Text></Button>
<Text style={{color: theme.colors.itemTextColor}}>Response Code: {sqlResult!=null ? sqlResult.code : null}</Text>
<TextInput
value={(sqlResult==null ? null : JSON.stringify(sqlResult.records))}
            multiline={true}
            style={{width: "100%", height: 400, textAlignVertical: 'top', borderColor: theme.colors.itemTextColor, borderWidth: 1}}>
              </TextInput>
            </View>
      
            )
      }
      
    
    
    setLoading(true);
  }




  
  return (
    
    
    <View style={styles.container}>
      {loading ? <StatusOverlay config={config}></StatusOverlay> : null}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer isSubPage={false} customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
            navTo(navName, user, config, null, navigation)
          
          }} />
      </View>
      <Button onP={() => {navTo("Dashboard", user, config, refresh, navigation)}} style={styles.backButton} mode="outlined">
      <Ionicons name="return-down-back" size={24} color={theme.colors.itemTextColor}></Ionicons>
        </Button>
    
    <View style={[styles.content, {backgroundColor: themeColor + "11", borderColor: themeColor}]}>
    {id==510 && activeSpot!=null && <View style={{position: "absolute", zIndex: 100, top: 100, bottom: 0, borderColor: "#000", borderWidth: 4, borderRadius: 10, left: 0, right: 0, backgroundColor: theme.colors.listItemBackground}}>
<Text style={{fontSize: 22, fontFamily: "Carter-One", color: theme.colors.itemTextColor}}>Assigning for {(activeSpot.site_Name)}</Text>
<MultiSelect
 hideTags
 items={userList}
 uniqueKey="id"
 onSelectedItemsChange={(selectedItems) => { 
  console.log(selectedItems)
  setActiveUsers(selectedItems)}}
 selectedItems={activeUsers}
 selectText="Select Users"
 searchInputPlaceholderText="Search Users..."
 onChangeInput={ (text)=> console.log(text)}
 itemTextColor={theme.colors.itemTextColor}
 selectedItemTextColor={theme.colors.greenButton}
 selectedItemIconColor={theme.colors.greenButton}
 styleTextDropdownSelected={{color: theme.colors.itemTextColor}}
 styleTextDropdown={{color: theme.colors.itemTextColor}}
 searchInputStyle={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
 styleSelectorContainer={{height: 350, backgroundColor: theme.colors.listItemBackground}}
 styleMainWrapper={{backgroundColor: theme.colors.listItemBackground}}
 styleListContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleInputGroup={{color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenu={{backgroundColor: theme.colors.listItemBackground}}
 styleIndicator={{backgroundColor: theme.colors.listItemBackground}}
 styleItemsContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenuSubsection={{backgroundColor: theme.colors.listItemBackground}}
 displayKey="name"
 hideSubmitButton={true}
 submitButtonColor={activeUsers.length>0 ? theme.colors.greenButton : theme.colors.redButton}
 submitButtonText="Submit"
/><Button onP={() => {
  assignToSpot(activeSpot.SID, activeUsers.join(","))
}} style={{backgroundColor: theme.colors.greenButton, width: "50%", alignSelf: "center"}}><Text style={{fontFamily: "Glacial-Indifference", color: theme.colors.itemTextColor, fontWeight:"bold", fontSize: 18}}>Submit</Text></Button>
  </View>}

    <ScrollView style={{width: "90%"}}
   contentContainerStyle={{width: '100%'}}>
    {getPage()}
    
    </ScrollView>
      </View>
      {confetti}
      </View>



    
    </View>
   ) };
