import React, { memo, useCallback } from 'react';
import { StyleSheet, Text, View, StatusBar, TouchableOpacity } from 'react-native';
import { theme } from '../core/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import {LinearGradient} from 'expo-linear-gradient';
import Constants from 'expo-constants'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {activities} from '../core/activities'

export default function Header({ children }) {

  const[chickenAndHam, setChickenAndHam] = React.useState(false)
  const[config, setConfig] = React.useState(null)

  const[tools, setTools] = React.useState(null)


  React.useEffect(() => {
    async function getConfig() {
      let config = await AsyncStorage.getItem('config')
      if(config != null){
        setConfig(JSON.parse(config))
        setTools(activities(config.theme))
      }
    }
    getConfig()
  }, [])



//  colors={[scoreboardColor, announcementsColor, listColor, mapColor, assignmentsColor]}
  const [listColor, setListColor] = React.useState(theme.colors.list)
  const [mapColor, setMapColor] = React.useState(theme.colors.map)
  const [assignmentsColor, setAssignmentsColor] = React.useState(theme.colors.assignments)
  const [announcementsColor, setAnnouncementsColor] = React.useState(theme.colors.announcements)
  const [scoreboardColor, setScoreboardColor] = React.useState(theme.colors.scoreboard)
  const [colorList, setColorList] = React.useState(["#000", "#000"])
  const[flag, setFlag] = React.useState(true)
  //update colors to match config
  React.useEffect(() => {
    if(config != null && tools != null){
      var eCL = config.tools.map((tool, index) => {
        var t = tools!=null ? tools.find((t) => t.id == tool) : null
        if(t!=null && t!=undefined)
        {
          if(t.isAllowed || t.isAllowed == null)
          {
            return(config.theme.length>index ? (config.theme[index]!="transparent" ? config.theme[index] : "#000000") : theme.colors.activities[index])
          }
          else
          {
            return null
          }
      }
      });
      setColorList(eCL.filter((c) => c!=null && c!=undefined))

    }    
  }, [config, tools])


  
 
const styles = StyleSheet.create({
  container: {
    backgroundColor: colorList.length>=2 ? "transparent" : colorList.length>=1 ? colorList[0] : "#000000",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20
    
  },
  header: {
    fontSize: chickenAndHam ? 20 : 32,
    color: "#000000",
    textAlign: "center"
  },
});

const [fontsLoaded] = useFonts({
  'Carter-One': require('../assets/fonts/carter-one/CarterOne.ttf'),
});

const onLayoutRootView = useCallback(async () => {
  if (fontsLoaded) {
    await SplashScreen.hideAsync();
  }
}, [fontsLoaded]);

if (!fontsLoaded) {
  return(
<View style={styles.container} onLayout={onLayoutRootView}>
 {colorList.length>=2 ? <LinearGradient
  start={[0, 1]}
  end={[1, 0]}
  style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}
  colors={colorList}
   /> : null
 }
<Text style={[styles.header, {color: "white"}]}>{children==null ? (chickenAndHam ? "Bam Bam\nChicken and Ham" : "Outpost") : children}</Text>
</View>
  )
}

/*scoreboard: "#3e3e3d",
    announcements: "#f5008f",
    assignments: "#22b5d3",
    map: "#62d945",
    list: "#fe9d0b"*/
    
  return(

<View style={styles.container} onLayout={onLayoutRootView}>
  { colorList.length>=2 ?
  <LinearGradient
  start={[0, 1]}
  end={[1, 0]}
  style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}
  colors={colorList}
   /> : null
  }
   
<TouchableOpacity activeOpacity={1} onLongPress={() => {setChickenAndHam(!chickenAndHam)}}><Text style={[styles.header, {fontFamily: "Carter-One", color: "white"}]}>{children==null ? (chickenAndHam ? "Bam Bam\nChicken And Ham" : "Outpost") : children}</Text></TouchableOpacity>
</View>
)
  
}

