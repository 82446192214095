import { TouchableOpacity, Linking, Image, StyleSheet, Text, View, Touchable } from 'react-native';
import {theme} from '../core/theme';
import {decode} from 'html-entities';
import React from 'react';
import { AntDesign } from '@expo/vector-icons'; 
import Moment from 'moment';
import Button from './Button'
//Todo: Tiling News through the tile


export const CalendarItem = (({config, isAdmin, item, del, signUp, userScreenName}) => {

    function isAllowed()
    {
        var workers = item.Workers.split("&");
        return (workers.length-1<item.amountOfWorkers) || workers.includes(userScreenName);
    }

    //console.log(item)
    const styles = StyleSheet.create({
        container: {
            fontSize: 48,
            fontWeight: 'bold',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: 20,
            backgroundColor: theme.colors.listItemBackground,
            paddingVertical: 15,
      
          },
          tag:
          {
              color: theme.colors.white,
              fontSize: 17,
              fontWeight: '400',
              marginLeft: 5
          },
          title:
          {
              color: theme.colors.itemTextColor,
              fontSize: 20,
              marginLeft: 20
          },
          hour:
          {
              marginLeft:30,
              marginTop: 10,
              fontSize: 14,
              color: theme.colors.itemTextColor,
          },
          location:
          {
              marginLeft: 10,
              marginTop: 20,
              fontSize: 12,
              color: theme.colors.white,
          },
          split:
          {
            display: "flex",
            flexDirection: "column",
            paddingLeft: 20
          },
          desc:
        {
            flex: 0.7,
        },
        signUp:
        {
        },
        button:
        {
            backgroundColor: theme.colors.greenButton,
            
        },
        
        button2:
        {
            backgroundColor: "#d93",
            
        },
        buttonText:{
            color: theme.colors.itemTextColor
        }
        })
    
        console.log(item.Details)
    return(
        <View style={styles.container}>
                    <Text style={styles.title}>{decode(item.Title)}</Text>
            <View style={styles.split}>
                <View style={styles.desc}>
                    <Text style={styles.hour}>{item.Workers!="" ? item.Workers.split("&").join(", ").slice(0, -2) + (item.Details!="" ? "\n\nDetails:\n\t" + 
                item.Details.split("\n").join("\n\t") : "") : "Unclaimed!"} </Text>
                <View style={styles.signUp}>
                    {isAdmin || isAllowed() ?
                    <Button style={styles.button} onP={() => signUp(item)}><Text style={styles.buttonText}>Sign Up</Text></Button>
                : null}
                {isAdmin?
                    <Button style={styles.button2} onP={() => del(item)}><Text style={styles.buttonText}>Delete</Text></Button>
                    : null}
                
                </View>
                </View>
            </View>
        </View>
    )
  
})

