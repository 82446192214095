import React, { memo, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 




export default function PrintSiteContainer({  single, open, itemsRemaining, title, children }) {

  const [showChildren, toggleChildren] = React.useState(true)
 
const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    flex: 0.2,
    justifyContent: "space-between",
    alignItems: "center",
    
  },
  header: {
    fontSize: 18,
    width: "100%",
    color: "#000000",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    color: "#000000",
  }
});

const [fontsLoaded] = useFonts({
  'Glacial-Indifference-Bold': require('../assets/fonts/glacial-indifference/GlacialIndifference-Bold.otf'),
});

const onLayoutRootView = useCallback(async () => {
  if (fontsLoaded) {
    await SplashScreen.hideAsync();
  }
}, [fontsLoaded]);

if (!fontsLoaded) {
  return null;
}

  return(
<View style={[styles.container, itemsRemaining>0 ? {} : {backgroundColor: theme.colors.greenButton + "93"}]} onLayout={onLayoutRootView}>
  
<TouchableOpacity onPress={() => {
  toggleChildren(!showChildren)
}} style={single ? {display: "none"} : styles.title} >
{itemsRemaining>0 ? !showChildren ? <MaterialCommunityIcons color={"#000000"} name="chevron-right" size={18} /> : <MaterialCommunityIcons color={"#000000"} name="chevron-down" size={28}/> : <View style={{marginLeft: 30}} />}<Text style={[styles.header, {fontFamily: "Glacial-Indifference-Bold", color: "#000000"}]} numberOfLines={1}>{title}</Text>
</TouchableOpacity>
{showChildren ? children : null}
</View>
)
  
}

