import { useState } from 'react';
import { Text, TextInput, View } from 'react-native';
import { Button } from 'react-native-paper';
import { styles } from '../styles/LoginScreenStyle';
import { emailValidator, passwordValidator } from '../core/utils';

export default function Landing({ route, navigation }) {

  const [reply, setReply] = useState("")

  const _onLoginPressed = () => {
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);

    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({"command": "ValidateLogin",
      "email": email.value,
      "pass": password.value})
   })
   .then((response) => response.json())
   .then((responseJson) => {
     
     setReply(JSON.stringify(responseJson))
  })
}
    

  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [data, setData] = useState([]);
  const [inputText, setInputText] = useState('');
  const [text, setText] = useState('');

return(
  
  <View style={{width: "100%"}}>
      <Text style={{textAlign: 'center', width: "100%", marginTop: 100}}>
        Outpost
      </Text>
      <View style={{width: "50%"}}>
      <TextInput
      placeholder='Email Address'
  label="Email"
  returnKeyType="next"
  value={email.value}
  onChangeText={text => setEmail({ value: text, error: '' })}
  error={!!email.error}
  errorText={email.error}
  autoCapitalize="none"
  autoCompleteType="email"
  textContentType="emailAddress"
  keyboardType="email-address"
  underlineColorAndroid="transparent"
/>

<TextInput 
  label="Password"
  placeholder='Password'
  returnKeyType="done"
  value={password.value}
  onChangeText={text => setPassword({ value: text, error: '' })}
  error={!!password.error}
  errorText={password.error}
  secureTextEntry
/>
<Button style={styles.loginButton} mode="contained" onPress={_onLoginPressed}>
  <Text style={styles.loginButtonText} >Log In</Text>
</Button>
</View>
  </View>
);
}

