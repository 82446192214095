import Matter from "matter-js";
import { Dimensions } from "react-native"
import React from "react";

let counter = 8;

const Physics = (entities, {touches, time, dispatch}) => {
    
    
    
    let engine = entities.physics.engine

    if(touches.length==0)
    {
        Matter.Body.setPosition(entities.Bird.body, {
            x: -200,
            y: -400
        })
    }
    touches.filter(t => t.type==='move')
    .forEach(t => {
        Matter.Body.setPosition(entities.Bird.body, {
            x: t.event.pageX,
            y: t.event.pageY
        })
    })



    Matter.Engine.update(engine, time.delta)

    const windowHeight = Dimensions.get('window').height
    const windowWidth = Dimensions.get('window').width

    
    Matter.Events.on(engine, 'collisionStart', (event) => {
        counter = 8
        dispatch({type: 'game_over'})
    })

    return entities;
}

export default Physics