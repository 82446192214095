import React, { memo, useReducer } from 'react';
import Background from '../components/Background';
import Logo from '../assets/logo.svg';
import AdvElement from '../components/AdvElement';
import Header from '../components/Header';
import Paragraph from '../components/Paragraph';
import Button from '../components/Button';
import { useFonts } from 'expo-font';
import { theme } from '../core/theme';
import { styles } from '../styles/ProfileStyle';
import { Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import ProfileModule from '../components/ProfileModule';
import Announcement from '../components/Announcement';
import Avatar from '../components/Avatar';


const Profile = ({ navigation, user }) => {
  const task1 = () => {
    return ["Sweep Outdoors1", "Sweep Outdoors2", "Sweep Outdoors3", "Sweep Outdoors4", "Sweep Outdoors1", "Sweep Outdoors2", "Sweep Outdoors3", "Sweep Outdoors4", "Sweep Outdoors1", "Sweep Outdoors2", "Sweep Outdoors3", "Sweep Outdoors4", "Sweep Outdoors1", "Sweep Outdoors2", "Sweep Outdoors3", "Sweep Outdoors4", "Sweep Outdoors1", "Sweep Outdoors2", "Sweep Outdoors3", "Sweep Outdoors4", "Sweep Outdoors1", "Sweep Outdoors2", "Sweep Outdoors3", "Sweep Outdoors4"];
  }

  var complete = false;

  function advanced(title)
  {
   
    setADV(!showAdv);
    setHeading(title);
   
  }


  const [heading, setHeading] = React.useState(null);

  const [showAdv, setADV] = React.useState(false); //Announcement Box trigger

  function close() {
    setADV(false);
  }

  return (
    
    
    <View style={styles.container}>
{/*
<Button style={styles.logoutButton} mode="outlined" onPress={() => navigation.navigate('HomeScreen')}>
      <Text style={styles.logoutButton} >Logout</Text> 
  </Button> */}

{//user.screenName
}
    <Text style={styles.welcome}></Text>    
    <View style={styles.profileHeader}><Avatar user={user}></Avatar>
      <View style={styles.profileSubHeader}>
        <Text style={styles.profileUsername}>
          {user.screenName}
        </Text>
        <Text style={styles.profilePoints}>
          {user.points} pts
        </Text>
      </View>

    </View>
    <View borderwidth={1} style={styles.ListBox}>
    <View style={[showAdv ? styles.advBoxShow : styles.advBoxHide]}>
    <View style={styles.buttonHolder}>
    <Button style={styles.leave} mode="outlined" onPress={close}>
      <Text style={styles.leave} >Exit</Text>
  </Button>
</View>
      <Text style={styles.ListHeading}>{heading}
      </Text>
      <ScrollView contentContainerStyle={styles.advScrollView}>
      </ScrollView>
    </View>




    <ScrollView style={{width: '100%', paddingLeft: 20,paddingRight: 20,}} contentContainerStyle={{width: '100%'}}>
    <View style={styles.scrollViewContainer}>
      {/*Todo: Find way to show selected checkable list */}
    <ProfileModule adv={advanced} title="Edit Profile" />
    <ProfileModule adv={advanced} title="Change Profile Picture" />
    <ProfileModule adv={advanced} title="Change Email"/>
    <ProfileModule adv={advanced} title="Change Password" />
    <ProfileModule adv={advanced} title="Provide Feedback" />
    <ProfileModule adv={advanced} title="Temperature Log" />
    <ProfileModule adv={advanced} title="Add Contact Report" />
    <ProfileModule adv={advanced} title="Secret Santa" />
    </View>
    </ScrollView></View>
    </View>
   ) };

export default memo(Profile);
