import { DefaultTheme } from 'react-native-paper';
import { getItemAsync } from 'expo-secure-store';
import { Appearance, useColorScheme } from 'react-native';
export const theme = Appearance.getColorScheme() === 'dark' ? {
  ...DefaultTheme,
  myOwnProperty: true,

  colors: {
    ...DefaultTheme.colors,
    error: '#f13a59',
    medBlue: '#77b7f2',
    lightGreen: '#c1ff9a',
    medGreen: '#51A96A',
    darkBlue: '#3978b1',
    lightBlue: '#cbe6ff',
    red: "#f00",
    yellow: "#ee0",
    background: '#fff',
    activities: [
      "#fe9d0b",
      "#62d945",
      "#22b5d3",
      "#f5008f",
      "#3e3e3d",
    ],

    loadingIndicator: "#000",
    gray: "#444",

    listItemBackground: "#222222",
    itemTextColor: "#dddddd",

    calendarFilled: "#51A96A",
    calendarPartial: "#FFEB21",
    calendarEmpty: "#FF4026",

    greenButton: "#61af3a",
    redButton: "#dd8d4d",
    adminOnlyOption: "#eedd88",
    managerOnlyOption: "#ffaa55"
  },
} : {
  ...DefaultTheme,
  myOwnProperty: true,

  colors: {
    ...DefaultTheme.colors,
    error: '#f13a59',
    medBlue: '#77b7f2',
    lightGreen: '#c1ff9a',
    medGreen: '#51A96A',
    reports: "#3e3e3d",
    darkBlue: '#3978b1',
    lightBlue: '#cbe6ff',
    red: "#f00",
    yellow: "#ee0",
    background: '#fff',
    activities: [
      "#fe9d0b",
      "#62d945",
      "#22b5d3",
      "#f5008f",
      "#3e3e3d",
    ],
    loadingIndicator: "#000",
    gray: "#444",

    listItemBackground: "#ffffff",
    itemTextColor: "#000000",

    calendarFilled: "#51A96A",
    calendarPartial: "#FFEB21",
    calendarEmpty: "#FF4026",

    greenButton: "#c1ff9a",
    redButton: "#fdad9d",

    adminOnlyOption: "#eedd88"
  },
} 