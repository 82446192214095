import { memo } from 'react';
import { TouchableOpacity, Dimensions, StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import * as React from 'react';
import Avatar from './Avatar';

const MappedUser = ({config, user}) => {




const styles = StyleSheet.create({
  text: {
    fontSize: 20, 
    lineHeight: 26,
    color: theme.colors.itemTextColor,
    textAlign: 'center',
  },
  header: {
    fontSize: 24,
    lineHeight: 24,
    textAlign: 'center',
    color: theme.colors.itemTextColor,
    flex: 0.8
    },
  container:
  {
    lineHeight: 26,
    borderRadius: 15,
    padding: 10,
    alignItems: "center",
    width: '100%',
    zIndex: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  }
  
});


return (
<View style={styles.container}>
<Avatar containerStyle={{borderWidth: 2, borderColor: "#000"}} user={user}></Avatar>
<Text style={styles.header}>{user.screenName}</Text>
</View>
   );
};
export default memo(MappedUser);
