import React, { memo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';

const GraphBar = ({config, value, maxValue, color, group, place}) => {

const styles = StyleSheet.create({
  bar: {
    backgroundColor: theme.colors.darkBlue, 
    width: (value*100/maxValue) + "%", 
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    zIndex: 100,
    position: 'absolute',
    top: 0,
    left: 0,

  },
  barBackground: {
    backgroundColor: theme.colors.lightBlue, 
    width: "100%", 
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    flex: 1

  },
  barLabel: {

    fontSize: 21, 
    color: "#000000",
    marginRight: 5
    
  },
  barGroup: {

    fontSize: 21,
    color: "#000000",
    textAlign: "left",
    marginLeft: 15,
  },
  barContainer: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  barText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  notNumber: {
    display: "flex",
    flex: 1
  },
  number: {
    display: "flex",
    flex: 0.08,
    fontSize: 40,

    textAlign: "right",
    marginRight: 5,
    marginTop: 10,
    textAlignVertical: "center"
  }

});

return (
  <View style={styles.barContainer}><View style={styles.number}><Text style={styles.number}>{place}</Text></View><View style={styles.notNumber}><View style={styles.barText}><Text style={styles.barGroup}>{group}</Text><Text style={styles.barLabel}>{value} pts</Text></View>
  <View style={styles.barBackground}><View style={styles.bar}><Text></Text></View></View></View>

</View>
);

};
export default memo(GraphBar);
