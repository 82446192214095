import React, { memo, useEffect } from 'react';
import Header from '../components/Header';
import Button from '../components/Button';
import { styles } from '../styles/DashboardStyle';
import { Dimensions, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import TodayItem from '../components/TodayItem';
import ConfettiCannon from 'react-native-confetti-cannon';
import { MaterialIcons } from '@expo/vector-icons'; 
import Avatar from '../components/Avatar';
import Title from '../components/Title';
import NavDrawer  from '../components/NavDrawer';
import Heading from '../components/Heading';
import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { theme } from '../core/theme';
import Moment from 'moment';
import { activities } from '../core/activities';
import { navTo } from '../core/utils';
import * as AuthSession from 'expo-auth-session';
import { getSpotifyData } from '../core/spotify';






export default function Dashboard({ route, navigation })  {

  async function deleteItem(key) {
    console.log("Deleting " + JSON.stringify(key))
    await AsyncStorage.removeItem(key);
  }
  
  const[user,setUser] = React.useState(route.params.user)
  const[config,setConfig] = React.useState(route.params.config)
  const[specialText, setSpecialText] = React.useState("")

  var activityList = activities(config);
  
  function uploadToken(token)
  { if(user!=null)
    {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UploadToken",
        "camp": user.Home,
        "user": user.UID,
        "token": token
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      
   });
  }
  }

  const [complete, setComplete] = React.useState(false); 
  const [formattedTasks, setFormattedTasks] = React.useState(null);
  const [myTasks, setMyTasks] = React.useState(null);

  
  function refresh() {

    console.log("REfreshing Dashboard")
    global.marked = 0;
    if(user!=null)
    {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetMyTasks",
        "camp": user.Home,
        "user": user.screenName
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      setMyTasks({tasks: responseJson.calendar, claims: responseJson.claimedSites});
    });
    let b = JSON.stringify({
      "command": "GetUser",
      "camp": user.Home,
      "user": user.UID
      
  });
  console.log(b)
   fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
   method: 'POST',
    body: b
 }).then((response2) => {
 return(response2.json())
}).then((responseJson2) => {
    
    if(responseJson2.records!=null)
    {
      setUser(responseJson2.records);
    }
 });
  }

  }


  function jumpToEvent(event) {
    navigation.navigate("SignUp", {user: user, config: config, refresh: refresh, event: event})
  }
  function jumpToTask(spot) {
    navigation.navigate("Details", {user: user, config: config, spot: spot, onBack: refresh})
  }

  useEffect(() => {
    if(myTasks!=null)
    {
      setFormattedTasks(
        [myTasks.tasks.map((item) => {
          return(
            <TodayItem config={config} key={item.EID} onPress={() => jumpToEvent(item)} title={item.Title} time={Moment(item.Date).format('dddd, MMM Do')} type={0} />
            )
        }),
        myTasks.claims.map((item) => {
          return(
            <TodayItem config={config} key={item.SID} onPress={() => jumpToTask(item)} isDenied={item.denied} title={item.site_Name} time={item.denied==1 ? "Denied Claimed Site" : "Claimed Site"} type={1} /> 
          )
        })]
        
        )
 
    }
  }, [myTasks])
  
  

  useEffect(() => {
    global.marked = 0;
if(user!=null)
{
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetMyTasks",
        "camp": user.Home,
        "user": user.screenName
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      
      setMyTasks({tasks: responseJson.calendar, claims: responseJson.claimedSites});
   });
  }
  }, [user])

  const check = (status) => {
    }

  const confettiTime = () => {
    setComplete(!complete);
    setTimeout(() => {
      setComplete(false);
      
      
    }, 20000);
  }
  
  return (
    
    
    <View style={styles.container}>


    <Button style={styles.logoutButton} mode="outlined" onPress={() => {
      setUser(null); 
      deleteItem("user")
      deleteItem("config")
      navigation.replace("HomeScreen");
      }}>
        

          <MaterialIcons name="logout" size={24} color={theme.colors.itemTextColor} /> 
        </Button> 
        <Button style={styles.settingsButton} mode="outlined" onLongP={() => {console.log(config)}} onPress={() => {navigation.navigate('Settings', {user: user, config: config, refresh: refresh})}}>
          <MaterialIcons name="menu" size={24} color={theme.colors.itemTextColor} /> 
        </Button> 
    <View style={styles.body}>
      
    <View style={styles.navDrawer}>
      <NavDrawer customTheme={config.theme} items={config.tools} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
              </View>
    <View style={styles.content}>
      {user==null ? null : <Avatar 
          user={user} 
          containerStyle={
            { borderRadius: "60%", 
              width: Dimensions.get("screen").height*0.3,
              height: Dimensions.get("screen").height*0.3,
              borderWidth: 4
            }} 
          logoStyle={
            {
              width: "100%", 
              height: "100%", 
              resizeMode: "stretch"
            }} />} 
        <Title color={theme.colors.itemTextColor}>{user==null ? null : user.screenName}{user==null || !config.pointsAvailable ? null : " - " + user.points}</Title>
        <Heading color={theme.colors.itemTextColor} fontSize={24}>Your Tasks</Heading>
        {//<Text color={theme.colors.itemTextColor}>{false && specialText}</Text>
}
        <ScrollView showsVerticalScrollIndicator={true} persistentScrollbar={true} style={{marginBottom: 50, width: "90%"}}>
          {formattedTasks}
        </ScrollView>
      </View>
      </View>



    
    </View>
   ) };


