import { memo } from 'react';
import { TouchableOpacity, Dimensions, StyleSheet, Text, View, Image } from 'react-native';
import { theme } from '../core/theme';
import * as React from 'react';
import CheckedBox from '../assets/checkedBox.svg';
import UncheckedBox from '../assets/uncheckedBox.svg';
import {MaterialCommunityIcons, FontAwesome, Ionicons} from '@expo/vector-icons'
import Task from './Task';
import { ScrollView } from 'react-native';
import TodayItem from './TodayItem';
import Button from './Button';
import TextInput from './TextInput';

const ComplexListItem = ({ status, home, userList, priority, hasImage, viewAll, config, editSite, isManager, approve, deny, simple, siteOwner, SID, adv, logs, claim, progress, title, isDenied, people, style, tasks, totalTasks, children, ...props }) => {
var advList;
const [selectedTasks, setSelectedTasks] = React.useState("")

const [image, setImage] = React.useState(hasImage ? "https://outpostorganizer.com/SITE/Global/Spots/" + home + "/" + SID + "/img.png?rand=" + (Math.random()*10000)%10000 : null);

function getPriorityColor()
{
  switch(priority)
  {
    case("0"):
      return theme.colors.greenButton + "52";
    case("2"):
      return theme.colors.error + "52";
    default:
      return theme.colors.listItemBackground
      
  }
}

function attemptDeny() {
  var test = false;
  setChecked(true)
  if(selectedTasks=="")
  {
    setTaskError(true)
    test = true;
  }
  else
  {
    setTaskError(false)
  }
  if(!test)
  {
    deny(SID, "deny", comments, selectedTasks);
  }
}
const taskList = tasks.map((task, index) => { 
  if(task!="")
  {
    return(<Task key={index} title={task}/> ) 
  }
   });

   const totalTaskList = totalTasks.split("& ").map((task, index) => { 
    if(task!="")
    {
      return(<Task key={index} title={task}/> ) 
    }
     });

const open = () => {
  setChecked(!checked);
}

function calculatePercent() {
  return Math.round(progress*100)/100;
}

const list = () => {
  setSite(!showSite);
}

const openWindow = () => {
  adv(title);
  }


  const claimSite = () => {
    claim();
    }


const [checked, setChecked] = React.useState(false);
const [showSite, setSite] = React.useState(false);
const [comments, setComments] = React.useState("")
const [commentError, setCommentError] = React.useState(false)
const [taskError, setTaskError] = React.useState(false)


function getSiteOwners()
{
  if(userList!=null)
  {
    return siteOwner.split(",").map((sO) => {
      return(
        userList.find((u) => u.UID==sO || u.screenName==sO)==null ? sO : userList.find((u) => u.UID==sO || u.screenName==sO).screenName
      )
    }).join(", ") 
  }
}
function isMarked(task)
{
  
  return selectedTasks.includes(task)
}

function mark(task)
{
  if(selectedTasks.includes(task))
  {
    setSelectedTasks(selectedTasks.replace(task + "& ",""))
  }
  else
  {
    let allTasks = totalTasks.split("& ");
    let newAllTasks = "";
    for(var i=0; i<allTasks.length; i++)
    {
      if(isMarked(allTasks[i]) || allTasks[i]==task)
      {
        newAllTasks = newAllTasks + allTasks[i] + "& "
      }
    }
    setSelectedTasks(newAllTasks)
  }
}

const styles = StyleSheet.create({
  text: {
    fontSize: 22,
    lineHeight: 26,
    color: theme.colors.itemTextColor,
    textAlign: 'right',
    
    flex: 1,
    textAlignVertical: "bottom"
    
  },
  item: {
    fontSize: 15,
    flex: 1,
    color: theme.colors.itemTextColor,
  },
  header: {
    fontSize: 18,
    color: theme.colors.itemTextColor,
    lineHeight: 24,
    textAlign: 'left',
    paddingRight: 5,
    flex: 0.3,
        
    textAlignVertical: "bottom"
  },
  percent: {
    fontSize: 20,
    color: theme.colors.itemTextColor,
    lineHeight: 24,
    textAlign: 'left',
    flex: 0.1,
    textAlignVertical: "bottom"
  },
  container:
  {
    lineHeight: 26,
    marginBottom: 14,
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subContainer:
  {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    display: "flex",
    width: "100%"

  },
  expandedContainer:
  {
    width: "100%"
  },
  expandedContainerHide:
  {
    display: "none",
    overflow: "hidden"
    
  },
  checkbox:
  {
    
  },
  checkboxContainer:
  {
    position: 'absolute',
    left: 20,
    top: '22%',
    overflow: 'visible',
  },
  bar:
  {
    width: progress + "%",
    backgroundColor: config!=null && config.theme.list!=null ? config.theme.list : theme.colors.list,
    borderRadius: 4,
    flex: 50

  },
  barBackground:
  {
    width: "100%",
    backgroundColor: config!=null && config.theme.list!=null ? config.theme.list + "44" : theme.colors.list + "44",
    borderRadius: 4,
    marginBottom: 5,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  people:
  {
    textAlign: 'right',
    flexDirection: 'row',
    flex: 0.3
  },
  line:
  {
    height: 2,
    flex: 10,
    width: "100%",
    backgroundColor: theme.colors.itemTextColor,
    marginBottom: 5
  },
  lineHidden:
  {
    display: "none"
  },
  taskListHidden:
  {
    display: "none"
  },
  taskList:
  {
    textAlign: "left",
    width: "100%",
  },
  join: {
    height: 'auto',
    borderRadius: 20,
    backgroundColor: theme.colors.greenButton,
    color: '#000000',
  
    width: '30%',
    
    
},
deny: {
  height: 'auto',
  borderRadius: 20,
  backgroundColor: theme.colors.redButton,
  color: '#000000',

  width: '30%',
  
  
},
buttonContainer: {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-around"
},
taskContainer: {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "space-around"
},
owner: {
  fontSize: 16,
  color: theme.colors.itemTextColor,
},
task: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
},
title: {
  fontSize: 16,
  fontStyle: "italic",
  color: theme.colors.itemTextColor
},

titleError: {
  fontSize: 16,
  fontStyle: "italic",
  color: theme.colors.itemTextColor,
  fontWeight: "bold",
  textDecorationColor: theme.colors.redButton,
  textDecorationLine: "underline"
}
  
});
var markableItems=totalTasks.split("& ").map((task) => {
  return(
    <TouchableOpacity onPress={() => {
      mark(task)
    }} activeOpacity={1} style={isMarked(task) ? [styles.task, {backgroundColor: theme.colors.redButton}] : [styles.task, {backgroundColor: theme.colors.listItemBackground}]}><Button style={[{borderWidth: 3, borderColor: theme.colors.itemTextColor, width: 25, marginRight: 10}, isMarked(task) ? {backgroundColor: theme.colors.redButton} : {backgroundColor: theme.colors.greenButton + "93"}]} onP={() => {mark(task)}}></Button><Text style={styles.item}>{task}</Text></TouchableOpacity>
  )
})

if(simple)
{
  return (
<View style={styles.container}>
 
 <TouchableOpacity activeOpacity={1.0} style={[styles.container, {backgroundColor: theme.colors.listItemBackground}, {paddingLeft: 20,
    paddingTop: 6,
    paddingRight: 20}]} onPress={open} >
     <Text style={styles.header}>{title}</Text>
     <Text style={styles.owner}>{siteOwner==null ?  "Unclaimed": "Owned By: " + siteOwner.slice(0, -2)}</Text>
       <View style={styles.buttonContainer}>
       <Button style={styles.join} mode="outlined" onPress={() => {approve()}}>
     <Text style={styles.join} ><Ionicons name="checkmark" size={24} color={theme.colors.itemTextColor}/></Text>
 </Button> 
 <Button style={styles.deny} mode="outlined" onPress={() => {attemptDeny()}}>
 <FontAwesome name="remove" size={24} color={theme.colors.itemTextColor}/>
 </Button></View>
 <View style={checked ? styles.expandedContainer : styles.expandedContainerHide}>
 
<View style={[styles.taskContainer, taskError ? {borderColor: theme.colors.error, borderWidth: 5, padding: 5} : {}]}>
  <Text style={taskError ? styles.titleError : styles.title}>Select all tasks that need to be redone.</Text>
{markableItems}</View><TextInput
  label="Comments"
  multiline={true}
  value={comments}
  onChangeText={text => setComments(text)}
  numberOfLines={4}
  style={commentError ? {width: "90%", borderColor: theme.colors.error, borderWidth: 2, backgroundColor: theme.colors.redButton + "66"} : {width: "90%", borderColor: theme.colors.itemTextColor, borderWidth: 1}}
/></View></TouchableOpacity>
 </View>
 
  );
}
else
{
return (
  <View style={[styles.container, isDenied ? {borderWidth: 8, borderColor: "#f54", backgroundColor: theme.colors.listItemBackground} : {}]}>
             {viewAll && status!=0 ? <View style={{position: "absolute", zIndex: 500, left: 0, top: 0, flexDirection: "row", justifyContent: "center", alignItems: "center", padding: 2, backgroundColor: "#f0d02088"}}><Ionicons name="checkmark" size={25} color={theme.colors.itemTextColor}></Ionicons><Text style={{color: theme.colors.itemTextColor}}>{status==1 ? "Submitted" : status==2 ? "Approved" : status==-1 ? "Hidden" : ""}</Text></View> : null}
     {priority==1 ? null : <MaterialCommunityIcons style={{position: "absolute", left: 5, top: 5}} name={priority==0 ? "arrow-down" : "exclamation-thick"} size={25} color={theme.colors.itemTextColor}></MaterialCommunityIcons>}
 <View style={{backgroundColor: getPriorityColor(), width: "100%",
    paddingLeft: 20,
    paddingTop: 6,
    paddingRight: 20,}}>

  <TouchableOpacity activeOpacity={1.0} style={styles.container} onPress={open} >
    <View style={styles.barBackground}>
      <View style={styles.bar}>
        <Text></Text>
      </View>
    </View>
    <View style={styles.subContainer}>
      <Text style={styles.header}>{title}</Text>
      <Text numberOfLines={1} style={styles.percent}>{calculatePercent()}%</Text>
      <View style={styles.people}><Text {...props} style={styles.text}>{people} </Text>
      <MaterialCommunityIcons name="account-multiple" color={theme.colors.itemTextColor} size={30}/></View></View>
      <Text style={styles.owner}>{siteOwner==null ?  "Unclaimed": "Owned By: " + getSiteOwners()}</Text>
      {image==null ? null : <Image source={{uri: image}} style={{width: 200, height: 200, marginTop: 20}}/>}
      <Text style={styles.owner}>{isDenied==1 ?  logs=="" ? "Denied!" : "\nReason for Denial:\n" + logs : ""}</Text>
      <View style={[checked ? styles.expandedContainer : styles.expandedContainerHide ]}>
        <View style={styles.buttonContainer}>
      {!viewAll || status==0 ? <Button style={styles.join} mode="outlined" onPress={openWindow}>
      <Text style={styles.join} >Join</Text>
  </Button> : null }
  {siteOwner!=null ? null : <Button style={styles.join} mode="outlined" onPress={claimSite}>
      <Text style={styles.join} >Claim</Text>
  </Button>}
  {!isManager ? null : <Button style={styles.join} mode="outlined" onPress={editSite}>
      <Text style={styles.join} >Edit</Text>
  </Button>}</View>
        <View style={[checked ? styles.line : styles.lineHidden ]}><Text></Text>
        </View>
        <View style={styles.taskList}>
        {viewAll ? totalTaskList : progress==100 ? null :taskList}</View>
        
      </View>
  </TouchableOpacity>
  </View>
  </View>
   );
};
}
export default memo(ComplexListItem);
