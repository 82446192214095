import { memo } from 'react';
import {  Text, TouchableOpacity, View } from 'react-native';
import { theme } from '../core/theme';
import * as React from 'react';
import Avatar from './Avatar';
import { Dimensions } from 'react-native';

const MessageBubble = ({  item, userList, user, addAvatar, ...props }) => {

  const bubbleStyles = {
    else: {justifyContent: "flex-start", padding: 10, maxWidth: "60%", alignContent: "center", alignSelf: "flex-start", borderRadius: 20, backgroundColor: theme.colors.greenButton},
    self: {justifyContent: "flex-start", padding: 10, maxWidth: "60%", alignContent: "center", alignSelf: "flex-end", borderRadius: 20, backgroundColor: theme.colors.medBlue}
  }

  const avatarStyles = {
    else: 
    { alignSelf: "center",
      borderRadius: 50, 
      width: 50, 
      height: 50,
      borderWidth: 4,
      position: "absolute",
      left: -45,
      top: 0
    },
    self: 
    { alignSelf: "center",
      borderRadius: 50, 
      width: 50, 
      height: 50,
      borderWidth: 4,
    }
  }
  
  const [showName, setShowName] = React.useState(false)

return (
  
  <View style={{}}>
    <TouchableOpacity activeOpacity={0.9} style={[item.SenderUID==user.UID ? bubbleStyles.self: bubbleStyles.else, {backgroundColor: "transparent", width: "60%", paddingBottom: 0, flexDirection: item.SenderUID==user.UID ? "row-reverse" : "row", justifyContent: "flex-start", paddingTop: 10, marginLeft: 50}]} onPress={() => {setShowName(!showName)}}>
    {addAvatar && <Avatar user={userList.find((u) => u.UID==item.SenderUID)} 
      containerStyle={
            item.SenderUID!=user.UID ? avatarStyles.else : avatarStyles.self} 
          logoStyle={
            {
              width: "100%", 
              height: "100%", 
              resizeMode: "stretch"
            }} /> }
  <View style={[item.SenderUID==user.UID ? bubbleStyles.self: bubbleStyles.else, {}]}>
        <Text style={[item.SenderUID!=user.UID && {paddingLeft: 10}, {color:  theme.colors.itemTextColor, fontSize: 16,  fontFamily: "Glacial-Indifference"}]}>{item.Message}</Text>
      </View>
      </TouchableOpacity>
      {showName && item.SenderUID!=user.UID && <Text style={{color:  theme.colors.itemTextColor, fontSize: 16, fontFamily: "Carter-One"}}>{userList!=null && userList.find((u) => u.UID==item.SenderUID)!=null ? userList.find((u) => u.UID==item.SenderUID).screenName : null}</Text>}
      </View>
   );
};
export default memo(MessageBubble);
