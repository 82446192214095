import { Dimensions, StyleSheet } from 'react-native'
import { theme } from '../core/theme'

export const styles = StyleSheet.create({
    
    fixed: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0   
    },
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
        backgroundColor: theme.colors.listItemBackground
    },
    logoutButton: {
      position: "absolute",
      top: 0,
      width: 20,
      right: 20,
      zIndex: 100,
      borderRadius: 50,
      backgroundColor: theme.colors.listItemBackground,
      borderColor: "#000"
  },
  settingsButton: {
    position: "absolute",
    backgroundColor: theme.colors.listItemBackground,
    borderColor: "#000",
    top: 0,
    width: 20,
    right: 200,
    zIndex: 100,
    borderRadius: 50
},
    body: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "stretch",
    },

    navDrawer: {
      display: "flex",
      flexDirection: "column",
      flex: 0.1,
      alignItems: "stretch"
    },
    content: {
        flexDirection: "column",
        justifyContent: "flex-start",   
        alignItems: "center",    
        paddingTop: 50, 
        flex: 0.7,
        display: "flex"

    }
    
  });