import React from 'react';
import { Provider } from 'react-native-paper';
import App from './src';
import { theme } from './src/core/theme';
import * as SplashScreen from 'expo-splash-screen';
import { BackHandler } from 'react-native-web';


const Main = () => {
  return (
  <Provider theme={theme}>
    <App />
  </Provider>
  )
  };

export default Main;
