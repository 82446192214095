import React, { useState, memo, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/SignUpStyle';
import { Dimensions, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import TextInput from '../components/TextInput';
import StatusBar from '../components/StatusOverlay';
import { Calendar } from 'react-native-calendars';
import { Entypo } from '@expo/vector-icons'; 
import Moment from 'moment';
import * as ImagePicker from 'expo-image-picker'
import {Picker} from '@react-native-picker/picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import MultiSelect from 'react-native-multiple-select'
import { navTo } from '../core/utils';
import {manipulateAsync} from 'expo-image-manipulator';


export default function NewSite({ route, navigation })  {

  var user = route.params.user;
var config = route.params.config;
  var refreshBack = route.params.refresh;
  var list = route.params.list;
  var assigned = route.params.assigned;
  var spotID = route.params.spotID;
  var siteID= route.params.siteID;

  function getTextForStatus()
  {
    switch(status)
    {
      case(-1):
        return "Hidden"
      case(0):
        return "Default"
      case(1):
        return "Submitted"
      case(2):
        return "Complete"
    }
  }


  function checkImageURL(URL) {
    fetch(URL)
      .then((res) => {
        if (res.status == 404) {
          setImage(null);
        } else {
          console.log("setting image")
          setImage("https://outpostorganizer.com/SITE/Global/Spots/" + user.Home + "/" + spotID + "/img.png?random=" + (Math.random()));
        }
      })
      .catch((err) => {
        setImage(null)
      });
  }

  const [workers, setWorkers] = React.useState(null)
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState(route.params.title==null ? null : route.params.title)

  const[time, setTime] = React.useState(new Date())
  const [dotsMapped, setDots] = React.useState(null)
  const [formattedSelDay, setFSD] = React.useState({})
  const [updateTime, setUpdateTime] = React.useState(false)
  const [flag, setFlag] = React.useState(false)
  const [status, setStatus] = React.useState(-9999)
  const [priority, setPriority] = React.useState(-9999)

  const [tasks, setTasks] = React.useState(route.params.tasks==null ? null : route.params.tasks.split("& ").join("\n"))
  const [allSites, setAllSites] = React.useState(null)
  const [selectedSite, setSelectedSite] = React.useState(route.params.siteID==null ? {id: -1} : {id: route.params.siteID})

  const [userList, setUserList] = React.useState([])
  const [selectedUsers, setSelectedUsers] = React.useState(route.params.assigned==null ? [] : route.params.assigned.split(","))

  const [image, setImage] = useState(route.params.hasImage==1 ? "https://outpostorganizer.com/SITE/Global/Spots/" + user.Home + "/" + spotID + "/img.png?random2=" + (Math.random()*10070)%10000 : null);
  
  const [orderedSites, setOrderedSites] = React.useState(null)

  var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];




  
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [3, 3],
      quality: 1,
    });


    if (result.hasOwnProperty('uri')) {
      const manipulateResult = await manipulateAsync(
           result.uri,
          [],
          { 
            base64: true,
            compress: 0.4 } // from 0 to 1 "1 for best quality"
      );
      setImage(manipulateResult);
    
  };
  }



  useEffect(() => {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "GetSites",
        "camp": user.Home,
        "user": user.UID,
        "list": list.id
        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    setAllSites(responseJson.sites)

    console.log(responseJson.sites.length)
    if(responseJson.sites.length>0)
    {
      setSelectedSite(responseJson.sites[0])
    }
    setLoading(false)
  }).catch((error) =>
  {
    setLoading(false);
  })

  }, [])

  function getTasks()
  {
    var t = (tasks.split("\n").filter((task) => task != "").join("& "))
    console.log("Combined tasks: " + t)
    return t
  }

  function submit() {
    if(title==null || tasks==null || title=="" || tasks=="" || status==-9999)
    {
      setFlag(true)
      setLoading(false)
      return;
    }
    console.log("SELECTED USERS: " + selectedUsers==null)
    console.log("Submitting", image==null ? " without image" : " with image")
    console.log("Assigned", selectedUsers!=null ? selectedUsers : null)
    console.log(selectedSite)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "UpdateSpots",
        "camp": user.Home,
        "list": list.id,
        "title": title,
        "assigned": selectedUsers!=null&&selectedUsers.length>0 ? selectedUsers.filter((item) => item!=" " && item!="").join(",") : null,
        "tasks": getTasks(),
        "status": status, 
        "siteID": selectedSite.SiteID,
        "spotID": spotID,
        "priority": priority,
        "user": user.UID,
        "hasImage": image==null ? 0 : 1,

        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    if(image!=null)
    {
    console.log(image.base64)
    fetch('https://www.outpostorganizer.com/SITE/v2/uploadAPI.php', {
        method: 'POST',
        body: JSON.stringify({
        "user": user.UID,
        "camp": user.Home,
        "spot": spotID,
        "image": image.base64,
        "type": "spot",
        
        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
    console.log(responseJson)
    }).catch((error) =>
  {
    console.log(error)
    setLoading(false);
  }).finally(() => {
    navTo("back", user, config, refreshBack, navigation)
  })
}
else
{
  console.log(responseJson)
  setLoading(false)
  navTo("back", user, config, refreshBack, navigation)
  if(refreshBack!=null)
    {
      refreshBack()  
    }
}
  })
  }
  
  function deleteSite() {

    console.log("Deleting")
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "DeleteSpot",
        "camp": user.Home,
        "user": user.UID,
        "spotID": spotID

        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
    console.log(responseJson)
    }).catch((error) =>
  {
    console.log(error)
    setLoading(false);
  }).finally(() => {
    navTo("back", user, config, refreshBack, navigation)
  })

  }

  useEffect(() => {
    setLoading(true);
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "GetUsers",
          "camp": user.Home,
          "user": user.UID,
        })
      })
     .then((response) => {
     return(response.json())
    })
     .then((responseJson) => {
        setUserList(Object.values(responseJson.records).map((item) => {
          return (
            {name: item.screenName,
            id: item.UID}
          )
            
        }));
      }).finally(() => {
        setLoading(false)
      })
      }, [])
  

  useEffect(() => {
    setOrderedSites(
      <Picker
      itemStyle={{ color: theme.colors.itemTextColor  }}
  style={{backgroundColor: theme.colors.listItemBackground, color:theme.colors.itemTextColor, borderWidth: 2, borderColor: "#000"}}
  selectedValue={selectedSite.SiteID}
  onValueChange={(itemValue, itemIndex) => {
    console.log(allSites.find((site) => site.SiteID==itemValue).SiteName)
    setSelectedSite(allSites.find((site) => site.SiteID==itemValue))
  }
  }>
    {allSites!=null ? [...allSites.map((site) => {
      if(site.SiteID==siteID)
      return(
        <Picker.Item label={site.SiteName} color={theme.colors.itemTextColor} value={site.SiteID}></Picker.Item>
      )
    }), ...allSites.map((site) => {
      if(site.SiteID!=siteID)
      return(
        <Picker.Item label={site.SiteName} color={theme.colors.itemTextColor} value={site.SiteID}></Picker.Item>
      )
    })] : null}
    
</Picker>)

  }, [allSites])
  
  
  



  
  return (
    
    
    <View style={styles.container}>
      {loading ? <StatusBar config={config}></StatusBar> : null}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          
            navTo(navName, user, config, null, navigation)
          
          }} /></View>

    <View style={[styles.content, {backgroundColor: config!=null && config.theme.list!=null ? config.theme.list + "12" : themeColor + "12", borderColor: config!=null && config.theme.list!=null ? config.theme.list : themeColor}]}>
<ScrollView style={{width: "100%"}} contentContainerStyle={{paddingBottom: 200}}>
  
<TextInput
  label={<Text style={{color: theme.colors.itemTextColor}}>List Name</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="next"
  value={list.title}
  editable={false}
  onChangeText={text => setTitle(text)}
/>    


{allSites!=null && (allSites.length > 1) ? 
<View><Text style={{color: theme.colors.itemTextColor, marginTop: 20}}>Category: {selectedSite.SiteName}</Text>
{orderedSites}</View> : null}
<TextInput
   label={<Text style={{color: theme.colors.itemTextColor}}>Title</Text>}
   autoCapitalize="words"
   theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
   style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="next"
  value={title}
  errorText={flag && (title=="" || title==null) ? "Error.  Title is required." : ""}
  onChangeText={text => setTitle(text)}
/>    
<Button mode="contained" onPress={() => {pickImage()}} style={{
  backgroundColor: config.theme.list==null ? themeColor + "99" : config.theme.list + "99",
  width: "90%", marginTop: 10, marginBottom: 10}}>{image==null ? "Add" : "Update"} Image</Button>
{image!=null ? <Image source={{uri: image.base64==null ? image : "data:image/png;base64," + image.base64}} style={{width: 200, height: 200}}></Image> : null}
<TextInput
  label={<Text style={{color: theme.colors.itemTextColor}}>Tasks (Denoted by new lines)</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="next"
  multiline
  value={tasks}
  errorText={flag && (tasks=="" || tasks==null) ? "Error.  Tasks are required." : ""}
  onChangeText={tasks => {
    if(!(tasks.includes("'") || tasks.includes("\"")))
    {
      setTasks(tasks)
    }
    }}
/>   
<MultiSelect
 hideTags
 items={userList}
 uniqueKey="id"
 onSelectedItemsChange={(selectedItems) => { setSelectedUsers(selectedItems)}}
 selectedItems={selectedUsers}
 selectText="Users Assigned"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 itemTextColor={theme.colors.itemTextColor}
 selectedItemTextColor={theme.colors.greenButton}
 selectedItemIconColor={theme.colors.greenButton}
 styleTextDropdownSelected={{color: theme.colors.itemTextColor}}
 styleTextDropdown={{color: theme.colors.itemTextColor}}
 searchInputStyle={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
 styleSelectorContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleMainWrapper={{backgroundColor: theme.colors.listItemBackground}}
 styleListContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleInputGroup={{color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenu={{backgroundColor: theme.colors.listItemBackground}}
 styleIndicator={{backgroundColor: theme.colors.listItemBackground}}
 styleItemsContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenuSubsection={{backgroundColor: theme.colors.listItemBackground}}
 displayKey="name"
 submitButtonColor={theme.colors.greenButton}
 submitButtonText="Submit"

/>
<Text style={{color: theme.colors.itemTextColor, marginTop: 20}}>Status: {getTextForStatus()}</Text>
<Picker
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,   borderWidth: 2, borderColor: "#000"}}
  selectedValue={status}
  onValueChange={(itemValue, itemIndex) =>
    {
    console.log(itemValue)
    setStatus(itemValue)
    }
  }>
  {status==-9999 ? <Picker.Item label="Click to Set Item Status" value={-9999} /> : null }
  <Picker.Item label="Default" value={0} color={theme.colors.itemTextColor} />
  <Picker.Item label="Hidden" value={-1} color={theme.colors.itemTextColor} />

  <Picker.Item label="Submitted" value={1} color={theme.colors.itemTextColor} />
  <Picker.Item label="Complete" value={2} color={theme.colors.itemTextColor} />

</Picker>
{flag && status==-9999 ? <Text style={{color: theme.colors.error}}>Please select a status.</Text> : null}


<Text style={{color: theme.colors.itemTextColor, marginTop: 20}}>Priority: {priority==0 ? "Low" : priority==1 ? "Default" : priority==2 ? "High" : ""}</Text>
<Picker
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,  borderWidth: 2, borderColor: "#000"}}
  selectedValue={priority}
  onValueChange={(itemValue, itemIndex) =>
    setPriority(itemValue)
  }>
  {priority==-9999 ? <Picker.Item color={theme.colors.itemTextColor} label="Click to Set Item Priority" value={-9999} /> : null }
  <Picker.Item label="Low" color={theme.colors.itemTextColor} value={"0"} />
  <Picker.Item label="Default" color={theme.colors.itemTextColor} value={"1"} />
  <Picker.Item label="High" color={theme.colors.itemTextColor} value={"2"} />
</Picker>
{flag && priority==-9999 ? <Text style={{color: theme.colors.error}}>Please set the item's priority.</Text> : null}



<Button onP={() => {
  setLoading(true)
  submit()
}} style={{backgroundColor: theme.colors.greenButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Submit</Text></Button>
<Button onP={() => {
  navTo("back", user, config, null, navigation)
}} style={{backgroundColor: theme.colors.redButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Cancel</Text></Button>
<Button onP={() => {
  deleteSite()
}} style={{backgroundColor: theme.colors.redButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Delete Spot</Text></Button>

      </ScrollView>
    
      </View>

      </View>

    
    </View>
   ) };
