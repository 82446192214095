import React, { memo, useState, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/TemplateStyle';
import { Dimensions, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import GraphBar from '../components/GraphBar';


export default function Scoreboard({ route, navigation })  {
  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;
  const [data, setData] = useState(null);

  const [graphs, setGraphs] = useState(null);

  const [maxVal, setMaxVal] = useState(0);
  async function navTo(location) {
    if(refresh!=null)
    {
      refresh()
    }
    navigation.navigate(location, {user: user, config: config})
    
  }


  
  useEffect(() => {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "GetScores",
        "user": user.UID,
        "camp": user.Home,
        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    
    setData(responseJson.records);
    
  } ).catch((exception) => {
    
  })
  }, []);

  useEffect(() => {
    console.log("data: " + data)
    if(data!=null)
    {
      console.log(data)
      setGraphs(data.map(function(item, index) {
        return (<GraphBar key={item.id} place={index+1} color={theme.colors.medBlue} maxValue={data[0].tempPoints} value={item.tempPoints} group={item.Name}/>);
      }))
    }
   }, [data])
  
    
  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer customTheme={config.theme} items={config.tools} active={4} shrink={true} navTo={navTo} />
      </View>

    <View style={[styles.content, {backgroundColor: theme.colors.scoreboard + "12", borderColor: theme.colors.scoreboard}]}>
        
    <ScrollView style={{width: "100%"}}>
  {graphs}
    </ScrollView>

      </View>
    
      </View>



    
    </View>
   ) };
