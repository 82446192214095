import { activities } from "./activities";

export const emailValidator = email => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return 'Email cannot be empty.';
  if (!re.test(email)) return 'Ooops! We need a valid email address.';

  return '';
};

export const spotifyCredentials = {
  clientId: '92892dfaebfa4a168fe0817fb0efe68f',
  clientSecret: '41f9a9ac2fca46729900fcb408db5808',
  redirectUri: 'https://auth.expo.io/@zlaoch/OUTPOST'
  }

export async function navTo(locID, argUser, argConfig, argRefresh, navigation) {
  console.log("NavTo: " + locID, argRefresh==null ? " without refresh." :  "with refresh.") 
  if(locID=="back")
  {
    argRefresh!=null ? argRefresh() : null
    navigation.goBack()
  }
  else if(locID=="Dashboard")
  {
    argRefresh!=null ? argRefresh() : null
    navigation.navigate("Dashboard", {user: argUser, config: argConfig})
  }
  else
  {
  var location = activities(argConfig).find((item) => item.id==locID).navName
  if(location=="Settings2")
  {
    navigation.goBack()
    if(argRefresh==null)
    {
      navigation.navigate("Settings2", {user: argUser, config: argConfig, id: locID})
    }
    else
    {
      navigation.navigate("Settings2", {user: argUser, config: argConfig, id: locID, refresh: argRefresh})
    }
  }
  else if(argRefresh!=null)
  {
    navigation.navigate(location, {user: argUser, config: argConfig, refresh: argRefresh, id: locID})
  }
  else
  {
    navigation.navigate(location, {user: argUser, config: argConfig, id: locID})
  }
}
}

export const passwordValidator = password => {
  if (!password || password.length <= 0) return 'Password cannot be empty.';

  return '';
};

export const nameValidator = name => {
  if (!name || name.length <= 0) return 'Name cannot be empty.';

  return '';
};


export function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      darkColor : lightColor;
  };


