import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/ShoppingStyle';
import { Dimensions, Image, Linking, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {MaterialIcons, FontAwesome5, MaterialCommunityIcons, Ionicons} from '@expo/vector-icons';
import { activities } from '../core/activities';
import { navTo } from '../core/utils';
import Title from '../components/Title';
import StatusBar from '../components/StatusOverlay';


var items = [
  {
    id: 1,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 2,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 3,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 4,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 5,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 1,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 2,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 3,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 4,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 5,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 1,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 2,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 3,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 4,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 5,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 1,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 2,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 3,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 4,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  {
    id: 5,
    name: "Matches",
    image: "http://mobileimages.lowes.com/productimages/6653d58b-86d9-49c1-a120-f89cc6d9ee12/46526654.png",
    price: "$3.99"
  },
  
]



export default function ShoppingList({ route, navigation })  {
  var colorList = JSON.parse(JSON.stringify(theme.colors.activities)).sort(() => Math.random() - 0.5)

  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;
 
var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

const [wishlist, setWishList] = React.useState(null)
const [loading, setLoading] = React.useState(true)


useEffect(() => {
  console.log("fetching")
  fetch(config.ShoppingLink, {
    method: 'GET',
 })
.then((response) => response.text())
.then((responseHTML) => {

var wlhtml = (responseHTML.split('<span class="a-list-item">').slice(2))
var aLists = wlhtml.map(item=> {
  return(
    item.split("<img")
  )
})
var aLists2 = aLists.map((listItem, index) => {
  if(listItem.length>1)
  {
    var title = listItem[1].split("\"")[1]
    var image = listItem[1].split("\"")[3]
    var link = listItem[1].split("a-link-normal")
    var price = listItem[1].split("span class=\"a-offscreen\">")[1]
    var raw = listItem[1]
    if(link.length>1)
    {
      var link = link[1].split("href")[1].split("\"")[1]
    }
    if(price!=null && price.length>1)
    {
      var price = price.split("</span")[0]
    }
  return({
    id: index,
    name: title,
    image: image,
    link: "http://amazon.com" + link,
    price: price,
    //raw: raw

  }
  )
  } 
})

setWishList((aLists2.slice(0, -1)))

}).catch((error) =>
{
  console.log(error)
setLoading(false);
}).finally(() => {
  setLoading(false)
})
}, [])


function getColor(ind) {
  return colorList[ind % colorList.length]
}

  
  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
          </View>

    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
    <TouchableOpacity onPress={() => {
      console.log("back")
      
      navTo("Dashboard", user, config, refresh, navigation)}} style={styles.backButton} mode="outlined">
      <Ionicons style={{}} name="return-down-back" size={24} color={theme.colors.itemTextColor}></Ionicons>
        </TouchableOpacity>
          <Title color={theme.colors.itemTextColor}>Shopping List</Title>
          {loading ? <StatusBar></StatusBar> : null}
          <ScrollView style={{width: "90%"}} contentContainerStyle={{alignContent: "center", alignItems: "center"}}>
            {wishlist!=null ? wishlist.map((item, ind) => {
              if(item!=null)
              {
              return(
              <TouchableOpacity onPress={() => {
                Linking.openURL(item.link)
              }} style={{backgroundColor: getColor(ind), width: "90%", height: 120, display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                <View style={{backgroundColor: "#ffffff", width: 100, marginLeft: 10, borderWidth: 3, height: 100, justifyContent: "center", alignItems: "center", borderRadius: 50}}>
                  
                  <Image source={{uri: item.image}} style={{resizeMode: "contain", width: "70%", height: 75, marginRight: 0}}></Image>
                </View>
                <View style={{justifyContent: "flex-start", alignItems: "flex-start", alignContent: "flex-start", display :"flex", flexDirection: "column", height: "100%", marginTop: 20}}>
                  <Text numberOfLines={2} style={{color: theme.colors.itemTextColor, width: 175, flexWrap: "wrap", fontFamily: "Carter-One", fontSize: 19, alignSelf: "flex-start"}}>{item.name}</Text>
                  <Text style={{color: theme.colors.itemTextColor, fontSize: 20, marginLeft: 20, fontFamily: "Glacial-Indifference"}}>{item.price}</Text>
                </View>
              </TouchableOpacity>
              )
            }
            }) : null}
            </ScrollView>
      </View>
      



    
    </View>
    </View>
   ) };
