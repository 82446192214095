import React, { memo, useEffect, useState } from 'react';
import { styles } from '../styles/EntryStyle';
import { Dimensions, ScrollView, Image, Text, TouchableOpacity, View } from 'react-native';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import StatusOverlay from '../components/StatusOverlay'
import { navTo, pickTextColorBasedOnBgColorSimple } from '../core/utils';
import { activities } from '../core/activities';
import Button from '../components/Button';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import TextInput from '../components/TextInput';
import MultiSelect from 'react-native-multiple-select'
import {Picker} from '@react-native-picker/picker';


export default function NewCalCat({ navigation,route })  {
  var user = route.params.user;
var config = route.params.config;
var refresh = route.params.refresh;
var pageID = route.params.id;  
var index = config!=null ? config.tools.filter((p) => {
  var toolSet = activities(config)
  return toolSet.find((t) => t.id==p).isAllowed!="false"
}).indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

var [colorList, setColorList] = React.useState(JSON.parse(JSON.stringify(config.theme.length>0 ? config.theme : theme.colors.activities)).sort(() => Math.random() - 0.5))
function getColor(ind) {
  return colorList[ind % colorList.length]
}


const [formattedEntryCodes, setFormattedCalendarCategories] = React.useState(null)
const [loading, setLoading] = React.useState(false)
const [newEntryCode, isMakingNewCategory] = React.useState(false)

const [categories,setCategories] = React.useState(null)


const [currentCategoryName, setCurrentCategoryName] = React.useState(null)
const [currentValues, setCurrentValues] = React.useState(null)

  useEffect(() => {
    if(categories==null)
    {
      setFormattedCalendarCategories(
        <Text style={{color: theme.colors.itemTextColor, fontSize: 26, marginLeft: 5, fontFamily: "Carter-One"}}>Tap below to create a calendar category</Text>
      )
    }
    else
    {
      setFormattedCalendarCategories(
        categories.map((code,ind) => {
          return(
            <View style={{backgroundColor: getColor(ind), padding: 10, marginVertical: 10, borderRadius: 20, width: "100%", justifyContent: "space-between", display: "flex", alignItems: "center", flexDirection: "row"}}>
            <View style={{backgroundColor: "transparent", padding: 0, borderRadius: 20, flex: 0.8, justifyContent: "space-between", display: "flex", alignItems: "center", flexDirection: "column"}}>
              <Text style={{color: pickTextColorBasedOnBgColorSimple(getColor(ind), "#eee", "#000"), fontSize: 26, marginLeft: 0, alignSelf: "flex-start", fontFamily: "Carter-One"}}>{code.CategoryName}</Text>
            </View>
            <Button onP={() => {deleteCategory(code.CID)}} style={{backgroundColor: theme.colors.redButton, width:"auto"}}>
            <MaterialCommunityIcons name="delete" size={24} color={theme.colors.itemTextColor} />
            </Button>
            </View>
          ) 
        }))
    }
  }, [categories])

  function deleteCategory(catID)
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "DeleteCalendarCategory",
        "camp": user.Home,
        "user": user.UID,
        "deleteID": catID
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
    setCategories(responseJson.records)
   }
   else if(responseJson.code==4)
   {
    setCategories(null)
   }
  }).finally(() => {
    setLoading(false)
   });

  }


  function createCode()
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "CreateCalendarCategory",
        "camp": user.Home,
        "user": user.UID,
        "title": currentCategoryName,
        "defaultOptions": currentValues.split("\n").join("&")
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
      
    setCategories(responseJson.records)
   }
  }).finally(() => {
    setLoading(false)
  setCurrentCategoryName(null)
  setCurrentValues(null)
  isMakingNewCategory(false)
   });

  }

  function cancelEditor()
  {
    
  setCurrentCategoryName(null)
  setCurrentValues(null)
  isMakingNewCategory(false)
  }


  function getUsers() {

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetCategories",
        "camp": user.Home,
        "user": user.UID
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
      setCategories(responseJson.records)
   }}).finally(() => {
    setLoading(false)
   });

  }
  

  useEffect(() => {
  setLoading(true)
   getUsers()

  }, [])




  return (
    
    
    <View style={styles.container}>
      {loading && <StatusOverlay></StatusOverlay>}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer active={pageID} customTheme={config.theme} items={config.tools} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
      </View>
      <Button onP={() => {isMakingNewCategory(!newEntryCode)}} style={styles.AddButton} mode="outlined">
      <MaterialCommunityIcons name="plus" style={{width: "100%"}} size={24} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
        </Button>
      <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
   
      {newEntryCode && 
        <View style={{position: "absolute", zIndex: 50, top: 0, bottom: 0, left: 0, right: 0, backgroundColor: theme.colors.listItemBackground}}>
               <Text style={{color: theme.colors.itemTextColor, fontSize: 26, alignSelf: "center", marginTop: 20, fontFamily: "Carter-One"}}>New Category</Text>
               <TextInput
  textColor={theme.colors.itemTextColor}
  label={<Text style={{color: theme.colors.itemTextColor}}>Name</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="next"
  value={currentCategoryName}
  onChangeText={text => setCurrentCategoryName(text)}
/>    
<TextInput
  textColor={theme.colors.itemTextColor}
  label={<Text style={{color: theme.colors.itemTextColor}}>Default Options (Separated by New Lines)</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  style={{height: 300, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="done"
  multiline={true}
  value={currentValues}
  onChangeText={text => setCurrentValues(text)}
/>    

<Button onP={() => {
   createCode()
}} style={{backgroundColor: theme.colors.greenButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Create</Text></Button>
<Button onP={() => {
  cancelEditor()
}} style={{backgroundColor: theme.colors.redButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Cancel</Text></Button>

        </View>}
   <ScrollView style={{width: "90%"}}>
   {formattedEntryCodes}
   <View style={{paddingBottom: 50}}></View>
   </ScrollView>
    </View>
    </View>
    </View>
   ) };
