import React, { memo, useEffect } from 'react';
import { styles } from '../styles/InventoryAdminStyle';
import { Dimensions, Image, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import Heading from '../components/Heading';
import InventoryCategory from '../components/InventoryCategory';
import TextInput from '../components/TextInput';
import { Picker } from '@react-native-picker/picker';
import Button from '../components/Button';
import SizeEntry from '../components/SizeEntry';
import StatusBar from '../components/StatusOverlay';
import Barcode from '../components/Barcode';
import * as ImagePicker from 'expo-image-picker'
import {manipulateAsync} from 'expo-image-manipulator';
import BackButton from '../components/BackButton'


var barcodeTypes = [
  "CODE128",
  "CODE39",
  "EAN",
  "ITF",
  "MSI",
  "UPC"
]

var sizes = [
  { 
    id: 1,
    name: "Baby",
    abbreviatedValue: "B",
    values: [
      {
        id: 1,
        value: "0-3 Months",
        abbreviatedValue: "3M"
      },
      {
        id: 2,
        value: "3-6 Months",
        abbreviatedValue: "6M"
      },
      {
        id: 3,
        value: "6-9 Months",
        abbreviatedValue: "9M"
      },
      {
        id: 4,
        value: "12 Months",
        abbreviatedValue: "12M"
      },
      {
        id: 5,
        value: "18 Months",
        abbreviatedValue: "18M"
      },
      {
        id: 6,
        value: "24 Months",
        abbreviatedValue: "24M"
      },
      
    ]
  },
  { id: 2,
    name: "Youth",
    abbreviatedValue: "Y",
    values: [
      
      {
        id: 1,
        value: "Extra Small",
        abbreviatedValue: "XS"
      },
      {
        id: 2,
        value: "Small",
        abbreviatedValue: "S"

      },
      {
        id: 3,
        value: "Medium",
        abbreviatedValue: "M"
      },
      {
        id: 4,
        value: "Large",
        abbreviatedValue: "L"
      },
      {
        id: 5,
        value: "Extra Large",
        abbreviatedValue: "XL"
      }
    ]
  },
  {
    id: 3,
    name: "Adult",
    abbreviatedValue: "A",
    values: [
      {
        id: 1,
        value: "Small",
        abbreviatedValue: "S"
      },
      {
        id: 2,
        value: "Medium",
        abbreviatedValue: "M"

      },
      {
        id: 3,
        value: "Large",
        abbreviatedValue: "L"
      },
      {
        id: 4,
        value: "Extra Large",
        abbreviatedValue: "XL"
      },
      {
        id: 5,
        value: "Double Extra Large",
        abbreviatedValue: "XXL"
      },
      {
        id: 6,
        value: "Triple Extra Large",
        abbreviatedValue: "XXXL"
      }
    ]
  },
  {
    id: 4,
    name: "Other",
    abbreviatedValue: "O",
    values: [
      {
        id: 1,
        value: "Small",
        abbreviatedValue: "S"
      },
      {
        id: 2,
        value: "Medium",
        abbreviatedValue: "M"

      },
      {
        id: 3,
        value: "Large",
        abbreviatedValue: "L"
      }
    ]
  },
  {
    id: 5,
    name: "Specified Value",
    abbreviatedValue: "SV"
  }
]

var sortOptions = [
  {id: 1, name: "Type"}, {id: 2, name: "Size"}
]


export default function InventoryAdmin({ route, navigation })  {
  var user = route.params.user;
  var config = route.params.config;
  var pageID = route.params.id!=null ? route.params.id : 0;  




  const [image, setImage] = React.useState(null)
  const [showImageDialog, setShowImageDialog] = React.useState(false)

  const [barcodeMenuVisibility, setBarcodeMenuVisibility] = React.useState(false)

  function pickImage() 
  { setShowImageDialog(!showImageDialog) }

  const pickImageFromLibrary = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [2, 3],
      quality: 1,
    });
  
  
    if (result.hasOwnProperty('uri')) {
      const manipulateResult = await manipulateAsync(
           result.uri,
          [],
          { 
            base64: true,
            compress: 0.4 } // from 0 to 1 "1 for best quality"
      );
      setImage(manipulateResult);
    
  };
  }

  const pickImageFromCamera = async () => {

    const permissionResult = await ImagePicker.requestCameraPermissionsAsync()
    if(permissionResult.granted === false)
    {
      alert("This app does not have access to your camera.")
      return;
    }

    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [3, 2],
      quality: 1,
    });
  
  
    if (result.hasOwnProperty('uri')) {
      const manipulateResult = await manipulateAsync(
           result.uri,
          [],
          { 
            base64: true,
            compress: 0.4 } // from 0 to 1 "1 for best quality"
      );
      setImage(manipulateResult);
    
  };
  }
  
  
  var newNumber =  new Date().getMinutes()

  const emptyList = <Text style={{color: theme.colors.itemTextColor, fontSize: 15}}>No Inventory Items Loaded.</Text>

  const [sort, setSort] = React.useState(1)
  const [types, setTypes] = React.useState([])
  const [inventory, setInventory] = React.useState(null)
  const [inventoryList, setInventoryList] = React.useState(emptyList)
  const [showEditor, setShowEditor] = React.useState(false)
  const [catInventory, setCatInventory] = React.useState(null)

  const [showTypeBox, setShowTypeBox] = React.useState(false)
  const [rawInventory, setRawInventory] = React.useState(null)

  
  const [workingItem, setWorkingItem] = React.useState(null)
  const [isShowingEmptySize, toggleEmptySize] = React.useState(null)
  const [barcodeEntry, setBarcodeEntry] = React.useState(false)

  const [updatedItemCode, setUpdatedItemCode] = React.useState(null)
  const [updatedItemCodeType, setUpdatedItemCodeType] = React.useState(null)
  const [barcodeMap, setbarcodeMap] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [newBarcodeItem, setNewBarcodeItem] = React.useState(null)
  const [barcode, setBarcode] = React.useState(null)


  function updateQuantity(type, IGD, sizeType, size, quantity)
  {

    var newInv = {...catInventory}
    console.log(type, IGD, sizeType, size, quantity)
    newInv[type][IGD].quantity[sizeType][size] = quantity
    setCatInventory(newInv)
    
  }

  function updateBarcode(workingItem, IGD, sizeType, size)
  {
    console.log(IGD, sizeType, size)
    console.log(rawInventory.find((rItem) => rItem.itemGroupID==IGD && rItem.size==size && rItem.sizeType==sizeType).IID)
    if(barcodeMap[rawInventory.find((rItem) => rItem.itemGroupID==IGD && rItem.size==size && rItem.sizeType==sizeType).IID]!=null &&
    barcodeMap[rawInventory.find((rItem) => rItem.itemGroupID==IGD && rItem.size==size && rItem.sizeType==sizeType).IID]!=undefined &&
    barcodeMap[rawInventory.find((rItem) => rItem.itemGroupID==IGD && rItem.size==size && rItem.sizeType==sizeType).IID]!=""
    )
    {
      console.log("Barcode: ",barcodeMap[rawInventory.find((rItem) => rItem.itemGroupID==IGD && rItem.size==size && rItem.sizeType==sizeType).IID])
      setBarcode(<Barcode value={barcodeMap[rawInventory.find((rItem) => rItem.itemGroupID==IGD && rItem.size==size && rItem.sizeType==sizeType).IID].split(":")[1]} propOptions={{format: barcodeMap[rawInventory.find((rItem) => rItem.itemGroupID==IGD && rItem.size==size && rItem.sizeType==sizeType).IID].split(":")[0]}} />)
    }
    setNewBarcodeItem(rawInventory.find((rItem) => rItem.itemGroupID==IGD && rItem.size==size && rItem.sizeType==sizeType).IID)
    setBarcodeEntry(true)
   
    
  }

  
  function updateQuantity2(type, IGD, sizeType, size, quantity)
  {

    var newInv = {...catInventory}
    if(newInv[type]==null || newInv[type]==undefined)
    {
      newInv[type] = {}
    }
    if(IGD==-1 && newInv[type][IGD]==null)
    {
      console.log(type, IGD, sizeType, size, quantity)
      
      newInv[type][IGD] = workingItem
      newInv[type][IGD].quantity = {}
    }
    if(newInv[type][IGD].quantity[sizeType]==null) {
      newInv[type][IGD].quantity[sizeType] = {}
    }
    newInv[type][IGD].quantity[sizeType][size] = quantity
    console.log(newInv[type][IGD])
    setCatInventory(newInv)
    toggleEmptySize(false)
  }

  function updateCode()
  {
    console.log()
    if(updatedItemCode!="")
    {
    var map = barcodeMap
    map[newBarcodeItem] = updatedItemCodeType + ":" + updatedItemCode
    setbarcodeMap(map)
    }
    setBarcodeEntry(false)
  }

  useEffect(() => {
    console.log("BarcodeMap: ", barcodeMap)
  }, [barcodeMap])


  useEffect(() => {
    if(workingItem!=null && workingItem!=undefined && workingItem.type=="Other")
    {
      setShowTypeBox(true)
    }
    console.log(workingItem)
  }, [workingItem])



useEffect(() => {
  console.log("LOADING!!__________________________________________")
  fetchData()
}, [])

function fetchData()
{
  setLoading(true)
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "GetInventoryList",
      "user": user.UID,
      "camp": user.Home,
    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  var barcodeMap = {}
  if(responseJson.records!=null && responseJson.records.length>0)
  {
  responseJson.records.map((item) => {
    barcodeMap[item.IID] = item.itemCode
  })
}
  setbarcodeMap(barcodeMap)
  setInventory(responseJson.records)
  setRawInventory(responseJson.records)
  setLoading(false)
 })
}

function deleteItem()
{
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "DeleteInventoryGroup",
      "user": user.UID,
      "camp": user.Home,
      "itemGroup": workingItem.itemGroupID
    })
  })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  console.log(responseJson)
  setShowEditor(false)
  setShowTypeBox(false)
  setImage(null)

  fetchData()
 })
}

function submitChanges(GID)
{
  setLoading(true)
  if(image!=null)
  {
    console.log(GID)
  fetch('https://www.outpostorganizer.com/SITE/v2/uploadAPI.php', {
        method: 'POST',
        body: JSON.stringify({
        "user": user.UID,
        "camp": user.Home,
        "spot": GID,
        "image": image.base64,
        "type": "inventory",
        
        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    console.log("Complete:", responseJson)
    var imageURL = "https://www.outpostorganizer.com/SITE" + responseJson.inventoryPath
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateInventoryGroup",
        "camp": user.Home,
        "itemGroupID": GID,
        "user": user.UID,
        "itemName": workingItem.itemName,
        "imageURL": imageURL,
        "itemCode": workingItem.itemCode,
        "itemPrice": workingItem.price,
        "itemDesc": workingItem.description,
        "itemType": workingItem.type
        
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log("Complete")
      setShowEditor(false)
      setShowTypeBox(false)
      setImage(null)
      console.log(responseJson)
      submitQuantityChanges(responseJson.itemGroupID, responseJson.imgURL)
       })
  })
  }
  else
  {
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateInventoryGroup",
        "camp": user.Home,
        "itemGroupID": GID,
        "user": user.UID,
        "itemName": workingItem.itemName,
        "imageURL": workingItem.imageURL,
        "itemCode": workingItem.itemCode,
        "itemPrice": workingItem.price,
        "itemDesc": workingItem.description,
        "itemType": workingItem.type
        
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log("Complete")
      setShowEditor(false)
      setShowTypeBox(false)
      setImage(null)

      submitQuantityChanges(responseJson.itemGroupID, responseJson.imgURL)
   })
} 
}

function submitQuantityChanges(newItemGroupID, imgURL)
{
  setLoading(true)
 (Object.keys(workingItem.quantity).map((sizeType) => {
    (Object.keys(workingItem.quantity[sizeType]).map((size) => {
      
      console.log("HERE", workingItem.itemGroupID, sizeType, size, workingItem.quantity[sizeType][size])
      var currentItem = (rawInventory!=null && rawInventory!=undefined) ? (rawInventory.find((i) => i.itemGroupID==workingItem.itemGroupID && i.sizeType==sizeType && i.size==size)) : null
      var cIID = currentItem==null || currentItem==undefined ? -1 : currentItem.IID
      console.log("Barcodemap", barcodeMap)
      console.log("index", barcodeMap[cIID])
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateInventoryItem",
        "camp": user.Home,
        "itemGroupID": newItemGroupID,
        "IID": cIID,
        "user": user.UID,
        "itemName": workingItem.itemName,
        "imageURL": imgURL,
        "itemCode": (barcodeMap[cIID]==null || barcodeMap[cIID]==undefined) ? "" : barcodeMap[cIID],
        "itemPrice": workingItem.price,
        "itemDesc": workingItem.description,
        "itemType": workingItem.type,
        "sizeType": sizeType,
        "size": size,
        "quantity": workingItem.quantity[sizeType][size]
        
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson2) => {
    setbarcodeMap({})
    fetchData()
      console.log(responseJson2)
    })
  }))
}))
}


useEffect(() => {
if(inventory!=null){
  const categorizedInventory = {}
  inventory.map((item) => {
      if(!(item.type in categorizedInventory)) {
        categorizedInventory[item.type] = {}
      }
      if(!(item.itemGroupID in categorizedInventory[item.type])) {
        var currentItemQuantity = item.quantity
        item.quantity = {}
        item.quantity[item.sizeType] = {}
        item.quantity[item.sizeType][item.size] = currentItemQuantity
        categorizedInventory[item.type][item.itemGroupID] = item
        categorizedInventory[item.type][item.itemGroupID].totalQuantity = +currentItemQuantity
      }
      else {
        if(categorizedInventory[item.type][item.itemGroupID].quantity[item.sizeType]==null){
          categorizedInventory[item.type][item.itemGroupID].quantity[item.sizeType] = {}
        }
        categorizedInventory[item.type][item.itemGroupID].quantity[item.sizeType][item.size] = item.quantity
        categorizedInventory[item.type][item.itemGroupID].totalQuantity += +item.quantity
      }
  })
  setCatInventory(categorizedInventory)
}
else
{
  setCatInventory(null)
}
}, [inventory])

useEffect(() => {
  console.log("fired")
  if(catInventory!=null)
  {
    var keys = Object.keys(catInventory)
    setTypes(keys)
    if(workingItem!=null && showEditor)
    {
      console.log("setting")
      if(workingItem.IID!=-1)
      {
        setWorkingItem(catInventory[inventory.find((rItem => rItem.itemGroupID==workingItem.itemGroupID)).type][workingItem.itemGroupID]) 
      }
      else
      {
        setWorkingItem(catInventory[workingItem.type][workingItem.itemGroupID])
      }
    } 
    setInventoryList(
      keys.map((title) => {
        return(
        <InventoryCategory title={title}>{Object.values(catInventory[title]).map((item) => {
          return(
            <TouchableOpacity onPress={() => {
              update(item.itemGroupID)
            }} style={{width: "90%", display: "flex", flexDirection: "row", alignItems: "center"}}>
              <View style={{justifyContent: "flex-start", paddingVertical: 10, paddingLeft: 10, width: "100%", alignContent: "center", display :"flex", flexDirection: "row", height: "100%"}}>
              <View style={{flex: 0.35}}>
              <Image source={{uri: item.imageURL + "?refresh=" + newNumber}} style={{width: "100%", height: 125}}></Image>              
              </View>
              <View style={{flex: 0.65, justifyContent: "flex-start", paddingLeft:10, alignContent: "center", display :"flex", flexDirection: "column", height: "100%"}}>
                <Text numberOfLines={2} style={{color: theme.colors.itemTextColor,  fontFamily: "Carter-One", fontSize: 18}}>{item.itemName}</Text>
                <Text style={{color: theme.colors.itemTextColor, fontSize: 18, fontFamily: "Glacial-Indifference"}}>Quantity: {item.totalQuantity}</Text>
              </View>
              </View>
            </TouchableOpacity>          )
        })}</InventoryCategory>)
      })
    )
  }
  else
  {
    setInventoryList(emptyList)
  }
}, [catInventory])


function update(item)
{
  setWorkingItem(catInventory[inventory.find((rItem => rItem.itemGroupID==item)).type][item]) 
}

useEffect(() => {
  if(workingItem!=null)
  {
    setShowEditor(true)
    setUpdatedItemCodeType(workingItem.itemCode != null ? workingItem.itemCode.split(":")[0] : null)
    setUpdatedItemCode(workingItem.itemCode != null ? workingItem.itemCode.split(":")[1] : null)
  }
}, [workingItem])

useEffect(() => {
  if(!showEditor)
  {
    setWorkingItem(null)
  }
}, [showEditor])




  async function navTo(location) {
  navigation.navigate(location, {user: user, config: config})
    
  }


  
  return (
    
    
    <View style={styles.container}>
      {loading ? <StatusBar config={config}></StatusBar> : null}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer customTheme={config.theme} items={config.tools} shrink={true} navTo={navTo} />
      </View>

    <View style={[styles.content, {backgroundColor: theme.colors.list + "12", borderColor: theme.colors.list}]}>
    <TouchableOpacity onPress={() => {
if(showEditor)
{
  setShowEditor(false)
  setWorkingItem(null)
}
else
{
  navTo("Dashboard", user, config, null, navigation)
}
  

}} style={styles.backButton} mode="outlined">
<Ionicons style={{}} name="return-down-back" size={24} color={theme.colors.itemTextColor}></Ionicons>
</TouchableOpacity>
{barcodeEntry &&
          <View style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0, zIndex: 1000, padding: 20, paddingTop: 0, backgroundColor: theme.colors.listItemBackground + "ee"}}>
                    <View style={{position: "absolute", left: 50, right: 50, top: 200, bottom: 400, zIndex: 1000, padding: 20, paddingTop: 0, backgroundColor: theme.colors.listItemBackground}}>
          <TouchableOpacity style={{padding: 10, borderRadius: 7, borderWidth: 2, width: 50, marginBottom: 20}} onPress={() => {setBarcodeEntry(false)}}>
            <Ionicons name="return-down-back" size={26} color={theme.colors.itemTextColor}></Ionicons>
          </TouchableOpacity> 
          {barcode!=null &&
          <View style={{justifyContent: "center", width: "100%", alignContent: "center", alignItems: "center"}}>
         {barcode}</View>}
          <View style={{borderWidth: 1, borderRadius: 0, borderColor: "#000"}}><Picker
          itemStyle={{color: theme.colors.itemTextColor}}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,  borderWidth: 2, borderColor: "#000"}}
          selectedValue={updatedItemCodeType}
          onValueChange={(itemValue, itemIndex) =>
            setUpdatedItemCodeType(itemValue)
          }>
            
          {workingItem.itemCode=="" ? 
          <Picker.Item label="Set Barcode Type" value={"Click to set Barcode Type"} /> : null }
          {barcodeTypes.map((type) => {
            return(
            <Picker.Item label={type} value={type} />
            )
          })}
        </Picker></View>

          <TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Code</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="done"
          keyboardType="text"
          value={updatedItemCode}
          onChangeText={text => setUpdatedItemCode(text)}
          />
          <Button onP={() => {updateCode()}}><Text style={{color: theme.colors.itemTextColor}}>Update</Text></Button>
          </View></View>
}

    {showEditor &&
      <ScrollView style={{position: "absolute", top: 0, marginTop: 40, bottom: 0, left: 0, right: 0, backgroundColor: theme.colors.listItemBackground, zIndex: 2}}>
        <TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Item Name</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="next"
          value={workingItem.itemName}
          onChangeText={text => setWorkingItem({...workingItem, itemName: text})}
          />
         {!showTypeBox && <View><Text style ={{paddingLeft: 5, color: theme.colors.itemTextColor}}>Type</Text>
<Picker
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,  borderWidth: 2, borderColor: "#000"}}
          selectedValue={workingItem.type}
          itemStyle={{color: theme.colors.itemTextColor}}
          onValueChange={(itemValue, itemIndex) =>
            {
            setShowTypeBox(false)
            setWorkingItem({...workingItem, type: itemValue})
            }
          }>
          {workingItem.type==null ? 
          <Picker.Item label="Click to Set Item Type" value={"Click to Set Item Type"} /> : null }
          {types.map((type) => {
            return(
            <Picker.Item label={type} value={type} />
            )
          })}
          {!types.includes("Other") && <Picker.Item label={"Other"} value={"Other"} /> }
        </Picker></View>}
        {showTypeBox && <TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Type</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="next"
          value={workingItem.type}
          onChangeText={text => setWorkingItem({...workingItem, type: text})}
          /> }

        <TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Price</Text>}
          theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
          style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="done"
          keyboardType="numeric"
          value={workingItem.price}
          onChangeText={text => setWorkingItem({...workingItem, price: text})}
          />
 <TextInput
          label={<Text style={{color: theme.colors.itemTextColor}}>Description</Text>}
multiline={true}
theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
style={{width: "100%", alignSelf: "center", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 1}}
          returnKeyType="done"
          blurOnSubmit={true}
          value={workingItem.description}
          onChangeText={text => { setWorkingItem({...workingItem, description: text})
            }}
></TextInput>      
      <View style={{display: "flex", flexDirection: "row"}}>
        <View style={{display: "flex", flexDirection: "column", flex: 1, padding: 15}}>
        <TouchableOpacity onPress={() => {
            pickImage()
          }} style={{alignItems: "center", padding: 10, borderRadius: 10, backgroundColor: theme.colors.listItemBackground, borderColor: "#000", borderWidth: 1}}><Text style={{color: theme.colors.itemTextColor, fontSize: 16}}>Picture Settings</Text></TouchableOpacity>
        {showImageDialog && <View>
        <TouchableOpacity onPress={() => {
          pickImageFromLibrary()
        }} style={{alignItems: "center", padding: 10, borderRadius: 10, backgroundColor: theme.colors.listItemBackground, borderColor: "#000", borderWidth: 1}}><Text style={{textAlign: "center", color: theme.colors.itemTextColor, fontSize: 16}}>Update From Library</Text></TouchableOpacity>
        <TouchableOpacity onPress={() => {
            pickImageFromCamera()
          }} style={{alignItems: "center", padding: 10, borderRadius: 10, backgroundColor: theme.colors.listItemBackground, borderColor: "#000", borderWidth: 1}}><Text style={{textAlign: "center", color: theme.colors.itemTextColor, fontSize: 16}}>Update From Camera</Text></TouchableOpacity>
          </View>}
          
          <Image source={{uri: image==null ? workingItem.imageURL + "?id=" + newNumber : image.base64==null ? image : "data:image/png;base64," + image.base64}} style={{flex: 1, height: 200}} />
        </View>
        <View style={{display: "flex", flexDirection: "column", flex: 0, padding: 15}}>
  </View></View>
      <Text style={{fontSize: 18, paddingLeft: 5, color: theme.colors.itemTextColor}}>Sizes</Text>
      {Object.keys(workingItem.quantity).map((itemType) => {
        return(
          Object.keys(workingItem.quantity[itemType]).map((size) => {
            return(
              <SizeEntry updateBarcode={updateBarcode} updateQuantity={updateQuantity} sizes={sizes} workingItem={workingItem} sizeType={sizes.find((s) => s.id == itemType)} size={size}></SizeEntry>
            )
          })
        )
      })}
      {isShowingEmptySize ? <SizeEntry updateQuantity={updateQuantity2} sizes={sizes} workingItem={workingItem} sizeType={null} size={null}></SizeEntry>
      : null }

<Button onP={() => {
        toggleEmptySize(true)
      }}><Text style={{color: theme.colors.itemTextColor}}>New Size</Text></Button>
      <View style={{width: "100%", display: "flex", flexDirection: "row"}}>
      <TouchableOpacity style={{marginLeft: 20, alignItems: "center", width: "30%", padding: 15, borderRadius: 15, backgroundColor: theme.colors.redButton}} onPress={() => {
        setShowEditor(false)
        setShowTypeBox(false)
        setImage(null)

      }}><Text style={{color: theme.colors.itemTextColor, fontSize: 20}}>Cancel</Text></TouchableOpacity>
            <TouchableOpacity style={{marginLeft: 20, alignItems: "center", width: "30%", padding: 15, borderRadius: 15, backgroundColor: theme.colors.greenButton}} onPress={() => {
        submitChanges(workingItem.itemGroupID)
      }}><Text style={{color: theme.colors.itemTextColor, fontSize: 20}}>Submit</Text></TouchableOpacity></View>
                  <TouchableOpacity style={{marginTop: 50, marginLeft: 20, alignItems: "center", width: "30%", padding: 15, borderRadius: 15, backgroundColor: theme.colors.redButton}} onPress={() => {
        deleteItem(workingItem.itemGroupID)
      }}><Text style={{color: theme.colors.itemTextColor, fontSize: 20}}>Delete</Text></TouchableOpacity>
      
      <View style={{paddingBottom: 300}}></View>
      </ScrollView>}
      <TouchableOpacity style={{alignItems: "center", backgroundColor: theme.colors.listItemBackground, width: 70, borderColor: "#000", borderWidth: 4, borderRadius: 40 }} onPress={() => {console.log("PRESS") 
      setWorkingItem({IID: -1, itemGroupID: -1, itemName: "", itemCode: "", itemDesc: "", itemPrice: "", itemType: "Other", quantity: {}})}} >
        <MaterialCommunityIcons name="plus" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </TouchableOpacity>
    <ScrollView
   contentContainerStyle={{width: '100%'}}>
    {inventoryList}
    <View style={{padding: 200}}></View>
    </ScrollView>
      </View>
    
      </View>



    
    </View>
   ) };
