import React, { memo } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { TextInput as Input } from 'react-native-paper';
import { theme } from '../core/theme';

const TextInput = ({  errorText, ...props }) => 
{
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      marginVertical: 12,
      borderWidth: 0
    },
    input: {
      backgroundColor: theme.colors.listItemBackground,
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
      borderTopRightRadius: 30,
      borderTopLeftRadius: 30,
      color: theme.colors.itemTextColor
  
      
    },
    error: {
      fontSize: 14,
      color: theme.colors.error,
      paddingHorizontal: 4,
      paddingTop: 4,
    },
  });

  
  return(
  <View style={styles.container}>
    <Input 
      style={styles.input}
      selectionColor={theme.colors.itemTextColor}
      underlineColor="transparent"
      placeholderTextColor={theme.colors.itemTextColor}
      
      {...props}
    />
    {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
  </View>
);}


export default memo(TextInput);
