import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/ListStyle';
import { Dimensions, Image, ActivityIndicator, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import ComplexListItem from '../components/ComplexListItem';
import SiteContainer from '../components/SiteContainer';
import Dashboard from './Dashboard';
import ConfettiCannon from 'react-native-confetti-cannon';
import { activities } from '../core/activities';
import { navTo } from '../core/utils';
import MultiSelect from 'react-native-multiple-select';
import { CheckBox } from 'react-native-web';

export default function List({ route, navigation })  {
  var pageID = route.params.id!=null ? route.params.id : 0;  
  var user = route.params.user;
var config = route.params.config;
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];
  var openSite = route.params.openSite;
  var load = false;
  var refresh = route.params.refresh;
  const [advTasks, setAdvTasks] = React.useState(null);

  const [formattedAdvTasks, setFormattedAdvTasks] = React.useState(null);
  const [complete, setComplete] = React.useState(false);
  const [sites,setSites] = React.useState(null)
  const [heading, setHeading] = React.useState(null);

  const [showAdv, setADV] = React.useState(false); //Announcement Box trigger

  const [items, setItems] = React.useState(null)

  const [loading, setLoading] = React.useState(false);

  const [data, setData] = useState(null);

  const [approval, setApproval] = useState(false);

  const [title, setTitle] = React.useState("")
  const [listID, setListID] = React.useState(1)

  const [editable, setEditable] = React.useState(false)
  const [refreshPage, setRefresh] = React.useState(false)
  const [listPercent, setListPercent] = React.useState(0)

  const [confetti, setConfetti] = React.useState(null)
  const [viewAll, setViewAll] = React.useState(null)

  const [isShowingPrintDialog, togglePrintDialog] = React.useState(false)

  const [selectedPrintPriorities, setSelectedPrintPriorities] = React.useState([])

  const [selectedPrintSites, setSelectedPrintSites] = React.useState([])


  function calculatePercent(item)
  {
    var splitTasks = item.tasks.split("& ")
    var splitTemp = item.tempTasks.split("& ")
    if(splitTemp.length==1 && splitTemp[0]=="")
    {
      return (100)
    }
    return(
      (
        (splitTasks.length - splitTemp.length)*100)
        /splitTasks.length)
  }

  if(load)
  {
    load = false;
  }
  
  function joinSite(spot) {
    console.log("Joining")
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "JoinSite",
        "camp": user.Home,
        "spot": spot.SID,
        "user": user.UID,
        
    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    if(responseJson.code==1)
    {
      navigation.navigate("Details", {user, spot, onBack, config, confetti: summonConfetti, id: pageID})
      setLoading(false);    
    }
    else if(responseJson.code==4)
    {
      setLoading(false);
    }
      
   });

  }

  function summonConfetti() {
    //summon confetti
    setConfetti(
      <ConfettiCannon count={200} colors={[theme.colors.assignments, theme.colors.map, themeColor, theme.colors.announcements, theme.colors.scoreboard]} origin={{x: -10, y: 0}} />
      
    )
    setTimeout(() => {
      setConfetti(null);
      
      
    }, 9000);
  }

  



  function claimSite(spot) {
    console.log("Claiming " +  spot)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "ClaimSpot",
        "camp": user.Home,
        "spot": spot,
        "user": user.screenName,
        
    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    if(responseJson.code==1)
    {
      setLoading(false); 
      onBack()   
    }
    else if(responseJson.code==4)
    {
      setLoading(false);
    }
      
   });

  }

  function setStatus(item, status, comments, tasks)
  {
    setLoading(true)
    console.log(tasks)

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateSpot",
        "spot": item,
        "denied": status=="deny" ? 1 : 0,
        "status": status=="approve" ? 2 : 0,
        "camp": user.Home,
        "logs": status=="deny" ? comments : "",
        "tasks": tasks!=null && tasks!=undefined && tasks!="" ? tasks.slice(0,-2) : tasks,
        "user": user.UID
        
    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
    }
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetListItems",
        "list": listID,
        "user": user.UID,
        "camp": user.Home,
        "approval": approval

    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    if(responseJson.code==1)
    {
      setComplete(false);
      setData(responseJson.records);
      setTitle(responseJson.title);
      setSites(responseJson.sites)
      setEditable(responseJson.editable==0 ? false : true)
      setListPercent(responseJson.listPercent)
    }
    else if(responseJson.code==4)
    {
      setTitle(responseJson.title)
      setComplete(true);
      setLoading(false);
      setEditable(responseJson.editable==0 ? false : true)
      setListPercent(responseJson.listPercent)

    }
 //     
   });
      
   });
   
  }

  function onBack(spot) {
    setLoading(true);
    if(spot!=null)
    {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "LeaveSpot",
        "spot": spot,
        "camp": user.Home,
        "user": user.UID
        
    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    if(responseJson.code==1)
    {
    }
      
   });
  }
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetListItems",
        "list": listID,
        "user": user.UID,
        "camp": user.Home,
        "approval": approval

    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    if(responseJson.code==1)
    {
      setComplete(false);
      setData(responseJson.records);
      setTitle(responseJson.title);
      setSites(responseJson.sites)
      setEditable(responseJson.editable==0 ? false : true)
      setListPercent(responseJson.listPercent)
    }
    else if(responseJson.code==4)
    {
      setTitle(responseJson.title)
      setComplete(true);
      setEditable(responseJson.editable==0 ? false : true)
      setListPercent(responseJson.listPercent)
      setLoading(false);
    }
 //     
   });

  }
  const [userList, setUsers] = React.useState(null)

  useEffect(() => {
    console.log("REloading")
    setLoading(true);
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetListItems",
        "list": listID,
        "user": user.UID,
        "camp": user.Home,
        "approval": approval

    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
      setComplete(false);
      setData(responseJson.records);
      setTitle(responseJson.title);
      setSites(responseJson.sites)
      setEditable(responseJson.editable==0 ? false : true)
      console.log("ListPercent: " + responseJson.listPercent)
      setListPercent(responseJson.listPercent)
    }
    else if(responseJson.code==4)
    {
      setTitle(responseJson.title)
      setComplete(true);
      setLoading(false);
      setEditable(responseJson.editable==0 ? false : true)
      setListPercent(responseJson.listPercent)

    } })


    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetUsers",
        "user": user.UID,
        "camp": user.Home,
        "approval": approval

    })
   })
   .then((response) => response.json())
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
      setComplete(false);
      setUsers(responseJson.records);
    }
    else if(responseJson.code==4)
    {
      setLoading(false)
    }
 //     
   });

  }, [load, listID])

  useEffect(() => {
    if(sites!=null && data!=null && userList!=null)
    {
    /*   setItems()
*/
      setItems(sites.map((site) => {
        var itemsRemaining = data.filter((item) => item.SiteID==site.SiteID).length;
        return (
          <SiteContainer shouldShow={viewAll} single={sites.length==1} open={sites.length==1 || openSite==site.SiteID} itemsRemaining={itemsRemaining} key={site.SiteID} title={site.SiteName}>
            {data.filter((item) => item.SiteID==site.SiteID && (item.status==(approval ? 1 : 0) || (viewAll && !approval))).sort((a,b) => {
              return b.priority - a.priority
            }).map(function(item, index) {

  return (<ComplexListItem userList={userList} status={item.status} viewAll={viewAll} home={user.Home} priority={item.priority} hasImage={item.hasImage==1} config={config} editSite={() => {
    navigation.navigate("NewSite", {user: user, config: config, refresh: () => {setRefresh(!refreshPage)}, spotID: item.SID, list: {id: listID, title: title}, status: item.status, title: item.site_Name, priority: item.priority, tasks: item.tasks, siteID: item.SiteID, assigned: item.siteOwner, id: pageID, hasImage: item.hasImage==1})
  }}isManager={config.permissions.CanCreateSpots==1} approve={() => {setStatus(item.SID, "approve")}} SID={item.SID} logs={item.logs} isDenied={item.denied==1} deny={setStatus} totalTasks={item.tasks} simple={approval} siteOwner={item.siteOwner} site={item.SiteID} key={item.SID} 
  claim={() => {
    setLoading(true)
    claimSite(item.SID)
  }}
  adv={() => {
  setLoading(true)
  joinSite(item)
}}
  progress={calculatePercent(item)} title={item.site_Name} people={item.usersWorking.split("&").length-1} tasks={item.tempTasks.split("& ")}/>);
})}
          </SiteContainer>
        )
      }))
    }
  }, [sites, data,viewAll, userList])

  useEffect(() => {
    if(refreshPage!=null)
    {
      console.log(refreshPage)
    }
    console.log(listID)
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetListItems",
        "list": listID,
        "user": user.UID,
        "camp": user.Home,
        "approval": approval
        
    })
   })
   .then((response) => {
    return(response.json())})
   .then((responseJson) => {
    if(responseJson.code==1)
    {
      setComplete(false);
      setData(responseJson.records);
      setTitle(responseJson.title);
      setSites(responseJson.sites)
      setEditable(responseJson.editable==0 ? false : true)
    }
    else if(responseJson.code==4)
    {
      setTitle(responseJson.title)
      setComplete(true);
      setLoading(false);
      setEditable(responseJson.editable==0 ? false : true)
    }
 //     
   });
 
    
  }, [approval, refreshPage])



  useEffect(() => {
    if(items!=null)
    {
      setLoading(false);
    }

  }, [items])

  useEffect(() => {
    setLoading(true)
    setTitle("")
  }, [listID])





  return (
    
    
    <View style={styles.container}>
      
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
      </View>

{isShowingPrintDialog ? <View style={[styles.content, {backgroundColor: config.theme.list == null ? themeColor + "12" : config.theme.list + "12", borderColor: config.theme.list==null ? themeColor: config.theme.list}]}>
<View style={styles.buttonContainer}>
        <Button style={styles.switchButton} onP={() => {setSelectedPrintPriorities([]); setSelectedPrintSites([]); setListID(listID-1 < 1 ? 1 : listID-1)}}><MaterialCommunityIcons name="chevron-left" color={theme.colors.itemTextColor} size={28} /></Button>
        <Text style={styles.title}>Print - {title}</Text>
        <Button style={styles.switchButton} onP={() => {setSelectedPrintPriorities([]); setSelectedPrintSites([]); setListID(listID+1)}}><MaterialCommunityIcons name="chevron-right" color={theme.colors.itemTextColor} size={28} /></Button>
      </View>
                  
              {sites.length>1 ? <View style={{width: "60%"}}>
<Text style={{marginBottom: 10, marginTop: 15}}>Print Groups</Text>
<MultiSelect
 hideTags
 items={sites.map((g) => {return {id: g.SiteID, name: g.SiteName}})}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {
  console.log(s)
  setSelectedPrintSites(s)}
 }
 selectedItems={selectedPrintSites}
 selectText="Select Sites"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 itemTextColor={theme.colors.itemTextColor}
 selectedItemTextColor={"#4b4"}
 selectedItemIconColor={"#4b4"}
 styleTextDropdownSelected={{color: theme.colors.itemTextColor}}
 styleTextDropdown={{color: theme.colors.itemTextColor}}
 searchInputStyle={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
 styleSelectorContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleMainWrapper={{backgroundColor: theme.colors.listItemBackground}}
 styleListContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleInputGroup={{color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenu={{backgroundColor: theme.colors.listItemBackground}}
 styleIndicator={{backgroundColor: theme.colors.listItemBackground}}
 styleItemsContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenuSubsection={{backgroundColor: theme.colors.listItemBackground}}
 style={{width: "100%"}}
 submitButtonColor={selectedPrintSites.length>0 ? "#7b7" : "#f77"}
 submitButtonText="Submit"
/>
<View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row"}}>
<CheckBox
    
    onValueChange={()=>{
      setSelectedPrintSites(selectedPrintSites.length==sites.length ? [] : sites.map((site) => {
        return(
          site.SiteID
          )
        }
        ))
    }}
    value={selectedPrintSites.length==sites.length}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Select All Sites"}</Text></View>

</View> : null }

<View style={{width: "60%"}}>
<Text style={{marginBottom: 10, marginTop: 15}}>Priories to Print</Text>
<MultiSelect
 hideTags
 items={[{id: "0", name: "Low"}, {id: "1", name: "Default"}, {id: "2", name: "High"}]}
 uniqueKey="id"
 onSelectedItemsChange={(s) => {
  setSelectedPrintPriorities(s)}
 }
 selectedItems={selectedPrintPriorities}
 selectText="Select Priority"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)}
 itemTextColor={theme.colors.itemTextColor}
 selectedItemTextColor={"#4b4"}
 selectedItemIconColor={"#4b4"}
 styleTextDropdownSelected={{color: theme.colors.itemTextColor}}
 styleTextDropdown={{color: theme.colors.itemTextColor}}
 searchInputStyle={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
 styleSelectorContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleMainWrapper={{backgroundColor: theme.colors.listItemBackground}}
 styleListContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleInputGroup={{color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenu={{backgroundColor: theme.colors.listItemBackground}}
 styleIndicator={{backgroundColor: theme.colors.listItemBackground}}
 styleItemsContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenuSubsection={{backgroundColor: theme.colors.listItemBackground}}
 style={{width: "100%"}}
 submitButtonColor={selectedPrintPriorities.length>0 ? "#7b7" : "#f77"}
 submitButtonText="Submit"
/><View style={{justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "row"}}>
<CheckBox
    
    onValueChange={()=>{
      setSelectedPrintPriorities(selectedPrintPriorities.length==3 ? [] : ["0", "1", "2"])
    }}
    value={selectedPrintPriorities.length==3}
/><Text style={{marginLeft: 20, color: theme.colors.itemTextColor}}>{"Select All Priorities"}</Text></View>

</View>

<Button  onP={() => {
      navigation.navigate("ListPrint", {refresh: refresh, config: config, pageID: pageID, user: user, params: {printPriorities: selectedPrintPriorities, printSites: selectedPrintSites}, listTitle: title, sites: sites, items: data})

}} style={[{width: "60%", zIndex: 500, borderColor: "#000", borderWidth: 1, backgroundColor: selectedPrintPriorities.length>0 && selectedPrintSites.length>0 ? theme.colors.greenButton : theme.colors.redButton}]} disabled={selectedPrintPriorities.length==0 || (sites.length>1 && selectedPrintSites.length==0)}><Entypo name='print' color={theme.colors.itemTextColor} size={25}></Entypo><Text style={{color: theme.colors.itemTextColor}}> Print</Text></Button>
    <Button onP={() => {togglePrintDialog(!isShowingPrintDialog)}} style={[{zIndex: 500, justifyContent: "center", alignItems: "center", position: "absolute", bottom: "10%", width: 60, height: 60, backgroundColor: theme.colors.listItemBackground, borderRadius: 50, left: "5%", borderColor: "#000", borderWidth: 1}]}><Entypo name='print' color={theme.colors.itemTextColor} size={25}></Entypo></Button>


    
    </View> :
    <View style={[styles.content, {backgroundColor: config.theme.list == null ? themeColor + "12" : config.theme.list + "12", borderColor: config.theme.list==null ? themeColor: config.theme.list}]}>
    {config.permissions.CanApproveSpots==1 ? <Button style={[styles.approvalButton, {width: "80%"}, approval ? {backgroundColor: theme.colors.greenButton + "99"} : {backgroundColor: theme.colors.redButton + "57"}]} onP={() => {
        setLoading(true) 
        setApproval(!approval)}}>{approval ? <MaterialCommunityIcons name="thumb-up" color={theme.colors.itemTextColor} size={15}/> : <MaterialCommunityIcons name="thumb-up-outline" color={theme.colors.itemTextColor} size={15}/> }<Text style={styles.buttonText}> Approval Mode</Text></Button> : null}
    {!approval ? <Button style={[styles.approvalButton, {width: "80%"}, viewAll ? {backgroundColor: theme.colors.greenButton + "99"} : {backgroundColor: theme.colors.redButton + "57"}]} onP={() => {
        setViewAll(!viewAll)}}>{viewAll ? <MaterialCommunityIcons name="magnify-close" color={theme.colors.itemTextColor} size={15}/> : <MaterialCommunityIcons name="magnify" color={theme.colors.itemTextColor} size={15}/> }<Text style={styles.buttonText}> View All Items</Text></Button> : null}
      <View style={styles.buttonContainer}>
        <Button style={styles.switchButton} onP={() => {setListID(listID-1 < 1 ? 1 : listID-1)}}><MaterialCommunityIcons name="chevron-left" color={theme.colors.itemTextColor} size={28} /></Button>
        <Text style={styles.title}>{title}</Text>
        <Button style={styles.switchButton} onP={() => {setListID(listID+1)}}><MaterialCommunityIcons name="chevron-right" color={theme.colors.itemTextColor} size={28} /></Button>
      </View>
       
        <View style={{borderRadius: 30, borderWidth: 2, height: 30, width: "80%", justifyContent: "center", alignSelf: "center",  borderColor: theme.colors.itemTextColor}}>
        {listPercent<5 ? null :  <View style={{borderRadius: 30, height: 30, backgroundColor: config!=null && config.theme.list!=null ? config.theme.list + "99": themeColor + "77", width: listPercent + "%", borderWidth:2,borderRightWidth:0, borderColor: theme.colors.itemTextColor}}>   
          <Text></Text>     
          </View>}
        </View>
      
    <ScrollView style={{width: "100%"}}
   contentContainerStyle={{width: '100%', paddingBottom: 50}}>
      {/*Todo: Find way to show selected checkable list */}
      {!loading ? null : <ActivityIndicator size={59} color={theme.colors.itemTextColor}/>}
      {complete ? <Text style={styles.welcome}>There are no items in this list.</Text> : items}
      <View style={{marginBottom: 70}}></View>
    </ScrollView>
    <Button onP={() => {togglePrintDialog(!isShowingPrintDialog)}} style={[{zIndex: 500, justifyContent: "center", alignItems: "center", position: "absolute", bottom: "10%", width: 60, height: 60, backgroundColor: theme.colors.listItemBackground, borderRadius: 50, left: "5%", borderColor: "#000", borderWidth: 1}]}><Entypo name='print' color={theme.colors.itemTextColor} size={25}></Entypo></Button>
    {!(config.permissions.CanApproveSpots==1 || editable) ? null : <Button style={styles.addButton} onP={() => {    navigation.navigate("NewSite", {user: user, config: config, refresh: () => {setRefresh(!refreshPage)}, spotID: -1, list: {id: listID, title: title}, id: pageID, hasImage: 0})}} >
        <MaterialCommunityIcons name="plus" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>}
      </View>

      }
      {confetti}
      </View>



    
    </View>
   ) };
