import React, { memo, useEffect, useRef, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/MessengerStyle';
import { Dimensions, Image, KeyboardAvoidingView, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import StatusOverlay from '../components/StatusOverlay'
import { navTo, pickTextColorBasedOnBgColorSimple } from '../core/utils';
import MessageBubble from '../components/MessageBubble';
import TextInput from '../components/TextInput';
import MultiSelect from 'react-native-multiple-select'
import { TriangleColorPicker } from 'react-native-color-picker';



export default function Messenger({ navigation, route })  {
  var user = route.params.user;
var config = route.params.config;
var refresh = route.params.refresh;
var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

var passedGroup = route.params.activeGroup
console.log("pg", passedGroup)


var [colorList, setColorList] = React.useState(JSON.parse(JSON.stringify(theme.colors.activities)).sort(() => Math.random() - 0.5))
function getColor(ind) {
  return colorList[ind % colorList.length]
}

const [userList, setUsers] = React.useState(null)
const [msUserList, setMSUserList] = React.useState([])
const [loading, setLoading] = React.useState(false)

const [messageGroups, setMessageGroups] = React.useState(null)
const [formattedGroupList, setFormattedGroupList] = React.useState(null)

const [activeGroup, setActiveGroup] = React.useState(null)
const [activeGroupMessages, setActiveGroupMessages] = React.useState(null)
const [formattedGroupMessages, setFormattedGroupMessages] = React.useState(null)

const [currentMessage, setCurrentMessage] = React.useState("")
const scrollViewRef = useRef();


const [groupCreation, setGroupCreator] = React.useState(false)

const [groupTitle, setGroupTitle] = React.useState(null)
const [groupMembers, setGroupMembers] = React.useState([user.UID])
const [groupColor, setGroupColor]  = React.useState(null)

const [colorPickerModal, setColorPickerModal] = React.useState(null)


useEffect(() => {
  if(messageGroups!=null && passedGroup!=null)
  {
    setActiveGroup(messageGroups.find((mg) => mg.MGID==passedGroup))
  }
}, [messageGroups])

useEffect(() => {
  console.log("AGM", activeGroupMessages)
  if(activeGroupMessages!=null && userList!=null)
  {
    
    setFormattedGroupMessages(
      activeGroupMessages.map((item, ind) => {
        return(
          <MessageBubble addAvatar={activeGroupMessages[ind+1]==null || activeGroupMessages[ind+1].SenderUID!=item.SenderUID} userList={userList} user={user} item={item}></MessageBubble>
        )
      })
    )
    
  }
  else
  {
    setFormattedGroupList(<Text>No messages</Text>)
  }
  setLoading(false)
}, [activeGroupMessages])

useEffect(() => {
  if(activeGroup!=null)
  {
    setLoading(true)

    getMyGroupsMessages()
  }
}, [activeGroup])

function getUsers() {

  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "GetUsers",
      "camp": user.Home,
      "user": user.UID
      
  })
 })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  setUsers(Object.values(responseJson.records))
  setMSUserList(Object.values(responseJson.records).map((item) => {
    return (
      {name: item.screenName,
      id: item.UID}
    )
      
  }))
 }).finally(() => {
  setLoading(false)
 });

}

function getMyGroupsMessages()
{
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "GetGroupMessages",
      "camp": user.Home,
      "user": user.UID,
      "group": activeGroup.MGID
      
  })
 })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  
  if(responseJson.code==1)
  {
    setActiveGroupMessages((responseJson.records))
  }
  else
  {
    setActiveGroupMessages(null)
  }
 }).finally(() => {
 });

}

function sendMessage()
{
  setLoading(true)
  console.log(JSON.stringify({
    "command": "SendMessage",
    "camp": user.Home,
    "user": user.UID,
    "group": activeGroup.MGID,
    "message": currentMessage,
    "groupName": activeGroup.GroupName,
    "senderName": user.screenName
    
})) 
  if(activeGroup.MGID==-1)
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "CreateGroup",
      "camp": user.Home,
      "user": user.UID,
      "groupName": groupTitle,
      "groupColor": groupColor,
      "groupMembers": groupMembers.join(","),
  })
 })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  console.log(responseJson)
  if(responseJson.code==1)
  {
    setActiveGroup(responseJson.records)
    console.log(JSON.stringify({
      "command": "SendMessage",
      "camp": user.Home,
      "user": user.UID,
      "group": responseJson.records.MGID,
      "message": currentMessage,
      "groupName": responseJson.records.GroupName,
      "senderName": user.screenName
      
  }))
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SendMessage",
        "camp": user.Home,
        "user": user.UID,
        "group": responseJson.records.MGID,
        "message": currentMessage,
        "groupName": responseJson.records.GroupName,
        "senderName": user.screenName
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(responseJson.code==1)
    {
      setActiveGroupMessages((responseJson.records))
      setCurrentMessage("")
    }
   }).finally(() => {
   });
  }
 }).finally(() => {
 });
  }
  else
  {
   
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "SendMessage",
      "camp": user.Home,
      "user": user.UID,
      "group": activeGroup.MGID,
      "message": currentMessage,
      "groupName": activeGroup.GroupName,
      "senderName": user.screenName
      
  })
 })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  if(responseJson.code==1)
  {
    setActiveGroupMessages((responseJson.records))
    setCurrentMessage("")
  }
 }).finally(() => {
 });

}
}

function leaveGroup() {
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "LeaveGroup",
      "camp": user.Home,
      "user": user.UID,
      "group": activeGroup.MGID
      
  })
 })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  setLoading(false)
  console.log((responseJson))
  setActiveGroup(null)
  setFormattedGroupMessages(null)
 }).finally(() => {
 });

}

function getMyMessageGroups() {

  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "GetMyMessageGroups",
      "camp": user.Home,
      "user": user.UID
      
  })
 })
 .then((response) => {
 return(response.json())
})
 .then((responseJson) => {
  setMessageGroups((responseJson.records))
 }).finally(() => {
 });

}

const [tick, setTick] = React.useState(0);

useEffect(() => {
  const timer = setTimeout(() => {
    setTick(tick+1)
    if(activeGroup!=null)
    {
      getMyGroupsMessages();
  }
  else
  {
    getMyMessageGroups();
  }
  }, 1000);
  return () => {
    clearTimeout(timer);
  };
}, [tick]);


  useEffect(() => {
  setLoading(true)
   getUsers()
   getMyMessageGroups()

  }, [])


  useEffect(() => {
    if(userList!=null && messageGroups!=null)
    {
      setLoading(false)
      console.log(messageGroups.length)
      if(messageGroups.length!=undefined && messageGroups.length>0)
      {
        
      setFormattedGroupList(messageGroups.map((item) => {
        return(
          <TouchableOpacity onPress={() => {
            setActiveGroup(item)
          }} style={{backgroundColor: item.Color, width: "100%", marginVertical: 10, display: "flex", flexDirection: "row", alignItems: "center"}}>
            <View style={{justifyContent: "flex-start", padding:20, width: "100%", alignContent: "center", display :"flex", flexDirection: "column", height: "100%"}}>
              <Text numberOfLines={2} style={{color: pickTextColorBasedOnBgColorSimple(item.Color, "#eee", "#222"),  fontFamily: "Carter-One", fontSize: 20}}>{item.GroupName}</Text>
              <Text style={{color: pickTextColorBasedOnBgColorSimple(item.Color, "#eee", "#222"), fontSize: 20, fontFamily: "Glacial-Indifference"}}>{userList!=null && userList.find((u) => u.UID==item.SenderUID)!=null ? userList.find((u) => u.UID==item.SenderUID).screenName : null}: {item.Message}</Text>
            </View>
          </TouchableOpacity>
        )
      }))
    }
    else
    {
      setFormattedGroupList(
              <Text numberOfLines={2} style={{color: pickTextColorBasedOnBgColorSimple(theme.colors.listItemBackground, "#eee", "#222"),  fontFamily: "Carter-One", fontSize: 20, marginTop: 30, marginLeft: 30}}>No Messages</Text>
      )
    }
    }
  }, [userList, messageGroups])

  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer active={pageID} customTheme={config.theme} items={config.tools} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
      </View>
      <Button onP={activeGroup!=null || groupCreation ? () => {
        setActiveGroup(null)
        setGroupCreator(false)
        setFormattedGroupMessages(null)
        }  : () => {navTo("Dashboard", user, config, null, navigation)}} style={styles.backButton} mode="outlined">
      <Ionicons name="return-down-back" style={{width: "100%"}} size={24} color={theme.colors.itemTextColor}></Ionicons>
        </Button>
        {activeGroup!=null && activeGroup.MGID!=-1 && <Button onP={activeGroup!=null || groupCreation ? () => {
        setLoading(true)
        leaveGroup()
        }  : () => {navTo("Dashboard", user, config, null, navigation)}} style={styles.leaveButton} mode="outlined">
      <FontAwesome5 name="door-open" style={{width: "100%"}} size={24} color={theme.colors.itemTextColor}></FontAwesome5>
        </Button>}
        {activeGroup==null && <Button onP={() => {setGroupCreator(!groupCreation)}} style={styles.AddButton} mode="outlined">
      <MaterialCommunityIcons name="plus" style={{width: "100%"}} size={24} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
        </Button>}
        {loading ? <StatusOverlay color={themeColor}></StatusOverlay> : activeGroup==null ? 
        groupCreation ? <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
          
<TextInput
  label={<Text style={{color: theme.colors.itemTextColor}}>Group Name</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  textColor={theme.colors.itemTextColor}
  style={{backgroundColor: theme.colors.listItemBackground, marginTop: 20,color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1, width: "90%"}}
  returnKeyType="next"
  value={groupTitle}
  onChangeText={text => setGroupTitle(text)}
/>
<View style={{width: "100%"}}> 
<MultiSelect
 hideTags
 items={msUserList}
 uniqueKey="id"
 onSelectedItemsChange={(selectedItems) => { 
  console.log(selectedItems)
  setGroupMembers(selectedItems)}}
 selectedItems={groupMembers}
 selectText="Group Members"
 searchInputPlaceholderText="Search Users..."
 onChangeInput={ (text)=> console.log(text)}
 itemTextColor={theme.colors.itemTextColor}
 selectedItemTextColor={theme.colors.greenButton}
 selectedItemIconColor={theme.colors.greenButton}
 styleTextDropdownSelected={{color: theme.colors.itemTextColor}}
 styleTextDropdown={{color: theme.colors.itemTextColor}}
 searchInputStyle={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
 styleSelectorContainer={{height: 300, backgroundColor: theme.colors.listItemBackground}}
 styleMainWrapper={{backgroundColor: theme.colors.listItemBackground}}
 styleListContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleInputGroup={{color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenu={{backgroundColor: theme.colors.listItemBackground}}
 styleIndicator={{backgroundColor: theme.colors.listItemBackground}}
 styleItemsContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenuSubsection={{backgroundColor: theme.colors.listItemBackground}}
 displayKey="name"
 submitButtonColor={groupMembers.length>0 ? theme.colors.greenButton : "#ccc"}
 submitButtonText="Submit"

/></View>
{colorPickerModal!=null ? colorPickerModal
 : <View style={{width: "100%", justifyContent: "center", alignItems: "center", marginTop: 100}}><Button onP={() => {
  setColorPickerModal( 
    
    <TriangleColorPicker
    oldColor={groupColor}
    onColorSelected={color => {
        setGroupColor(color)
        setColorPickerModal(null)
      }
    }
    style={{width: "100%", height: 300}}
  />)
}} style={{backgroundColor: groupColor==null ? theme.colors.redButton : groupColor, borderColor: theme.colors.itemTextColor, borderWidth: 1, marginBottom: 50, width: "60%"}}><Text style={{color: pickTextColorBasedOnBgColorSimple(groupColor==null ? theme.colors.greenButton : groupColor, "#eee", "#222")}}>Select a Color</Text></Button>

   <Button onP={() => {
    if(groupColor!=null&&groupTitle!=null&&groupMembers.length>0)
    {
      setLoading(true)
      setActiveGroup({MGID: -1, GroupName: groupTitle})
    }
}} style={{backgroundColor: groupColor!=null&&groupTitle!=null&&groupMembers.length>0 ? theme.colors.greenButton : theme.colors.gray, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Create</Text></Button>
<Button onP={() => {
  setGroupColor(null)
  setGroupMembers([user.UID])
  setGroupTitle("")
  setGroupCreator(false)
}} style={{backgroundColor: theme.colors.redButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Cancel</Text></Button>
</View>}
  </View> :
      <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
      <ScrollView style={{width: "100%"}} contentContainerStyle={{width: "100%"}}>
      {formattedGroupList}
      <View style={{paddingBottom: 100}}></View>
      </ScrollView>
</View> :

   <View style={[styles.content, {backgroundColor: activeGroup.Color!=null ? activeGroup.Color + "12" : themeColor + "12", borderColor: activeGroup.Color!=null ? activeGroup.Color : themeColor}]}>
<Text numberOfLines={2} style={{color: theme.colors.itemTextColor,  fontFamily: "Carter-One", fontSize: 20}}>{activeGroup.GroupName}</Text>  
   <TextInput
  label={<Text style={{color: theme.colors.itemTextColor}}>Enter Message</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  textColor={theme.colors.itemTextColor}
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="send"
  value={currentMessage}
  onSubmitEditing={(event) => {
    sendMessage()
    console.log(event.nativeEvent.text)
  }}
  onChangeText={text => setCurrentMessage(text)}
/>
    <ScrollView ref={scrollViewRef}
      onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
    automaticallyAdjustKeyboardInsets={true} contentContainerStyle={{display: "flex", flexDirection: "column", width: "100%"}} style={{height: Dimensions.get("screen").height-200, width: "100%"}}>
   {formattedGroupMessages}
   <View style={{paddingBottom: 50}}><Text>  </Text></View>
   </ScrollView>


</View> 
}
    </View>
    </View>
   ) };
