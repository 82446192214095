import React, { memo, useCallback } from 'react';
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import { theme } from '../core/theme';


export default function StatusBar({  style, children, color }) {

 
const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: theme.colors.listItemBackground,
    zIndex: 1000
    
  },
  header: {
    fontSize: 32,
    color: theme.colors.itemTextColor
  },
});


  return(

<View style={styles.container}>
  <ActivityIndicator size={54} color={color==null ? theme.colors.itemTextColor : color} style={[{marginTop: 50}, style]}></ActivityIndicator>
</View>
)
  
}

