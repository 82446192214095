import React, { memo } from 'react';
import { TouchableOpacity, Image, StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';

const Avatar = ({  user, logoStyle, containerStyle, image}) => 
{
  
const styles = StyleSheet.create({
  tinyLogo: {
    width: 70,
    height: 70,
    overflow: "hidden"
  },

outer: {
backgroundColor: theme.colors.listItemBackground,
borderRadius: 40,
overflow: "hidden",
alignItems: "center",
justifyContent: "center"
},
});

return(

<View style={[styles.outer, containerStyle]}>
<Image source={{uri: image==null ? user.profilePicURL : "data:image/png;base64," + image.base64}} style={[styles.tinyLogo, logoStyle]} />
  </View>
)
}

export default memo(Avatar);
