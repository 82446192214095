import { Dimensions, StyleSheet } from 'react-native'
import { theme } from '../core/theme'

export const styles = StyleSheet.create({
    
    fixed: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0   
    },
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
        backgroundColor: theme.colors.listItemBackground
    },
    logoutButton: {
        position: "absolute",
        top: 0,
        width: 20,
        left: 20,
        zIndex: 100,
        borderRadius: 50
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "stretch",
    },

    navDrawer: {
      display: "flex",
      flexDirection: "column",
      flex: 0.1,
      alignItems: "stretch"
    },
    content: {
        borderWidth: 4,
        flexDirection: "column",
        justifyContent: "center",   
        alignItems: "center",    
        paddingTop: 30, 
        flex: 0.9,
        display: "flex"
    },
    backButton: {
      position: "absolute",
      top: 0,
      width: 30,
      left: 20,
      zIndex: 100,
      borderRadius: 50,
      backgroundColor: theme.colors.listItemBackground,
      borderColor: "#000",
      borderWidth: 1
  },
    title: {
      color: theme.colors.itemTextColor,
      fontSize: 22,
      alignSelf: "center"
    }
    
  });