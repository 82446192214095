import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/NewsStyle';
import { Dimensions, Image, Text, View, TouchableOpacity } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {MaterialIcons, FontAwesome5, MaterialCommunityIcons, Ionicons, Entypo} from '@expo/vector-icons';
import { navTo } from '../core/utils';
import Title from '../components/Title';
import { shareAsync } from 'expo-sharing';
import * as ImagePicker from 'expo-image-picker'
import * as Print from 'expo-print'
import TextInput from '../components/TextInput';
import {manipulateAsync} from 'expo-image-manipulator';
import moment from 'moment';
import StatusBar from '../components/StatusOverlay';





export default function NewsCreate({ route, navigation })  {


  
  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;
 
var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

const [flag, setFlag] = React.useState(false)
const [title, setTitle] = React.useState(null)
const [body, setBody] = React.useState(null)
const [loading, setLoading] = React.useState(false)
const [image, setImage] = React.useState(null)

const pickImage = async () => {
  // No permissions request is necessary for launching the image library
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    aspect: [3, 2],
    quality: 1,
  });


  if (result.hasOwnProperty('uri')) {
    const manipulateResult = await manipulateAsync(
         result.uri,
        [],
        { 
          base64: true,
          compress: 0.4 } // from 0 to 1 "1 for best quality"
    );
    setImage(manipulateResult);
  
};
}

function submit() {
  var name = user.Home + "_" + moment().format("YYYY-MM-DD-HH-MM-SS");
  setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "PublishNewsStory",
        "camp": user.Home,
        "user": user.UID,
        "title": title,
        "body": body.split("\"").join("\"\"").split("'").join("''"),
        "imgURL": "https://www.outpostorganizer.com/SITE/Global/News/" + name + ".png",
        "datePosted": moment().format("MM-DD-YYYY")
        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    console.log(responseJson)
    if(image!=null)
    {
    console.log(image.base64)
    fetch('https://www.outpostorganizer.com/SITE/v2/uploadAPI.php', {
        method: 'POST',
        body: JSON.stringify({
        "user": user.UID,
        "camp": user.Home,
        "name": name,
        "image": image.base64,
        "type": "news",
        
        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
    console.log(responseJson)
    }).catch((error) =>
  {
    console.log(error)
    setLoading(false);
  })}
    setLoading(false)
  }).catch((error) =>
  {
    setLoading(false);
  }).finally(() => {
    navTo("back", user, config, refresh, navigation)
  })

}


  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
          </View>
          <TouchableOpacity onPress={() => {

        navTo("back", user, config, refresh, navigation)
          
    
      }} style={styles.backButton} mode="outlined">
      <Ionicons style={{}} name="return-down-back" size={24} color={theme.colors.itemTextColor}></Ionicons>
        </TouchableOpacity>
    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor, alignItems: "center", alignContent: "center"}]}>
       {loading && <StatusBar/>}
          <Title color={theme.colors.itemTextColor}>Create News</Title>
          <ScrollView style={{width: "100%"}}>
     <TextInput
     label={<Text style={{color: theme.colors.itemTextColor}}>Title</Text>}
     theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
     style={{alignSelf: "center", borderRadius: 0, width: "80%", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.itemTextColor, borderWidth: 1}}
     returnKeyType="next"
     errorText={flag && title=="" ? "Title Required" : ""}
     value={title}
     onChangeText={text => setTitle(text)}></TextInput>
     <TextInput
     label={<Text style={{color: theme.colors.itemTextColor}}>Body</Text>}
     theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
     multiline={true}
     style={{alignSelf: "center", width: "80%", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.itemTextColor, borderWidth: 1}}
     returnKeyType="next"
     errorText={flag && body=="" ? "Body Required" : ""}
     value={body}
     onChangeText={text => setBody(text)}></TextInput>
     <View style={{display:"flex", flexDirection: "row", justifyContent: "space-around"}}>
     <Button style={{flex: 0.4, backgroundColor: theme.colors.activities[2]}} onP={() => {pickImage()}}><Text style={{color: theme.colors.itemTextColor}}>{image!=null ? "Update" : "Add"} Image</Text></Button>
     <Button style={{flex: 0.4, backgroundColor: theme.colors.greenButton}} onP={() => {submit()}}><Text style={{color: theme.colors.itemTextColor}}>Submit</Text></Button>
     </View>
     {image!=null ? <Image source={{uri: image.base64==null ? image : "data:image/png;base64," + image.base64}} style={{width: "100%", height: 200}}></Image> : null}
     <View style={{marginBottom: 500}}></View>
     </ScrollView>
       </View>



    
    </View>
    </View>
   ) };
