import React, { memo, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/ReportStyle';
import {MaterialCommunityIcons} from '@expo/vector-icons'
import { Dimensions, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import { navTo, pickTextColorBasedOnBgColorSimple } from '../core/utils';
import {Picker} from '@react-native-picker/picker';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryLine, VictoryArea, VictoryZoomContainer } from "victory";
import OpenAI from 'openai';
import { Table, Row, Rows } from 'react-native-table-component';
import TextInput from '../components/TextInput';
import { TouchableOpacity } from 'react-native';
import { encode as btoa } from 'base-64';
import { StyleSheet } from 'react-native';
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';


const graphOptions = [
  {
    GID: 0,
    Name: "Bar",
    XAxisSources: [
      {
        XID: 0,
        Name: "Users",
        AssociatedTable: "Users",
        DisplayableField: "UID"
      },
      {
        XID: 1,
        Name: "Logged Events by Event Type",
        AssociatedTable: "Log",
        DisplayableField: "EventType"
      },
      {
        XID: 2,
        Name: "Logged Events by User",
        AssociatedTable: "Log",
        DisplayableField: "User"
      },
      {
        XID: 3,
        Name: "Small Groups",
        AssociatedTable: "SmallGroups",
        DisplayableField: "Name"

      },
      {
        XID: 4,
        Name: "Inventory Items",
        AssociatedTable: "Inventory",
        DisplayableField: "itemName, IID"

      },
      {
        XID: 5,
        Name: "Inventory Groups",
        AssociatedTable: "Inventory",
        DisplayableField: "itemName"
      },
      {
        XID: 6,
        Name: "Inventory Categories",
        AssociatedTable: "Inventory",
        DisplayableField: "type"
      },
    ],
  YAxisOptions: [
    {
      YID: 0,
      Name: "Count",
      Column: "Count",
      AssociatedXID: [0,1,2,3,4,5]
    },
    {
      YID: 1,
      Name: "Points",
      Column: "Points",
      AssociatedXID: [0,2]
    },
    {
      YID: 2,
      Name: "Quantity",
      Column: "Quantity",
      AssociatedXID: [3,4,5]
    },
    
  ]

  },
  {
    GID: 1,
    Name: "Line",
    XAxisSources: [
      {
        XID: 0,
        Name: "Audit Log",
        AssociatedTable: "Log",
        DisplayableField: "EventType", 
        SQL: {
          Metrics: "DATE(`TimeStamp`) AS Date, COUNT(*) AS DailyCount, SUM(COUNT(*)) OVER (ORDER BY DATE(`TimeStamp`)) AS CumulativeCount",
          Source: "Log",
          SortKey: "DATE(`TimeStamp`)"
        },
      },
      {
        XID: 1,
        Name: "Calendar",
        AssociatedTable: "Calendar",
        DisplayableField: "Title",
        SQL: {
          Metrics: "Date, COUNT(*) AS DailyCount, SUM(COUNT(*)) OVER (ORDER BY Date) AS CumulativeCount",
          Source: "Calendar",
          SortKey: "Date"
      },
      },
      {
        XID: 2,
        Name: "Errors",
        AssociatedTable: "Errors",
        DisplayableField: "Error",
        SQL: {
          Metrics: "DATE(`Timestamp`) AS Date, COUNT(*) AS DailyCount, SUM(COUNT(*)) OVER (ORDER BY DATE(`Timestamp`)) AS CumulativeCount",
          Source: "Errors",
          SortKey: "DATE(`Timestamp`)"
      },

      },
    ],
  YAxisOptions: [
    {
      YID: 0,
      Name: "Count",
      Column: "Count",
      AssociatedXID: [0,1,2]
    },
    {
      YID: 1,
      Name: "Instances",
      Column: "Instances",
      AssociatedXID: [0,1,2]
    },    
  ]

  }
]

export default function Report({ route, navigation })  {
  var user = route.params.user;
  var config = route.params.config;
  var refresh = route.params.refresh;
  var pageID = route.params.id;  
  var index = config!=null ? config.tools.indexOf(pageID) : 0;
  var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

  const [loading, setLoading] = React.useState(false)
  const [formattedAIResp, setFormattedAIResp] = React.useState(null)
  const [aiResp, setAIResp] = React.useState("")
  //const [aiResp, setAIResp] = React.useState([ { "index": 0, "message": { "role": "assistant", "content": "SELECT Users.UID, Users.screenName, COUNT(Log.LogID) AS ScoreIndex\nFROM Users\nLEFT JOIN Log ON Users.UID = Log.User\nGROUP BY Users.UID, Users.screenName;" }, "finish_reason": "stop" }, { "index": 1, "message": { "role": "assistant", "content": "SELECT Users.UID, Users.screenName, COUNT(Log.LogID) AS ScoreIndex \nFROM Users \nLEFT JOIN Log ON Users.UID = Log.User \nGROUP BY Users.UID;" }, "finish_reason": "stop" }, { "index": 2, "message": { "role": "assistant", "content": "SELECT Users.UID, Users.screenName, COUNT(Log.LogID) as ScoreIndex\nFROM Users\nLEFT JOIN Log ON Users.UID = Log.User\nGROUP BY Users.UID, Users.screenName;" }, "finish_reason": "stop" } ])
  const [currentData, setCurrentData] = React.useState(null)
  const [currentCommand, setCurrentCommand] = React.useState(null)
  const [currentGraph, setCurrentGraph] = React.useState({})
  const [eventTypes, setEventTypes] = React.useState(null)
  const [table, setTable] = React.useState(null)
  

  const [apiKey, setAPIKey] = React.useState(null)
  const [openai, setOpenAI] = React.useState(null)  
  useEffect(() => {
  setOpenAI(new OpenAI({
      dangerouslyAllowBrowser: true,
      apiKey: apiKey,
    }))
  }, [apiKey])
//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
  function runCommand(command)
  {
    console.log({command: command})
    setCurrentCommand(command)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
          method: 'POST',
          body: JSON.stringify({
            "command": "RunAIReport",
            "camp": user.Home,
            "user": user.UID,
            "sqlData": btoa(command),
//            "cond" : current.XAxisConditional=="" ? 1 : current.XAxisConditional,
          })
        })
      .then((response) => {
      return(response.json())
      })
      .then((responseJson) => {
        if(responseJson.code==4)
        {
          console.log(responseJson)
        }
        else
        {
          setCurrentData(responseJson.records)
          console.log(responseJson)
        }
      })

  }

  function runB64Command(command)
  {
    console.log({command: command})
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
          method: 'POST',
          body: JSON.stringify({
            "command": "RunAIReport",
            "camp": user.Home,
            "user": user.UID,
            "sqlData": (command),
//            "cond" : current.XAxisConditional=="" ? 1 : current.XAxisConditional,
          })
        })
      .then((response) => {
      return(response.json())
      })
      .then((responseJson) => {
        if(responseJson.code==4)
        {
          console.log(responseJson)
        }
        else
        {
          setReportData(responseJson.records)
          console.log(responseJson)
        }
      })

  }

  function DeleteReport()
  {
    //console.log({command: command})
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
          method: 'POST',
          body: JSON.stringify({
            "command": "DeleteReport",
            "camp": user.Home,
            "user": user.UID,
            "RID": selectedReport,
//            "cond" : current.XAxisConditional=="" ? 1 : current.XAxisConditional,
          })
        })
      .then((response) => {
      return(response.json())
      })
      .then((responseJson) => {
        if(responseJson.code==4)
        {
          console.log(responseJson)
          setReports(null)
          setReportOptions(<Picker.Item label={"No Reports Found :("} value={-1}></Picker.Item>)
        }
        else
        {
          setReports(responseJson.records)
          setReportOptions([<Picker.Item label={"Select a Report :)"} value={-1}></Picker.Item>, ...responseJson.records.map((report) => {
            return(
              <Picker.Item label={report.ReportName} value={report.RID}></Picker.Item>
            )
          })])
          console.log(responseJson)
        }
      })

  }


  async function getResponse()
  {
    console.log("attempting")
    
    const response = await openai.chat.completions.create({
      model: "gpt-4",
      n: 1,
      messages: [{"role": "system", "content": "You are an AI model in the year " + Date().toString() + " designed to generate SQL queries to aid in creating reports. Responses should ONLY contain the SQL query needed to grab the required information, as the responses will be parsed directly by the system.  Any other freetext will break the system, so please only reply with the SQL query"},
      {"role": "assistant", "content": "Here is the schema for the database: " + table + "\n\nThe primary key of each table is the first column, and are generally referenced in other tables."},
      {"role": "assistant", "content": "Most reports are going to build from the `Log` table, which logs all events on the server.  The EventType is always one of the following: " + eventTypes.join(", ") + "\nThe Raw field is a comma delimited string of all data items that were passed to the server as a part of the request.  And the User field will always be a numerical User ID (UID) for a user "},
        {"role": "user", "content": prompt}],
      //prompt: "I have a sql database called " + user.Home + " with the following schema: " + table + "give me a SQL query to get all users, and the name of the small group where the small group ID matches the user ID",
      temperature: 0.5,
      max_tokens: 3002,
    }).then((r) => {
      console.log("Choices", r.choices)
      setAIResp(r.choices[0].message.content)
    });
    return response
  }
  
var [colorList, setColorList] = React.useState(JSON.parse(JSON.stringify(config.theme.length>0 ? config.theme : theme.colors.activities)).sort(() => Math.random() - 0.5))
function getColor(ind) {
  return colorList[ind % colorList.length]
}

const [formattedCurrentData, setFormattedCurrentData] = React.useState(null)

useEffect(() => {

  if(currentData!=null)
  {
    
const convertedData = currentData.map(item => {
  const valuesArray = Object.values(item);
  return valuesArray;
});

    setFormattedCurrentData(
      <Table borderStyle={{borderWidth: 2, borderColor: '#c8e1ff'}}>
          <Row data={Object.keys(currentData[0])} style={containerStyles.head} textStyle={containerStyles.headText}/>
          <Rows data={convertedData} textStyle={containerStyles.text}/>
</Table>
      )
    
  }

}, [currentData])


const containerStyles = StyleSheet.create({
  container: { flex: 1, padding: 16, paddingTop: 30, backgroundColor: '#fff' },
  head: { justifyContent: "center", alignContent: "center", height: 40, backgroundColor: theme.colors.itemTextColor },
  headText: {alignSelf: "center", fontFamily: "Carter-One", fontWeight: "bold", fontSize: 26, color: theme.colors.listItemBackground},
  text: { fontFamily: "Glacial-Indifference", fontSize: 26, color: theme.colors.itemTextColor}
});

  useEffect(() => {
    if(aiResp!=null)
    {
      var choice = aiResp
    }
    //getResponse()
  }, [aiResp])


  const [showCreation, setShowCreation] = React.useState(null)
  const [prompt, setPrompt] = React.useState("")

  const [reportOptions, setReportOptions] = React.useState(null)
  const [reports, setReports] = React.useState([])
  const [selectedReport, setSelectedReport] = React.useState(-2)


  const [reportData, setReportData] = React.useState(null)
  const [report, setReport] = React.useState(null)

  const [reportTable, setReportTable] = React.useState(null)

  const [users, setUsers] = React.useState(null)
  

  useEffect(() => {
    if(selectedReport>0)
    {
      var current = reports.find((r) => selectedReport==r.RID)
      console.log(current)
      runB64Command(current.Query)

   /*   setLoading(true)
      var current = reports.find((r) => selectedReport==r.RID)
      var cmd = ""
      if(current.GraphType==0)
      {
        if(current.YAxisSource==0)
        {
          cmd = (graphOptions[current.GraphType].XAxisSources[current.XAxisSource].DisplayableField + ",", graphOptions[current.GraphType].YAxisOptions[current.YAxisSource].Column + "(*) AS RowCount", "FROM", graphOptions[current.GraphType].XAxisSources[current.XAxisSource].AssociatedTable,
          "WHERE", current.XAxisConditional=="" ? 1 : current.XAxisConditional, "GROUP BY", graphOptions[current.GraphType].XAxisSources[current.XAxisSource].DisplayableField)
    
        }
        else
        {
          cmd = (graphOptions[current.GraphType].XAxisSources[current.XAxisSource].DisplayableField + ",", graphOptions[current.GraphType].YAxisOptions[current.YAxisSource].Column, "FROM", graphOptions[current.GraphType].XAxisSources[current.XAxisSource].AssociatedTable,
          "WHERE", current.XAxisConditional=="" ? 1 : current.XAxisConditional, "ORDER BY", graphOptions[current.GraphType].YAxisOptions[current.YAxisSource].Column, "DESC")
    
        }
        console.log(current)
        fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
          method: 'POST',
          body: JSON.stringify({
            "command": "RunReport0",
            "camp": user.Home,
            "user": user.UID,
            "isCount": current.YAxisSource==0? "true" : "false",
            "x": graphOptions[current.GraphType].XAxisSources[current.XAxisSource].DisplayableField,
            "y": graphOptions[current.GraphType].YAxisOptions[current.YAxisSource].Column,
            "table": graphOptions[current.GraphType].XAxisSources[current.XAxisSource].AssociatedTable,
            "cond" : current.XAxisConditional=="" ? 1 : current.XAxisConditional,
            "yCond" : current.YAxisConditional=="" ? 1 : current.YAxisConditional
            //"cmd": cmd
          })
        })
      .then((response) => {
      return(response.json())
      })
      .then((responseJson) => {
        if(responseJson.code==4)
        {
          console.log(responseJson)
        }
        else
        {
          console.log(responseJson)
          setReportData(responseJson.records.filter(v => parseInt(v.Y)>0).map((value) => {
            
            var x = current.ConvertID==1&&users!=null&&users.find((u) => u.UID==value.X) ? users.find((u) => u.UID==value.X).screenName : value.X;
            console.log("X", current.ConvertID, users!=null)
            return({
              X: x,
              Y: parseInt(value.Y)
            })
        
          }))
        }
        }).finally(() => {
          setLoading(false)
        })
      }
      else if(current.GraphType==1)
      {
        //For instances: SELECT DATE(TimeStamp) AS LogDate, COUNT(*) AS RowCount FROM Log GROUP BY LogDate ORDER BY LogDate;
        
        var cmd = graphOptions[1].XAxisSources[current.XAxisSource].SQL
        console.log(cmd)
        console.log(current)
        console.log(current.XAxisConditional=="" ? 1 : current.XAxisConditional)
        fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
          method: 'POST',
          body: JSON.stringify({
            "command": "RunReport1",
            "camp": user.Home,
            "user": user.UID,
            "sqlData": cmd,
            "cond" : current.XAxisConditional=="" ? 1 : current.XAxisConditional,
          })
        })
      .then((response) => {
      return(response.json())
      })
      .then((responseJson) => {
        if(responseJson.code==4)
        {
          console.log(responseJson)
        }
        else
        {
          console.log(responseJson)
          setReportData(responseJson.records.map((value) => {
            return({
              X: (value.Date),
              Y: current.YAxisSource==1 ? parseInt(value.DailyCount) : parseInt(value.CumulativeCount)
            })
          }))
          // setReportData(responseJson.records.filter(v => parseInt(v.Y)>0).map((value) => {
          //   return({
          //     X: value.X,
          //     Y: parseInt(value.Y)
          //   })
        
          // }))
        }
        }).finally(() => {
          setLoading(false)
        })
      }
    */
    }
  }, [selectedReport])

  const [reportName, setReportName] = React.useState("")

  function createReport()
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "CreateReport",
        "camp": user.Home,
        "user": user.UID,
        "reportName": reportName,
        "query": btoa(currentCommand),
        "graphType": currentGraph.GraphType,
        "xSource": currentGraph.XAxis,
        "ySource": currentGraph.YAxis 
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(responseJson.code==4)
    {
      buildEmptyReportList()
    }
    else
    {
      console.log(responseJson)
      setUsers(responseJson.users)
      setReports(responseJson.records)
      setReportOptions([<Picker.Item label={"Select a Report :)"} value={-1}></Picker.Item>, ...responseJson.records.map((report) => {
        return(
          <Picker.Item label={report.ReportName} value={report.RID}></Picker.Item>
        )
      })])
    }
    }).finally(() => {
      setLoading(false)
      setCurrentCommand(null)
      setCurrentData(null)
      setCurrentGraph({})
      setFormattedAIResp(null)
      setFormattedCurrentData(null)
      setPrompt(null)
      setShowCreation(false)
    })
  }
  
  useEffect(() => {
    setLoading(true);
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetReports",
        "camp": user.Home,
        "user": user.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(responseJson.code==4)
    {
      buildEmptyReportList()
      setAPIKey(responseJson.apiKey)
    }
    else
    {
      console.log(responseJson)
      setUsers(responseJson.users)
      setAPIKey(responseJson.apiKey)
      setReports(responseJson.records)
      setReportOptions([<Picker.Item label={"Select a Report :)"} value={-1}></Picker.Item>, ...responseJson.records.map((report) => {
        return(
          <Picker.Item label={report.ReportName} value={report.RID}></Picker.Item>
        )
      })])
    }
    })
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetSchema",
        "camp": user.Home,
        "user": user.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(responseJson.code==4)
    {
   }
    else
    {
      const tableColumns = {};

    responseJson.records.forEach((item) => {
      const { TABLE_NAME, COLUMN_NAME } = item;
      if (!tableColumns[TABLE_NAME]) {
        tableColumns[TABLE_NAME] = [];
      }
      tableColumns[TABLE_NAME].push(COLUMN_NAME);
    });
    const lowTokenString = Object.keys(tableColumns).map((tableName) => {
      const columns = tableColumns[tableName].join(', ');
      return `${tableName}: ${columns}`;
    }).join('\n');

      setTable(lowTokenString)
    }
    })
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetEventTypes",
        "camp": user.Home,
        "user": user.UID,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(responseJson.code==4)
    {
    }
    else
    {
      setEventTypes(responseJson.records)
   }
    })
      }, [])

    
  useEffect(() => {
    if(table!=null && eventTypes!=null && reports!=null)
    {
      setLoading(false)
    }
  }, [table, eventTypes, reports])
    
  const cleanTable = (data) => {
    let result = '';
    data.forEach((item, index) => {
      const { table_name, column_name, data_type } = item;
      result += `${table_name}.${column_name} (${data_type})`;
      if (index < data.length - 1) {
        result += ', ';
      }
    });
    return result;
  };

function buildEmptyReportList() 
{
  setReportOptions(<Picker.Item label={"No Reports Found :("} value={-1}></Picker.Item>)
}

const html =  `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
   Brought to you by Outpost
  </body>
</html>
` ;

const printToFile = async () => {
  // On iOS/android prints the given html. On web prints the HTML from the current page.
  const { uri } = await Print.printToFileAsync({html});
  console.log('File has been saved to:', uri);
  await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
};

const selectPrinter = async () => {
  const printer = await Print.selectPrinterAsync(); // iOS only
  setSelectedPrinter(printer);
};
  useEffect(() => {
    if(reportData!=null)
    {
      var currentReportDefinition = reports.find((r) => selectedReport==r.RID)
      console.log(reportData, currentReportDefinition)
      
const convertedData = reportData.map(item => {
  const valuesArray = Object.values(item);
  return valuesArray;})
      setReportTable(
        <Table borderStyle={{borderWidth: 2, borderColor: '#c8e1ff'}}>
          <Row data={Object.keys(reportData[0])} style={containerStyles.head} textStyle={containerStyles.headText}/>
          <Rows data={convertedData} textStyle={containerStyles.text}/>
</Table>
      )
      if(currentReportDefinition.GraphType==0 && currentReportDefinition.XAxisSource!=null && currentReportDefinition.XAxisSource!="")
      {
        setReport(
          <VictoryChart   domainPadding={10}
           containerComponent={<VictoryZoomContainer/>} style={{
            parent: { backgroundColor: "#eee", border: "1px solid #ccc" }
                      }} width={Dimensions.get("screen").width * 0.7} height={300} theme={VictoryTheme.material}>
                    <VictoryBar barWidth={20} data={reportData.map((value) => {
                      return({
                        X: (value[currentReportDefinition.XAxisSource]),
                        Y: parseFloat(value[currentReportDefinition.YAxisSource])
                      })})}
                      
                      style={{
                      data: { fill: themeColor,  stroke: "#000"},
                      labels: { fontSize: 24, fontFamily: "Glacial-Indifference", color: "#000" },
            parent: { border: "1px solid #ccc" }
                      }} x={"X"} y="Y" />
          
   
                              </VictoryChart> 
        )
      }
      else if(currentReportDefinition.GraphType==1 && currentReportDefinition.XAxisSource!=null && currentReportDefinition.XAxisSource!="")
      {
        setReport(
          <VictoryChart containerComponent={<VictoryZoomContainer/>}  style={{
            parent: { backgroundColor: "#eee", border: "1px solid #ccc" }
                      }} width={Dimensions.get("screen").width * 0.7} theme={VictoryTheme.material}>
                    <VictoryLine data={reportData.map((value) => {
                      return({
                        X: (value[currentReportDefinition.XAxisSource]),
                        Y:  parseFloat(value[currentReportDefinition.YAxisSource])
                                            })})}
                      
                      style={{
                      data: { fill: themeColor,  stroke: "#000"},
                      labels: { fontSize: 24, fontFamily: "Glacial-Indifference", color: "#000" },
            parent: { border: "1px solid #ccc" }
                      }} x={"X"} y="Y" />
                        
                    <VictoryAxis style={{
                        axisLabel: { fontSize: 24, fontFamily: "Glacial-Indifference", color: "#000" },
                        tickLabels: {fontSize: 12, fontFamily: "Glacial-Indifference", color: "#000",  },
                    }} /><VictoryAxis  dependentAxis style={{
                        axisLabel: { fontSize: 24, fontFamily: "Glacial-Indifference", color: "#000" },
                        tickLabels: {fontSize: 12, fontFamily: "Glacial-Indifference", color: "#000",  },
                    }} ></VictoryAxis>
                              </VictoryChart> 
        )
      }
      else
      {
        setReport(null)
      }

    }
  }, [reportData])

  
  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer active={pageID} customTheme={config.theme} items={config.tools} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
    </View>

    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
  {showCreation && 
    <View style={{backgroundColor: theme.colors.listItemBackground, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000}}>
    <View style={{backgroundColor: themeColor + "12", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, display: "flex", flexDirection: "row"}}>
      <View style={{width: "50%"}}>
      <TextInput
      textColor={theme.colors.itemTextColor}
      label={<Text style={{color: theme.colors.itemTextColor}}>Prompt</Text>}
      theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
      style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 2}}
      returnKeyType="submit"
      value={prompt}
      onChangeText={text => setPrompt(text)}>
      </TextInput>
      <Button style={{backgroundColor: prompt!=null && prompt!="" ? theme.colors.greenButton : theme.colors.gray, width: "30%"}} onP={() => {if(prompt!=null && prompt!="") {
        getResponse()}
        else {
          console.log(table)
        }}}>
        <Text style={{color: theme.colors.itemTextColor, fontFamily: "Carter-One", width: "30%"}}>Get Data</Text>
      </Button>
      {aiResp!=null && aiResp!="" && 
      
      <View style={{width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
        <TextInput
      textColor={theme.colors.itemTextColor}
      label={<Text style={{color: theme.colors.itemTextColor}}>SQL Call</Text>}
      theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
      style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 2, width: "100%"}}
      returnKeyType="next"
     // returnKeyType="submit"
      value={aiResp}
      onChangeText={text => setAIResp(text)}>
      </TextInput>
      <Button style={{backgroundColor: aiResp!=null && aiResp!="" ? theme.colors.greenButton : theme.colors.gray, width: "100%"}} onP={() => {
        runCommand(aiResp)}}>
        <Text style={{color: theme.colors.itemTextColor, fontFamily: "Carter-One", width: "100%", justifyContent: "center", alignItems: "center", alignContent: "center"}}>Generate Table</Text>
      </Button>
      </View>
      }
      <View style={{height:400}}>
      <ScrollView style={{width: "100%"}}>
      {formattedCurrentData}

      <View style={{paddingBottom: 50}}></View>
      </ScrollView></View>
      {
        currentData!=null && 
        <View style={{width: "100%"}}>
          <Picker
                itemStyle={{ color: theme.colors.itemTextColor,  }}
            style={{backgroundColor: theme.colors.listItemBackground, color:theme.colors.itemTextColor, fontSize: 28, fontFamily: "Glacial-Indifference", borderWidth: 2, borderColor: "#000"}}
            selectedValue={currentGraph.GraphType}
            onValueChange={(itemValue, itemIndex) => {
              console.log(itemValue)
              if(itemValue=="null")
              {
                setCurrentGraph({...currentGraph, GraphType: null})
              }
              setCurrentGraph({...currentGraph, GraphType: itemValue})
            }
            }>
            <Picker.Item label={"Set Graph Type"} value={"null"}></Picker.Item>
              {(graphOptions.map((column) => {
                return(
                  <Picker.Item label={column.Name} value={column.GID}></Picker.Item>
                )
              }))}
              </Picker>

          <Picker
                itemStyle={{ color: theme.colors.itemTextColor,  }}
            style={{backgroundColor: theme.colors.listItemBackground, color:theme.colors.itemTextColor, fontSize: 28, fontFamily: "Glacial-Indifference", borderWidth: 2, borderColor: "#000"}}
            selectedValue={currentGraph.XAxis}
            onValueChange={(itemValue, itemIndex) => {
              console.log("setting current graph x axis to: ", itemValue)
              if(itemValue=="null")
              {
                setCurrentGraph({...currentGraph, XAxis: null})
              }
              setCurrentGraph({...currentGraph, XAxis: itemValue})
            }
            }>
            <Picker.Item label={"Set X Axis Source"} value={"null"}></Picker.Item>
              {Object.keys(currentData[0]).map((column) => {
                return(
                  <Picker.Item label={column} value={column}></Picker.Item>
                )
              })}
              </Picker>
        </View>
      }
            {
        currentData!=null && 
        <View style={{width: "100%"}}>

          <Picker
                itemStyle={{ color: theme.colors.itemTextColor,  }}
            style={{backgroundColor: theme.colors.listItemBackground, color:theme.colors.itemTextColor, fontSize: 28, fontFamily: "Glacial-Indifference", borderWidth: 2, borderColor: "#000"}}
            selectedValue={currentGraph.YAxis}
            onValueChange={(itemValue, itemIndex) => {
              console.log(itemValue)
              if(itemValue=="null")
              {
                setCurrentGraph({...currentGraph, GraphType: null})
              }
              setCurrentGraph({...currentGraph, YAxis: itemValue})
            }
            }>
              <Picker.Item label={"Set Y Axis Source"} value={"null"}></Picker.Item>
              {Object.keys(currentData[0]).map((column) => {
                return(
                  <Picker.Item label={column} value={column}></Picker.Item>
                )
              })}
              </Picker><View style={{display: "flex", flexDirection: "row", width: "100%"}}>

              <Button style={{backgroundColor: (currentData!=null && reportName!="" && aiResp!="") ? theme.colors.greenButton : theme.colors.gray, width: "20%", height: 40, marginRight: 40}} onP={() => {
                if(currentData!=null && reportName!="" && aiResp!="") {
        createReport()}}}>
        <Text style={{color: theme.colors.itemTextColor, fontFamily: "Carter-One", width: "30%"}}>Save Report</Text>
      </Button>             
       <TextInput
      textColor={theme.colors.itemTextColor}
      label={<Text style={{color: theme.colors.itemTextColor}}>Report Name</Text>}
      theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
      style={{width: "50%", backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: "#000", borderWidth: 2, height: 40}}
      returnKeyType="submit"
      value={reportName}
      onChangeText={text => setReportName(text)}>
      </TextInput></View>
        </View>
      }
      </View>
      <View style={{width: "50%"}}>
        {(currentGraph.XAxis!=null && currentGraph.YAxis!=null && currentGraph.GraphType!=null) && currentGraph.GraphType==0 ? 
                    <VictoryChart containerComponent={<VictoryZoomContainer/>} style={{
                      parent: { backgroundColor: "#eee", border: "1px solid #ccc" }
                                }} width={Dimensions.get("screen").width * 0.7} theme={VictoryTheme.material}>
                              <VictoryBar data={currentData.map((value) => {
                                return({
                                  X: (value[currentGraph.XAxis]),
                                  Y: parseFloat(value[currentGraph.YAxis])
                                })})}
                                
                                style={{
                                data: { fill: themeColor,  stroke: "#000"},
                                labels: { fontSize: 24, fontFamily: "Glacial-Indifference", color: "#000" },
                      parent: { border: "1px solid #ccc" }
                                }} x={"X"} y="Y" />
                    
           
                              </VictoryChart> : currentGraph.GraphType==1 ? 
                              
                    <VictoryChart containerComponent={<VictoryZoomContainer/>}  style={{
                      parent: { backgroundColor: "#eee", border: "1px solid #ccc" }
                                }} width={Dimensions.get("screen").width * 0.7} theme={VictoryTheme.material}>
                              <VictoryLine data={currentData.map((value) => {
                                return({
                                  X: (value[currentGraph.XAxis]),
                                  Y: parseFloat(value[currentGraph.YAxis])
                                })})}
                                
                                style={{
                                data: { fill: themeColor,  stroke: "#000"},
                                labels: { fontSize: 24, fontFamily: "Glacial-Indifference", color: "#000" },
                      parent: { border: "1px solid #ccc" }
                                }} x={"X"} y="Y" />
                    
                    <VictoryAxis style={{
                        axisLabel: { fontSize: 24, fontFamily: "Glacial-Indifference", color: "#000" },
                        tickLabels: {fontSize: 12, fontFamily: "Glacial-Indifference", color: "#000",  },
                    }} /><VictoryAxis  dependentAxis style={{
                        axisLabel: { fontSize: 24, fontFamily: "Glacial-Indifference", color: "#000" },
                        tickLabels: {fontSize: 12, fontFamily: "Glacial-Indifference", color: "#000",  },
                    }} ></VictoryAxis>
                              </VictoryChart> : null
        }
      </View>
      </View>
      </View>
  }
      <View style={{display: "flex", flexDirection: "row"}}>
      <Text style={{fontFamily: "Glacial-Indifference", fontSize: 28, color: theme.colors.itemTextColor}}>Report: </Text>
    <Picker
      itemStyle={{ color: theme.colors.itemTextColor,  }}
  style={{backgroundColor: theme.colors.listItemBackground, color:theme.colors.itemTextColor, fontSize: 28, fontFamily: "Glacial-Indifference", borderWidth: 2, borderColor: "#000"}}
  selectedValue={selectedReport}
  onValueChange={(itemValue, itemIndex) => {
    console.log(itemValue)
    setSelectedReport(itemValue)
  }
  }>
    {reportOptions}
    </Picker>
    </View>
    <View
    style={{width: "90%"}}>
      {report!=null && <View style={{width: "100%", height: 400}}>{report}</View>}
      <ScrollView style={{width: "100%", height: report==null ? 600 : 200}}>
      {reportTable}</ScrollView>
      
      {report!=null && 
          <Button style={{backgroundColor: theme.colors.redButton, width: "20%", height: 40, marginRight: 40}} onP={() => {
                DeleteReport()
              }}>
              <Text style={{color: theme.colors.itemTextColor, fontFamily: "Carter-One", width: "30%"}}>Delete Report</Text>
            </Button>      
      }
    </View>
      </View>
    
      </View>
      <Button style={[styles.button2, {zIndex: 120}]} onP={() => {setShowCreation(!showCreation)}}>
        <MaterialCommunityIcons name="plus" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>
      
      <Button style={[styles.button, {zIndex: 120}]} onP={() => {printToFile()}}>
        <MaterialCommunityIcons name="printer" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>


    
    </View>
   ) };
