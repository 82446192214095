import * as React from 'react';
import { memo } from 'react';
import { Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Dashboard from './Dashboard';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Logo from '../assets/logo.svg';
import Score from './Scoreboard-old';
import CalendarPage from './CalendarPage';
import List from './List';
import Profile from './Profile';



const Tab = createBottomTabNavigator();

export default function Wrapper({ route, navigation }) {
 
  return (
    <NavigationContainer>
      <Tab.Navigator initialRouteName='Dashboard' screenOptions={{headerShown: false, tabBarShowLabel: false, tabBarActiveTintColor: '#c1ff9a', tabBarInactiveTintColor: '#c1ff9a', tabBarInactiveBackgroundColor: '#77b7f2', tabBarActiveBackgroundColor: '#3978b1',
      adaptive: 'true',
    }}
    >
        <Tab.Screen name="List" options={{
          tabBarLabel: 'List',
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="view-list" color={color} size={size} />
          ),
        }} children={() => (
          <List
            navigation={navigation}
            user={route.params.user}
            load={true}

          />
        )} />
        <Tab.Screen name="Scoreboard" options={{
          tabBarLabel: 'Scoreboard',
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="chart-bar" color={color} size={size} />
          ),
        }} 
        children={() => (
          <Score
            navigation={navigation}
            user={route.params.user}

          />
        )} />
        <Tab.Screen name="Dashboard" options={{
          tabBarLabel: 'Dashboard',
          
          tabBarIcon: ({ focused, color, size }) => (
        <View style={{position: "absolute", width: "100%", top: 0, bottom: 0, margin: "auto", backgroundColor: "#77b7f2"}}><Text> </Text>
          <View
        style={[focused ? {
          position: 'absolute',
          bottom: 0, // space from bottombar
          height: 82,
          width: 82,
          borderRadius: 58,
          backgroundColor: '#ffffff',
          borderWidth: 4,
          borderColor: "#3978b1",
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 100,
        } : {position: 'absolute',
        bottom: 0, // space from bottombar
          height: 82,
          width: 82,
          borderRadius: 58,
          borderWidth: 1,
        backgroundColor: '#ffffff',
        borderColor: '#77b7f2',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,}]}>
       <Logo width={50} />
      </View></View>
             ),
        }} 
        children={() => (
          <Dashboard
            navigation={navigation}
            user={route.params.user}
          />
        )}
        
        />
        <Tab.Screen name="Calendar" style={{zIndex: 2}} options={{
          tabBarLabel: 'Calendar',
          tabBarIcon: ({ focused, color, size }) => (
            <MaterialCommunityIcons name="calendar-month" color={color} size={size} />
          ),
        }}  children={() => (
          <CalendarPage
            navigation={navigation}
            user={route.params.user}

          />
        )} />
        <Tab.Screen name="Profile" options={{
          tabBarLabel: 'Profile',
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="account-circle" color={color} size={size} />
          ),
        }} children={() => (
          <Profile
            navigation={navigation}
            user={route.params.user}

          />
        )} />
        
      </Tab.Navigator>
    </NavigationContainer>
  ) 
};

