import React, { memo, useState, useEffect } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/CalendarStyle';
import { Dimensions, Image, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import {Calendar, CalendarList, AgendaList} from 'react-native-calendars';
import {CalendarItem} from '../components/CalendarItem';
import { Entypo } from '@expo/vector-icons'; 
import Moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { navTo } from '../core/utils';
import { StyleSheet, Platform } from 'react-native';
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';
import { FlatList, Picker, TouchableOpacity } from 'react-native-web';
import StatusBar from '../components/StatusOverlay';


const html = `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
    <h1 style="font-size: 50px; font-family: Helvetica Neue; font-weight: normal;">
      Hello Expo!
    </h1>
    <img
      src="https://d30j33t1r58ioz.cloudfront.net/static/guides/sdk.png"
      style="width: 90vw;" />
  </body>
</html>
`;

var printStyles = [
  {
    id: 0,
    name: "Group By Day"
  },
  {
    id: 1,
    name: "Group By Category"
  }
]

export default function CalendarPrint({ route, navigation })  {
  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;
  var events = route.params.events;
  var printSettings = route.params.settings;


  const [itemList, setItemList] = React.useState(null)
  const [dotsMapped, setDots] = React.useState(null)
  const [selectedDay, setSelectedDay] = React.useState(null)
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [reload, setReload] = useState(false);

  const [isShowingPrintDialog, togglePrintDialog] = useState(false);

  const [printStyle, setPrintStyle] = useState(0);
  const [printDirection, setPrintDirection] = useState(0);

  const[categories, setCategories] = useState([])
  const[formattedEvent, setFormattedEvent] = useState([])
  var pageID = route.params.id;  
  var index = config!=null ? config.tools.indexOf(pageID) : 0;
  var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];
  
  var eventsInRange = events.filter((item) => Moment(item.Date).isBetween(Moment(printSettings.startDate), Moment(printSettings.endDate), null, '[]'))


console.log(config)
  function signUp(event) {
    navigation.navigate("SignUp", {refresh: refresh, config: config, refresh2: refresh2, user: user, event: event, id: pageID})
  }


  const [selectedPrinter, setSelectedPrinter] = React.useState();

  const print = async () => {
    // On iOS/android prints the given html. On web prints the HTML from the current page.
    await Print.printAsync({
      html,
      printerUrl: selectedPrinter?.url, // iOS only
    });
  };

  const printToFile = async () => {
    // On iOS/android prints the given html. On web prints the HTML from the current page.
    const { uri } = await Print.printToFileAsync({ html });
    console.log('File has been saved to:', uri);
    await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
  };

  const selectPrinter = async () => {
    const printer = await Print.selectPrinterAsync(); // iOS only
    setSelectedPrinter(printer);
  };


  const renderItem = ( item) => {
    if(printSettings.printStyle==0)
    {
      return(
        <View style={{flex: 1}}>
        <Text style={{fontSize: 23, fontFamily: "Carter-One", fontWeight: "bold", textDecorationLine: "underline"}}>
            {Moment(item.id).format("dddd, MMMM Do YYYY")}
          </Text>
          {item.events.sort((a,b) => {
            return Moment(a.Time).isAfter(Moment(b.Time)) ? 1 : -1
          }).map((e) => {
              return(
                <View style={{padding: 10, borderRadius: 5, backgroundColor: e.Workers.length<=1 ? theme.colors.redButton : theme.colors.listItemBackground, marginLeft: 10, marginBottom: 10}}>
                <Text style={{fontSize:16, fontFamily: "Carter-One", fontWeight: "bold"}}>
                  - {e.Title}
                </Text>
                <View style={{marginLeft: 15}}>
                <Text style={{fontSize:14, fontFamily: "Carter-One", fontWeight: "bold", fontStyle: "italic"}}>
                {(categories.find((cat) => cat.CID == e.CID) != null ? categories.find((cat) => cat.CID == e.CID).CategoryName : "Uncategorized")} - {Moment(Moment().format("YYYY-MM-DD")+"T"+e.Time).format("h:mm A")}
                </Text>
                {e.Workers.length>1 ? <Text 
                  style={{fontStyle: "normal"}}>Assigned to: {e.Workers.split("&").join(", ").slice(0, -2)}
                  </Text> : <Text style={{fontWeight: "bold"}}>
                  Unclaimed</Text>}
                <Text style={{fontStyle: "italic"}}>
                  Notes:{e.Details == null || e.Details == "" ? "\n___________\n___________" : e.Details}
                </Text>
                </View>
                </View>
              )
          })}
        </View>
      )
    }
    else
    {
      
    var category = (categories.find((c) => c.CID==item.id));
    if(item.events!=null && item.events.length>0)
  {
    return(
      <View style={{flex: 1}}>
      <Text style={{fontSize: 23, fontFamily: "Carter-One", fontWeight: "bold", textDecorationLine: "underline"}}>
          {category!=null ? category.CategoryName : "Uncategorized"}
        </Text>
        {item.events.map((e) => {
            return(
              <View style={{padding: 10, borderRadius: 5, backgroundColor: e.Workers.length<=1 ? theme.colors.redButton : theme.colors.listItemBackground, marginLeft: 10, marginBottom: 10}}>
              <Text style={{fontSize:16, fontFamily: "Carter-One", fontWeight: "bold"}}>
                - {e.Title}
              </Text>
              <View style={{marginLeft: 15}}>
              <Text style={{fontSize:14, fontFamily: "Carter-One", fontWeight: "bold", fontStyle: "italic"}}>
              {Moment(e.Date).format("dddd, MMM Do")}
              </Text>
                  {e.Workers.length>1 ? <Text 
                  style={{fontStyle: "normal"}}>Assigned to: {e.Workers.split("&").join(", ").slice(0, -2)}
                  </Text> : <Text style={{fontWeight: "bold"}}>
                  Unclaimed</Text>}
                <Text style={{fontStyle: "italic"}}>
                  Notes: {e.Details == null || e.Details == "" ? "\n___________\n___________" : e.Details}
                </Text>
                </View>
              </View>

            )
          })}
      </View>
      )
        }
        
    }
  }

useEffect(() => 
  {
    var categories;
    var result;
    

if(printSettings.printStyle==0)
{
  var eventDates = []
  eventsInRange.map((e) => {
    console.log(e)
    if(!eventDates.includes(e.Date))
    {
      eventDates.push(e.Date)
    }})
  setFormattedEvent(eventDates.map((e) => {
    return(
      {
        id: e,
        events: eventsInRange.filter((event) => event.Date==e).sort((a, b) => {
          if(Moment(a.Date).isBefore(Moment(b.Date)))
          {
            return -1;
          }
          else if(Moment(a.Date).isAfter(Moment(b.Date)))
          {
            return 1;
          }
          else
          {
            return 0;
          }
        })
      }
    )
  }))
  
}
else
{
  var eventTypes = []
  eventsInRange.map((e) => {
    console.log(e)
    if(!eventTypes.includes(e.CID))
    {
      eventTypes.push(e.CID)
    }})
  setFormattedEvent(eventTypes.map((e) => {
    return(
      {
        id: e,
        events: eventsInRange.filter((event) => event.CID==e).sort((a, b) => {
          if(Moment(a.Date).isBefore(Moment(b.Date)))
          {
            return -1;
          }
          else if(Moment(a.Date).isAfter(Moment(b.Date)))
          {
            return 1;
          }
          else
          {
            return 0;
          }
        })
      }
    )
  }))
  
}

}, [])
    

  

  useEffect(() => {
//fetch with command GetCategories
fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
  method: 'POST',
  body: JSON.stringify({
  "command": "GetCategories",
  "user": user.UID,
  "camp": user.Home})
})
.then((response) => response.json())
.then((responseJson) => {
console.log(responseJson)
  setCategories(responseJson.records)
}
      
).finally(() => {

})
  }, [])


  useEffect(() => {
    if(categories!=null && categories.length>0 && formattedEvent!=null)
    {
      setLoading(false)
    }
  }, [])

  return (
    
    
    <View style={styles.container}>
    <View style={styles.body}>
    
<View style={[styles.content, {width: "100%", flex: 1, backgroundColor: themeColor + "12", borderColor: themeColor}]}>
<TouchableOpacity onPress={() => navigation.goBack()} style={{position: "absolute", bottom: 100, left: 10, zIndex: 1}}>
  <Entypo name="arrow-left" size={30} color={themeColor} />
</TouchableOpacity>
{loading && <StatusBar backgroundColor={theme.colors.itemTextColor} barStyle="light-content" />}
{!loading && <View style={{flex: 1}}>
<View style={{flexDirection: "row",
    flexWrap: "wrap",
    flex: 1
}}>
        {formattedEvent.sort((a,b) => {
          if(Moment(a.id).isBefore(Moment(b.id)))
          {
            return -1;
          }
          else if(Moment(a.id).isAfter(Moment(b.id)))
          {
            return 1;
          }
          else
          {
            return 0;
          }
        }).map((e) => {
    return (renderItem(e))
  })}    </View>


      </View>}
</View>
    
      </View>



    
    </View>
   ) };
