import React, { memo, useEffect } from 'react';
import Header from '../components/Header-old';
import Button from '../components/Button';
import Paragraph from '../components/Paragraph';
import { theme } from '../core/theme';
import { styles } from '../styles/ForgotPasswordScreenStyle'
import { View, Text } from 'react-native';
import Background from '../components/Background';
import AsyncStorage from '@react-native-async-storage/async-storage';
import StatusBar from '../components/StatusOverlay';
import * as Network from 'expo-network';

export default function Init({ route, navigation }) {
  console.log("Init.js")
  
  
  const[user,setUser] = React.useState(navigation.params == null ? null : navigation.params.user);
  const[theme, setTheme] = React.useState(20);
  const[loading,setLoading] = React.useState(true)
  const[flag, setFlag] = React.useState(false)
  const[config, setConfig] = React.useState(null)


  async function save(key, value) {
    console.log("Saving " + JSON.stringify(value))
    await AsyncStorage.setItem(key, JSON.stringify(value));
  }
  
  async function getValueFor(key) {
    let result = await AsyncStorage.getItem(key);
    if (result) {
      return result;
    } else {
      return null;
    }
  }
  

  async function attemptUser()
  {
    setLoading(true)
    try {
      var cacheUser = (JSON.parse(await getValueFor("user")))
    } catch (error) {
      cacheUser=null
    }
    if(cacheUser==null)
    {
      navigation.navigate("LoginScreen");
    }
    console.log((cacheUser))
    if(cacheUser!=null)
    {
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({"command": "ValidateLoginFromCache",
      "email": cacheUser.recoveryEmail,
      "pass": cacheUser.password})
   })
   .then((response) => response.json())
   .then((responseJson) => {
    console.log(responseJson)
         if(responseJson.code==1)
         {
            setUser(responseJson.records)
            save("user", responseJson.records)
        }
          else
          {
              navigation.navigate("LoginScreen");
          }
          }
          
   ).finally(() => {
    setLoading(false)
    
   })
    
    }
  }

  useEffect(() => {
    if(user!=null)
    {
      console.log("getting Config")
      getConfig()
    }
  }, [user])

  async function getConfig() {
    console.log("Getting Config") 
    var cacheConfig = JSON.parse(await getValueFor("config"))
    console.log(await getValueFor("configHash"))
    var cacheHash;
    
    try {
      cacheHash = JSON.parse(await getValueFor("configHash"))
    }
    catch {
      cacheHash = (await getValueFor("configHash"))
    }
    console.log(cacheConfig)
    if(cacheConfig==null)
    {
      var status = await Network.getNetworkStateAsync()
      if(status.isInternetReachable)
      {
        pullConfig(cacheConfig)
      }
      else
      {
        setConfig({
          "theme": [],
          "tools": [
          ],
          "pointsAvailable": false,
        })
      }
    }
    else
    {
      var status = await Network.getNetworkStateAsync()

      if(status.isInternetReachable)
      {
        fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({"command": "CheckConfigHash",
      "home": user.Home,
      "user": user.UID,
      "hash": cacheHash})
   })
   .then((response) => response.json())
   .then((responseJson) => {
         if(responseJson.code==1)
         {
            setConfig(cacheConfig)
            //pullConfig()

         }
         else
         {
          save("configHash", responseJson.newHash)
          pullConfig(cacheConfig)
         }
        console.log(responseJson)
          }
          
   ).catch((error) => {
    console.log("Error")
    pullConfig(cacheConfig)
   })
      }
      else
      {
        setConfig(cacheConfig)
      }
    } 
  }
  
  function pullConfig(cacheConfig)
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({"command": "GetConfig",
      "home": user.Home,
      "user": user.UID})
   })
   .then((response) => response.json())
   .then((responseJson) => {
         if(responseJson.code==1)
         {
          console.log("resp:", responseJson)
            var newConfig = responseJson.records
            var newTools = responseJson.activities
            var newAdditionalTools = responseJson.additionalActivities
            var newPermissions = responseJson.permissions
            if(newTools!=null && newTools.length>0)
            {

              newConfig.tools = responseJson.records.tools.concat(newTools.split(",")).map((item) => parseInt(item))
            }
            if(newAdditionalTools!=null && newAdditionalTools.length>0)
            {
            newConfig.additionalTools = responseJson.records.additionalTools.concat(newAdditionalTools)
            newConfig.additionalTools = newConfig.additionalTools.filter((item) => item != null && item != "").map((item) => parseInt(item))
            }
            
            newConfig.permissions = (newPermissions)
            console.log("cache", newConfig.theme.length>0 ? newConfig.theme : cacheConfig.theme)
            console.log(newConfig)
            getValueFor("userTheme").then((userTheme) => {
              if(newConfig.theme.length==0 && JSON.parse(userTheme).value==0 && responseJson.defaultThemeColors!=null && responseJson.defaultThemeColors!="")
              {
                console.log(userTheme.value)
                newConfig.theme = responseJson.defaultThemeColors.split(",")
              }
              else
              {
                newConfig.theme = newConfig.theme.length>0 ? newConfig.theme : cacheConfig.theme
              }
              setConfig(newConfig)
              save("config", newConfig)
            })
          
         
        }
          }
          
   ).catch((error) => {
    console.log("Error, ", error)
   })
  }

  useEffect(() => {
    attemptUser()
  }, [])

  useEffect(() => {

    if(config!=null)
    {
      navigation.navigate("Dashboard", {user: user, config: config})
    }
    
  }, [config])

  useEffect(() => {
    if(flag && user==null)
    {
      navigation.navigate("HomeScreen")
    }
  }, [flag])
  
  return (    <View>
<StatusBar color={"#000"} config={null} style={{marginTop: 200}}></StatusBar>
</View>
    )
};

