import { Dimensions, StyleSheet } from 'react-native'
import { theme } from '../core/theme'

export const styles = StyleSheet.create({
    
    fixed: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0   
    },
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
            flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    },
    forgotPassword: {
      width: '100%',
      alignItems: 'flex-end',
      marginBottom: 24,
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    row: {
      flexDirection: 'row',
      marginTop: 4,
    },
    label: {
      color: "#eee",
    },
    link: {
      fontWeight: 'bold',
      color: "#eee",
    },
    loginBox:
    {
      width: "100%",
      backgroundColor: theme.colors.listItemBackground + "99",
      padding: 15,
      
    },
    loginButton: {
        height: 'auto',
        borderRadius: 20,
        backgroundColor: theme.colors.lightGreen,
        color: '#000000',
        width: '40%'
    },
    loginButtonText: {
      color: "#000"
    },
    loginField: {
        borderRadius: 20,
        color: "#ffffff",
        fontWeight: 'normal'
    }
  });