import { Dimensions, StyleSheet } from 'react-native'
import { theme } from '../core/theme'

export const styles = StyleSheet.create({
    
    fixed: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0   
    },
    container: {
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width,
            flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.medBlue,
    },
    advScrollView: {
      width: Dimensions.get("window").width,
      display: "flex",
      flexDirection: "column",
     // backgroundColor: "#ff0000",
      alignItems: "stretch",
      paddingBottom: 100,
    },
    advScrollViewHeight: {
      height: 10
    },
    ListHeading: {
      justifyContent: 'center',
      alignItems: 'center',
      
      fontSize: 30,
      textDecorationLine: 'underline',
      marginBottom: 20,
      color: theme.colors.darkBlue
    },
    ListPassword: {
      width: '100%',
      alignItems: 'flex-end',
      marginBottom: 24,
      
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    row: {
      flexDirection: 'row',
      marginTop: 4,
      
    },
    label: {
      color: theme.colors.secondary,
      
    },
    link: {
      fontWeight: 'bold',
      color: theme.colors.primary,
      
    },
    profileHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "70%",
      paddingBottom: 5,
    },
    welcome: {
      marginTop: 30,
      color: "#000000",
      flex: 0.1,
      fontSize: 25,
      
    },
    profileSubHeader: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "25%"
    },
    profileUsername: {
      
      fontSize: 36,
      color: "#ffffff"
    },
    profilePoints: {
      
      fontSize: 20,
      color: "#ffffff"
    },
    ListBox: {
      flex: 1,
      width: '100%',
      backgroundColor: "#ffffff",
      padding: 10,
      borderWidth: 1,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTopColor: theme.colors.darkBlue,
      borderBottomWidth: 0,
      
      justifyContent: 'center',
      alignItems: 'center',
      
      
    },
    scrollViewContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      width: "100%"
      
    },
    registerSubmissions: {

    },
    logoutButton: {
        height: 'auto',
        borderRadius: 20,
        backgroundColor: theme.colors.lightGreen,
        color: '#000000',
           
        width: 'auto',
        position: 'absolute',
        right: 10,
        top: 30,
        
    },
    registerField: {
        borderRadius: 20,
        color: "#ff0000",
        
        
    },
    buttonHolder: {
      display: "flex",
      flexDirection: "row",
      width: "70%",
    //  backgroundColor: "#00FF00",
      justifyContent: "center"
    },
    leave: {
      height: 'auto',
      borderRadius: 30,
      backgroundColor: theme.colors.lightGreen,
      color: '#000000',
         
      width: '40%',
      borderColor: "#000000",
      fontSize: 18,
      borderWidth: 2
      
      
  },
  showCompleteButton: {
    height: 'auto',
    borderRadius: 30,
    backgroundColor: theme.colors.lightGreen,
    color: '#000000',
       
    width: '50%',
    borderColor: "#000000",
    fontSize: 15,
    borderWidth: 2,
    marginLeft: 40,
},
hideCompleteButton: {
  display: "none",
  
  
  
},
  advBoxShow: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#ffffff",
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height-200,
    zIndex: 1000,
    borderRadius: 0,
    alignItems: "center",
    elevation: 25,
    justifyContent: "center",
  },
  advBoxHide: {
    display: "none"
  },
  });