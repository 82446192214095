import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, Image, View, Dimensions, ImageBackground, Linking } from 'react-native';
import { styles } from '../styles/SecretStyle';
import { GameEngine} from 'react-native-game-engine';
import entities from '../game/entities';
import Physics from '../game/physics';
import { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TextInput } from 'react-native-paper';
import NavDrawer  from '../components/NavDrawer';
import { theme } from '../core/theme';
import { navTo } from '../core/utils';

export default function Secret({ route, navigation }) {

  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;
  const [running, setRunning] = useState(false)
  const [gameEngine, setGameEngine] = useState(null)
  const [currentPoints, setCurrentPoints] = useState(0)
  const [highScore, setHighScore] = useState(0)
  const [jonah, setJonah] = useState(0)
  const [seKey, setSEKey] = useState("")
  const [globalHS, setGlobalHS] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [game, setGame] = useState(null)
  var pageID = route.params.id;  
  var index = config!=null ? config.tools.indexOf(pageID) : 0;
  var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];
  


  useEffect(() => {
    console.log(config.ScoreboardType)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({"command": "GetScores",
        "camp": user.Home,
        "user": user.UID,
        "config": config.ScoreboardType
        })
     })
  .then((response) => response.json())
  .then((responseJson) => {
    console.log(responseJson.points)
    setData({groups: responseJson.smallGroups, points: responseJson.points});
  } ).catch((exception) => {
    
  })
  }, []);


  useEffect(() => {
    if(data!=null)
    {
      setGame(        <GameEngine
        ref={(ref) => {setGameEngine(ref)}}
        style={{width: "100%"}}
        entities={entities(data)}
        systems={[Physics]}
        running={!running}
        onEvent={(e) => {
          switch(e.type) {
            
          }
        }}
      >
      </GameEngine>
      )
    }
  }, [data])



  return (
   
      <View style={styles.container}>
            <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          console.log("navving to " + navName)
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
          </View>

    <View style={[styles.content, {backgroundColor: themeColor + "dd", borderColor: themeColor}]}>

        {game}
        <StatusBar style="auto" hidden={true}></StatusBar>
      </View>
      </View>
      </View>
    
  );
}

