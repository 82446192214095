import React, { memo, useEffect, useState } from 'react';
import { styles } from '../styles/EntryStyle';
import { Dimensions, ScrollView, Image, Text, TouchableOpacity, View } from 'react-native';
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import StatusOverlay from '../components/StatusOverlay'
import { navTo, pickTextColorBasedOnBgColorSimple } from '../core/utils';
import { activities } from '../core/activities';
import Button from '../components/Button';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import TextInput from '../components/TextInput';
import MultiSelect from 'react-native-multiple-select'
import * as ImagePicker from 'expo-image-picker'
import {manipulateAsync} from 'expo-image-manipulator';
import {Picker} from '@react-native-picker/picker';


export default function FileUpload({ navigation, route })  {
  var user = route.params.user;
var config = route.params.config;
var refresh = route.params.refresh;
var pageID = route.params.id;  
var index = config!=null ? config.tools.filter((p) => {
  var toolSet = activities(config)
  return toolSet.find((t) => t.id==p).isAllowed!="false"
}).indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];

var [colorList, setColorList] = React.useState(JSON.parse(JSON.stringify(config.theme.length>0 ? config.theme : theme.colors.activities)).sort(() => Math.random() - 0.5))
function getColor(ind) {
  return colorList[ind % colorList.length]
}


const [loading, setLoading] = React.useState(false)



const [currentFileName, setCurrentFileName] = React.useState(null)
const [fileDesc, setFileDesc] = React.useState(null)
const [categoryOptions, setCategoryOptions] = React.useState(null)
const [currentFileCategory, setFileCategory] = React.useState(null)
const [newFileCategory, setNewFileCategory] = React.useState(null)

const [image, setImage] = React.useState(null)


  function uploadFile()
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SubmitFile",
        "camp": user.Home,
        "user": user.UID,
        "file": currentFileName,
        "desc": fileDesc,
        "category": currentFileCategory=="NEW" ? newFileCategory : currentFileCategory
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(responseJson.code==1)
    {
        console.log(responseJson)
        fetch('https://www.outpostorganizer.com/SITE/v2/uploadAPI.php', {
        method: 'POST',
        body: JSON.stringify({
        "user": user.UID,
        "camp": user.Home,
        "name": responseJson.generatedName,
        "category": currentFileCategory=="NEW" ? newFileCategory : currentFileCategory,
        "image": image.base64,
        "type": "file",
        
        })
     })
  .then((response) => response.json())
  .then((responseJson2) => {
    console.log("Complete:", responseJson2)}).finally(() => {
      setLoading(false)
      navTo("back", user, config, refresh, navigation)
    });
    }
  })

  }

  const [ doc, setDoc ] = useState();
  const pickDocument = async () => {
      let result = await DocumentPicker.getDocumentAsync({ type: "*/*", copyToCacheDirectory: true }).then(response => {
          if (response.type == 'success') {          
            let { name, size, uri } = response;
            let nameParts = name.split('.');
            let fileType = nameParts[nameParts.length - 1];
            var fileToUpload = {
              name: name,
              size: size,
              uri: uri,
              type: "application/" + fileType
            };
            console.log(fileToUpload, '...............file')
            setDoc(fileToUpload);
          } 
        });
      // console.log(result);
      console.log("Doc: " + doc.uri);
  }


  const pickImageFromLibrary = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [2, 3],
      quality: 1,
    });
  
  
    if (result.hasOwnProperty('uri')) {
      const manipulateResult = await manipulateAsync(
           result.uri,
          [],
          { 
            base64: true,
            compress: 0.4 } // from 0 to 1 "1 for best quality"
      );
      setImage(manipulateResult);
    
  };
  }


  
  const pickImageFromCamera = async () => {

    const permissionResult = await ImagePicker.requestCameraPermissionsAsync()
    if(permissionResult.granted === false)
    {
      alert("This app does not have access to your camera.")
      return;
    }

    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      quality: 1,
    });
  
  
    if (result.hasOwnProperty('uri')) {
      const manipulateResult = await manipulateAsync(
           result.assets[0].uri,
          [],
          { 
            base64: true,
            compress: 0.6 } // from 0 to 1 "1 for best quality"
      );
      setImage(manipulateResult);
    
  };
  }



  function getData() {

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetFileCategories",
        "camp": user.Home,
        "user": user.UID
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
    if(responseJson.code==1)
    {
    setCategoryOptions(responseJson.records)
   }}).finally(() => {
    setLoading(false)
   });

  }
  

  useEffect(() => {
  setLoading(true)
   getData()

  }, [])




  return (
    
    
    <View style={styles.container}>
      {loading && <StatusOverlay></StatusOverlay>}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer active={pageID} customTheme={config.theme} items={config.tools} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
      </View>
      <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
   

        <View style={{position: "absolute", zIndex: 50, top: 0, bottom: 0, left: 0, right: 0, backgroundColor: theme.colors.listItemBackground}}>
               <Text style={{color: theme.colors.itemTextColor, fontSize: 26, alignSelf: "center", marginTop: 20, fontFamily: "Carter-One"}}>Upload File</Text>
            <View style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
               <Button onP={() => {
   pickImageFromCamera()
}} style={{backgroundColor: theme.colors.greenButton + "66", flex: 0.4, borderRadius: 5, borderColor: theme.colors.itemTextColor, borderWidth: 1}}><Text style={{color: theme.colors.itemTextColor}}>Take New Image</Text></Button>
            <Button onP={() => {
   pickImageFromLibrary()
}} style={{backgroundColor: theme.colors.greenButton + "66", borderRadius: 5, borderColor: theme.colors.itemTextColor, borderWidth: 1, flex: 0.4}}><Text style={{color: theme.colors.itemTextColor}}>Select Image</Text></Button>
            </View>
  {
    image!=null && 
    <Image source={{uri: "data:image/png;base64," + image.base64 }} resizeMode={"contain"} style={{alignSelf: "center", width: "90%", height: 200}}></Image>
  }
               <TextInput
  textColor={theme.colors.itemTextColor}
  label={<Text style={{color: theme.colors.itemTextColor}}>File Name</Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="next"
  value={currentFileName}
  onChangeText={text => setCurrentFileName(text)}
/>    
<TextInput
  textColor={theme.colors.itemTextColor}
  label={<Text style={{color: theme.colors.itemTextColor}}>File Description <Text style={{fontStyle: "italic"}}>(Optional)</Text></Text>}
  theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
  returnKeyType="next"
  multiline={true}
  value={fileDesc}
  onChangeText={text => setFileDesc(text)}
/>    

{currentFileCategory!="NEW" ? <Picker
  style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor,   borderWidth: 2, borderColor: "#000"}}
  selectedValue={currentFileCategory}
  onValueChange={(itemValue, itemIndex) =>
    {
    console.log(itemValue)
    setFileCategory(itemValue)
    }
  }>
  {currentFileCategory==null ? <Picker.Item label="Click to Set File Category" value={null} /> : null }
  {categoryOptions==null ? null : categoryOptions.map((sg) => {
    return(
      <Picker.Item label={sg} value={sg}  />
    )
  })}
        <Picker.Item label={"New Category"} value={"NEW"}  />
</Picker> : <TextInput
 textColor={theme.colors.itemTextColor}
 label={<Text style={{color: theme.colors.itemTextColor}}>New File Category</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.listItemBackground, borderWidth: 1}}
 returnKeyType="next"
 value={newFileCategory}
 onChangeText={(text) => {if(!text.includes(" ")) {
  setNewFileCategory(text)}}
  }
>

</TextInput>
}

<Button onP={() => {
   uploadFile()
}} style={{backgroundColor: theme.colors.greenButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Upload</Text></Button>
<Button onP={() => {
  navTo("back", user, config, refresh, navigation)
}} style={{backgroundColor: theme.colors.redButton, borderColor: theme.colors.itemTextColor, borderWidth: 1, width: "70%"}}><Text style={{color: theme.colors.itemTextColor}}>Cancel</Text></Button>

        </View>
    </View>
    </View>
    </View>
   ) };
