import { memo } from 'react';
import { TouchableOpacity, Dimensions, StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import * as React from 'react';
import CheckedBox from '../assets/checkedBox.svg';
import UncheckedBox from '../assets/uncheckedBox.svg';
import {MaterialCommunityIcons, Ionicons} from '@expo/vector-icons'
import { ScrollView } from 'react-native';
import TodayItem from './TodayItem';
import Button from './Button';
import TextInput from './TextInput';
import * as moment from 'moment';

const FeedbackItem = ({  del, feedback, userList}) => {

  function getUsersFor()
  {
    var list = feedback.SubmittedTo.split("&").map((user) => {
      return userList.find((item) => item.id==user).name
    });
    return (list).join(", ")
  }

  const styles = StyleSheet.create({
 container: {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  borderWidth: 2,
  marginBottom: 10,
  padding: 5,
  backgroundColor: theme.colors.listItemBackground,
 },
 topContainer: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
 },
 
 bottomContainer: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end"
 },
 header: {
  fontSize: 16,
  color: theme.colors.itemTextColor,
 },
 submittedBy: {
  fontStyle: "italic",
  fontSize: 16,
  color: theme.colors.itemTextColor,
  marginTop: 15
  
 },
 details: {
  marginLeft: 10,
  color: theme.colors.itemTextColor,
 },
 detailsBold: {
  fontWeight: "bold",
  color: theme.colors.itemTextColor,
},
button: {
  color: theme.colors.itemTextColor,
  margin: 0
},
date: {
  color: theme.colors.itemTextColor,
}

});

return (
  <View style={styles.container}>
 
    
    <View style={styles.topContainer}>
      <Text style={styles.header}>{getUsersFor()}</Text>
      <Text style={styles.date}>{new Date(feedback.date).toDateString()}</Text>
    </View>
      <Text style={styles.details}><Text style={styles.detailsBold}>Details: </Text>{feedback.details}</Text>
      <View style={styles.bottomContainer}>
      <View style={styles.people}><Text style={styles.submittedBy}>Submitted By: {feedback.isAnonymous==0 ? userList.find((item) => item.id==feedback.staffSubmitted).name : "Anonymous"} </Text>
      </View>
      <View style={styles.button}><Button style={{alignSelf: "flex-end", marginBottom: 0, backgroundColor: theme.colors.redButton, borderColor: "#000", borderWidth: 2}} onP={() => {del(feedback.ID)}}><Text style={{color: "#000"}}>Delete</Text></Button></View>
      </View>
    
  </View>
   );
}
export default memo(FeedbackItem);
