import { memo } from 'react';
import { TouchableOpacity, Dimensions, StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import * as React from 'react';

const ProfileModule = ({  adv, title, ...props }) => {

const openWindow = () => {
  
  adv(title);
  }


const [checked, setChecked] = React.useState(false);
const [showSite, setSite] = React.useState(false);

const styles = StyleSheet.create({
  text: {
    fontSize: 22,
    lineHeight: 26,
    color: theme.colors.darkBlue,
    textAlign: 'right',

    flex: 1,
    textAlignVertical: "bottom"
    
  },
  header: {
    fontSize: 30,
    color: theme.colors.darkBlue,
    textAlign: 'left',
    
    flex: 0.5,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    textAlignVertical: "bottom"
  },
  container:
  {
    backgroundColor: theme.colors.lightBlue,
    marginBottom: 20,
    width: '100%',
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: 15,
    borderWidth: 1,
    borderColor: theme.colors.darkBlue,
  },
  
});


return (
 
  <TouchableOpacity activeOpacity={1.0} style={styles.container} onPress={openWindow} >
       <Text style={styles.header}>{title}</Text>
   </TouchableOpacity>
   );
};
export default memo(ProfileModule);
