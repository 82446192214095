import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/GameStyle';
import { Dimensions, Image, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native';
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import { Picker } from '@react-native-picker/picker';
import StatusOverlay from '../components/StatusOverlay'
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import { navTo } from '../core/utils';
import Avatar from '../components/Avatar';



export default function Game({ route, navigation })  {
  var user = route.params.user;
var config = route.params.config;
var refresh = route.params.refresh;
var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];


var colorList = JSON.parse(JSON.stringify(theme.colors.activities)).sort(() => Math.random() - 0.5)
function getColor(ind) {
  return colorList[ind % colorList.length]
}



const [games, setGames] = React.useState(null)
const [userList, setUsers] = React.useState(null)
const [gameCards, setGameCards] = React.useState(null)
const [loading, setLoading] = React.useState(false)


const [showCreation, setShowCreation] = React.useState(false)
const [target, setTarget] = React.useState(-1)

const [activeGame, setActiveGame] = React.useState(null)
const [activeCounter, setActiveCounter] = React.useState(0)
const [opponentScore, setOpponentScore]  =React.useState(0)

const [intervalTimer, setIntervalTimer] = React.useState(null)

const [playing, setPlaying] = React.useState(false)
const [FHS, setFHS] = React.useState({top: 0, left: 0})

const [userScores, setScores] = React.useState({})
const [matches, setMatches] = React.useState({})

const [showLeaderboard, setShowLeaderboard] = React.useState(false)
const [leaderboard, setLeaderboard]  =React.useState(null)
const [leaderboardView, setLeaderboardView] = React.useState(null)



useEffect(() => {
  console.log(userScores, matches)
}, [userScores, matches])

function getTransparency()
{
  var total = 99;
  total = parseInt(total)-parseInt(2*activeCounter-opponentScore)
  if(total<=0)
  {
    return "01"
  }
  else if (total>=100)
  {
    return "99"
  }
  else
  {
  total = total.toString()
  if(total.length==1)
  {
    total = "0" + total
  }
  return total
}
}

async function opponentReady() {
  const response = await fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
    method: 'POST',
    body: JSON.stringify({
      "command": "CheckGameStatus",
      "camp": user.Home,
      "game": activeGame.GID,
  })
})
const responseJson = await response.json()
return(responseJson.status>=3)

}



function findLastMatch()
{
  console.log(target)
  if(games!=null)
  {
  var targetGames = games.filter((g) => g.Receiving==target || g.Sending==target).reverse()
  var ret = null
  targetGames.forEach(targetGame => {
    if(targetGame!=null && targetGame!=undefined && userList!=null)
    {
      ret = ret + "\n" + userList.find((u) => u.UID==targetGame.Sending).screenName + ": " + targetGame.SenderScore + " | " + 
      userList.find((u) => u.UID==targetGame.Receiving).screenName + ": " + targetGame.ReceiverScore
    }
  })
  return ret!=null ? ret.replace("null", "") : ret
}
  return null
}

function updateScore(ac, GID) {
  console.log("ac: ", ac)
  console.log("uipdateGID: ", GID)
  if(ac!=0 && activeGame!=null)
  {
    console.log(user.UID, activeGame.Sending)
  console.log(user.UID==activeGame.Sending ? "isP1: " : "is NOT P1: ", user.screenName)
  
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "UpdateGame",
        "camp": user.Home,
        "isP1": user.UID==activeGame.Sending ? "true" : "false",
        "game": GID,
        "user": user.UID,
        "score": ac
    })
  })
  .then((response) => {
  return(response.json())
 })
  .then((responseJson) => {
   console.log(responseJson)
     setOpponentScore(responseJson.opponentScore)
  });
  }
}

function finalizeScore(ac, GID) {

  console.log("ac: ", ac)
  console.log("gid", GID)
  fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "FinalizeGame",
        "camp": user.Home,
        "isP1": user.UID==activeGame.Sending ? "true" : "false",
        "game": GID,
        "score": ac
    })
  })
  .then((response) => {
  return(response.json())
 })
  .then((responseJson) => {
   console.log(responseJson)
     setActiveCounter(0)
     setPlaying(false)
    // setActiveGame(null)
     setOpponentScore(0)
  });
}

useEffect(() => {
  if(!playing)
  {
    setActiveGame(null)
    getMyGames()
  }
}, [playing])

  function getMyGames()
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetMyGames",
        "camp": user.Home,
        "user": user.UID
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    console.log(responseJson)
      setGames(responseJson.records)
      var scores = {}
      var matches = {}
      responseJson.records.map(game => {
        console.log(game)
        if(user.UID===(game[Object.keys(game)[1]]))
        {
          if(game.Receiving in scores)
          {
            scores[game.Receiving] = game.SenderScore>=game.ReceiverScore ? scores[game.Receiving]+1 : scores[game.Receiving]-1
          }
          else
          {
            scores[game.Receiving] = game.SenderScore>=game.ReceiverScore ? 1 : -1
          }
          if(game.Receiving in matches)
          {
           matches[game.Receiving] = matches[game.Receiving]+1
          }
          else
          {
            matches[game.Receiving] = 1
          }
        }
          if(game.Sending in scores)
          {
            scores[game.Sending] = game.SenderScore>=game.ReceiverScore ? scores[game.Receiving]-1 : scores[game.Receiving]+1
          }
          else
          {
            scores[game.Sending] = game.SenderScore>=game.ReceiverScore ? -1 : 1
          }
          if(game.Sending in matches)
          {
           matches[game.Sending] = matches[game.Sending]+1
          }
          else
          {
            matches[game.Sending] = 1
          }
      })
      setScores(scores)
      setMatches(matches)
      setLoading(false)
   });

  }

  function updateLeaderboard() {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetTopGameScores",
        "camp": user.Home,
        "user": user.UID,        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    setLeaderboard(responseJson.records)
    setLoading(false)
   });

  }

  useEffect(() => {
    if(leaderboard!=null && userList!=null)
    {
      setLeaderboardView(leaderboard.map((leader, i) => {
        return(
       <View style={{width: "100%", padding: 20, display: "flex", flexDirection: "row", textAlignVertical: "center", alignContent: "center"}}><MaterialCommunityIcons name="trophy" size={50} color={i==0 ? "gold" : i==1 ? "silver" : i==2 ? "#960" : theme.colors.itemTextColor}></MaterialCommunityIcons><Text style={{fontFamily: "Carter-One", lineHeight: 60, textAlignVertical: "center", color: theme.colors.itemTextColor, fontSize: 30}}> {userList.find(u => leader.UserID==u.UID).screenName}: {leader.Score} Points{"\n"}</Text>
        </View>)
      }))
    }
  }, [leaderboard, userList])

  function submitRequest() {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SubmitGameRequest",
        "camp": user.Home,
        "userScreenName": user.screenName,
        "user": user.UID,
        "opponent": target
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    setShowCreation(false)
    getMyGames()
   });

  }

  function getUsers() {

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetUsers",
        "camp": user.Home,
        "user": user.UID
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    setUsers(Object.values(responseJson.records))
   });

  }

  useEffect(() => {
    if(activeGame!=null)
    {
      setLoading(true)
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
        method: 'POST',
        body: JSON.stringify({
          "command": "UpdateGameStatus",
          "camp": user.Home,
          "game": activeGame.GID,
          "user": user.UID
      })
    })
    .then((response) => {
    return(response.json())
   })
    .then((responseJson) => {
    });
    const intervalId = setInterval(async () => {
      const ready = await opponentReady()
      console.log(ready)
      if(ready)
      {
        clearInterval(intervalId)
        setLoading(false)
        setPlaying(true)
      } 
    }, 2000); 

    return () => clearInterval(intervalId)
  }
  }, [activeGame])

  

  

  useEffect(() => {

   getMyGames()
   getUsers()

  }, [])

  useEffect(() => {
    //console.log(games.filter(game => game.Status<=3))
    if(games==null || games.filter(game => game.Status<=3).length==0)
    {
      setGameCards(<Text style={{fontSize: 18, fontFamily: "Glacial-Indifference", color: theme.colors.itemTextColor}}>Press the plus button to create a new game!</Text>)
    }
    else
    {
    setGameCards( 
      games.filter((game) => game.Status<3).map((game, ind) => {
        return(
          <TouchableOpacity onPress={() => {
            setActiveGame(game)
          }} style={{backgroundColor: getColor(ind), width: "90%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10}}>
            <View style={{justifyContent: "flex-start", padding:20, width: "100%", alignContent: "center", display :"flex", flexDirection: "column", height: "100%"}}>
          {game.Sending==game.Receiving ? <Text style={{color: theme.colors.itemTextColor, fontSize: 30, fontFamily: "Carter-One"}}>Practice</Text> :
             <Text style={{color: theme.colors.itemTextColor, fontSize: 30, fontFamily: "Carter-One"}}>{userList!=null ? userList.find((u) => u.UID==game.Sending).screenName : null} vs. {userList!=null ? userList.find((u) => u.UID==game.Receiving).screenName : null}</Text>
        }</View>
          </TouchableOpacity>
        )
      })


    )
    }
  }, [games, userList])

  
  return (
    
    
    <View style={styles.container}>
      {loading && <StatusOverlay>{activeGame==null ? null : <Text style={{color: theme.colors.itemTextColor, fontSize: 24, fontFamily: "Glacial-Indifference", alignContent: "center", alignSelf: "center"}}>Waiting for opponent...</Text>}</StatusOverlay>}
    <View style={styles.body}>
    <View style={styles.navDrawer}>
        <NavDrawer active={pageID} customTheme={config.theme} items={config.tools} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
      </View>

      <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
    <Button style={[styles.leaderboardButton]} onP={() => {
      updateLeaderboard()
      setShowLeaderboard(!showLeaderboard)}}>
        <MaterialCommunityIcons name="trophy" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>
        {playing && <View style={{position: "absolute", left: 0, justifyContent: "center", alignSelf: "center", alignContent: "center", alignItems: "center", right: 0, top: 0, bottom: 0, backgroundColor: theme.colors.listItemBackground, zIndex: 1000}}>
        <Text style={{color: theme.colors.itemTextColor, fontSize: 30, fontFamily: "Carter-One"}}>{activeGame.Sending==activeGame.Receiving ? "" : "Opponent:" + opponentScore}</Text>
        <CountdownCircleTimer
        isPlaying
        isSmoothColorTransition
        colorsTime={[18,15,11,5,0]}
        duration={20}
        onUpdate={updateScore(activeCounter, activeGame.GID)}
        onComplete={() => {
          finalizeScore(activeCounter, activeGame.GID)

        }}
        colors={theme.colors.activities}
        ></CountdownCircleTimer>
        <Text style={{color: theme.colors.itemTextColor, fontSize: 30, fontFamily: "Carter-One"}}>{activeCounter}</Text>
        <View style={{width: "100%", backgroundColor: "transparent", position: "absolute", left:0, right:0, bottom: 30, height: Dimensions.get("window").height-60, zIndex: 100}}>

        <TouchableOpacity style={{zIndex: 10000, position: "absolute", top: FHS.top, left: FHS.left}} onPress={() => {
          setFHS({...FHS, top: Math.abs(Math.round(Math.random()*Dimensions.get("window").height-150)), left: Math.abs(Math.round(Math.random()*Dimensions.get("window").width-60))})
          setActiveCounter(activeCounter+1)}}><FontAwesome5 name="frog" size={100-(4*(activeCounter-opponentScore))<=0 ? 2 : 100-(4*(activeCounter-opponentScore))} color={theme.colors.greenButton + getTransparency()}></FontAwesome5>
</TouchableOpacity>
        </View>
    </View>}
    {showCreation && <View style={{position: "absolute", left: 0, justifyContent: "center", alignSelf: "center", alignContent: "center", alignItems: "center", right: 0, top: 0, bottom: 0, backgroundColor: theme.colors.listItemBackground, zIndex: 100}}>
    <Text style={{fontFamily: "Carter-One", fontSize: 26, color: theme.colors.itemTextColor, position: 'absolute', top: 40}}>Choose an Opponent</Text>
    <View style={{width: "100%", justifyContent: "space-around", alignContent: "center", height: 500, alignItems: "center"}}>{target!=-1 && target!=0 && <Avatar logoStyle={{width: 200, height: 200}} containerStyle={{width: 200, height: 200, borderRadius: 110, borderWidth: 2, borderColor: "#000"}} user={userList.find((user) => user.UID==target)}></Avatar>}
    <Picker
  style={{color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground, borderWidth: 2, borderColor: "#000", width: "100%"}}
  selectedValue={target}
  onValueChange={(selectedUser, itemIndex) => {
    setTarget(selectedUser)
    
  }
  }>
    
     <Picker.Item label={"Click Here to Choose an Opponent"} value={0}></Picker.Item>
    
    {userList!=null && userList.map((user) => {
     if(user!=null)
     {
      return(
        <Picker.Item label={user.screenName} value={user.UID}></Picker.Item>
      )
     }
      
    }) }
</Picker>
{target!=-1 && target!=0 && <TouchableOpacity style={{backgroundColor: theme.colors.greenButton, padding: 10, borderRadius: 15, borderWidth: 2}} onPress={() => {submitRequest()}}><Text style={{fontSize: 20, color: theme.colors.itemTextColor}}>{user.UID==target ? "Practice" : "Submit Request"}</Text></TouchableOpacity>}
   {userScores[target]!=null && !target==user.UID && <Text style={{marginTop: 40, fontSize: 22, fontFamily: "Glacial-Indifference", color: theme.colors.itemTextColor}}>Score: {userScores[target]} : {matches[target]-userScores[target]}</Text>
   }
   {findLastMatch() != null && <Text style={{fontSize: 24, color: theme.colors.itemTextColor, marginTop: 15, fontFamily: "Glacial-Indifference"}}>Game History:</Text>}
   <ScrollView style={{flex: 0.2}}><Text style={{fontSize: 24, color: theme.colors.itemTextColor, marginTop: 15, fontFamily: "Glacial-Indifference"}}>{findLastMatch()} </Text>
   </ScrollView></View>
    </View>}


    {showLeaderboard && <View style={{position: "absolute", left: 0, justifyContent: "center", alignSelf: "center", alignContent: "center", alignItems: "center", right: 0, top: 0, bottom: 0, backgroundColor: theme.colors.listItemBackground, zIndex: 400}}>
    <Text style={{fontFamily: "Carter-One", fontSize: 26, color: theme.colors.itemTextColor, position: 'absolute', top: 120}}>Leaderboard</Text>
    <View style={{width: "100%", justifyContent: "space-around", alignContent: "center", height: 500, alignItems: "center"}}>
      {leaderboardView!=null ? <ScrollView contentContainerStyle={{width: "100%"}} style={{width: "100%", display: "flex", height: 400, flexDirection: "row"}}>{leaderboardView}
   </ScrollView> : <Text style={{fontFamily: "Glacial-Indifference", fontSize: 22, color: theme.colors.itemTextColor, position: 'absolute', top: 120}}>No leaderboard found.</Text>
}
   
   </View>
    </View>}



    <ScrollView
   contentContainerStyle={{width: '100%'}}>
    {gameCards!=null ? gameCards : null}
      {/*Todo: Find way to show selected checkable list */}
    </ScrollView>
    <Button style={[styles.button, {zIndex: 120}]} onP={() => {setShowCreation(!showCreation)}}>
        <MaterialCommunityIcons name="plus" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>
      </View>
    
      </View>



    
    </View>
   ) };
