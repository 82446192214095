import React, { memo, useEffect, useState } from 'react';
import Button from '../components/Button';
import { styles } from '../styles/ListStyle';
import { Dimensions, Image, ActivityIndicator, Text, View } from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import ComplexListItem from '../components/ComplexListItem';
import SiteContainer from '../components/SiteContainer';
import Dashboard from './Dashboard';
import ConfettiCannon from 'react-native-confetti-cannon';
import { activities } from '../core/activities';
import { navTo } from '../core/utils';
import MultiSelect from 'react-native-multiple-select';
import { CheckBox, TouchableOpacity } from 'react-native-web';
import PrintComplexListItem from '../components/PrintComplexListItem';
import PrintSiteContainer from '../components/PrintSiteContainer';

export default function ListPrint({ route, navigation })  {

  
    
  var params = route.params.params;
  // {printPriorities: selectedPrintPriorities, printSites: selectedPrintSites},
  var listTitle = route.params.listTitle
  var listSites =  route.params.sites;
  var listItems =  route.params.items;

  var pageID = route.params.id!=null ? route.params.id : 0;  
  var user = route.params.user;
var config = route.params.config;
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];
  var openSite = route.params.openSite;
  var load = false;
  var refresh = route.params.refresh;
  console.log(params)
  const [complete, setComplete] = React.useState(false);
  const [sites,setSites] = React.useState(params.printSites!=null && params.printSites.length>0 ? listSites.filter((site) => params.printSites.includes(site.SiteID)) : listSites)
  const [items, setItems] = React.useState(null)
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = useState(listItems);

  const [approval, setApproval] = useState(false);

  const [title, setTitle] = React.useState(listTitle)
  const [listID, setListID] = React.useState(1)

  const [editable, setEditable] = React.useState(false)
  const [refreshPage, setRefresh] = React.useState(false)

  const [confetti, setConfetti] = React.useState(null)

  const [isShowingPrintDialog, togglePrintDialog] = React.useState(false)

  const [selectedPrintPriorities, setSelectedPrintPriorities] = React.useState([])

  const [selectedPrintSites, setSelectedPrintSites] = React.useState([])


  function calculatePercent(item)
  {
    var splitTasks = item.tasks.split("& ")
    var splitTemp = item.tempTasks.split("& ")
    if(splitTemp.length==1 && splitTemp[0]=="")
    {
      return (100)
    }
    return(
      (
        (splitTasks.length - splitTemp.length)*100)
        /splitTasks.length)
  }

  useEffect(() => {
    
      setItems(sites.map((site) => {
        var itemsRemaining = data.filter((item) => item.SiteID==site.SiteID).length;
        return (
          <PrintSiteContainer single={sites.length==1} open={sites.length==1 || openSite==site.SiteID} itemsRemaining={itemsRemaining} key={site.SiteID} title={site.SiteName}>
            {data.filter((item) => item.SiteID==site.SiteID && params.printPriorities.includes(item.priority)).sort((a,b) => {
              return b.priority - a.priority
            }).map(function(item, index) {

  return (<PrintComplexListItem priority={item.priority} hasImage={item.hasImage==1} config={config} editSite={() => {
    navigation.navigate("NewSite", {user: user, config: config, refresh: () => {setRefresh(!refreshPage)}, spotID: item.SID, list: {id: listID, title: title}, status: item.status, title: item.site_Name, priority: item.priority, tasks: item.tasks, siteID: item.SiteID, assigned: item.siteOwner, id: pageID, hasImage: item.hasImage==1})
  }}isManager={config.permissions.CanCreateSpots==1} SID={item.SID} logs={item.logs} isDenied={item.denied==1} totalTasks={item.tasks} siteOwner={item.siteOwner} site={item.SiteID} key={item.SID} 
  claim={() => {
    setLoading(true)
    claimSite(item.SID)
  }}
  adv={() => {
  setLoading(true)
  joinSite(item)
}}
  progress={calculatePercent(item)} title={item.site_Name} people={item.usersWorking.split("&").length-1} tasks={item.tempTasks.split("& ")}/>);
})}
          </PrintSiteContainer>
        )
      }))
  }, [])




  return (
    
    
    <View style={styles.container}>
      
    <View style={styles.body}>
    <View style={[styles.content, {paddingTop: 10, flex: 1, backgroundColor: "#ffffff", borderColor: config.theme.list==null ? themeColor: config.theme.list}]}>
    <TouchableOpacity onPress={() => navigation.goBack()} style={{position: "absolute", bottom: 100, left: 10, zIndex: 1}}>
  <Entypo name="arrow-left" size={30} color={themeColor} />
</TouchableOpacity>
      <View style={styles.buttonContainer}>
        <Text style={[styles.title, {color: "#000000"}]}>{title}</Text>
      </View>
       
      
      <View style={{flex: 1, width: "100%"}}>
<View style={{flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start"
}}>
  {items}
      </View>


      </View>
      </View>
      </View>



    
    </View>
   ) };
