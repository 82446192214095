import React, { memo, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '../core/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 




export default function SiteContainer({  shouldShow, single, open, itemsRemaining, title, children }) {

  const [showChildren, toggleChildren] = React.useState(open)
 
const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    width: "50%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
  },
  header: {
    fontSize: 25,
    width: "100%",
    color: theme.colors.itemTextColor,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    color: theme.colors.itemTextColor,
  }
});

const [fontsLoaded] = useFonts({
  'Glacial-Indifference-Bold': require('../assets/fonts/glacial-indifference/GlacialIndifference-Bold.otf'),
});

const onLayoutRootView = useCallback(async () => {
  if (fontsLoaded) {
    await SplashScreen.hideAsync();
  }
}, [fontsLoaded]);

if (!fontsLoaded) {
  return null;
}
if(children.length==0 && !shouldShow)
{
  return null
}
return(
<View style={[styles.container, itemsRemaining>0 ? {} : {backgroundColor: theme.colors.greenButton + "93"}]} onLayout={onLayoutRootView}>
  
<TouchableOpacity onPress={() => {
  toggleChildren(!showChildren)
}} style={single ? {display: "none"} : styles.title} >
{itemsRemaining>0 ? !showChildren ? <MaterialCommunityIcons color={theme.colors.itemTextColor} name="chevron-right" size={28} /> : <MaterialCommunityIcons color={theme.colors.itemTextColor} name="chevron-down" size={28}/> : <View style={{marginLeft: 30}} />}<Text style={[styles.header, {fontFamily: "Glacial-Indifference-Bold", color: theme.colors.itemTextColor}]} numberOfLines={1}>{title}</Text>
</TouchableOpacity>
{showChildren ? children : null}
</View>
)
  
}

