import React, { memo, useEffect } from 'react';
import { styles } from '../styles/AnnouncementStyle';

import { Dimensions, Image, TouchableOpacity, Text, View, ActivityIndicator} from 'react-native';
import { ScrollView } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { theme } from '../core/theme';
import NavDrawer  from '../components/NavDrawer';
import Announcement from '../components/Announcement';
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import MultiSelect from 'react-native-multiple-select'
import { navTo } from '../core/utils';



export default function Announcements({ route, navigation })  {
  var user = route.params.user;
var config = route.params.config;
  var refresh = route.params.refresh;
  const [announcements, setAnnouncements] = React.useState(null)
  const [formattedAnnouncements, setFormattedAnnouncements] = React.useState(null)
  const [newAnnouncement, setNew] = React.useState(false);
  const [newShoutout, setShoutout] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [body, setBody] = React.useState("");
  const [security, setSecurity] = React.useState(0);
  const [viewSubmit, setViewSubmit] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [userList, setUserList] = React.useState([])
  const [selectedUsers, setSelectedUsers] = React.useState([])
  const [shoutoutDesc, setShoutoutDesc] = React.useState("")
  const [anonymous, setAnonymous] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [adminsOnly, setAdminsOnly] = React.useState(false)
  var pageID = route.params.id;  
var index = config!=null ? config.tools.indexOf(pageID) : 0;
var themeColor = config.theme.length>index ? config.theme[index] : theme.colors.activities[index];


useEffect(() => {
  setLoading(true);
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetUsers",
        "user": user.UID,
        "camp": user.Home,
      })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      setUserList(Object.values(responseJson.records).map((item) => {
        return (
          {name: item.screenName,
          id: item.UID}
        )
      }));
    }).finally(() => {
      setLoading(false)
    })
    }, [])


  function submitFeedback() {
    if(title==null || body==null || selectedUsers.length==0)
    {
      setFlag(true);
      return;
    }
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "SubmitShoutout",
        "camp": user.Home,
        "user": user.UID,
        "users": selectedUsers.join("&"),
        "shoutout": shoutoutDesc,
        "isAnonymous": anonymous ? 1 : 0
    })
    })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      setLoading(false)
      setShoutoutDesc(null)
      setShoutout(false)
   });


  
}



  function del(AID)
  {
    setLoading(true)
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "DeleteAnnouncement",
        "AID": AID,
        "camp": user.Home,
        "user": user.UID        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetMyAnnouncements",
        "camp": user.Home,
        "user": user.UID
        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
    if(responseJson.code==1)
    {
      setAnnouncements(responseJson.records);
    }
    else if(responseJson.code==4)
    {
      setAnnouncements(null);
    }
   })


   }).finally(() => {
    setLoading(false);
   });

   
  }

  useEffect(() => {
    if(announcements!=null)
    {
      setFormattedAnnouncements(
        announcements.map((a) => {
          return(
            <Announcement config={config} AID={a.AID} onP={del} heading={a.title} key={a.AID}>{a.info}</Announcement>
          )
        })
      )
    }
  }, [announcements])

  function sendAnnouncement() {
    if(title==null || body==null)
    {
      setFlag(true);
      setLoading(false);
      return;
    }
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "CreateAnnouncement",
        "camp": user.Home,
        "title": title,
        "body": body,
        "user": user.screenName,
        "security": adminsOnly ? 1 : 0        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      if(responseJson.code!=5)
    {
      setAdminsOnly(false)
      setNew(false);
      setTitle("");
      setBody("");
      setSecurity(0);
    }
    else
    {
      console.log("Error: ", responseJson)
    }
    }).finally(() => {
      setLoading(false);
    });

  }
  
  function getAnnouncements()
  {
    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetMyAnnouncements",
        "camp": user.Home,
        "user": user.UID,

        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      
      setAnnouncements(responseJson.records);
   });

  }

  useEffect(() => {

    fetch('https://www.outpostorganizer.com/SITE/v2/api.php', {
      method: 'POST',
      body: JSON.stringify({
        "command": "GetMyAnnouncements",
        "camp": user.Home,
        "user": user.UID,

        
    })
   })
   .then((response) => {
   return(response.json())
  })
   .then((responseJson) => {
      
      setAnnouncements(responseJson.records);
   });

  }, [newAnnouncement, newShoutout, loading])

  
  return (
    
    
    <View style={styles.container}>
      {loading ? <View style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 500, backgroundColor: "#0009", paddingTop: 20}}><ActivityIndicator size={60} color={"#eee"}></ActivityIndicator></View> : null}
       <View style={styles.body}>
    <View style={styles.navDrawer}>
    <NavDrawer customTheme={config.theme} items={config.tools} active={pageID} shrink={true} navTo={(navName) => {
          if(refresh==null)
          {
            navTo(navName, user, config, null, navigation)
          }
          else
          {
            navTo(navName, user, config, refresh, navigation)
          }
          }} />
                </View>

    <View style={[styles.content, {backgroundColor: themeColor + "12", borderColor: themeColor}]}>
    {newAnnouncement ? <View style={styles.modal}>
    <Text style={styles.title}>Send Camp Announcement</Text>

    {/*<TextInput
  label="Title"
  returnKeyType="next"
  value={title}
  errorText={flag && title=="" ? "Title Required" : ""}
  onChangeText={text => setTitle(text)}
  style={{width: "90%", marginLeft: 10, borderColor: theme.colors.itemTextColor, borderWidth: 1}}/>*/}

<TextInput
 label={<Text style={{color: theme.colors.itemTextColor}}>Message</Text>}
 theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
 style={{borderColor: theme.colors.itemTextColor, borderWidth: 1, backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
  value={body}
  errorText={flag && body=="" ? "Message Required" : ""}
  onChangeText={text => setBody(text)}
  autoCapitalize="none">
  </TextInput>
{/*
<TextInput
  label="Priority"
  value={security}
  onChangeText={text => setSecurity(text)}
  autoCapitalize="none"
  errorText={flag && (security==0) ? "Priority Value Required" : ""}
  keyboardType="number-pad"
  style={{width: "90%", marginLeft: 10, borderColor: theme.colors.itemTextColor, borderWidth: 1}}/>
*/}

<Button style={styles.submitButton} onP={() => {setViewSubmit(true)}}>
<Text style={styles.label}>Send</Text>
</Button>
<Button style={styles.cancelButton} onP={() => {
  setFlag(false);
  setViewSubmit(false);
  setNew(false)}}>
  <Text style={styles.label}>Cancel</Text>
</Button>
{viewSubmit ? 
            <View style={{marginTop: 20}}>
              <Text style={styles.subTitle}>This announcement will go to {adminsOnly ? "the assigned admins" : "the entire group"}.  Are you sure you would like to send this?</Text>
              <Button onP={() => {
                setLoading(true)
                sendAnnouncement()}} style={[styles.submitButton, {alignSelf: "center"}]}><Text style={{color: "#000"}}>SEND IT!</Text></Button>
              </View>
            : null}
          

    </View> : null}
    {newShoutout ? <View style={styles.modal}>
    
   <Text style={styles.title}>Send Staff Shoutout</Text>
   <View style={{width: "90%"}}>
   <MultiSelect
 hideTags
 items={userList}
 uniqueKey="id"
 onSelectedItemsChange={(selectedItems) => {setSelectedUsers(selectedItems)}}
 selectedItems={selectedUsers}
 selectText="Select Users"
 searchInputPlaceholderText="Search Items..."
 onChangeInput={ (text)=> console.log(text)} itemTextColor={theme.colors.itemTextColor}
 selectedItemTextColor={theme.colors.greenButton}
 selectedItemIconColor={theme.colors.greenButton}
 styleTextDropdownSelected={{color: theme.colors.itemTextColor}}
 styleTextDropdown={{color: theme.colors.itemTextColor}}
 searchInputStyle={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor}}
 styleSelectorContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleMainWrapper={{backgroundColor: theme.colors.listItemBackground}}
 styleListContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleInputGroup={{color: theme.colors.itemTextColor, backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenu={{backgroundColor: theme.colors.listItemBackground}}
 styleIndicator={{backgroundColor: theme.colors.listItemBackground}}
 styleItemsContainer={{backgroundColor: theme.colors.listItemBackground}}
 styleDropdownMenuSubsection={{backgroundColor: theme.colors.listItemBackground}}
 displayKey="name"
 submitButtonColor={theme.colors.greenButton}
 submitButtonText="Submit"
/>
{(selectedUsers.length==0 && flag) ? <Text style={styles.error}>Error.  No users selected.</Text> : null}</View>
   <TextInput
     label={<Text style={{color: theme.colors.itemTextColor}}>Shoutout</Text>}
     theme={{ colors: { text: theme.colors.itemTextColor, primary: theme.colors.itemTextColor } }}
     style={{backgroundColor: theme.colors.listItemBackground, color: theme.colors.itemTextColor, borderColor: theme.colors.itemTextColor, borderWidth: 1}}
     returnKeyType="next"
     errorText={flag && shoutoutDesc=="" ? "Message Required" : ""}
     value={shoutoutDesc}
     onChangeText={text => setShoutoutDesc(text)}></TextInput>
          <View style={{display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}><TouchableOpacity onPress={() => {setAnonymous(!anonymous)}} style={[{width: 40, height: 40, borderWidth: 0, borderRadius: 5}, anonymous ? {backgroundColor: theme.colors.greenButton} : {backgroundColor: theme.colors.redButton}]}><MaterialCommunityIcons color={theme.colors.itemTextColor} name={anonymous ? "checkbox-marked" : "checkbox-marked-outline"} size={40}></MaterialCommunityIcons></TouchableOpacity><Text style={{marginLeft: 10, fontSize: 20, color: theme.colors.itemTextColor}}>Send Anonymously?</Text></View>
     <Button style={styles.submitButton} onP={() => {submitFeedback(true)}}>
<Text style={styles.label}>Send</Text>
</Button>
<Button style={styles.cancelButton} onP={() => {
  setFlag(false);
  setShoutoutDesc(null)
  setShoutout(false)}}>
  <Text style={styles.label}>Cancel</Text>
</Button>
          

    </View> : null}
 
    {config.permissions.CanCreateAnnouncements==1 ? <View style={styles.floatingButton}>
      <Button style={styles.button} onP={() => {setNew(true)}} >
        <MaterialCommunityIcons name="access-point" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>
    </View> : null}
    <View style={config.permissions.CanSendShoutouts==1 ? styles.floatingButton2 : {display: "none"}}>
      <Button style={styles.button} onP={() => {setShoutout(true)}} >
        <MaterialCommunityIcons name="message-star-outline" size={26} color={theme.colors.itemTextColor}></MaterialCommunityIcons>
      </Button>
    </View>
    <ScrollView
   contentContainerStyle={{width: '100%'}}>
    {formattedAnnouncements}
    </ScrollView>
      </View>
    
      </View>

    
    </View>
   ) };


